import { HttpRequest } from 'services/api';
import { APP_CONSTANT, YEAR_ID_DEFAULT } from '_constants/default.constants';
import { CreateReviewApi } from '../constants/createReview.constants';

export class CreateReviewHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListReview = ({ pageId, perPage, courseId, periodId }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${CreateReviewApi.LIST_REVIEW}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}${periodId}`
    );
  };

  public addReview = ({ title, courseId, periodIds }): Promise<any> => {
    return this.request.post(CreateReviewApi.ADD_REVIEW, {
      title: title,
      course_id: courseId,
      period_ids: periodIds
    });
  };

  public deleteReview = ({ id }): Promise<any> => {
    return this.request.delete(`${CreateReviewApi.DELETE_REVIEW}/${id}`);
  };

  public detailReview = (id): Promise<any> => {
    return this.request.get(`${CreateReviewApi.DETAIL_REVIEW}/${id}`);
  };

  public updateReview = ({
    title,
    evaluationId,
    classifications,
    standards,
    delete_classification_ids,
    delete_standard_ids,
    periodId,
    courseId
  }): Promise<any> => {
    return this.request.post(CreateReviewApi.UPDATE_REVIEW, {
      evaluation_id: evaluationId,
      title,
      period_ids: periodId,
      course_id: courseId,
      classifications: classifications,
      standards,
      delete_classification_ids,
      delete_standard_ids
    });
  };

  public listTeacherReview = ({ id, pageId, perPage, statusId, periodId }): Promise<any> => {
    return this.request.post(CreateReviewApi.LIST_REVIEW_TEACHERS, {
      evaluation_id: id,
      page_id: pageId,
      per_page: perPage,
      status_id: statusId,
      period_id: periodId
    });
  };

  public getDataState = (state: any) => {
    return new Promise((resolve, reject) => {
      resolve({
        ok: true,
        data: state
      });
    });
  };

  public getDetailEvaluationByTeacher = ({ evaluationId, userId, periodId }): Promise<any> => {
    return this.request.get(
      `${CreateReviewApi.DETAIL_EVALUATION_BY_TEACHER}/${evaluationId}/${userId}/${periodId}`
    );
  };

  public copyReview = ({ evaluationId, courseId, periodId, titleReview }): Promise<any> => {
    return this.request.post(CreateReviewApi.COPY_REVIEW, {
      evaluation_id: evaluationId,
      course_id: courseId,
      period_ids: periodId,
      title: titleReview
    });
  };
}
