import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { defaultSidebarNavItems, RoutePath } from 'Routes';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import { INIT_PAGE } from '_constants/default.constants';
import Logo from '../assets/images/logo.png';
import _ from 'lodash';
// import LogoMini from '../assets/images/logo_so_mini.png';
import * as FromCommonSlice from '../store/common/shared/slice';
import * as FromAgenciesSoSlice from 'store/ListAgenciesSo/shared/slice';
import ModalViewFilesDetails from '../components/ViewFiles/index';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import useAuth from 'hooks/useAuth';

function Sidebar({ roles }) {
  const localStorage = new LocalStorageService();
  const listAgenciesSoHttp = new ListAgenciesSoHttp();
  // const roles: any = localStorage.getItem(LocalStorageKey.roles);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState<string>('');

  const [nameDropdown, setNameDropdown] = useState<string>('');
  const [arrShowDropdown, setarrShowDropdown] = useState<any>(defaultSidebarNavItems);
  const [valuaIndex, setValueIndex] = useState<any>();
  const [isCheckOpen, setIscheckOpen] = useState<string>('none');
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const [isSelect, setIsSelect] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [isToggleDropdown1, setIsToggleDropdown1] = useState<boolean>(false);
  const [isToggleDropdown2, setIsToggleDropdown2] = useState<boolean>(false);
  const [isToggleDropdown3, setIsToggleDropdown3] = useState<boolean>(false);
  const [department, setDepartment] = useState<any>();
  const [link, setLink] = useState('');
  const { filters, setFilters } = useAuth();

  const [elm, setElm] = useState<object>();

  const location = window.location.pathname;

  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();
  // console.log(localStorage.getItem(LocalStorageKey.infoSchools));
  const [role, setRole] = useState<any>();
  useEffect(() => {
    dispatch(FromCommonSlice.actions.getCourses(INIT_PAGE));
    dispatch(FromCommonSlice.actions.getPeriods(INIT_PAGE));
    // dispatch(FromCommonSlice.actions.getProvinces());
    // dispatch(FromCommonSlice.actions.getDistricts());
    setLink(window.location.pathname);
    setRole(localStorage.getItem(LocalStorageKey.infoSchools));
    loadListUserDepartments();
  }, []);

  useLayoutEffect(() => {
    let elm = document.getElementsByClassName('navbar-menu-wrapper');
    // setHeight(elm[0].clientHeight);
    // console.log(height, 'heighttt');
  });

  const loadListUserDepartments = () => {
    listAgenciesSoHttp.getListAgenciesSoDepartments(filters).then((res) => {
      if (res.ok && res.status === 200) {
        setDepartment(res.data.data.edu_schools);
        dispatch(
          FromAgenciesSoSlice.actions.getListAgenciesSoDepartmentsSuccess(res.data.data.edu_schools)
        );
      }
    });
  };

  // useEffect(() => {
  //   if(arrShowDropdown && arrShowDropdown.length >  0) {
  //    return setarrShowDropdown(arrShowDropdown.map(val => {
  //       val?.children?.map(item => {
  //         console.log(location, 'location ')
  //       })
  //     }))
  //   }

  // }, [location, arrShowDropdown])

  const handleHiddenSidebar = () => {
    document.body.classList.toggle('sidebar-icon-only');
    setIsToggle(!isToggle);
    setIsOpenSidebar(!isOpenSidebar);
    setValueIndex(-1);
  };

  const handleMouseOver = (name: string) => {
    //console.log(name, 'name');
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (body!.classList.contains('sidebar-icon-only')) {
      setHovered(name);
    }
    return;
  };

  const handleMouseOut = () => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (body!.classList.contains('sidebar-icon-only')) {
      setHovered('');
    }
    return;
  };

  const handleRemoveToggleCanvas = (items) => {
    setIsSelect(false);
    setNameDropdown(items);
    const toggleCanvas: any = document.querySelector('.sidebar-offcanvas');
    if (toggleCanvas) {
      toggleCanvas.classList.remove('active');
    }
  };

  const showDropdown = (item, index) => {
    // setIsShowDropdown(!isShowDropdown);
    // const domNav = document.getElementsByClassName(`nav-link`);
    // console.log(domNav);
    // for (let i = 0; i < domNav.length; i++) {
    //   console.log(domNav[i]);
    //   domNav[i].addEventListener('click', (e) => {
    //     let a = e.target;
    //     console.log(a, 'a');
    //   });
    // }
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (body!.classList.contains('sidebar-icon-only')) {
      setValueIndex(-1);
    } else {
      setValueIndex(valuaIndex === index ? -1 : index);
    }
  };

  const selectLink = ({ item }) => {
    setNameDropdown(item);
  };

  const showSelect = (item) => {
    setIsSelect(true);
    setNameDropdown(item);
  };

  const handleChange = (e, vl) => {
    if (vl === 1) {
      setIsToggleDropdown1(!isToggleDropdown1);
    }
    if (vl === 2) {
      setIsToggleDropdown2(!isToggleDropdown2);
    }
    if (vl === 9) {
      setIsToggleDropdown3(!isToggleDropdown3);
    }
  };

  const CustomLink = ({ children, to, name, classDropdown, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    //console.log(match, 'match');
    return (
      <li
        onMouseOver={() => handleMouseOver(name)}
        onMouseOut={handleMouseOut}
        className={`nav-item menu-items  ${classDropdown !== '' ? classDropdown : ''}
        ${hovered === name ? ' hover-open' : ''}`}
        onClick={() => handleRemoveToggleCanvas(children)}>
        <Link to={to} {...props} className={`nav-link ${match ? ' active ' : ''}`}>
          {children}
        </Link>
      </li>
    );
  };

  //var checkClass = [''];
  const handleClickDrop = (item) => {
    setarrShowDropdown(
      arrShowDropdown.map((val) => {
        if (val.id === item.id) {
          return {
            ...val,
            isChecked: !val.isChecked
          };
        } else {
          return {
            ...val
          };
        }
      })
    );

    // const tagContainer = e.currentTarget;
    // const tagUl = tagContainer.getElementsByTagName('ul');
    // //setIscheckOpen(tagUl[0].style.display);
    // if (tagUl[0].style.display === 'none') {
    //   tagUl[0].style.display = 'block';
    //   tagUl[0].classList.add('active');
    // } else {
    //   tagUl[0].style.display = 'none';
    // }
  };
  const CustomLinkDropdownOpen = ({ item, index }) => {
    return (
      <li
        id={item.id}
        onMouseOver={() => handleMouseOver(item.name)}
        onMouseOut={handleMouseOut}
        className={`nav-item dropdown ${item?.isChecked ? ' show__dropdown' : ''}`}>
        <a data-index={index} className={`nav-link`} onClick={() => handleClickDrop(item)}>
          <img src={item.src} alt="" className="w-20 mr-10" />
          <span className="grow">{item.name}</span>
          <i className="fas fa-chevron-left"></i>
        </a>
        <ul className={`flex d-flex-column sub-menu-drop`}>
          {/* <span className="grow">{item.name}</span> */}
          {item.children.map((itm: any, idx) => {
            return (
              <div className={`nav-item menu-items `} key={itm.childrenId.toString()}>
                {/* <span className="grow">{item.name}</span> */}
                <CustomLink
                  className="flex d-flex-column items-center"
                  to={itm.path}
                  key={itm.idx}
                  classDropdown={itm.className}
                  name={itm.name}>
                  <span className="grow">{itm.name}</span>
                </CustomLink>
                {/* <Link
                  to={itm.path}
                  className={`nav-link ${
                    (isSelect && nameDropdown === itm) || (itm.path === link) ? ' active ' : ''
                  }`}
                  onClick={() => showSelect(itm)}>
                  <span className="grow">{itm.name}</span>
                </Link> */}
              </div>
            );
          })}
        </ul>
      </li>
    );
  };

  const CustomLinkDropdownClose = ({ item, index }) => {
    return (
      <li
        onMouseOver={() => handleMouseOver(item.name)}
        onMouseOut={handleMouseOut}
        className={`nav-item dropdown ${
          hovered === item.name ? ' hover-open ' + ' show__menu' : ''
        }`}
        onClick={() => showDropdown(item, index)}>
        <a className="nav-link">
          <img src={item.src} alt="" className="w-20 mr-10" />
          <span className="grow-close">{item.name}</span>
          <i className="fas fa-chevron-left"></i>
        </a>
        <ul className="sub-menu-drop">
          {item.children.map((itm: any, idx) => {
            return (
              <li
                onClick={() => selectLink({ item })}
                className={`nav-item-close`}
                key={itm.childrenId.toString()}>
                {/* <span className="grow">{item.name}</span> */}
                <Link to={itm.path} className="nav-link-close">
                  <span className="grow">{itm.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    );
  };

  const CustomLinkDropdown = ({ item, index }) => {
    return (
      <>
        {/* <li
          onMouseOver={() => handleMouseOver(item.name)}
          onMouseOut={handleMouseOut}
          className={`nav-item dropdown ${valuaIndex === index ? ' show__dropdown ' : ''} ${
            hovered === item.name ? ' hover-open ' + ' show__menu' : ''
          }`}
          onClick={() => showDropdown(item, index)}>
          {isOpenSidebar && <CustomLinkDropdownOpen item={item} index={index} />}
        </li> */}
        {isOpenSidebar && <CustomLinkDropdownOpen item={item} index={index} />}
        {/* <li
          onMouseOver={() => handleMouseOver(item.name)}
          onMouseOut={handleMouseOut}
          className={`nav-item dropdown ${valuaIndex === index ? ' show__dropdown ' : ''} ${
            hovered === item.name ? ' hover-open ' + ' show__menu' : ''
          }`}
          onClick={() => showDropdown(item, index)}>
          {!isOpenSidebar && <CustomLinkDropdownClose item={item} index={index} />}
        </li> */}
        {!isOpenSidebar && <CustomLinkDropdownClose item={item} index={index} />}
      </>
    );
  };

  const renderSideBar = () => {
    if (roles?.roles) {
      return arrShowDropdown?.map((item, index) => {
        if (roles?.roles?.school) {
          if (roles?.is_vp_so) {
            if (
              item.path === RoutePath.DASHBOARD ||
              item.path === RoutePath.MANAGER_LIST_REVIEW ||
              item.path === RoutePath.MANAGER_TEACHER_REVIEW ||
              item.path === RoutePath.LIST_EXPERT ||
              item.path === RoutePath.LIST_ACHIEVAMENT_SCHOOL ||
              item.path === RoutePath.LIST_COMPETITION ||
              //item.path === RoutePath.LIBRARY_DOE ||
              item.path === RoutePath.SUPPORT ||
              item.path === RoutePath.SELF_ASSESSMENT_COMPETITION_DEPARTMENT ||
              item.path === RoutePath.COMPETITION_RATING_DEPARTMENT
            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
          } else if (roles?.schools[0]?.is_pgd && roles?.schools[0]?.is_admin_school) {
            if (
              item.path === RoutePath.LIST_EXPERT ||
              // item.path === RoutePath.LIBRARY_DOE ||
              item.path === RoutePath.SUPPORT ||
              item.path === RoutePath.DECENTRALIZED_MANAGEMENT ||
              item.path === RoutePath.LIST_BROWSE_THE_COMPETITION_PGDQ3 ||
              item.path === RoutePath.LIST_INTITIATIVE_MARK_PGD ||
              // item.path === RoutePath.SELF_ASSESSMENT_COMPETITION_PGD ||
              item.path === RoutePath.COMPETITION_RATING_PGD
            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
            // check role trường thuộc pgd quận 3
          } else {
            if (!roles?.schools[0]?.is_pgd && roles?.schools[0]?.is_admin_school) { // admin trường vào đây
              if (
                item.path === RoutePath.LIST_TEACHER ||
                item.path === RoutePath.LIST_COMPETITION_SCHOOL_PGDQ ||
                item.path === RoutePath.SUPPORT ||
                item.path === RoutePath.MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN_PGDQ ||
                item.path === RoutePath.SELF_ASSESSMENT_COMPETITION_SHOOL_PGD ||
                item.path === RoutePath.MANAGER_LIST_REVIEW // mở lại cho pgd: 2024-06-14
              )
                return item.className === '' ? (
                  <React.Fragment key={index}>
                    <CustomLink
                      className="flex d-flex-column items-center"
                      to={item.path}
                      key={index}
                      classDropdown={item.className}
                      name={item.name}>
                      <img src={item.src} alt="" className="w-20 mr-10" />
                      <span className="grow">{item.name}</span>
                    </CustomLink>
                  </React.Fragment>
                ) : (
                  <CustomLinkDropdown item={item} key={index} index={index} />
                );
            } else {
              if (
                item.path === RoutePath.DASHBOARD ||
                item.path === RoutePath.MANAGER_LIST_REVIEW ||
                item.path === RoutePath.MANAGER_TEACHER_REVIEW ||
                item.path === RoutePath.LIST_TEACHER ||
                item.path === RoutePath.LIST_COMPETITION ||
                //item.path === RoutePath.LIBRARY_DOE ||
                item.path === RoutePath.SUPPORT ||
                item.path === RoutePath.DECENTRALIZED_MANAGEMENT ||
                item.path === RoutePath.SELF_ASSESSMENT_COMPETITION_SHOOL ||
                item.path === RoutePath.MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN ||
                item.path === RoutePath.RESULT_COMPETITION_END_YEAR_SCHOOL ||
                item.path === RoutePath.SUMMARY_COMPETITION_END_YEAR
              )
                return item.className === '' ? (
                  <React.Fragment key={index}>
                    <CustomLink
                      className="flex d-flex-column items-center"
                      to={item.path}
                      key={index}
                      classDropdown={item.className}
                      name={item.name}>
                      <img src={item.src} alt="" className="w-20 mr-10" />
                      <span className="grow">{item.name}</span>
                    </CustomLink>
                  </React.Fragment>
                ) : (
                  <CustomLinkDropdown item={item} key={index} index={index} />
                );
            }
          }
        } else if (roles?.roles?.teacher) {
          // if (
          //   item.path === RoutePath.PRO_FILE_USER ||
          //   item.path === RoutePath.EMULATION_TITLE ||
          //   item.path === RoutePath.INTITIATIVE_ACHIEVAMENT ||
          //   item.path === RoutePath.EMULATION_AND_REWARD_Q3 ||
          //   item.path === RoutePath.SUPPORT
          // )
          //   return item.className === '' ? (
          //     <React.Fragment key={index}>
          //       <CustomLink
          //         className="flex d-flex-column items-center"
          //         to={item.path}
          //         key={index}
          //         classDropdown={item.className}
          //         name={item.name}>
          //         <img src={item.src} alt="" className="w-20 mr-10" />
          //         <span className="grow">{item.name}</span>
          //       </CustomLink>
          //     </React.Fragment>
          //   ) : (
          //     <CustomLinkDropdown item={item} key={index} index={index} />
          //   );
          // giáo viên các trường thuộc pgd quận 3, sau có api thì check chỗ này
          const isVPSo = department?.filter((item) => item.id === roles?.schools[0]?.school_id);
          if (roles?.schools[0]?.is_vp_so) {
            if (
              item.path === RoutePath.MANAGER_SELF_ASSESSMENT ||
              item.path === RoutePath.LIST_OF_EVALUATION_RESULTS ||
              item.path === RoutePath.PRO_FILE_USER ||
              item.path === RoutePath.EMULATION_TITLE ||
              item.path === RoutePath.INTITIATIVE_ACHIEVAMENT ||
              //item.path === RoutePath.LIBRARY_DOE ||
              item.path === RoutePath.EMULATION_AND_REWARD ||
              item.path === RoutePath.SELF_ASSESSMENT_COMPETITION ||
              item.path === RoutePath.SUPPORT ||
              item.path === RoutePath.GIVE_POINTS_INITIATIVE ||
              item.path === RoutePath.MARK_RATING_SO
            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
          } else if (roles?.schools[0]?.is_pgd) {
            if (
              item.path === RoutePath.MANAGER_SELF_ASSESSMENT ||
              item.path === RoutePath.LIST_OF_EVALUATION_RESULTS ||
              item.path === RoutePath.PRO_FILE_USER ||
              item.path === RoutePath.EMULATION_TITLE ||
              item.path === RoutePath.INTITIATIVE_ACHIEVAMENT ||
              //item.path === RoutePath.LIBRARY_DOE ||
              item.path === RoutePath.EMULATION_AND_REWARD ||
              item.path === RoutePath.SELF_ASSESSMENT_COMPETITION ||
              item.path === RoutePath.SUPPORT ||
              item.path === RoutePath.GIVE_POINTS_INITIATIVE ||
              item.path === RoutePath.MARK_RATING_PGD
            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
          } else if (!roles?.schools[0]?.is_pgd) {
            if ( // tk cá nhân giáo viên trường
              item.path === RoutePath.PRO_FILE_USER ||
              item.path === RoutePath.EMULATION_TITLE ||
              item.path === RoutePath.INTITIATIVE_ACHIEVAMENT ||
              item.path === RoutePath.EMULATION_AND_REWARD_Q3 ||
              item.path === RoutePath.SUPPORT || 
              item.path === RoutePath.SELF_ASSESSMENT_COMPETITION

            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
          } else {
            if (
              item.path === RoutePath.MANAGER_SELF_ASSESSMENT ||
              item.path === RoutePath.LIST_OF_EVALUATION_RESULTS ||
              item.path === RoutePath.PRO_FILE_USER ||
              item.path === RoutePath.EMULATION_TITLE ||
              item.path === RoutePath.INTITIATIVE_ACHIEVAMENT ||
              //item.path === RoutePath.LIBRARY_DOE ||
              item.path === RoutePath.EMULATION_AND_REWARD ||
              item.path === RoutePath.SELF_ASSESSMENT_COMPETITION ||
              item.path === RoutePath.SUPPORT ||
              item.path === RoutePath.GIVE_POINTS_INITIATIVE
            )
              return item.className === '' ? (
                <React.Fragment key={index}>
                  <CustomLink
                    className="flex d-flex-column items-center"
                    to={item.path}
                    key={index}
                    classDropdown={item.className}
                    name={item.name}>
                    <img src={item.src} alt="" className="w-20 mr-10" />
                    <span className="grow">{item.name}</span>
                  </CustomLink>
                </React.Fragment>
              ) : (
                <CustomLinkDropdown item={item} key={index} index={index} />
              );
          }
        } else if (roles?.roles?.sgd) {
          // if (!roles?.schools[0]?.is_admin_school) {
          //   if (item.path === RoutePath.MARK_RATING_SO)
          //     return item.className === '' ? (
          //       <React.Fragment key={index}>
          //         <CustomLink
          //           className="flex d-flex-column items-center"
          //           to={item.path}
          //           key={index}
          //           classDropdown={item.className}
          //           name={item.name}>
          //           <img src={item.src} alt="" className="w-20 mr-10" />
          //           <span className="grow">{item.name}</span>
          //         </CustomLink>
          //       </React.Fragment>
          //     ) : (
          //       <CustomLinkDropdown item={item} key={index} index={index} />
          //     );
          // } else {
          //   if (
          //     item.path === RoutePath.LIST_COLLECTIVE_COMPETITION ||
          //     item.path === RoutePath.LIST_SCHOOL_SO ||
          //     item.path === RoutePath.LIST_ACHIEVAMENT_SO ||
          //     item.path === RoutePath.LIST_BROWSE_THE_COMPETITION ||
          //     item.path === RoutePath.COMPETITION_RATING_SO ||
          //     item.path === RoutePath.LIST_AGENCIES_SO ||
          //     // item.path === RoutePath.COMPETITION_RATING ||
          //     item.path === RoutePath.DECISION_LEVEL ||
          //     item.path === RoutePath.LIBRARY_DOE
          //   )
          //     return item.className === '' ? (
          //       <React.Fragment key={index}>
          //         <CustomLink
          //           className="flex d-flex-column items-center"
          //           to={item.path}
          //           key={index}
          //           classDropdown={item.className}
          //           name={item.name}>
          //           <img src={item.src} alt="" className="w-20 mr-10" />
          //           <span className="grow">{item.name}</span>
          //         </CustomLink>
          //       </React.Fragment>
          //     ) : (
          //       <CustomLinkDropdown item={item} key={index} index={index} />
          //     );
          // }
          if (
            //item.path === RoutePath.LIST_COLLECTIVE_COMPETITION ||
            item.path === RoutePath.LIST_SCHOOL_SO ||
            item.path === RoutePath.LIST_ACHIEVAMENT_SO ||
            item.path === RoutePath.LIST_BROWSE_THE_COMPETITION ||
            item.path === RoutePath.COMPETITION_RATING_SO ||
            item.path === RoutePath.LIST_INTITIATIVE_MARK_SO ||
            item.path === RoutePath.LIST_AGENCIES_SO ||
            item.path === RoutePath.DECENTRALIZED_MANAGEMENT_SO ||
            // item.path === RoutePath.COMPETITION_RATING ||
            item.path === RoutePath.DECISION_LEVEL ||
            //item.path === RoutePath.LIBRARY_DOE ||
            item.path === RoutePath.SUPPORT ||
            item.path === RoutePath.RESULT_COMPETITION_END_YEAR ||
            item.path === RoutePath.SUMMARY_COMPETITION_END_YEAR_SO
          )
            return item.className === '' ? (
              <React.Fragment key={index}>
                <CustomLink
                  className="flex d-flex-column items-center"
                  to={item.path}
                  key={index}
                  classDropdown={item.className}
                  name={item.name}>
                  <img src={item.src} alt="" className="w-20 mr-10" />
                  <span className="grow">{item.name}</span>
                </CustomLink>
              </React.Fragment>
            ) : (
              <CustomLinkDropdown item={item} key={index} index={index} />
            );
        }
      });
    }
  };
  return (
    <nav className="sidebar__customize sidebar-offcanvas">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <button onClick={() => handleHiddenSidebar()} className="navbar-toggler align-self-center">
          <span className="icon-menu" />
        </button>
      </div>
      <ul className="nav">
        {roles && arrShowDropdown && arrShowDropdown.length > 0 && renderSideBar()}
      </ul>
      {isOpenModalViewFileDetails && (
        <ModalViewFilesDetails
          isOpen={isOpenModalViewFileDetails}
          detail={detailFile}
          handleCancel={() => setIsOpenModalViewFileDetails(!setIsOpenModalViewFileDetails)}
        />
      )}
    </nav>
  );
}

export default Sidebar;
