import * as _ from 'lodash';
export type LocalStorageItem = {
  key: string;
  value: string | number | boolean;
};

export enum LocalStorageKey {
  token = '_token',
  userID = '_userId',
  tokenExpired = '_tokenExpired',
  roles = '_roles',
  userName = '_userName',
  isVisible = '_isVisible',
  isReLogin = '_isRelogin',
  pathName = '_pathName',
  isLoginSSO = '_isLoginSSO',
  userInfo = '_userInfo',
  infoSchools = '_infoSchools'
}

export class LocalStorageService {
  public _value: any;
  public _key: any;
  public initValue: any;

  set value(newValue: any) {
    this._value = newValue;
  }

  set key(newKey: string) {
    this._key = newKey;
  }

  get value() {
    return this._value;
  }

  get key() {
    return this._key;
  }

  constructor(initValue?: any) {
    this.initValue = initValue;
  }

  public setItem({ key, value }: LocalStorageItem): LocalStorageService {
    localStorage.setItem(key, JSON.stringify(value));
    return this;
  }

  public getItem(key: string): string | null {
    const value: any = localStorage.getItem(key);
    if (_.isEmpty(value) || value === 'undefined') return null;
    return JSON.parse(value);
  }

  public removeItem(key: LocalStorageKey): LocalStorageService {
    localStorage.removeItem(key);
    return this;
  }

  static clear() {
    localStorage.clear();
  }
}
