import useAuth from './useAuth';
import { Navigate } from 'react-router-dom';
export const RequireAuthSchoolSo = ({
  children,
  role
}: {
  children: JSX.Element;
  role: boolean;
}) => {
  const { auth } = useAuth();
  const userHasRequiredRole = auth && (auth.sgd === role || auth.school === role) ? true : false;
  return userHasRequiredRole ? children : <Navigate to="/login" />;
};
