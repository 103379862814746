import { Button, Card, Modal, Select, Tag } from 'antd';
import { useToast } from 'components/Toast';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectListCourses,
  selectListCoursesBase,
  selectListPeriod
} from 'store/common/shared/selectors';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { CompetitionRatingHttp } from 'store/competionRatingEdu/services/competitionRatingEdu.http';
import { RESPONSE_MESSAGE } from 'store/competionRatingEdu/constants/competitionRatingEdu.constants';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import useAuth from 'hooks/useAuth';
import {
  groupUnitPgd,
  YEAR_DEFAULT_PGDQ3,
  YEAR_ID_DEFAULT_PGDQ3
} from '_constants/default.constants';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

interface IModal {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
}

const { Option } = Select;
function ModalCreateReviewEdu({ isOpen, title, handleCancel, handleRefresh }: IModal) {
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listCoursesBase: any = useSelector(selectListCoursesBase);
  const listPeriod: any = useSelector(selectListPeriod);
  const [yearSchool, setYearSchool] = useState<number>(YEAR_ID_DEFAULT_PGDQ3);
  const [titleReview, setTitleReview] = useState<string>('');
  const [department, setDepartment] = useState<any>();
  const [valueDepartment, setValueDepartment] = useState<any>();
  const [valueDonVi, setValueDonVi] = useState<any>();
  const [period, setPeriod] = useState<any[]>([]);
  const { filters, setFilters } = useAuth();
  const navigate = useNavigate();
  const localStorage = new LocalStorageService();

  const competitionRatingHttp = new CompetitionRatingHttp();
  const listAgenciesSoHttp = new ListAgenciesSoHttp();

  const onSelectYear = (value: number): void => {
    setYearSchool(value);
  };

  // useEffect(() => {
  //   loadListUserDepartments();
  // }, []);

  // const loadListUserDepartments = () => {
  //   listAgenciesSoHttp.getListAgenciesSoDepartments(filters).then((res) => {
  //     if (res.ok && res.status === 200) {
  //       setDepartment(res.data.data.edu_schools);
  //     }
  //   });
  // };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitleReview((e.target as HTMLInputElement).value);
  };

  const handleSubmit = () => {
    if (titleReview === '' || !yearSchool) {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_REVIEW_EDU_SUCCESS);
      return;
    }
    if (valueDonVi === '') {
      toast?.pushWarning('Vui lòng chọn đơn vị!');
      return;
    }
    const request = {
      title: titleReview,
      evaluation_code_id: valueDonVi,
      course_id: yearSchool,
      period_ids: [1]
    };
    competitionRatingHttp.addReviewPgd(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ADD_REVIEW_EDU_SUCCESS);
        handleCancel();
        handleRefresh();
        navigate(
          `/manager-rating-pgd/create-standard-role-pgd/${res.data.data.evaluation.id}/${0}`
        );
        localStorage.setItem({ key: LocalStorageKey.isVisible, value: 'show' });
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.ADD_RATING_EDU_FAIL);
      }
    });
  };

  const onChangeOptionDonvi = (e) => {
    setValueDonVi(e);
  };

  return (
    <Modal
      title="Thêm đánh giá mới"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button
          key="submit"
          size="large"
          type="primary"
          disabled={yearSchool !== YEAR_ID_DEFAULT_PGDQ3}
          onClick={handleSubmit}>
          Thêm mới
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên đánh giá
            </label>
            <input
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          {/* <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Cơ quan phụ trách
            </label>
            <div className="relative">
              <StyleSelectCourse
                style={{
                  width: '100%',
                  height: 40,
                  position: 'relative'
                }}
                placeholder="Chọn cơ quan phụ trách"
                onChange={onChangeOptionDepartment}>
                {!_.isEmpty(department) &&
                  department.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </StyleSelectCourse>
            </div>
          </div> */}
          <div className="grid sm:grid-2 gap-15 sm:gap-20">
            <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Năm học
              </label>
              <div className="relative">
                <StyleSelectCourse
                  style={{ width: 350, height: 40 }}
                  placeholder="Chọn năm học"
                  defaultValue={yearSchool}
                  onChange={onSelectYear}>
                  {!_.isEmpty(listCoursesBase) &&
                    listCoursesBase.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.description}
                      </Option>
                    ))}
                </StyleSelectCourse>
              </div>
            </div>
            <div>
              <label className="font-bold mb-10 block">Chọn đơn vị</label>
              <div className="relative">
                <StyleSelectCourse
                  style={{ width: 350, height: 40 }}
                  placeholder="Chọn đơn vị"
                  onChange={onChangeOptionDonvi}>
                  {!_.isEmpty(groupUnitPgd) &&
                    groupUnitPgd.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.title}
                      </Option>
                    ))}
                </StyleSelectCourse>
                {/* <StyleSelectPeriod
                  mode="multiple"
                  style={{
                    width: 350,
                    height: 40,
                    position: 'relative'
                  }}
                  tagRender={tagRender}
                  placeholder="Chọn quý"
                  onChange={handleChange}
                  // value={period}
                  dropdownStyle={{ width: '350' }}>
                  {!_.isEmpty(listPeriod.periods) &&
                    listPeriod.periods.map((item) => (
                      <Option value={item.id} key={item.id} title={item.title}>
                        {item.title}
                      </Option>
                    ))}
                </StyleSelectPeriod>
                <IconDropdown>
                  <DownOutlined />
                </IconDropdown> */}
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalCreateReviewEdu;
