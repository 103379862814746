import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LocalStorageService, LocalStorageKey } from 'services/localStorage.service';
import { UserHttp } from '../services/user.http';
import { actions } from './slice';

const userHttp = new UserHttp();
const localStorage = new LocalStorageService();

export function* getInfoUser(api, action) {
  const response = yield call(api.getInfoUser, action.payload);
  try {
    if (response.ok) {
      const { user } = response.data.data;
      localStorage.setItem({ key: LocalStorageKey.userName, value: user.fullname });
      localStorage.setItem({ key: LocalStorageKey.roles, value: user.roles });
      localStorage.setItem({
        key: LocalStorageKey.userID,
        value: user.id
      });
      localStorage.setItem({
        key: LocalStorageKey.infoSchools,
        value: user.schools[0]
      });
      yield put(actions.getInfoUserSuccess(response.data.data));
    } else {
      yield put(actions.getInfoUserFail(response.data));
    }
  } catch (error) {
    yield put(actions.getInfoUserFail(error));
  }
}
export function* changePassWordUser(api, action) {
  const response = yield call(api.changePassWordUser, action.payload);

  try {
    if (response.ok) {
      yield put(actions.changePassWordUserSuccess(response.data.data));
    } else {
      yield put(actions.changePassWordUserFail(response.data));
    }
  } catch (error) {
    yield put(actions.changePassWordUserFail(error));
  }
}

export function* updateInfoUser(api, action) {
  const response = yield call(api.updateInfodUser, action.payload);

  try {
    if (response.ok) {
      yield put(actions.updateInfoUserSuccess(response.data.data));
    } else {
      yield put(actions.updateInfoUserFail(response.data));
    }
  } catch (error) {
    yield put(actions.updateInfoUserFail(error));
  }
}
export function* UserSaga() {
  yield all([
    yield takeLatest(actions.getInfoUser.type, getInfoUser, userHttp),
    yield takeLatest(actions.changePassWordUser.type, changePassWordUser, userHttp)
  ]);
}
