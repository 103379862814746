import { CreateReviewHttp } from '../services/createReview.http';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { actions } from './slice';
import FunctionUtils from 'utils/FunctionUtils';

const createReviewHttp = new CreateReviewHttp();

export function* getListReview(api, action) {
  const response = yield call(api.getListReview, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations
      );
      yield put(actions.getListReviewSuccess(configResponse));
    } else {
      yield put(actions.getListReviewFail(response));
    }
  } catch (error) {
    yield put(actions.getListReviewFail(error));
  }
}
export function* addReview(api, action) {
  const response = yield call(api.addReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.addReviewSuccess(response.data));
    } else {
      yield put(actions.addReviewFail(response));
    }
  } catch (error) {
    yield put(actions.addReviewFail(error));
  }
}
export function* deleteReview(api, action) {
  const response = yield call(api.deleteReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.deleteReviewSuccess(response.data));
    } else {
      yield put(actions.deleteReviewFail(response));
    }
  } catch (error) {
    yield put(actions.deleteReviewFail(error));
  }
}
export function* detailReview(api, action) {
  const response = yield call(api.detailReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.detailReviewSuccess(response.data));
    } else {
      yield put(actions.detailReviewFail(response));
    }
  } catch (error) {
    yield put(actions.detailReviewFail(error));
  }
}
export function* listTeacherReview(api, action) {
  const response = yield call(api.listTeacherReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.listTeacherReviewSuccess(response.data.data));
    } else {
      yield put(actions.listTeacherReviewFail(response));
    }
  } catch (error) {
    yield put(actions.listTeacherReviewFail(error));
  }
}
export function* getDetailEvaluationByTeacher(api, action) {
  const response = yield call(api.getDetailEvaluationByTeacher, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDetailEvaluationByTeacherSuccess(response.data.data));
    } else {
      yield put(actions.getDetailEvaluationByTeacherFail(response));
    }
  } catch (error) {
    yield put(actions.getDetailEvaluationByTeacherFail(error));
  }
}
export function* copyReview(api, action) {
  const response = yield call(api.copyReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.copyReviewSuccess(response.data.data));
    } else {
      yield put(actions.copyReviewFail(response));
    }
  } catch (error) {
    yield put(actions.copyReviewFail(error));
  }
}

export function* CreateReviewSaga() {
  yield all([
    yield takeLatest(actions.getListReview.type, getListReview, createReviewHttp),
    yield takeLatest(actions.addReview.type, addReview, createReviewHttp),
    yield takeLatest(actions.deleteReview.type, deleteReview, createReviewHttp),
    yield takeLatest(actions.detailReview.type, detailReview, createReviewHttp),
    yield takeLatest(actions.listTeacherReview.type, listTeacherReview, createReviewHttp),
    yield takeLatest(
      actions.getDetailEvaluationByTeacher.type,
      getDetailEvaluationByTeacher,
      createReviewHttp
    ),
    yield takeLatest(actions.copyReview.type, copyReview, createReviewHttp)
  ]);
}
