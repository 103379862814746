import { RootState } from 'store/core/types';
import { initialState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

const selectCompetitionRating = (state: RootState) => state?.competitionRatingEdu || initialState;

export const selectLoading = createSelector([selectCompetitionRating], (rating) => rating.loading);
export const selectSuccess = createSelector([selectCompetitionRating], (rating) => rating.success);
export const selectError = createSelector([selectCompetitionRating], (rating) => rating.error);

export const selectListReviewEdu = createSelector(
  [selectCompetitionRating],
  (rating) => rating.listReviewEdu
);

export const selectListReviewPgd = createSelector(
  [selectCompetitionRating],
  (rating) => rating.listReviewPgd
);

export const selectSchoolReviewEdu = createSelector(
  [selectCompetitionRating],
  (review) => review.listSchoolReviewEdu
);

export const selectManageGrades = createSelector(
  [selectCompetitionRating],
  (review) => review.listManageGrades
);

export const selectSetGradeId = createSelector(
  [selectCompetitionRating],
  (review) => review.gradeId
);
