export const APP_CONSTANT = {
  API: {
    ENDPOINT: process.env.REACT_APP_DOMAIN_API,
    ENDPOINTV2: process.env.REACT_APP_DOMAIN_API_V2,
    // LOGO: process.env.REACT_APP_STATIC_URL,
    // ENDPOINT_E_LEARNING: process.env.REACT_APP_E_LEARNING_API,
    ENDPOINT_LIST_SCHOOL: process.env.REACT_APP_LIST_SCHOOL,
    ENDPOINT_IMG_S3: process.env.REACT_APP_DOMAIN_S3,
    ENDPOINT_FILE_ANTD: process.env.REACT_APP_URL_ACTION_FILE_ANTD
  }
};

export const ROUTES_DEFAULT = {
  LOGO: 'content/assets/images/logo_so.png',
  LOGO_USER: 'content/images/icon-1.png',
  LOGO_PASSWORD: 'content/images/icon-2.png',
  LOGO_SCHOOL: 'content/images/icon-14.png'
};

export interface IPagi {
  pageId: number;
  perPage: number;
  status?: string;
}

export const INIT_PAGE: IPagi = {
  pageId: 1,
  perPage: 10
};

export interface IDataStatus {
  id: string;
  title: string;
}

export const TITLE_NAVBAR = {
  CREATE_REVIEW: 'Tạo đánh giá mới',
  CREATE_RATING: 'Tạo xếp loại mới',
  LIST_REVIEW: 'Danh sách đánh giá',
  STATISTICAL: 'Thống kê',
  CREATE_STANDARD: 'Tạo tiêu chuẩn',
  DETAIL_REVIEW: 'Thông tin chi tiết đánh giá',
  LIST_TEACHER: 'Danh sách giáo viên',
  LIST_TEACHER_SO: 'Danh sách giáo viên thuộc trường',
  INTITIATIVE_ACHIEVAMENT: 'Thành tích sáng kiến',
  LIST_ACHIEVAMENT_SCHOOL: 'Danh sách sáng kiến',
  INTITIATIVE_ACHIEVAMENT_SO: 'Công nhận danh sách sáng kiến',
  LIST_DETAIL_TEACHER: 'Danh sách các thành tích của giáo viên',
  LIST_DEPARTMENT_REVIEW_COMPETITION: 'Danh sách chuyên viên chấm điểm thi đua',
  LIST_AGENCIES_SO: 'Danh sách cơ quan phụ trách',
  COMPETITION_REVIEW_SO: 'Chấm điểm thi đua',
  MANAGER_RATING_SO: 'Quản lý đánh giá thi đua'
};

export enum FORMAT_DATE {
  DAY_TIME = 'DD/MM/yyyy HH:mm'
}

export enum STATUS_EVALUATION {
  NOT_EVALUATION = 'chua_tu_danh_gia',
  WAIT_EVALUATION = 'dang_tu_danh_gia',
  HAVE_EVALUATION = 'da_tu_danh_gia',
  WAITING_FOR_REVIEW = 'cho_danh_gia',
  EVALUATING = 'dang_danh_gia',
  HAVE_EVALUATED = 'da_danh_gia',
  GRADED_AGAIN = 'da_cham_lai',
  RECOMMEND_RE_RATING = 'de_nghi_cham_lai',
  RECHECKING = 'dang_cham_lai',
  RECEIPT = 'tiep_nhan_cham_lai',
  REFUSE_TO_RE_MARK = 'tu_choi_cham_lai'
}

export const dataStatusAdmin: any[] = [
  { id: STATUS_EVALUATION.NOT_EVALUATION, title: 'Chưa tự đánh giá' },
  { id: STATUS_EVALUATION.HAVE_EVALUATION, title: 'Đã tự đánh giá' },
  { id: STATUS_EVALUATION.EVALUATING, title: 'Quản lý đánh giá' },
  { id: STATUS_EVALUATION.HAVE_EVALUATED, title: 'Quản lý đã đánh giá' },
  { id: STATUS_EVALUATION.RECOMMEND_RE_RATING, title: 'Chờ duyệt đề nghị' },
  { id: STATUS_EVALUATION.RECEIPT, title: 'Sở đã tiếp nhận' },
  { id: STATUS_EVALUATION.REFUSE_TO_RE_MARK, title: 'Sở đã từ chối' },
  { id: STATUS_EVALUATION.RECHECKING, title: 'Đang chấm lại' },
  { id: STATUS_EVALUATION.GRADED_AGAIN, title: 'Đã chấm lại' }
];
export const dataStatusTeacher: any[] = [
  { id: STATUS_EVALUATION.NOT_EVALUATION, title: 'Chưa tự đánh giá' },
  { id: STATUS_EVALUATION.WAIT_EVALUATION, title: 'Đang tự đánh giá' },
  { id: STATUS_EVALUATION.HAVE_EVALUATION, title: 'Đã tự đánh giá' },
  { id: STATUS_EVALUATION.WAITING_FOR_REVIEW, title: 'Chờ đánh giá' },
  { id: STATUS_EVALUATION.EVALUATING, title: 'Quản lý đánh giá' },
  { id: STATUS_EVALUATION.HAVE_EVALUATED, title: 'Quản lý đã đánh giá' }
];

export const YEAR_DEFAULT: number = 2024;
export const YEAR_ID_DEFAULT: number = 63;

export const YEAR_DEFAULT_PGDQ3: number = 2024;
export const YEAR_ID_DEFAULT_PGDQ3: number = 63;

export const LAST_YEAR_FOR_TITLE_UPDATE = 2022; // niên khóa 2022-2023

export enum ROLE {
  ADMIN = 'school',
  TEACHER = 'teacher'
}

export const groupSchool: any = [
  { id: 1, evaluationCodeIds: [1], title: 'Khối thi đua 1' },
  { id: 2, evaluationCodeIds: [1], title: 'Khối thi đua 2' },
  { id: 3, evaluationCodeIds: [1], title: 'Khối thi đua 3' },
  { id: 4, evaluationCodeIds: [1], title: 'Khối thi đua 4' },
  { id: 5, evaluationCodeIds: [1], title: 'Khối thi đua 5' },
  { id: 6, evaluationCodeIds: [1], title: 'Khối thi đua 6' },
  { id: 7, evaluationCodeIds: [1], title: 'Khối thi đua 7' },
  { id: 8, evaluationCodeIds: [1], title: 'Khối thi đua 8' },
  { id: 9, evaluationCodeIds: [1], title: 'Khối thi đua 9' },
  { id: 10, evaluationCodeIds: [1], title: 'Khối thi đua 10' },
  { id: 11, evaluationCodeIds: [1], title: 'Khối thi đua 11' },
  { id: 12, evaluationCodeIds: [2], title: 'Khối thi đua 12' },
  { id: 13, evaluationCodeIds: [2], title: 'Khối thi đua 13' },
  { id: 14, evaluationCodeIds: [2], title: 'Khối thi đua 14' },
  { id: 15, evaluationCodeIds: [2], title: 'Khối thi đua 15' },
  { id: 16, evaluationCodeIds: [3, 4], title: 'Khối thi đua 27' },
  { id: 18, title: 'Khối thi đua 28' },
  { id: 17, title: 'Trung cấp - Cao đẳng' },
  { id: 19, evaluationCodeIds: [5], title: 'Khối thi đua P.GD' }
];

export const groupUnitPgd: any[] = [
  { id: 10, title: 'Trường Trung học cơ sở' },
  { id: 11, title: 'Trường Tiểu học' },
  { id: 12, title: 'Trường Mầm non' }
];

export const groupSchoolPgdQ3: any = [
  { id: 100, evaluationCodeIds: [10], title: 'Khối thi đua 7' },
  { id: 101, evaluationCodeIds: [11], title: 'Khối thi đua 8' },
  { id: 102, evaluationCodeIds: [11], title: 'Khối thi đua 9' },
  { id: 103, evaluationCodeIds: [12], title: 'Khối thi đua 10' },
  { id: 104, evaluationCodeIds: [12], title: 'Khối thi đua 11' }
];

export const unitPgdQ3: any = [
  { id: 10, title: 'Khối thi đua 7' },
  { id: 11, title: 'Khối thi đua 8' },
  { id: 12, title: 'Khối thi đua 9' },
  { id: 13, title: 'Khối thi đua 10' },
  { id: 14, title: 'Khối thi đua 11' }
];

export const DonVi: any = [
  { id: 1, title: 'Trường THPT công lập' },
  { id: 2, title: 'Trường ngoài công lập' },
  { id: 3, title: 'Trung tâm Giáo dục thường xuyên' },
  { id: 4, title: 'Đơn vị mầm non trực thuộc' },
  { id: 5, title: 'Phòng giáo dục' }
];

export const EvaluateData: any = [
  { id: 1, department_id: 16522, course_id: YEAR_ID_DEFAULT, title: 'Giáo dục Trung học' },
  {
    id: 2,
    department_id: 16522,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác dạy học tại các trường'
  },
  { id: 3, department_id: 16552, course_id: YEAR_ID_DEFAULT, title: 'Chính trị tư tưởng' },
  {
    id: 4,
    department_id: 16553,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác Kế hoạch - Tài chính'
  },
  { id: 5, department_id: 16554, course_id: YEAR_ID_DEFAULT, title: 'Công tác tổ chức' },
  { id: 6, department_id: 16554, course_id: YEAR_ID_DEFAULT, title: 'Công tác tổ chức cán bộ' },
  {
    id: 7,
    department_id: 16555,
    course_id: YEAR_ID_DEFAULT,
    title: 'Hoạt động thanh tra giáo dục'
  },
  {
    id: 8,
    department_id: 16556,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác khảo thí và kiểm định chất lượng giáo dục'
  },
  {
    id: 9,
    department_id: 16557,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác chuyển đổi số và ứng dụng công nghệ thông tin'
  },
  { id: 10, department_id: 16557, course_id: YEAR_ID_DEFAULT, title: 'Công tác văn phòng' },
  { id: 11, department_id: 16566, course_id: YEAR_ID_DEFAULT, title: 'Giáo dục Mầm non' },
  {
    id: 12,
    department_id: 16566,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác dạy và học tại các trường'
  },
  { id: 13, department_id: 16584, course_id: YEAR_ID_DEFAULT, title: 'Giáo dục Tiểu học' },
  {
    id: 14,
    department_id: 16568,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác dạy và học tại Trung tâm'
  },
  {
    id: 15,
    department_id: 16569,
    course_id: YEAR_ID_DEFAULT,
    title: 'Quản lý Nhà nước, Cơ sở vật chất, Tổ chức cán bộ'
  },
  { id: 16, department_id: 16570, course_id: YEAR_ID_DEFAULT, title: 'Công tác công đoàn' },
  {
    id: 17,
    department_id: 16569,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác phối hợp quản lý các cơ sở giáo dục ngoài công lập'
  },
  {
    id: 18,
    department_id: 16583,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác Mầm non'
  },
  {
    id: 19,
    department_id: 16585,
    course_id: YEAR_ID_DEFAULT,
    title: 'Công tác phối hợp quản lý các cơ sở giáo dục ngoài công lập'
  },
  {
    id: 20,
    department_id: 16585,
    course_id: YEAR_ID_DEFAULT,
    title: 'Quản lý Nhà nước, Cơ sở vật chất, Tổ chức cán bộ'
  },
  { id: 21, department_id: 16567, course_id: YEAR_ID_DEFAULT, title: 'Giáo dục Tiểu học' }
];

export const LIST_EVALUATION_ID: any = [
  '7bd17629-6dca-4a54-9e95-fd45730b9de4',
  '507bb15d-69a9-41db-b6a5-1e84078f9fcc',
  '26cfab22-3097-401b-951d-085881650235'
];
