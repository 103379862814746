import { APP_CONSTANT, YEAR_ID_DEFAULT } from '_constants/default.constants';
import { HttpRequest } from 'services/api';
import { CompetitionRatingEduSoApi } from '../constants/competitionRatingSo.constants';

export class CompetitionRatingSoHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListDepartmentSo = ({ pageId, perPage, courseId, periodId }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${CompetitionRatingEduSoApi.LIST_DEPARTMENT_SO}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}${periodId}`
    );
  };

  public deleteReviewtSo = ({ id }): Promise<any> => {
    return this.request.delete(`${CompetitionRatingEduSoApi.DELETE_REVIEW_SO}/${id}`);
  };

  public deleteReviewtPgd = ({ id }): Promise<any> => {
    return this.request.delete(`${CompetitionRatingEduSoApi.DELETE_REVIEW_PGD}/${id}`);
  };

  public deleteDepartmentSo = ({ id }): Promise<any> => {
    return this.request.delete(`${CompetitionRatingEduSoApi.DELETE_DEPARTMENT_SO}/${id}`);
  };

  public editDepartmentSo = ({ id }): Promise<any> => {
    return this.request.patch(`${CompetitionRatingEduSoApi.EDIT_DEPARTMENT_SO}/${id}`);
  };

  public soSendResult = (data): Promise<any> => {
    return this.request.post(`${CompetitionRatingEduSoApi.URL_EVALUATION_SO_SEND_RESULT}`, data);
  };

  public getListCompetitionsReviewSo = ({
    pageId,
    perPage,
    courseId,
    periodId,
    evaluationCodeId,
    departmentId
  }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${
        CompetitionRatingEduSoApi.LIST_COMOETITION_REVIEW_SO
      }?page_id=${pageId}&per_page=${perPage}&evaluation_code_id=${
        evaluationCodeId || 0
      }&department_id=${departmentId || 0}&course_id=${courseId}${periodId}`
    );
  };

  public evaluationsPublish = (dataPublish): Promise<any> => {
    return this.request.post(`${CompetitionRatingEduSoApi.URL_EVALUATION_SO_PUBLISH}`, dataPublish);
  };

  public evaluationsPublishPgd = (dataPublish): Promise<any> => {
    return this.request.post(
      `${CompetitionRatingEduSoApi.URL_EVALUATION_PGD_PUBLISH}`,
      dataPublish
    );
  };

  public downloadFilePgd = (filePath): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      `${CompetitionRatingEduSoApi.URL_EVALUATION_PGD_DOWNLOAD}?${filePath}&time=${dateNow.getTime()}`,
    );
  };

}
