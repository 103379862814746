import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import _ from 'lodash';
const { Text } = Typography;
interface ModalDetailAdd {
  isOpen: boolean;
  handleCancel: () => void;
  data: any;
  handleRefresh: () => void;
  isSingle?: boolean;
}

interface DataType {
  teacher_fullname: string;
  exists: boolean;
}

function ModalDetailAdd({ isOpen, handleCancel, data, handleRefresh, isSingle }: ModalDetailAdd) {
  const handleClose = () => {
    handleCancel();
    handleRefresh();
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#FCF3CF' }
        };
      },
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Họ và tên giáo viên',
      dataIndex: 'teacher_fullname',
      key: 'teacher_fullname',
      width: 300,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'exists',
      key: 'exists',
      width: 200,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (record) {
          return <span style={{ color: '#ea5455' }}>Đã tồn tại</span>;
        } else {
          return <span style={{ color: '#48b842' }}>Chưa tồn tại</span>;
        }
      }
    },
    {
      title: 'Kết quả',
      width: 200,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: 'rgb(183, 235, 143)' }
        };
      },
      render: (record) => {
        if (record.exists) {
          return <span style={{ color: '#ea5455' }}>Thất bại</span>;
        } else {
          return <span style={{ color: '#48b842' }}>Thành công</span>;
        }
      }
    }
  ];

  return (
    <Modal
      maskClosable={isSingle ? true : false}
      centered
      visible={isOpen}
      onCancel={handleClose}
      title={`Thông tin`}
      width={isSingle ? 400 : 1000}
      footer={null}>
      <div className="container">
        <Row wrap justify="center" align="middle">
          <Col span={24} className="mb-5">
            {isSingle ? (
              <>
                <Text>
                  <i
                    className="far fa-check-circle"
                    style={{ color: '#48b842', fontSize: '20px' }}
                  />{' '}
                  Thêm giáo viên thành công!
                </Text>
                {data &&
                  data.map((item) => (
                    <React.Fragment key={item.id}>
                      <p>
                        - Tên giáo viên:{' '}
                        <span style={{ color: '#48b842' }}>{item.teacher_fullname}</span>
                      </p>
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <Table
                showHeader
                columns={columns}
                dataSource={!_.isEmpty(data) ? data : []}
                size="middle"
                bordered
                pagination={false}
                rowKey={(record: any) => record.id}
              />
            )}
          </Col>
        </Row>
        <div className="flex justify-end mt-20">
          <Button key="back" size="large" onClick={handleClose}>
            Đóng
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(ModalDetailAdd);
