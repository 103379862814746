import moment from 'moment';
import { APP_CONSTANT } from '_constants/default.constants';
import FunctionUtils from './FunctionUtils';
interface ISchoolYear {
  id: number;
  title: string;
}

const currentYear = new Date().getFullYear();
const START_YEAR_DEFAULT = 2022;
export class AppHelper {
  static schoolYear() {
    const yearArr: ISchoolYear[] = [];
    let year = START_YEAR_DEFAULT;
    for (var i = 0; i <= currentYear - START_YEAR_DEFAULT; i++) {
      const strYear: string = `${year} - ${year + 1}`;
      yearArr.push({ id: year, title: strYear });
      year += 1;
    }
    return yearArr;
  }

  static generateUUIDV4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static nonAccentVietnamese = (str: string) => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
    return str;
  };

  static searchVNEntity = (multi: string, keyword: string): number => {
    return AppHelper.nonAccentVietnamese(multi.replace(/\s/g, '')).indexOf(
      AppHelper.nonAccentVietnamese(keyword.replace(/\s/g, ''))
    );
  };

  static expiredToken(tokenExpireTime: number): boolean {
    if (tokenExpireTime) {
      return +tokenExpireTime < new Date().getTime();
    }
    return false;
  }

  static formatDate(date: string, format: string): string {
    const strDate = moment(date).format(format);
    return strDate;
  }

  static createDownloadFile(file_path: string): void {
    let fileName = FunctionUtils.TileImg(file_path);
    //const url = APP_CONSTANT.API.ENDPOINT_IMG_S3 + '/' + file_path; // api da tra ve full link chay
    const url = file_path;
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    // a.setAttribute('download', fileName);
    a.click();
  }

  static downloadFileExcel(): void {
    const url = '/assets/docs/docs/teacher.xlsx';
    let a = document.createElement('a');
    a.href = url;
    a.click();
  }

  static enterKey(e, func: any): void {
    if (e.key === 'Enter') {
      e.target.blur();
      if (func) {
        func();
      }
    }
  }
  static dayNow() {
    const today = new Date();
    const options: any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return today.toLocaleString('vi-VN', options);
  }

  static convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader: any = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        let base64result = fileReader?.result?.split(';base64,')[1];
        resolve(base64result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  static transform = (str) => {
    let text = str.toLowerCase();
    if (text.charAt(0) === ' ') {
      text = text.trim();
    }
    if (text.charAt(text.length - 1) === '-') {
      // text = (text.replace(/-/g, ""));
    }
    text = text.replace(/ +/g, '-');
    text = text.replace(/--/g, '-');
    text = text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
    text = text.replace(/[đĐ]/g, 'd');
    text = text.replace(/[^a-zA-Z0-9 -]/g, '');

    return text;
  };
}
