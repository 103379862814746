import { Button, Col, Input, Modal, Row } from 'antd';
import { useToast } from 'components/Toast';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { RESPONSE_MESSAGE } from 'store/ListAgenciesSo/constants/ListAgenciesSo.constants';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import { TeacherSaga } from 'store/teacher/shared/saga';
interface ChangePassword {
  isOpen: boolean;
  handleCancel: () => void;
  department: any;
}
interface IFormInput {
  password: string;
  rePassword: string;
}
function ChangePassword({ isOpen, handleCancel, department }: ChangePassword) {
  // useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  // useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  // const dispatch = useDispatch();
  const toast = useToast();
  const listAgenciesSoHttp = new ListAgenciesSoHttp();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>();
  const onSubmit = (data: IFormInput) => {
    const request = {
      user_id: department?.id,
      password: data.password
    };
    setIsSubmit(true);
    listAgenciesSoHttp.resetPasswordAgenciesSo(request).then((res) => {
      if (res.ok && res.status === 200) {
        setIsSubmit(false);
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.CHANGE_PASS_SUCCESS);
        handleCancel();
        // handleRefresh();
      } else {
        setIsSubmit(false);
      }
    });
    handleCancel();
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Đặt lại mật khẩu cho cơ quan quản lý: ${department?.schools[0]?.name}`}
      footer={null}>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row wrap justify="space-between" align="middle">
            <Col span={24} className="mb-5">
              <h4>Mật khẩu mới</h4>
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 4, pattern: /^[a-zA-Z0-9]*$/ }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.password?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.password?.type === 'required' && (
                <span className="text-error">Mật khẩu không được để trống!</span>
              )}
              {errors?.password?.type === 'minLength' && (
                <span className="text-error">Mật khẩu có ít nhất 4 ký tự!</span>
              )}
              {errors?.password?.type === 'pattern' && (
                <span className="text-error">Mật khẩu không được có dấu hoặc khoảng trống!</span>
              )}
            </Col>
            <Col span={24}>
              <h4>Nhập lại mật khẩu</h4>
              <Controller
                name="rePassword"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => value === getValues('password')
                }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.rePassword?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập lại mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.rePassword?.type === 'required' && (
                <span className="text-error">Nhập lại mật khẩu không được để trống!</span>
              )}
              {errors?.rePassword?.type === 'validate' && (
                <span className="text-error">Nhập lại mật khẩu không trùng khớp!</span>
              )}
            </Col>
          </Row>
          <div className="flex justify-end mt-20">
            <Button key="back" size="large" onClick={handleCancel}>
              Đóng
            </Button>
            <Button htmlType="submit" loading={isSubmit} className="btn btn-add ml-10">
              Cập nhật
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePassword;
