import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Card, Input, Form } from 'antd';
import DocViewer, { DocViewerRenderers, PDFRenderer } from 'react-doc-viewer';
import _ from 'lodash';
import styled from 'styled-components';
import './style.css';
import { AppHelper } from '../../utils/app.helpers';
import { DownloadOutlined } from '@ant-design/icons';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

interface IModalUploadFile {
  isOpen: boolean;
  detail: any;
  handleCancel: () => void;
}
function ModalViewFilesDetails(this: any, { isOpen, detail, handleCancel }: IModalUploadFile) {
  const [title, setTitle] = useState<string>('');

  //   useEffect(() => {
  //     if (!_.isEmpty(detail)) {
  //       setTitle(detail.title);
  //       setFilePaths(Object.values(detail.files));
  //       setFileName(Object.values(detail.file_names));
  //     }
  //   }, [detail]);

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };

  return (
    <Modal
      className={'modal-viewfile'}
      title={title}
      centered
      style={{ top: 20 }}
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={'fit-content'}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel} style={{ marginRight: 10 }}>
          Đóng
        </Button>,

        <Button
          type="primary"
          onClick={() => handleDownLoad(detail)}
          icon={<DownloadOutlined />}
          size="large">
          Tải file
        </Button>
      ]}>
      <Container className="container-viewfile">
        <Card className="viewfile">
          {
            //!_.isEmpty(this.state.examDetail) && _.values(this.state.examDetail[0].files).map((file, num) => (
            !_.isEmpty(detail) ? ( //&& _.values(examDetail[0].files).map((file, num) => (
              detail.indexOf('.doc') > 0 ||
              detail.indexOf('.docx') > 0 ||
              detail.indexOf('.xlsx') > 0 ||
              detail.indexOf('.xls') > 0 ? (
                <DocViewer
                  className="ih-526 overflow-y-auto scroll-style"
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: detail, fileType: 'docx' }]}
                />
              ) : detail.indexOf('.ppt') > 0 ? (
                <DocViewer
                  className="ih-526 overflow-y-auto scroll-style"
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: detail, fileType: 'application/vnd.ms-powerpoint' }]}
                />
              ) : detail.indexOf('.pptx') > 0 ? (
                <DocViewer
                  className="ih-526 overflow-y-auto scroll-style"
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: detail,
                      fileType:
                        'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation'
                    }
                  ]}
                />
              ) : detail.indexOf('.pdf') > 0 ? (
                <iframe
                  className="ih-526 overflow-y-auto scroll-style"
                  src={detail}
                  allowFullScreen
                  width="100%"
                />
              ) : detail.indexOf('.mp4') > 0 ||
                detail.indexOf('.ogg') > 0 ||
                detail.indexOf('.webm') > 0 ||
                detail.indexOf('.mp3') > 0 ? (
                <div className="ih-526 overflow-y-auto scroll-style" style={{ width: 'auto' }}>
                  <video src={detail} autoPlay controls width="auto" className="ih-409"></video>
                </div>
              ) : (
                <iframe
                  id="examVideo"
                  className="ih-526 overflow-y-auto scroll-style"
                  src={detail}
                  allowFullScreen
                  width="100%"
                />
              )
            ) : (
              ''
            )
          }
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalViewFilesDetails;
