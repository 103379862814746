import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { UserApi } from '../constants/user.constants';

export class UserHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getInfoUser = (): Promise<any> => {
    return this.request.get(UserApi.GET_INFO);
  };

  public getDetailUser = (userId): Promise<any> => {
    return this.request.get(`${UserApi.UPDATE_INFO_USER}/${userId}`);
  };

  public activeUser = ({ new_password, confirmed_new_password }): Promise<any> => {
    return this.request.post(UserApi.ACTIVE_USER, {
      new_password,
      confirmed_new_password
    });
  };

  public changePassWordUser = ({ password, newPassword, confirmedNewPassword }): Promise<any> => {
    return this.request.post(UserApi.CHANGE_PASSWORD, {
      confirmed_new_password: confirmedNewPassword,
      new_password: newPassword,
      password
    });
  };

  public updateInfoUser = ({
    id,
    ma_cong_chuc,
    id_num,
    position_title,
    birthday,
    fullname,
    email,
    province_id,
    district_id,
    schools_id,
    gender
  }): Promise<any> => {
    return this.request.patch(UserApi.UPDATE_INFO_USER, {
      ma_cong_chuc,
      id_num,
      position_title,
      birthday,
      fullname,
      email,
      province_id,
      district_id,
      schools_id,
      gender
    });
  };


  public updateInfoUserPGD = ({
    province_id,
    district_id,
    ward_id,
    address
  }): Promise<any> => {
    return this.request.patch(UserApi.UPDATE_INFO_USER_PGD, {
      province_id,
      district_id,
      ward_id,
      address
    });
  };

  public updateInfoUserDetailPGD = (
    {
        user_id,
        is_dang_vien,
        ngay_du_bi,
        ngay_chinh_thuc,
        quy_hoach_id,
        quy_hoach_chuc_id,
        chinh_tri_id,
        chuyen_mon_id,
        giao_duc_id,
        chuc_vu_ids,
        thanh_tich_id,
        thanh_tich_khac,
        description,
        files,
        chuyen_mon_files,
        chinh_tri_files,
        giao_duc_files,
        thanh_tich_files
    }): Promise<any> => {
    return this.request.post(UserApi.UPDATE_INFO_USER_DETAIL_PGD, {
        user_id,
        is_dang_vien,
        ngay_du_bi,
        ngay_chinh_thuc,
        quy_hoach_id,
        quy_hoach_chuc_id,
        chinh_tri_id,
        chuyen_mon_id,
        giao_duc_id,
        chuc_vu_ids,
        thanh_tich_id,
        thanh_tich_khac,
        description,
        files,
        chuyen_mon_files,
        chinh_tri_files,
        giao_duc_files,
        thanh_tich_files
    });
  };

  public getUserInfoDetailPGD = (userId): Promise<any> => {
    return this.request.get(`${UserApi.UPDATE_INFO_USER_DETAIL_PGD  }/${userId}`);
  };
}
