import { createSlice } from '@reduxjs/toolkit';

export interface listAgenciesSoState {
  loading: boolean;
  success?: any;
  error?: any;
  listDataAgenciesSo?: any;
  listAgenciesDepartments?: any;
  detailevaluationGrantPermissions: any;
}

export const initialState: listAgenciesSoState = {
  loading: false,
  success: {},
  error: {},
  listDataAgenciesSo: {},
  listAgenciesDepartments: {},
  detailevaluationGrantPermissions: {}
};

const listAgenciesSoSlice = createSlice({
  name: 'listAgenciesSo',
  initialState,
  reducers: {
    getListAgenciesSo(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListAgenciesSoSuccess(state, action) {
      state.loading = false;
      state.listDataAgenciesSo = action.payload;
    },
    getListAgenciesSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListAgenciesSoDepartments(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListAgenciesSoDepartmentsSuccess(state, action) {
      state.loading = false;
      state.listAgenciesDepartments = action.payload;
    },
    getListAgenciesSoDepartmentsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDetailevaluationGrantPermissions(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDetailevaluationGrantPermissionsSuccess(state, action) {
      state.loading = false;
      state.detailevaluationGrantPermissions = action.payload;
    },
    getDetailevaluationGrantPermissionsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = listAgenciesSoSlice;
