import { useState, useEffect } from 'react';
import { Modal, DatePicker, Button, DatePickerProps } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import { YEAR_ID_DEFAULT, YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/evaluation/constants/evaluation.constants';

const dateTime = new Date();
const dateFormat = 'DD/MM/YYYY';

interface IModalViewFiles {
  isOpen: boolean;
  detail: any;
  handleCancel: () => void;
  handleRefresh: () => void;
}

function ModalCloseAchivement({ isOpen, detail, handleCancel, handleRefresh }: IModalViewFiles) {
  const evaluationHttp = new EvaluationHttp();
  const toast = useToast();
  const [dayLock, setDayLock] = useState<any>(moment(dateTime, 'DD/MM/yyyy'));
  const [dayUnLock, setDayUnLock] = useState<any>(moment(dateTime, 'DD/MM/yyyy'));
  const [courseId, setCourseId] = useState<number>(YEAR_ID_DEFAULT_PGDQ3);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(detail)) {
      if (detail.evaluation_config !== null) {
        const { to_date, from_date, title, course_id } = detail.evaluation_config;
        const dateTo = new Date(to_date);
        const dateFrom = new Date(from_date);
        setCourseId(course_id);
        setDayLock(moment(dateFrom, 'DD/MM/yyyy'));
        setDayUnLock(moment(dateTo, 'DD/MM/yyyy'));
      } else {
        const dateTo = new Date();
        const dateFrom = new Date();
        setCourseId(YEAR_ID_DEFAULT_PGDQ3);
        setDayLock(moment(dateFrom, 'DD/MM/yyyy'));
        setDayUnLock(moment(dateTo, 'DD/MM/yyyy'));
      }
    }
  }, [detail]);

  const getTime = () => {
    const data = {
      course_id: courseId,
      type: 3,
      from_date: moment(dayLock).format('yyyy-MM-DD').toString(),
      to_date: moment(dayUnLock).format('yyyy-MM-DD').toString(),
      config_type: 1
    };
    evaluationHttp.postLockDayRegisterEvaluationPersonalPgd(data).then((res) => {
      if (res.ok && res.status === 200) {
        handleCancel();
        setIsSubmit(false);
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.SET_DATE_REGISTER_SUCCESS);
        handleRefresh();
      }
    });
  };

  const onChangeLock: DatePickerProps['onChange'] = (date, dateString) => {
    setDayLock(moment(date, 'DD/MM/yyyy'));
  };

  const onChangeUnLock: DatePickerProps['onChange'] = (date, dateString) => {
    setDayUnLock(moment(date, 'DD/MM/yyyy'));
  };

  return (
    <Modal
      title="Khóa nộp đăng ký sáng kiến"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={600}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button
          loading={isSubmit}
          type="primary"
          key="submit"
          size="large"
          onClick={getTime}
          style={{ marginRight: 10 }}>
          Xác nhận
        </Button>
      ]}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', marginRight: 5 }}>
          <label>Ngày bắt đầu:</label>
          <DatePicker
            allowClear={false}
            inputReadOnly
            value={dayLock}
            format={dateFormat}
            style={{ width: '100%' }}
            placeholder="Ngày bắt đầu"
            onChange={onChangeLock}
          />
        </div>
        <div style={{ width: '50%', marginLeft: 5 }}>
          <label>Ngày kết thúc:</label>
          <DatePicker
            allowClear={false}
            inputReadOnly
            value={dayUnLock}
            format={dateFormat}
            style={{ width: '100%' }}
            placeholder="Ngày kết thúc"
            onChange={onChangeUnLock}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ModalCloseAchivement;
