import { RootState } from 'store/core/types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectAgenciesSo = (state: RootState) => state?.listAgenciesSo || initialState;

export const selectLoading = createSelector([selectAgenciesSo], (rating) => rating.loading);
export const selectSuccess = createSelector([selectAgenciesSo], (rating) => rating.success);
export const selectError = createSelector([selectAgenciesSo], (rating) => rating.error);

export const selectAgencesSo = createSelector(
  [selectAgenciesSo],
  (rating) => rating.listDataAgenciesSo
);
export const selectAgencesSoDepartments = createSelector(
  [selectAgenciesSo],
  (rating) => rating.listAgenciesDepartments
);
export const selectDetailevaluationGrantPermissions = createSelector(
  [selectAgenciesSo],
  (rating) => rating.detailevaluationGrantPermissions
);
