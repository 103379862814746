import FunctionUtils from 'utils/FunctionUtils';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AddressHttp } from '../services/address.http';
import { actions } from './slice';

const addressHttp = new AddressHttp();
export function* getProvinces(api, action) {
  const response = yield call(api.getProvinces, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getProvincesSuccess(response.data.data.provices));
    } else {
      yield put(actions.getProvincesFail(response));
    }
  } catch (error) {
    yield put(actions.getProvincesFail(error));
  }
}
export function* getDistricts(api, action) {
  const response = yield call(api.getDistricts, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDistrictsSuccess(response.data.data.districts));
    } else {
      yield put(actions.getDistrictsFail(response));
    }
  } catch (error) {
    yield put(actions.getDistrictsFail(error));
  }
}
export function* getWards(api, action) {
  const response = yield call(api.getWards, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getWardsSuccess(response.data.data.ward));
    } else {
      yield put(actions.getWardsFail(response));
    }
  } catch (error) {
    yield put(actions.getWardsFail(error));
  }
}

export function* AddressSaga() {
  yield all([
    yield takeLatest(actions.getProvinces.type, getProvinces, addressHttp),
    yield takeLatest(actions.getDistricts.type, getDistricts, addressHttp),
    yield takeLatest(actions.getWards.type, getWards, addressHttp),
    
  ]);
}
