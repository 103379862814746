import { InitiativeAchievementHttp } from '../services/initiativeAchievement.http';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { actions } from './slice';
import FunctionUtils from 'utils/FunctionUtils';

const initiativeAchievementHttp = new InitiativeAchievementHttp();

export function* getListInitiative(api, action) {
  const response = yield call(api.getListInitiative, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluation_intiatives
      );
      yield put(actions.getListInitiativeSuccess(configResponse));
    } else {
      yield put(actions.getListInitiativeFail(response));
    }
  } catch (error) {
    yield put(actions.getListInitiativeFail(error));
  }
}
export function* addInitiative(api, action) {
  const response = yield call(api.addInitiative, action.payload);
  try {
    if (response.ok) {
      yield put(actions.addInitiativeSuccess(response.data));
    } else {
      yield put(actions.addInitiativeFail(response));
    }
  } catch (error) {
    yield put(actions.addInitiativeFail(error));
  }
}
// export function* deleteInitiative(api, action) {
//   const response = yield call(api.deleteInitiative, action.payload);
//   try {
//     if (response.ok) {
//       yield put(actions.deleteInitiativeSuccess(response.data));
//     } else {
//       yield put(actions.deleteInitiativeFail(response));
//     }
//   } catch (error) {
//     yield put(actions.deleteInitiativeFail(error));
//   }
// }
export function* detailInitiative(api, action) {
  const response = yield call(api.detailInitiative, action.payload);
  try {
    if (response.ok) {
      yield put(actions.detailInitiativeSuccess(response.data));
    } else {
      yield put(actions.detailInitiativeFail(response));
    }
  } catch (error) {
    yield put(actions.detailInitiativeFail(error));
  }
}

export function* getListEffectScope(api, action) {
  const response = yield call(api.getListEffectScope, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluation_titles
      );
      yield put(actions.getListEffectScopeSuccess(configResponse));
    } else {
      yield put(actions.getListEffectScopeFail(response));
    }
  } catch (error) {
    yield put(actions.getListEffectScopeFail(error));
  }
}

export function* sendInitiativesToSo(api, action) {
  const response = yield call(api.sendInitiativesToSo, action.payload);
  try {
    if (response.ok) {
      yield put(actions.sendInitiativesToSoSuccess(response.data));
    } else {
      yield put(actions.sendInitiativesToSoFail(response));
    }
  } catch (error) {
    yield put(actions.sendInitiativesToSoFail(error));
  }
}

export function* getEvaluationInitiativesSo(api, action) {
  const response = yield call(api.getEvaluationInitiativesSo, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluation_intiatives
      );
      yield put(actions.getEvaluationInitiativesSoSuccess(configResponse));
    } else {
      yield put(actions.getEvaluationInitiativesSoFail(response));
    }
  } catch (error) {
    yield put(actions.getEvaluationInitiativesSoFail(error));
  }
}

export function* approveEvaluationInitiativeSo(api, action) {
  const response = yield call(api.approveEvaluationInitiative, action.payload);
  try {
    if (response.ok) {
      yield put(actions.approveEvaluationInitiativeSoSuccess(response.data));
    } else {
      yield put(actions.approveEvaluationInitiativeSoFail(response));
    }
  } catch (error) {
    yield put(actions.approveEvaluationInitiativeSoFail(error));
  }
}

export function* evaluationInitiativeDecisionNo(api, action) {
  const response = yield call(api.evaluationInitiativeDecisionNo, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListInitiativeSuccess(response.data));
    } else {
      yield put(actions.getListInitiativeFail(response));
    }
  } catch (error) {
    yield put(actions.getListInitiativeFail(error));
  }
}

export function* getListInitiativeSo(api, action) {
  const response = yield call(api.getListInitiativeSo, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluation_intiatives
      );
      yield put(actions.getListInitiativeSoSuccess(configResponse));
    } else {
      yield put(actions.getListInitiativeSoFail(response));
    }
  } catch (error) {
    yield put(actions.getListInitiativeSoFail(error));
  }
}

export function* getEvaluationInitiativesPgd(api, action) {
  const response = yield call(api.getEvaluationInitiativesPgd, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluation_intiatives
      );
      yield put(actions.getEvaluationInitiativesPgdSuccess(configResponse));
    } else {
      yield put(actions.getEvaluationInitiativesPgdFail(response));
    }
  } catch (error) {
    yield put(actions.getEvaluationInitiativesPgdFail(error));
  }
}

export function* CreateInitiativeSaga() {
  yield all([
    yield takeLatest(actions.getListInitiative.type, getListInitiative, initiativeAchievementHttp),
    yield takeLatest(actions.addInitiative.type, addInitiative, initiativeAchievementHttp),
    yield takeLatest(
      actions.getListEffectScope.type,
      getListEffectScope,
      initiativeAchievementHttp
    ),
    yield takeLatest(actions.detailInitiative.type, detailInitiative, initiativeAchievementHttp),
    yield takeLatest(
      actions.sendInitiativesToSo.type,
      sendInitiativesToSo,
      initiativeAchievementHttp
    ),
    yield takeLatest(
      actions.getEvaluationInitiativesSo.type,
      getEvaluationInitiativesSo,
      initiativeAchievementHttp
    ),
    yield takeLatest(
      actions.getEvaluationInitiativesPgd.type,
      getEvaluationInitiativesPgd,
      initiativeAchievementHttp
    ),
    yield takeLatest(
      actions.approveEvaluationInitiativeSo.type,
      approveEvaluationInitiativeSo,
      initiativeAchievementHttp
    ),
    yield takeLatest(
      actions.evaluationInitiativeDecisionNo.type,
      evaluationInitiativeDecisionNo,
      initiativeAchievementHttp
    ),
    yield takeLatest(
      actions.getListInitiativeSo.type,
      getListInitiativeSo,
      initiativeAchievementHttp
    )
  ]);
}
