export const UserApi = {
  GET_INFO: '/user_infos',
  ACTIVE_USER: '/required_change_password',
  CHANGE_PASSWORD: '/change_password',
  UPDATE_INFO_USER: '/user',
  UPDATE_INFO_USER_PGD: '/update_user',
  UPDATE_INFO_USER_DETAIL_PGD: '/user_profiles',
  
};

export const RESPONSE_CONSTANT = {
  SUCCESS: {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD'
  },
  FAIL: {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD'
  }
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ACTIVE_ACCOUNT: 'Kích hoạt tài khoản thành công!',
    CHANGE_PASSWORD: 'Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại!'
  },
  FAIL: {
    ACTIVE_ACCOUNT: 'Kích hoạt tài khoản thất bại!',
    CHANGE_PASSWORD: 'Thay đổi mật khẩu thất bại!'
  }
};
