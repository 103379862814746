import { Button, Card, Tooltip } from 'antd';
import ButtonCreate from 'components/ButtonCreate';
import ButtonSubmit from 'components/ButtonSubmit';
import React from 'react';
import styled from 'styled-components';
const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
export function NavBar(props) {
  const {
    title,
    isBtnPublishScore,
    isButton,
    handleClick,
    handleClickPublish,
    handleClickPublishScore,
    isButtonText,
    handleClickGolbal,
    titleGobal
  } = props;
  return (
    <ContainerHeader>
      <Card>
        <ContentNav>
          <div>
            <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
              <i className="fas fa-home" />
            </div>
            <span style={{ fontSize: '16px', color: 'rgb(24, 144, 255)', fontWeight: 500 }}>
              {title}
            </span>
          </div>

          {isButton && (
            <div
              className="grid gap-15 sm:gap-10"
              style={{ display: 'flex', flexDirection: 'row' }}>
              {isBtnPublishScore && (
                <ButtonSubmit
                  style={{ height: '32px' }}
                  text="Công bố điểm"
                  handleClick={handleClickPublishScore}
                />
              )}
              <ButtonCreate
                icon="fa-bullhorn"
                text="Công bố đánh giá"
                handleClick={handleClickPublish}
              />
              <ButtonCreate text="Thêm đánh giá" handleClick={handleClick} />
            </div>
          )}
          {isButtonText && (
            <div
              className="grid gap-15 sm:gap-10"
              style={{ display: 'flex', flexDirection: 'row' }}>
              <ButtonCreate icon="fa-bullhorn" text={titleGobal} handleClick={handleClickGolbal} />
            </div>
          )}
        </ContentNav>
      </Card>
    </ContainerHeader>
    // <div className="py-13 px-15 text-19 rounded-10 border-1 border-solid border-cbce bg-light shadow-lg d-flex justify-content-between">
    //   <div>
    //     <div className="rounded-10 bg-primary-light text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
    //       <i className="fas fa-home" />
    //     </div>

    //   </div>

    // </div>
  );
}
