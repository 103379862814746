import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import Header from './Header';
import ProviderContext from './ProviderContext';
import Sidebar from './Sidebar';
import * as FromUserSlice from '../store/user/shared/slice';
import { useInjectReducer } from 'store/core/@reduxjs/redux-injectors';
import { useInjectSaga } from 'redux-injectors';
import { UserSaga } from 'store/user/shared/saga';
import { selectDataUser } from 'store/user/shared/selectors';
import useAuth from 'hooks/useAuth';
import banner_elerning from '../assets/images/banner_elerning.png';
import logo_bach_khoa_mini from '../assets/images/logo_bach_khoa_mini.jpg'

function Layout() {
  useInjectReducer({ key: FromUserSlice.sliceKey, reducer: FromUserSlice.reducer });
  useInjectSaga({ key: FromUserSlice.sliceKey, saga: UserSaga });
  const dispatch = useDispatch();
  const localStorage = new LocalStorageService();
  const roles: any = localStorage.getItem(LocalStorageKey.roles);
  const infoUser: any = useSelector(selectDataUser);
  const { setAuth } = useAuth();

  useEffect(() => {
    dispatch(FromUserSlice.actions.getInfoUser());
  }, []);

  useEffect(() => {
    if (infoUser && infoUser.user && Object.entries(infoUser.user).length > 0) {
      setAuth(infoUser.user.roles);
    }
  }, [infoUser, infoUser.user]);
  return (
    <div className="container-scroller">
      {infoUser && infoUser.user && Object.entries(infoUser.user).length > 0 ? (
        <Sidebar roles={infoUser.user} />
      ) : (
        <Sidebar roles={null} />
      )}

      <div className="container-fluid page-body-wrapper">
        <Header />
        <div className="main-panel">
          <div className="content-wrapper">
            <ProviderContext>
              <Outlet />
            </ProviderContext>
          </div>
          {/* <div className="footer" style={{bottom: 10}}>
            <a href='https://elearning.ichcm.edu.vn/' className="brand-logo" style={{width: 350}}>
            <img src={banner_elerning} alt="logo" />
            </a>
            <div className=" flex flex-row" style={{alignItems: "center", paddingLeft: 30}}>
            <span style={{fontSize: "14px"}}>Đơn vị phối hợp thực hiện</span>
              <img
                src={logo_bach_khoa_mini}
                style={{width: 150}}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Layout;
