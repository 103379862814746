import { Card, Input, Select, Space, Table, Tooltip } from 'antd';
import ButtonCreateNew from 'components/ButtonCreateNew';
import { NavBar } from 'components/NavBar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { AgenciesSoSaga } from 'store/ListAgenciesSo/shared/saga';
import styled from 'styled-components';
import { TITLE_NAVBAR, YEAR_ID_DEFAULT } from '_constants/default.constants';
import * as AgenciesSo from 'store/ListAgenciesSo/shared/slice';
import * as common from '../../../store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { selectListSchools } from 'store/common/shared/selectors';
import _ from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { selectAgencesSoDepartments, selectLoading } from 'store/ListAgenciesSo/shared/selector';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ModalCreateAgencies from 'views/ListAgenciesSo/components/ModalCreateAgencies';
import ModalEditDecentralization from 'views/ListAgenciesSo/components/ModalEditDecentralization';
import ChangePassword from 'views/ListAgenciesSo/components/ChangePassword';
import ModalConfirmRemove from 'views/ListAgenciesSo/components/ModalConfirmRemove';
import ButtonCreate from 'components/ButtonCreate';
import ModalPrivilegeTeacherSo from '../components/ModalPrivilegeTeacherSo';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

export function TableReviewChamLSKSo() {
  // useInjectReducer({
  //   key: AgenciesSo.sliceKey,
  //   reducer: AgenciesSo.reducer
  // });
  // useInjectSaga({ key: AgenciesSo.sliceKey, saga: AgenciesSoSaga });
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });
  const toast = useToast();
  const loading: boolean = useSelector(selectLoading);
  const listSchool: any = useSelector(selectListSchools);
  const dispatch = useDispatch();
  const [isConfirmRemove, setIsConfirmRemove] = useState<boolean>(false);
  const [isAddDepartment, setIsAddDepartment] = useState<boolean>(false);
  const [filterData, setFilterData] = useState({
    key_search: '',
    name: '',
    provinceId: 1,
    districtId: 0,
    courseId: YEAR_ID_DEFAULT,
    perPage: 100,
    pageId: 1,
    gradeId: 18,
    type: 0, // 1: thpt,
    isExtraInitiative: true
  });

  const [valueSearch, setValueSearch] = useState('');
  const [isChangePass, setIsChangePass] = useState<boolean>(false);
  const [subNameDepartment, setSubNameDepartment] = useState<string>('');
  const [isInfoUser, setIsInfoUser] = useState<boolean>(false);
  const [dataInfoUser, setdataInfoUser] = useState<boolean>(false);
  const { filters, setFilters } = useAuth();
  const [isPenPrivilegeTeacher, setIsPenPrivilegeTeacher] = useState(false);
  const [isOpenConfirmPublicScore, setIsOpenConfirmPublicScore] = useState<boolean>(false);
  const [schoolObj, setSchoolObj] = useState<any>();
  const createReviewHttp = new InitiativeAchievementHttp();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    // dispatch(AgenciesSo.actions.getListAgenciesSo(filterData));
    dispatch(common.actions.getListSchool(filterData));
  });

  const handleRefresh = () => {
    // dispatch(AgenciesSo.actions.getListAgenciesSo(filters));
    dispatch(common.actions.getListSchool(filterData));
  };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  const handlePenPrivilegeTeacher = (e) => {
    setSchoolObj(e);
    setIsPenPrivilegeTeacher(true);
  };

  const handleCancelPrivilegeTeacher = () => {
    setIsPenPrivilegeTeacher(false);
    handleRefresh();
  };

  const ExportMark = () => {
    setIsOpenConfirmPublicScore(true);
  };

  const handleSendPublicScore = () => {
    const request = {
      course_id: 12
    };
    createReviewHttp.publishGrade28(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Công bố điểm thành công!');
        handleRefresh();
        setIsOpenConfirmPublicScore(false);
      } else {
        toast?.pushError(res.message);
        setIsOpenConfirmPublicScore(false);
      }
    });
  };

  interface dataDepartmentType {
    id: string;
    STT: string;
    department: string;
    user_name: string;
    name: string;
  }

  const columns: ColumnsType<dataDepartmentType> = [
    {
      title: 'STT',
      width: 30,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Tên phòng ban',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#ea5455' }}>--.--</span>;
      }
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#ea5455' }}>--.--</span>;
      }
    },
    {
      title: 'Chức năng',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      key: 'action',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <Link to={`${record.id}?grade_id=18`} style={{ fontSize: '16px' }}>
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-eye mr-5 fa-lg" aria-hidden="true"></i>
                </span>
              </Link>
              <Tooltip title="Xem chi tiết" placement="bottomLeft"></Tooltip>
            </Tooltip>
            <Tooltip title="Phân quyền chấm" placement="bottomLeft">
              <button
                onClick={() => handlePenPrivilegeTeacher(record)}
                style={{ fontSize: '16px' }}>
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-tasks mr-5 fa-lg"></i>
                </span>
              </button>
            </Tooltip>
          </Space>
        );
      }
    }
  ];
  return (
    <div className="pb-30">
      <div className="px-15">
        <NavBar title="Danh sách sáng kiến kinh nghiệm phòng ban" />
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-6 gap-8 sm:gap-10" style={{ marginBottom: '10px' }}>
              <ButtonCreate
                class="sm:span-1"
                style={{ width: '100%' }}
                icon="fas fa-bullhorn"
                text="Công bố điểm"
                handleClick={ExportMark}
              />
              {/* <ButtonCreate
                class="sm:span-1"
                style={{ width: '100%' }}
                icon="fa fa-download"
                text="Xuất file"
                // handleClick={DownloadFile}
              /> */}
            </div>
          </Card>
        </ContainerHeader>
        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                showHeader
                columns={columns}
                dataSource={!_.isEmpty(listSchool) && listSchool}
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.id}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
      </div>
      {isPenPrivilegeTeacher && (
        <ModalPrivilegeTeacherSo
          isOpen={isPenPrivilegeTeacher}
          school={schoolObj}
          listDepartment={!_.isEmpty(listSchool) && listSchool}
          handleCancel={handleCancelPrivilegeTeacher}
        />
      )}
      {isAddDepartment && (
        <ModalCreateAgencies
          isOpen={isAddDepartment}
          handleCancel={() => {
            setIsAddDepartment(!isAddDepartment);
          }}
          handleRefresh={handleRefresh}
        />
      )}
      {isConfirmRemove && (
        <ModalConfirmRemove
          isOpen={isConfirmRemove}
          handleOk={() => {
            setIsConfirmRemove(!isConfirmRemove);
          }}
          handleCancel={() => {
            setIsConfirmRemove(!isConfirmRemove);
          }}
        />
      )}
      {isInfoUser && (
        <ModalEditDecentralization
          isOpen={isInfoUser}
          handleRefresh={handleRefresh}
          handleCancel={() => {
            setIsInfoUser(!isInfoUser);
          }}
          detail={dataInfoUser}
        />
      )}
      {isChangePass && (
        <ChangePassword
          isOpen={isChangePass}
          handleCancel={() => setIsChangePass(!isChangePass)}
          department={subNameDepartment}
        />
      )}
      {isOpenConfirmPublicScore && (
        <ModalConfirmBasic
          isOpen={isOpenConfirmPublicScore}
          titleName="Bạn có chắc chắn muốn công bố điểm?"
          handleCancel={() => setIsOpenConfirmPublicScore(!isOpenConfirmPublicScore)}
          handleOk={handleSendPublicScore}
        />
      )}
    </div>
  );
}
