import styled from 'styled-components';

const HorizontalFilterLayout = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const RightContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const LeftContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export { HorizontalFilterLayout, LeftContainer, RightContainer };
