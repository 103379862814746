export const evaluationApi = {
  GET_API: '/evaluation_titles',
  URL_DOE_EVALUATION_TITLES: '/evaluation_titles',
  URL_EVALUATION_EMULATION_TYPES: 'evaluation_emulation_types',
  URL_EVALUATION_TITLE_TYPES: '/evaluation_title_types',
  URL_EVALUATION_TITLE_TYPES_CONSTRAINT: '/evaluation_title_types/constraint',
  URL_EVALUATION_TITLE_INITIATIVES: '/evaluation_titles_initiatives',
  URL_EVALUATION_TITLE_INITIATIVES_PGD: '/pgd/evaluation_titles_initiatives',
  URL_EVALUATION_TITLE_REGISTER_ALL: '/register_all',
  URL_EVALUATION_TITLE_REQUEST_SO: '/request_to_so',
  URL_EVALUATION_TITLE_GROUP: '/evaluation_titles',
  URL_ADMIN_EVALUATION_TITLES: '/admin/evaluation_titles',
  URL_ADMIN_EVALUATION_TITLES_EXPORT_FILE: '/admin/evaluation_titles/export_excel',
  URL_EVALUATION_DECISIONS_NO: '/evaluation_decisions_no',
  URL_UPDATA_USER_INFO: '/admin/user',
  URL_LIBARIES: '/evaluation_libraries',
  URL_EVALUATION_TITLES_PERSONAL: '/so/evaluation_titles_personal',
  URL_EVALUATION_TITLES_GROUP: '/so/evaluation_titles_group',
  URL_EVALUATION_FILE_REPORT: '/evaluation_schools',
  URL_EVALUATION_EXPORT_FILE_PERSONAL_SO: '/so/evaluation_titles_personal/export',
  URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD: '/pgd/evaluation_titles_personal/export',
  URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD_NEW: '/pgd/evaluations/summary/export',
  URL_EVALUATION_EXPORT_FILE_GROUP_SO: '/so/evaluation_titles_group/export',
  URL_EVALUATION_EXPORT_FILE_GROUP_PGD: '/pgd/evaluation_titles_group/export',
  URL_EVALUATION_EXPORT_FILE_GROUP_PGDNEW: '/pgd/evaluation_titles_group/export',
  URL_EVALUATION_UNREGISTER_SO: '/so/evaluation_titles/unregister',
  URL_EVALUATION_UNREGISTER_PGD: '/pgd/evaluation_titles/unregister',
  URL_EVALUATION_APPROVE_DECISION_NO: '/evaluation_titles/approve_decision_no',
  URL_EVALUATION_APPROVE_DECISION_NO_SO: '/so/evaluation_titles/approve_decision_no',
  URL_EVALUATION_DECISION_NO: '/evaluation_titles/decision_no',
  URL_EVALUATION_LOCK_REGISTER_PERSONAL: '/evaluation_configs',
  URL_EVALUATION_LOCK_REGISTER_PERSONAL_PGD: '/pgd/evaluation_configs',
  URL_EVALUATION_INITIATIVES_SCORE_ME: '/evaluation_initiatives/score/me',
  URL_EVALUATION_INITIATIVES_SCORE: '/evaluation_initiatives/score',
  URL_EVALUATION_LIST_RESULT_END_YEAR: '/evaluation_titles/year',
  URL_EVALUATION_SEND_RESULT_END_YEAR: '/evaluation_titles/send_end_year',
  URL_EVALUATION_SEND_USER_DESCRIPTION: '/evaluations/user_description',
  URL_EVALUATION_SUMMARY_EXPORT: '/so/evaluation_summary/export',
  URL_EVALUATION_LIST_RESULT_END_YEAR_PERSONAL: '/evaluation_titles_initiatives/tttt_personal',
  URL_EVALUATION_LIST_RESULT_END_YEAR_GROUP: '/evaluation_titles_initiatives/tttt_group',
  URL_EVALUATION_LIST_RESULT_END_YEAR_UPDATE: '/evaluation_titles_initiatives/tttt_update',
  URL_EVALUATION_LIST_RESULT_END_YEAR_SENDSO: '/evaluation_titles_initiatives/tttt_submit',
  URL_EVALUATION_LIST_RESULT_END_YEAR_EXPORT_FILE: '/evaluation_titles_initiatives/tttt_export',
  URL_EVALUATION_LIST_RESULT_END_YEAR_LOCK: '/evaluation_titles_initiatives/tttt_lock',
  URL_EVALUATION_TITLES_PERSONAL_PGDQ3: '/pgd/evaluation_titles_personal',
  URL_EVALUATION_TITLES_PERSONAL_PGDQ3_NEW: '/pgd/evaluations/summary', // dùng chung api
  URL_EVALUATION_TITLES_GROUP_PGDQ3: '/pgd/evaluation_titles_group',
  URL_EVALUATION_TITLES_GROUP_PGDQ3_NEW: '/pgd/evaluations/summary' // dùng chung api
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    REGISTER_SUCCESS: 'Đăng ký thành công',
    SEND_TO_SUCCESS: 'Gửi thành công',
    SEND_TO_SO_SUCCESS: 'Gửi về sở thành công',
    UPDATE_SUCCESS: 'Cập nhật thành công',
    ADD_SUCCESS: 'Thêm mới thành công',
    CANCEL_REGISTER_SUCCESS: 'Hủy đăng ký danh hiệu thành công!',
    DELETE_SUCCESS: 'Xóa thành công',
    SET_DATE_REGISTER_SUCCESS: 'Cài đặt thời gian thành công!',
    UPLOAD_SUCCESS: 'Tải file thành công!',
    UPDATE_SUCCESS_PROFILE: "Cập nhật thông tin cá nhân thành công."
  },
  FAIL: {
    REGISTER_FAIL: 'Đăng ký thất bại!',
    CANCEL_REGISTER_FAIL: 'Hủy đăng ký danh hiệu thất bại!',
    SEND_TO_FAIL: 'Gửi sở thất bại!',
    UPDATE_FAIL: 'Cập nhật thất bại!',
    DELETE_REVIEW_FAIL: 'Xóa đánh giá Thất bại!',
    ADD_RATING_FAIL: 'Thêm xếp loại thất bại!',
    ADD_STANDARD_FAIL: 'Thêm tiêu chuẩn thất bại!',
    EDIT_RATING_FAIL: 'Chỉnh sửa xếp loại thất bại!',
    REMOVE_RATING_FAIL: 'Xóa xếp loại thất bại!',
    EDIT_REVIEW_FAIL: 'Chỉnh sửa đánh giá thất bại!',
    EDIT_STANDARD_FAIL: 'Chỉnh sửa tiêu chuẩn, tiêu chí thất bại!',
    CREATE_EVALUATION_BY_ADMIN_FAIL: 'Thêm đánh giá từ quản lý thất bại!',
    SCORE_GREATER_THAN_DEFAULT:
      'Thầy, cô vui lòng nhập lại điểm, lưu ý điểm số không được cao hơn điểm chuẩn!',
    SORE_CHECK_NEGATIVE: 'Điểm đánh giá không được âm, thầy cô vui lòng nhập điểm lại!',
    COPY_REVIEW_FAIL: 'Copy đánh giá thất bại!',
    OVER_TIME_REGISTER:
      'Thời hạn nộp danh sách đăng ký danh hiệu thi đua đã kết thúc. Thầy/ cô vui lòng liên hệ <a target="_blank" href="https://html.spec.whatwg.org/multipage/embedded-content.html#the-track-element">Tại đây< / a> để được hỗ trợ',
    UPDATE_FAIL_PROFILE: "Cập nhật thông tin cá nhân không thành công."
  },
  WARN: {
    ADD_REVIEW_SUCCESS: 'Vui lòng truyền đầy đủ thông tin đánh giá!',
    WARNING_SCORE: 'Điểm đánh giá không được lớn hơn điểm chuẩn!',
    WARNING_INPUT: 'Nội dung không được để trống!',
    WARNING_CREATE_STANDARD:
      'Tổng điểm các tiêu chí phải bằng điềm tiêu chuẩn. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING:
      'Số điểm kết thúc không được nhỏ hơn điểm bắt đầu. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING__REGISTER_ALL: 'Vui lòng chọn danh hiệu!',
    WARNING_UPDATE_PROFILE: "Vui lòng chọn file cần tải lên!"
  }
};
