import { AppConfigType, PGD_KEY } from 'config';

const scorecard_template = {
  mam_non: '/assets/docs/Mầm non-tổng hợp điểm thi đua- năm 2023-2024.xlsx',
  tieu_hoc: '/assets/docs/Mầm non-tổng hợp điểm thi đua- năm 2023-2024.xlsx',
  thcs: '/assets/docs/Mầm non-tổng hợp điểm thi đua- năm 2023-2024.xlsx'
};

const config: { [key in PGD_KEY]: AppConfigType } = {
  general: {
    pages: {
      '/manager-rating-pgd': {
        name: 'Quản lý đánh giá thi đua',
        path: '/manager-rating-pgd',
        header: {
          title: 'Quản lý đánh giá thi đua',
          buttons: ['publishScore', 'publish', 'createReview']
        },
        filter: {
          buttons: []
        },
        scorecard: null
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: []
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {
        filter: {
          buttons: ['sendToGDDT']
        }
      }
    }
  },
  nhabe: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  },
  q3: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  },
  q7: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  },
  binhtan: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  },
  tanphu: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  },
  cantho: {
    pages: {
      '/manager-rating-pgd': {
        header: {
          buttons: ['publishScore', 'exportFile']
        },
        filter: {
          buttons: ['publish', 'createReview']
        },
        scorecard: {
          mam_non: scorecard_template.mam_non,
          tieu_hoc: scorecard_template.tieu_hoc,
          thcs: scorecard_template.thcs
        }
      },
      '/manager-rating-pgd/:id/:period_id': {
        filter: {
          buttons: []
        }
      },
      '/manager-rating-pgd/:evaluationId/detail-evaluation-role-pgd/:user_id/:period_id_self': {
        filter: {
          buttons: ['exportFile']
        }
      },
      '/competition-review-expert-pgd/:id/:period_id': {}
    }
  }
};

export default config;
