import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectListSubjects } from 'store/teacher/shared/selectors';
import styled from 'styled-components';
import _ from 'lodash';
import { selectCourseId } from 'store/common/shared/selectors';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherSaga } from 'store/teacher/shared/saga';
interface ChangeSubject {
  isOpen: boolean;
  handleCancel: () => void;
  teacher: string;
  subIdTeacher: any;
  detail: any;
}
interface IFormInput {
  password: string;
  rePassword: string;
}

const StyleSelect: typeof Select = styled(Select)`
height: 40px;
box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
border-radius: 5px;
& .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
  & .ant-select-selection-search {
      height: 40px !important;
   }
   & .ant-select-selection-search-input {
      height: 40px !important;
   }
   & .ant-select-selection-item {
      line-height: 40px !important;
   }
   & .ant-select-selection-placeholder {
      line-height: 40px !important;
   }
  }
}
` as any;
const { Option } = Select;
function ChangeSubject({ isOpen, handleCancel, teacher, subIdTeacher, detail }: ChangeSubject) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const listSubjects: any = useSelector(selectListSubjects);
  const [sub, setSub] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);

  const renderOpion = () => {
    if (!_.isEmpty(listSubjects) && listSubjects?.edu_subjects.length) {
      return listSubjects.edu_subjects.map((item) => {
        if (item.id === subIdTeacher) {
          return (
            <Option value={item.id} key={item.id} disabled>
              {item.name}
            </Option>
          );
        }
        return (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        );
      });
    }
  };

  const onChangeOption = (value: string) => {
    setSub(value);
  };

  const renderTitleSubject = () => {
    if (!_.isEmpty(listSubjects) && !_.isEmpty(listSubjects.edu_subjects) && subIdTeacher) {
      return (
        <h3>
          * Bộ môn hiện tại:{' '}
          {
            listSubjects.edu_subjects
              .filter((item) => item.id === subIdTeacher)
              ?.reduce((val) => ({ ...val }))?.name
          }
        </h3>
      );
    } else {
      <h3>Hệ thống không tìm thấy bộ môn hiện tại</h3>;
    }
  };

  const handleSubmit = () => {
    const request = {
      course_id: courseId,
      records_id: [detail.id],
      subject_id: parseInt(sub)
    };
    dispatch(FromTeacherSlice.actions.changeSubject(request));
    handleCancel();
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Chuyển giáo viên: ${teacher} sang bộ môn khác`}
      footer={null}>
      <div className="container">
        {renderTitleSubject()}
        <Row wrap justify="center" align="middle">
          <Col span={24} className="mb-5">
            <h4>Chọn môn</h4>
            <StyleSelect
              style={{ width: '100%', height: 40 }}
              placeholder="Chọn môn"
              maxTagCount={'responsive'}
              onChange={onChangeOption}>
              {renderOpion()}
            </StyleSelect>
          </Col>
        </Row>
        <div className="flex justify-end mt-20">
          <Button key="back" size="large" onClick={handleCancel}>
            Đóng
          </Button>
          <button type="button" className="btn btn-add ml-10" onClick={handleSubmit}>
            Cập nhật
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeSubject;
