import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface CommonState {
  loading?: boolean;
  error?: any;
  success?: any;
  listPeriods?: any;
  listCourses: any;
  courseId: number | null;
  periodId: number | null;
  statusId: string;
  listPeriodId: any;
  listEffectScopes: any;
  effectScopeId: number | null;
  listCoursesBase: any;
  listProvinces: any;
  listDistricts: any;
  listWards: any;
  listSchools: any;
  listSchoolsPgd?: any;
  schoolDetail: any;
  gradeId: number;
}

export const initialState: CommonState = {
  loading: false,
  error: null,
  success: {},
  listPeriods: {},
  listCourses: {},
  courseId: null,
  periodId: null,
  statusId: '',
  listPeriodId: {},
  listEffectScopes: {},
  effectScopeId: null,
  listCoursesBase: {},
  listProvinces: {},
  listDistricts: {},
  listWards: {},
  listSchools: {},
  schoolDetail: {},
  listSchoolsPgd: {},
  gradeId: 1
};

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    getPeriods(state, action) {
      state.loading = true;
      state.error = null;
    },
    getPeriodsSuccess(state, action) {
      state.loading = false;
      state.listPeriods = action.payload;
    },
    getPeriodsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getCourses(state, action) {
      state.loading = true;
      state.error = null;
    },
    getCoursesSuccess(state, action) {
      state.loading = false;
      state.listCourses = action.payload;
    },
    getCoursesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getCourseId(state, action) {
      state.loading = true;
      state.error = null;
    },
    getCourseIdSuccess(state, action) {
      state.loading = false;
      state.courseId = action.payload;
    },
    getCourseIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getPeriodId(state, action) {
      state.loading = true;
      state.error = null;
    },
    getPeriodIdSuccess(state, action) {
      state.loading = false;
      state.periodId = action.payload;
    },
    getPeriodIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListPeriodId(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListPeriodIdSuccess(state, action) {
      state.loading = false;
      state.listPeriodId = action.payload;
    },
    getListPeriodIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setStatusId(state, action) {
      state.loading = true;
      state.error = null;
    },
    setStatusIdSuccess(state, action) {
      state.loading = false;
      state.statusId = action.payload;
    },
    setStatusIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEffectScopes(state, action) {
      state.loading = true;
      state.error = null;
    },
    getEffectScopesSuccess(state, action) {
      state.loading = false;
      state.listEffectScopes = action.payload;
    },
    getEffectScopesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEffectScopesId(state, action) {
      state.loading = true;
      state.error = null;
    },
    getEffectScopesIdSuccess(state, action) {
      state.loading = false;
      state.effectScopeId = action.payload;
    },
    getEffectScopesIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getCoursesBase(state, action) {
      state.loading = true;
      state.error = null;
    },
    getCoursesBaseSuccess(state, action) {
      state.loading = false;
      state.listCoursesBase = action.payload;
    },
    getCoursesBaseFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getProvinces(state) {
      state.loading = true;
      state.error = null;
    },
    getProvincesSuccess(state, action) {
      state.loading = false;
      state.listProvinces = action.payload;
    },
    getProvincesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDistricts(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDistrictsSuccess(state, action) {
      state.loading = false;
      state.listDistricts = action.payload;
    },
    getDistrictsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getWards(state, action) {
      state.loading = true;
      state.error = null;
    },
    getWardsSuccess(state, action) {
      state.loading = false;
      state.listWards = action.payload;
    },
    getWardsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSchool(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSchoolSuccess(state, action) {
      state.loading = false;
      state.listSchools = action.payload;
    },
    getListSchoolFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getSchoolDetail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getSchoolDetailSuccess(state, action) {
      state.loading = false;
      state.schoolDetail = action.payload;
    },
    getSchoolDetailFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setGradeId(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setGradeIdSuccess(state, action) {
      state.loading = false;
      state.gradeId = action.payload;
    },
    setGradeIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSchoolPgd(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSchoolPgdSuccess(state, action) {
      state.loading = false;
      state.listSchoolsPgd = action.payload;
    },
    getListSchoolPgdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = CommonSlice;
