import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectAddress = (state: RootState) => state?.address || initialState;

export const selectLoading = createSelector([selectAddress], (address) => address.loading);

export const selectSucccess = createSelector([selectAddress], (address) => address.success);

export const selectError = createSelector([selectAddress], (address) => address.error);

export const selectListProvinces = createSelector([selectAddress], (address) => address.listProvinces);

export const selectListDistricts = createSelector([selectAddress], (address) => address.listDistricts);

export const selectListWards = createSelector([selectAddress], (address) => address.listWards);
