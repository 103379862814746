import { createSlice } from 'store/core/@reduxjs/toolkit';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants/user.constants';

export interface UserState {
  loading: boolean;
  error: any;
  success: any;
  user: any;
}

export const initialState: UserState = {
  loading: false,
  error: {},
  success: {},
  user: {}
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getInfoUser(state) {
      state.loading = true;
      state.error = null;
    },
    getInfoUserSuccess(state, action: any) {
      state.loading = false;
      state.user = action.payload;
    },
    getInfoUserFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    changePassWordUser(state, action: any) {
      state.loading = true;
      state.error = null;
    },
    changePassWordUserSuccess(state, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.CHANGE_PASSWORD,
        message: RESPONSE_MESSAGE.SUCCESS.CHANGE_PASSWORD,
        data: action.payload
      };
    },
    changePassWordUserFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearSuccess(state) {
      state.success = {};
    },
    updateInfoUser(state, action: any) {
      state.loading = true;
      state.error = null;
    },
    updateInfoUserSuccess(state, action: any) {
      state.loading = false;
      state.user = action.payload;
    },
    updateInfoUserFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
  }
});

export const { actions, reducer, name: sliceKey } = UserSlice;
