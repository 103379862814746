import React from 'react';
import './style.css';
export default function ButtonSubmit(props) {
  const { text, handleClick, style } = props;
  return (
    <div className="button" style={style ? style : {cursor:'pointer', height:'auto'}} onClick={handleClick}>
      <div className="icon">
        {/* <i className="fas fa-plus"></i> */}
        <span className="sp-text">{text}</span>
      </div>
    </div>
  );
};