import { APP_CONSTANT, YEAR_ID_DEFAULT } from '_constants/default.constants';
import { HttpRequest } from 'services/api';
import { CompetitionRatingEduApi } from '../constants/competitionRatingEdu.constants';

export class CompetitionRatingHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListReviewEdu = ({
    pageId,
    perPage,
    courseId,
    periodId,
    evaluationCodeId,
    keyword,
    departmentId
  }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${CompetitionRatingEduApi.LIST_REVIEW_EDU}?page_id=${pageId}&per_page=${perPage}&keyword=${
        keyword || ''
      }&evaluation_code_id=${evaluationCodeId || 0}&department_id=${
        departmentId || 0
      }&course_id=${courseId}${periodId}`
    );
  };

  public getListReviewPgd = ({
    pageId,
    perPage,
    courseId,
    periodId,
    evaluationCodeId,
    keyword,
    departmentId
  }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${CompetitionRatingEduApi.LIST_REVIEW_PGD}?page_id=${pageId}&per_page=${perPage}&keyword=${
        keyword || ''
      }&evaluation_code_id=${evaluationCodeId || 0}&course_id=${courseId}${periodId}`
    );
  };

  public getListReviewEduFilter = ({
    pageId,
    perPage,
    courseId,
    periodId,
    evaluationCodeId,
    keyword,
    departmentId
  }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${CompetitionRatingEduApi.LIST_REVIEW_EDU}?page_id=${pageId}&per_page=${perPage}&keyword=${
        keyword || ''
      }&evaluation_code_id=${evaluationCodeId || 0}&department_id=${
        departmentId || 0
      }&course_id=${courseId}${periodId}`
    );
  };

  public addReviewEdu = (data): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.ADD_REVIEW_EDU, data);
  };

  public addReviewPgd = (data): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.ADD_REVIEW_PGD, data);
  };

  public deleteReviewEdu = ({ id }): Promise<any> => {
    return this.request.delete(`${CompetitionRatingEduApi.DELETE_REVIEW_EDU}/${id}`);
  };

  public detailReviewEdu = (id): Promise<any> => {
    return this.request.get(`${CompetitionRatingEduApi.DETAIL_REVIEW_EDU}/${id}`);
  };

  public listSchoolReviewEdu = ({ id, gradeId, pageId, perPage, periodId }): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.LIST_REVIEW_EDU_DETAIL, {
      evaluation_id: id,
      grade_id: gradeId,
      page_id: pageId,
      per_page: perPage,
      period_id: periodId
    });
  };

  public updateReviewRoleEdu = ({
    evaluationId,
    title,
    standards,
    classifications,
    deleteStandardIds,
    deleteClassificationIds,
    deleteCriteriaIds,
    periodId,
    courseId
  }): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.UPDATE_REVIEW_EDU, {
      evaluation_id: evaluationId,
      title: title,
      standards: standards,
      classifications: classifications,
      delete_standard_ids: deleteStandardIds,
      delete_criteria_ids: deleteCriteriaIds,
      delete_classification_ids: deleteClassificationIds,
      period_ids: periodId,
      course_id: courseId
    });
  };

  public updateReviewRolePgd = ({
    evaluationId,
    title,
    standards,
    classifications,
    deleteStandardIds,
    deleteClassificationIds,
    deleteCriteriaIds,
    periodId,
    courseId
  }): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.UPDATE_REVIEW_PGD, {
      evaluation_id: evaluationId,
      title: title,
      standards: standards,
      classifications: classifications,
      delete_standard_ids: deleteStandardIds,
      delete_criteria_ids: deleteCriteriaIds,
      delete_classification_ids: deleteClassificationIds,
      period_ids: periodId,
      course_id: courseId
    });
  };

  public updateReviewRolePgd1 = (data): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.UPDATE_REVIEW_PGD, data);
  };

  public updateReviewRoleEdu1 = (data): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.UPDATE_REVIEW_EDU, data);
  };

  public sendResultToSo = (data): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.URL_EVALUATION_SEND_RESULT_TO_SO, data);
  };

  public detailReviewRoleEdu = (id): Promise<any> => {
    return this.request.get(`${CompetitionRatingEduApi.DETAIL_REVIEW_EDU}/${id}`);
  };

  public detailReviewRolePgd = (id): Promise<any> => {
    return this.request.get(`${CompetitionRatingEduApi.DETAIL_REVIEW_PGD}/${id}`);
  };

  public getListManageGrades = (): Promise<any> => {
    return this.request.get(`${CompetitionRatingEduApi.URL_MANAGE_GRADES}`);
  };

  public copyReviewRoleEdu = ({ evaluationId, courseId, periodId, titleReview }): Promise<any> => {
    return this.request.post(CompetitionRatingEduApi.COPY_REVIEW_EDU, {
      evaluation_id: evaluationId,
      course_id: courseId,
      period_ids: periodId,
      title: titleReview
    });
  };

  public detailEvaluationOfSchool = ({ evaluationId, schoolId, periodId }): Promise<any> => {
    return this.request.get(
      `${CompetitionRatingEduApi.DETAIL_EVALUATION_ROLE_EDU_OF_SCHOOL}/${evaluationId}/${schoolId}/${periodId}`
    );
  };

  public evaluationsApproveReject = (data): Promise<any> => {
    return this.request.patch(`${CompetitionRatingEduApi.URL_EVALUATION_APPROVE_REJECT}`, data);
  };

  public setGradeId = (id: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ ok: true, data: { id } });
      } catch (error) {
        reject(error);
      }
    });
  };
}
