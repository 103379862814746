import React, { useCallback, useEffect, useState, useRef } from 'react';
import AppLoading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import * as FromCreateInitiativeSlice from '../../../store/initiativeAchievement/shared/slice';
import { selectLoading } from 'store/createReview/shared/selectors';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import { useToast } from 'components/Toast';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { Space, Tooltip, Card, Select, Input } from 'antd';
import Pagination from 'components/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as common from '../../../store/common/shared/slice';
import _ from 'lodash';
import moment from 'moment';
import {
  selectListCoursesBase,
  selectListSchools,
  selectListSchoolsPgd,
  selectLoading as selectLoadingSchool
} from 'store/common/shared/selectors';
import { selectListProvinces, selectListDistricts } from 'store/address/shared/selectors';
import ModalCreateReview from '../components/ModalCreateReview';
import ModalCreateReviewDKSK from '../components/ModalCreateReviewDKSK';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import styled from 'styled-components';
import ModalEdit from '../components/ModalEdit';
import ModalDetail from '../components/ModalDetail';
import FunctionUtils from 'utils/FunctionUtils';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ButtonCreate from 'components/ButtonCreate';
import ButtonSubmitSearch from 'components/ButtonSubmitSearch';
import { Helmet } from 'react-helmet-async';
import { CommonSaga } from 'store/common/shared/saga';
import * as competionRatingEdu from 'store/competionRatingEdu/shared/slice';
import { CompetitionRatingSaga } from 'store/competionRatingEdu/shared/saga';
import ModalCloseAchivement from '../components/ModalCloseAchivement';
import { selectDataDayRegisterEvaluationPersonal } from 'store/evaluation/shared/selectors';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import * as address from 'store/address/shared/slice';
import { AddressSaga } from 'store/address/shared/saga';
import {
  YEAR_ID_DEFAULT,
  YEAR_ID_DEFAULT_PGDQ3,
  groupSchool,
  groupSchoolPgdQ3
} from '_constants/default.constants';
import ModalTimeExtension from '../components/ModalTimeExtension';
import { AppHelper } from 'utils/app.helpers';
import { CSVLink } from 'react-csv';
import { selectManageGrades } from 'store/competionRatingEdu/shared/selectors';
import useAuth from 'hooks/useAuth';
import { CompetitionRatingHttp } from 'store/competionRatingEdu/services/competitionRatingEdu.http';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;
const { Option } = Select;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TableReviewLSKPgd() {
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });

  useInjectReducer({ key: address.sliceKey, reducer: address.reducer });
  useInjectSaga({ key: address.sliceKey, saga: AddressSaga });

  useInjectReducer({ key: competionRatingEdu.sliceKey, reducer: competionRatingEdu.reducer });
  useInjectSaga({ key: competionRatingEdu.sliceKey, saga: CompetitionRatingSaga });

  const dispatch = useDispatch();
  const competitionRatingHttp = new CompetitionRatingHttp();
  const loading: any = useSelector(selectLoading);
  const listCoursesBase: any = useSelector(selectListCoursesBase);
  const loadingSchool: any = useSelector(selectLoadingSchool);
  const listManageGrades: any = useSelector(selectManageGrades);

  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const toast = useToast();
  const query = useQuery();
  const currentDay = new Date();
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isCreateInitiative, setIsCreateInitiative] = useState<boolean>(false);
  const [isCreateInitiativeDKSK, setIsCreateInitiativeDKSK] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(true);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const listSchool: any = useSelector(selectListSchoolsPgd);
  const listDistricts: any = useSelector(selectListDistricts);
  const dataTimeRegisterEvaluationPersonal: any = useSelector(
    selectDataDayRegisterEvaluationPersonal
  );

  const createReviewHttp = new InitiativeAchievementHttp();

  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [dateUnlockSend, setDateUnlockSend] = useState<any>();
  const [valueTimeExtension, setValueTimeExtension] = useState<Object>({});
  const [dataExportCsv, setDataExportCsv] = useState<any>([]);
  const [listGrades, setListGrades] = useState<any>([]);
  const [schoolId, setSchoolId] = useState(0);
  const [districtId, setDistrictId] = useState(0); // 10 default quan 1
  const [valueGroupSchool, setValueGroupSchool] = useState<number>(0);
  const [defaultValueYear, setDefaultValueYear] = useState<number>(YEAR_ID_DEFAULT_PGDQ3);
  const { filters, setFilters } = useAuth();
  const [filterData, setFilterData] = useState({
    school_id: 0,
    key_search: titleReview,
    name: '',
    provinceId: 1,
    districtId: 0,
    schoolId: schoolId,
    courseId: parseInt(query.get('yearId')!)
      ? parseInt(query.get('yearId')!)
      : YEAR_ID_DEFAULT_PGDQ3,
    perPage: 100,
    pageId: 1,
    type: 0, // 1: thpt,
    isExtraInitiative: true,
    gradeId: 100
  });

  // Show popup dong mo dang ki thu dua
  const [isShowModalCloseAchivement, setIsShowModalCloseAchivement] = useState<boolean>(false);
  const [isOpenTimeExtension, setIsOpenTimeExtension] = useState<boolean>(false);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  useEffect(() => {
    dispatch(common.actions.getListSchoolPgd(filterData));
    dispatch(common.actions.getCoursesBase(filters));
  }, [filterData]);
  // const useEffectOnMount = (effect: React.EffectCallback) => {
  //   useEffect(effect, [filterData]);
  // };

  // useEffectOnMount(() => {
  //   // dispatch(competionRatingEdu.actions.getListManageGrades(filters));
  //   dispatch(common.actions.getListSchoolPgd(filterData));
  //   dispatch(common.actions.getCoursesBase(filters));
  // });

  useEffect(() => {
    // dispatch(competionRatingEdu.actions.getListManageGrades(filters));
    dispatch(common.actions.getListSchoolPgd(filterData));
    dispatch(common.actions.getCoursesBase(filters));
    setDefaultValueYear(
      parseInt(query.get('yearId')!) ? parseInt(query.get('yearId')!) : YEAR_ID_DEFAULT_PGDQ3
    );
    competitionRatingHttp.getListManageGrades().then((res) => {
      if (res.ok) {
        const { grades } = res.data.data;
        setListGrades(grades);
        setValueGroupSchool(
          parseInt(query.get('grade_id')!) ? parseInt(query.get('grade_id')!) : grades[0]?.id
        );
        const newFilter = Object.assign({}, filterData, {
          gradeId: parseInt(query.get('grade_id')!)
            ? parseInt(query.get('grade_id')!)
            : grades[0]?.id
        });
        setNewFilters(newFilter);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(address.actions.getProvinces());
    dispatch(address.actions.getDistricts({ province_id: 1 }));
    const filters = {
      courseId: YEAR_ID_DEFAULT_PGDQ3,
      type: 3,
      config_type: 1
    };
    dispatch(evaluationList.actions.getLockDayRegisterEvaluationPersonal(filters));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataTimeRegisterEvaluationPersonal)) {
      setDateUnlockSend(dataTimeRegisterEvaluationPersonal.evaluation_config);
    }
  }, [dataTimeRegisterEvaluationPersonal]);

  // useEffect(() => {
  //   if (!_.isEmpty(listSchool)) {
  //     handleExportFile();
  //   }
  // }, [listSchool]);

  const handleExportFile = async () => {
    let dataIntiatives: any = [];
    let res;
    setLoadingExport(true);
    const filter = {
      pageId: 1,
      perPage: -1,
      courseId: YEAR_ID_DEFAULT,
      school_id: 0,
      gradeId: valueGroupSchool
    };
    createReviewHttp.downloadEvaluationInitiativesSo(filter).then((res) => {
      if (res.ok && res.status === 200) {
        const { evaluation_intiatives } = res.data.data;
        parserDataExportFile(evaluation_intiatives);
      } else {
        setLoadingExport(false);
      }
    });
    // const response = await Promise.all(
    //   listSchool?.map((item) => {
    //     const filter = {
    //       pageId: 1,
    //       perPage: 100,
    //       courseId: 12,
    //       school_id: item.id
    //     };
    //     res = createReviewHttp.getEvaluationInitiativesSo(filter).then((res) => {
    //       if (res.ok && res.status === 200) {
    //         const { evaluation_intiatives } = res.data.data;
    //         if (evaluation_intiatives.length > 0) {
    //           // console.log(evaluation_intiatives);
    //           evaluation_intiatives.map((item) => {
    //             dataIntiatives.push(item);
    //           });
    //         }
    //       }
    //     });
    //     return res;
    //   })
    // );
    // if (response !== undefined && response.length > 0) {
    //   response.map((item) => {
    //     if (item !== undefined) {
    //       item.map((itm) => {
    //         dataIntiatives.push(itm);
    //       });
    //     }
    //   });
    //   parserDataExportFile(dataIntiatives);
    // } else {
    //   setLoadingExport(false);
    //   toast?.pushWarning('Có lỗi. Vui lòng kiểm tra lại!');
    // }
  };

  const parserDataExportFile = (data) => {
    const listFilterData: any = [];
    let i = 1;
    if (!_.isEmpty(data)) {
      data?.map((item, index) => {
        if (item.status_cham_diem === 1 && item.status_cham_diem2 === 1) {
          const mediumScore = (item.score + item.score2) / 2;
          if (mediumScore >= 91 && item.is_tp) {
            const schoolName = listSchool.find((ls) => ls.id === item.school_id);
            let arena = '';
            if (item.is_tp && item.is_coso) {
              arena = 'cấp Quận, Thành phố';
            } else {
              arena = 'Thành phố';
            }
            const itm = {
              index: i++,
              id: item.id,
              name: item.name,
              user_fullname: item.user_fullname,
              position_title: item.position_title,
              school_name: schoolName.name,
              description: `1.Tóm tắt sáng kiến: ${item.description.replace(
                /"/g,
                ''
              )}\n2.Hiệu quả: ${item.effective.replace(/"/g, '')}\n3.Phạm vi ảnh hưởng: ${arena}`
            };
            listFilterData.push(itm);
          }
        }
      });
      setDataExportCsv(listFilterData);
      setTimeout(() => {
        setLoadingExport(false);
        csvInstance?.current?.link.click();
      }, 200);
    }
  };

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const onClickDetail = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenDetail(!isOpenDetail);
  };

  const handleRefreshSetTime = () => {
    const filters = {
      courseId: YEAR_ID_DEFAULT_PGDQ3,
      type: 3,
      config_type: 1
    };
    dispatch(evaluationList.actions.getLockDayRegisterEvaluationPersonal(filters));
    dispatch(common.actions.getListSchoolPgd(filterData));
  };

  const handleSendToSo = () => {
    if (yearSchool == null || yearSchool == '') {
      toast?.pushWarning('Năm học không tồn tại. Vui lòng chọn năm học.');
      return false;
    }
    const request = {
      courseId: yearSchool,
      ma_cong_chuc: '',
      id_num: '',
      name: '',
      user_fullname: '',
      action: 'dang_ky', // dang_ky hoặc huy_dang_ky
      page_id: -1,
      per_page: -1
    };
    createReviewHttp.sendInitiativesToSo(request).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Gửi danh sách sáng kiến thành công.');
      } else {
        toast?.pushError('Gửi danh sách sáng kiến không thành công.');
      }
      setIsModalConfirm(!isModalConfirm);
    });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listSchool)) {
      if (listSchool.length < filterData.perPage) return true;
      return false;
    }
    return true;
  }, [listSchool]);

  const handleRefresh = () => {
    //dispatch(common.actions.getListSchool(filterData));
  };

  const onChangeValue = (e) => {
    const { value } = e.target;
    if (e.target.value === '') {
      const newFilter = Object.assign({}, filterData, {
        name: ''
      });
      dispatch(common.actions.getListSchool(filterData));
    }
    setTitleReview(value.toLowerCase());
  };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleChangeEmulation = (id?) => {
    setValueGroupSchool(id);
    const newFilter = Object.assign({}, filterData, {
      gradeId: id
    });
    dispatch(common.actions.getListSchoolPgd(newFilter));
  };

  const onChangeOptionSchool = (value) => {
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      schoolId: parseInt(value)
    });
    setNewFilterData(newFilter);
    setSchoolId(value);
  };

  const handleSearch = () => {
    setLoading1(true);
    const newFilter = Object.assign({}, filterData, {
      name: titleReview
    });
    dispatch(common.actions.getListSchool(newFilter));
  };

  const handleShowModalCloseAchivement = () => {
    const filters = {
      courseId: YEAR_ID_DEFAULT_PGDQ3,
      type: 3,
      config_type: 1
    };
    dispatch(evaluationList.actions.getLockDayRegisterEvaluationPersonal(filters));
    setIsShowModalCloseAchivement(true);
  };

  const handleExtend = (item) => {
    const items = {
      evaluation_config_school: item,
      evaluation_config_golbal: dataTimeRegisterEvaluationPersonal
    };
    setValueTimeExtension(items);
    setIsOpenTimeExtension(true);
  };

  const headersExel = [
    { label: 'STT', key: 'index' },
    { label: 'TÊN SÁNG KIẾN', key: 'name' },
    { label: 'TÁC GIẢ', key: 'user_fullname' },
    { label: 'CHỨC VỤ', key: 'position_title' },
    { label: 'ĐƠN VỊ', key: 'school_name' },
    { label: 'TÓM TẮT SÁNG KIẾN, HIỆU QUẢ, PHẠM VI ẢNH HƯỞNG', key: 'description' }
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Mã đơn vị',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên trường',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Quận/Huyện',
      key: 'district_id',
      width: 150,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>
            {!_.isEmpty(listDistricts) &&
              FunctionUtils.parseTitleDistricts(listDistricts, record.district_id)}
          </span>
        );
      }
    },
    {
      title: 'Danh sách giáo viên nộp sáng kiến',
      width: 200,
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        setLoading1(false);
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <Link to={`${record.id}?grade_id=${valueGroupSchool}&yearId=${defaultValueYear}`}>
                <span style={{ color: '"#1890ff', cursor: 'pointer' }}>Xem chi tiết</span>
              </Link>
            </Tooltip>
          </Space>
        );
      }
    },
    {
      title: 'Ngày bắt đầu',
      key: 'from_date',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.evaluation_config)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span style={{ color: '#1890ff' }}>
            {AppHelper.formatDate(record.evaluation_config.from_date, 'DD/MM/yyyy')}
          </span>
        );
      }
    },
    {
      title: 'Ngày kết thúc',
      key: 'to_date',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.evaluation_config)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span style={{ color: '#1890ff' }}>
            {AppHelper.formatDate(record.evaluation_config.to_date, 'DD/MM/yyyy')}
          </span>
        );
      }
    },
    {
      title: 'Chức năng',
      width: 100,
      key: 'active',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (defaultValueYear !== YEAR_ID_DEFAULT) {
          return (
            <Space>
              <Tooltip title="Gia hạn nộp sáng kiến" placement="bottomLeft">
                <span className="clone">
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{
                      color: '#1890ff',
                      cursor: 'pointer',
                      opacity: '0.5'
                    }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-clock"></i>
                  </div>
                </span>
              </Tooltip>
            </Space>
          );
        }

        var currentDay = moment(currentDay).format('YYYY-MM-DD 00:00:00');
        var startDate = moment(dateUnlockSend?.from_date).format('YYYY-MM-DD 00:00:00');
        var endDate = moment(dateUnlockSend?.to_date).format('YYYY-MM-DD 00:00:00');
        if (!_.isEmpty(dateUnlockSend)) {
          if (startDate <= currentDay && currentDay <= endDate) {
            return (
              <Space>
                <Tooltip title="Gia hạn nộp sáng kiến" placement="bottomLeft">
                  <span className="clone">
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{
                        color: '#1890ff',
                        cursor: 'pointer',
                        opacity: '0.5'
                      }}>
                      {/* Xem chi tiết */}
                      <i className="fas fa-clock"></i>
                    </div>
                  </span>
                </Tooltip>
              </Space>
            );
          }
        }
        return (
          <Space>
            <Tooltip title="Gia hạn nộp sáng kiến" placement="bottomLeft">
              <span className="clone" onClick={() => handleExtend(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{
                    color: '#1890ff',
                    cursor: 'pointer'
                  }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-clock"></i>
                </div>
              </span>
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  const handleChangeCourses = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      courseId: e
    });
    setDefaultValueYear(e);
    setNewFilters(newFilter);
  };

  return (
    <div className="pb-0">
      {loadingExport && <AppLoading loading={true} />}
      {!_.isEmpty(listSchool) && loading1 && <AppLoading loading={true} />}
      <Helmet>
        <title>Danh sách trường nộp đăng ký sáng kiến</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      {/* {TITLE_NAVBAR.INTITIATIVE_ACHIEVAMENT_SO} */}
      <div className="px-15">
        <ContainerHeader>
          <Card>
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
              className="grid gap-15 sm:gap-10">
              <div className="sm:span-1">
                <Select
                  placeholder="Năm học"
                  style={{
                    width: '100%'
                  }}
                  onChange={($event) => handleChangeCourses($event)}
                  value={defaultValueYear}
                  options={
                    !_.isEmpty(listCoursesBase) &&
                    listCoursesBase.map((item) => {
                      return {
                        value: item.id,
                        label: item.name
                      };
                    })
                  }
                />
              </div>
              <div style={{ width: '12vw' }}>
                <Select
                  placeholder="Chọn khối"
                  style={{ width: '100%' }}
                  value={valueGroupSchool}
                  onChange={handleChangeEmulation}
                  options={
                    !_.isEmpty(listGrades) &&
                    listGrades.map((item) => {
                      return {
                        value: item.id,
                        label: item.title
                      };
                    })
                  }
                />
              </div>
              <div style={{ width: 250 }} className="md:grid-2 sm:grid-2">
                <Input.Search
                  allowClear
                  placeholder="Mã giáo viên, tên giáo, tên sáng kiến"
                  onChange={(e) => onChangeValue(e)}
                  onPressEnter={(e) => handleSearch()}
                  onSearch={handleSearch}
                  enterButton
                />
              </div>
              <div className="md:grid-2 sm:grid-2">
                <ButtonCreate
                  isDisable={defaultValueYear !== YEAR_ID_DEFAULT}
                  text={
                    moment(currentDay).format('DD/MM/yyyy') <=
                    moment(dateUnlockSend?.to_date).format('DD/MM/yyyy')
                      ? 'Khóa đăng ký sáng kiến'
                      : 'Gia hạn đăng ký sáng kiến'
                  }
                  icon="fa fa-calendar"
                  handleClick={handleShowModalCloseAchivement}
                />
              </div>
              <div className="md:grid-2 sm:grid-2">
                {/* <ButtonCreate
                  text="Xuất danh sách sáng kiến cấp TP"
                  icon="fa fa-download"
                  handleClick={handleExportFile}
                />
                <CSVLink
                  ref={csvInstance}
                  filename={'danh-sach-sang-kien.csv'}
                  style={{ display: 'none' }}
                  data={dataExportCsv}
                  headers={headersExel}
                /> */}
              </div>
            </div>
          </Card>
        </ContainerHeader>

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                //dataSource={!_.isEmpty(listReview) && listReview}
                dataSource={!_.isEmpty(listSchool) && listSchool}
                //dataSource={!_.isEmpty(schoolDetail) && schoolDetail}
                size="middle"
                bordered
                pagination={false}
                loading={loadingSchool}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        {/* <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div> */}
      </div>
      <div className="bg-overlay" />
      {isModalConfirm && (
        <ModalConfirmBasic
          isOpen={isModalConfirm}
          handleOk={handleSendToSo}
          handleCancel={() => setIsModalConfirm(!isModalConfirm)}
          titleName={'Thầy/Cô có đồng ý gửi danh sách sáng kiến về Sở GD&ĐT TP.HCM?'}
        />
      )}

      {isCreateInitiative && (
        <ModalCreateReview
          isOpen={isCreateInitiative}
          handleCancel={() => setIsCreateInitiative(!isCreateInitiative)}
          handleRefresh={handleRefresh}
        />
      )}
      {isCreateInitiativeDKSK && (
        <ModalCreateReviewDKSK
          isOpen={isCreateInitiativeDKSK}
          handleCancel={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenEdit && (
        <ModalEdit
          isOpen={isOpenEdit}
          handleCancel={() => setIsOpenEdit(!isOpenEdit)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isShowModalCloseAchivement && (
        <ModalCloseAchivement
          isOpen={isShowModalCloseAchivement}
          detail={dataTimeRegisterEvaluationPersonal}
          handleCancel={() => setIsShowModalCloseAchivement(false)}
          handleRefresh={handleRefreshSetTime}
        />
      )}
      {isOpenTimeExtension && (
        <ModalTimeExtension
          isOpen={isOpenTimeExtension}
          detail={valueTimeExtension}
          handleCancel={() => setIsOpenTimeExtension(!isOpenTimeExtension)}
          handleRefresh={handleRefreshSetTime}
        />
      )}
    </div>
  );
}
