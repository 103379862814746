export const TeacherApi = {
  LIST_TEACHER: '/manage_teachers',
  LIST_TEACHER_PGD: '/pgd/manage_teachers', // lấy ds giáo viên của trường role pgd
  LIST_TEACHER_SO: '/manage_teachers', // bỏ /so => dùng chung các role /so/manage_teachers
  EDU_SUBJECTS: '/edu_subjects',
  DOWNLOAD_FILE: '/uploads/teacher.xlsx',
  REMOVE_TEACHER: '/manage_teachers/remove',
  ADD_TEACHER: '/manage_teachers/add',
  EDIT_TEACHER: '/manage_teachers/update',
  CHANGE_PASSWORD: '/manage_teachers/reset_password',
  CHANGE_SUBJECT: '/manage_teachers/move',
  LIST_GRADES: '/manage_grades',
  LIST_CLASS_BY_GRADES: '/manage_classes',
  ROLE_TEACHER: '/manage_classes/assign_teacher',
  ADD_TEACHER_BY_FILE: '/manage_teachers/add',
  SEARCH_TEACHERS_BY_MACONGCHUC: '/manage_teachers/get_by_macongchuc',
  MIGRATE_MACONGCHUC: '/manage_teachers/migrate_macongchuc',
  HIDDEN_THIDUA: '/manage_teachers/hidden_thidua'
};

export const RESPONSE_CONSTANT = {
  SUCCESS: {
    REMOVE_TEACHER: 'REMOVE_TEACHER',
    ADD_TEACHER: 'ADD_TEACHER',
    EDIT_TEACHER: 'EDIT_TEACHER',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_SUBJECT: 'CHANGE_SUBJECT',
    ROLE_TEACHER: 'ROLE_TEACHER'
  },
  FAIL: {
    REMOVE_TEACHER: 'REMOVE_TEACHER',
    ADD_TEACHER: 'ADD_TEACHER',
    EDIT_TEACHER: 'EDIT_TEACHER',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_SUBJECT: 'CHANGE_SUBJECT',
    ROLE_TEACHER: 'ROLE_TEACHER'
  }
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    REMOVE_TEACHER: 'Xóa giáo viên thành công!',
    ADD_TEACHER: 'Thêm giáo viên thành công!',
    EDIT_TEACHER: 'Cập nhật thông tin thành công!',
    CHANGE_PASSWORD: 'Thay đổi mật khẩu thành công!',
    CHANGE_SUBJECT: 'Chuyển giáo viên sang bộ môn khác thành công!',
    ROLE_TEACHER: 'Phân quyền giáo viên thành công!'
  },
  FAIL: {
    REMOVE_TEACHER: 'Xóa giáo viên thất bạn!',
    ADD_TEACHER: 'Thêm giáo viên thất bại!',
    SAME_PHONE_NUMBER: 'Số điện thoại bị trùng với giáo viên:',
    EDIT_TEACHER: 'Cập nhật thông tin thất bại!',
    CHANGE_PASSWORD: 'Thay đổi mật khẩu thất bại!'
  }
};
