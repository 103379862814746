import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface evaluationState {
  loading?: boolean;
  error?: any;
  success?: any;
  dataGetApi?: any;
  dataDetail?: any;
  dataListEvaluationEmulationTypes?: any;
  dataListEvaluationTitleTypes?: any;
  dataListEvaluationTitleGroup?: any;
  dataListEvaluationTitlesTeacher?: any;
  dataDoeListEvaluationTitlesTeacher?: any;
  dataListEvaluationDecisionsNo?: any;
  dataListEvaluationLibraries?: any;
  dataListEvaluationTitlesPersonal?: any;
  dataListEvaluationFileReport?: any;
  dataListEvaluationTitlesGroup?: any;
  dataListAdminEvaluationTitlesGroup?: any;
  dataListEvaluationDecisionNo?: any;
  dataListEvaluationInitiativesScoreMe?: any;
  dataDayRegisterEvaluationPersonal?: any;
  dataListEvaluationDecisionNoSo?: any;
  dataListEvaluationResultEndYear?: any;
  dataListEvaluationResultEndYearPersonal?: any;
  dataListEvaluationResultEndYearGroup?: any;
  dataListEvaluationTitlesPgdQ3Personal?: any;
  dataListEvaluationTitlesPgdQ3PersonalNew?: any;
  dataListEvaluationTitlesPgdQ3Group?: any;
  dataListEvaluationTitlesPgdQ3GroupNew?: any;
}

export const initialState: evaluationState = {
  loading: false,
  error: {},
  success: {},
  dataGetApi: {},
  dataDetail: {},
  dataListEvaluationEmulationTypes: {},
  dataListEvaluationTitleTypes: {},
  dataListEvaluationTitleGroup: {},
  dataListEvaluationTitlesTeacher: {},
  dataDoeListEvaluationTitlesTeacher: {},
  dataListEvaluationDecisionsNo: {},
  dataListEvaluationLibraries: {},
  dataListEvaluationTitlesPersonal: {},
  dataListEvaluationFileReport: {},
  dataListEvaluationTitlesGroup: {},
  dataListAdminEvaluationTitlesGroup: {},
  dataListEvaluationDecisionNo: {},
  dataListEvaluationInitiativesScoreMe: {},
  dataDayRegisterEvaluationPersonal: {},
  dataListEvaluationDecisionNoSo: {},
  dataListEvaluationResultEndYear: {},
  dataListEvaluationResultEndYearPersonal: {},
  dataListEvaluationResultEndYearGroup: {},
  dataListEvaluationTitlesPgdQ3Personal: {},
  dataListEvaluationTitlesPgdQ3PersonalNew: {},
  dataListEvaluationTitlesPgdQ3Group: {},
  dataListEvaluationTitlesPgdQ3GroupNew: {}
};

const evaluationSlice = createSlice({
  name: 'evaluationTitles',
  initialState,
  reducers: {
    getApi(state, action) {
      state.loading = true;
      state.error = null;
    },
    getApiSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataGetApi = action.payload;
    },
    getApiFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitleTearch(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitleTearchSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesTeacher = action.payload;
    },
    getListEvaluationTitleTearchFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDoeListEvaluationTitleTearch(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDoeListEvaluationTitleTearchSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataDoeListEvaluationTitlesTeacher = action.payload;
    },
    getDoeListEvaluationTitleTearchFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDetailApi(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDetailApiSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataDetail = action.payload;
    },
    getDetailApiFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationEmulationTypes(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationEmulationTypesSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationEmulationTypes = action.payload;
    },
    getListEvaluationEmulationTypesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitleTypes(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitleTypesSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitleTypes = action.payload;
    },
    getListEvaluationTitleTypesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationDecisionsNo(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationDecisionsNoSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationDecisionsNo = action.payload;
    },
    getListEvaluationDecisionsNoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitleGroup(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitleGroupSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitleGroup = action.payload;
    },
    getListEvaluationTitleGroupFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListAdminEvaluationTitleGroup(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListAdminEvaluationTitleGroupSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListAdminEvaluationTitlesGroup = action.payload;
    },
    getListAdminEvaluationTitleGroupFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationLibraries(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationLibrariesSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationLibraries = action.payload;
    },
    getListEvaluationLibrariesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitlesPersonal(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesPersonalSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesPersonal = action.payload;
    },
    getListEvaluationTitlesPersonalFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitlesGroup(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesGroupSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesGroup = action.payload;
    },
    getListEvaluationTitlesGroupFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationFileReport(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationFileReportSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationFileReport = action.payload;
    },
    getListEvaluationFileReportFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationDecisionNo(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationDecisionNoSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationDecisionNo = action.payload;
    },
    getListEvaluationDecisionNoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationDecisionNoSo(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationDecisionNoSoSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationDecisionNoSo = action.payload;
    },
    getListEvaluationDecisionNoSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationInitiativesScoreMe(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationInitiativesScoreMeSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationInitiativesScoreMe = action.payload;
    },
    getListEvaluationInitiativesScoreMeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getLockDayRegisterEvaluationPersonal(state, action) {
      state.loading = true;
      state.error = null;
    },
    getLockDayRegisterEvaluationPersonalSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataDayRegisterEvaluationPersonal = action.payload;
    },
    getLockDayRegisterEvaluationPersonalFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationResultEndYear(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationResultEndYearSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationResultEndYear = action.payload;
    },
    getListEvaluationResultEndYearFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationResultEndYearPersonal(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationResultEndYearPersonalSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationResultEndYearPersonal = action.payload;
    },
    getListEvaluationResultEndYearPersonalFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationResultEndYearGroup(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationResultEndYearGroupSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationResultEndYearGroup = action.payload;
    },
    getListEvaluationResultEndYearGroupFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitlesPgdQ3Personal(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesPgdQ3PersonalSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesPgdQ3Personal = action.payload;
    },    
    getListEvaluationTitlesPgdQ3PersonalFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListEvaluationTitlesPgdQ3PersonalNew(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesPgdQ3PersonalNewSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesPgdQ3PersonalNew = action.payload;
    },  
    getListEvaluationTitlesPgdQ3PersonalNewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    
    getListEvaluationTitlesPgdQ3Group(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesPgdQ3GroupSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesPgdQ3Group = action.payload;
    },
    getListEvaluationTitlesPgdQ3GroupFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getListEvaluationTitlesPgdQ3GroupNew(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEvaluationTitlesPgdQ3GroupNewSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataListEvaluationTitlesPgdQ3GroupNew = action.payload;
    },
    getListEvaluationTitlesPgdQ3GroupNewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = evaluationSlice;
