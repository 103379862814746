import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import * as FromCreateInitiativeSlice from '../../../store/initiativeAchievement/shared/slice';
import * as evaluationList from 'store/evaluation/shared/slice';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import { TITLE_NAVBAR, YEAR_ID_DEFAULT } from '_constants/default.constants';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import { useToast } from 'components/Toast';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { Space, Tooltip, Card, Select, Input } from 'antd';
import Pagination from 'components/Pagination';
import moment from 'moment';
import * as common from '../../../store/common/shared/slice';
import _ from 'lodash';
import { selectListCoursesBase } from 'store/common/shared/selectors';
import ModalCreateReview from './ModalCreateReview';
import ModalCreateReviewDKSK from './ModalCreateReviewDKSK';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import styled from 'styled-components';
import ModalEdit from './ModalEdit';
import ModalDetail from './ModalDetail';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ButtonSubmit from 'components/ButtonSubmit';
import { Helmet } from 'react-helmet-async';
import { CommonSaga } from 'store/common/shared/saga';
import { selectDataDayRegisterEvaluationPersonal } from 'store/evaluation/shared/selectors';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import ModalNoti from 'components/Modal/ModalNoti';
import ButtonCreate from 'components/ButtonCreate';
import ModalPrivilegeTeacher from './ModalPrivilegeTeacher';
import ModalUploadFile from './ModalUploadFile';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import { ModalViewDecisionNo } from './ModalViewDecisionNo';
import { selectDataUser } from 'store/user/shared/selectors';
import { NavBar } from 'components/NavBar';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;
const ContentButton = styled.div`
  display: flex;
  justify-content: end;
  gap: 6px;
`;
const { Option } = Select;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}
export function TableReviewLSK() {
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  const dispatch = useDispatch();
  const dateNow = new Date();
  const evaluationHttp = new EvaluationHttp();
  const localStorageService = new LocalStorageService();
  const dataTimeRegisterEvaluationPersonal: any = useSelector(
    selectDataDayRegisterEvaluationPersonal
  );
  const listReview: any = useSelector(selectListReview);
  const dataUser: any = useSelector(selectDataUser);
  const loading: any = useSelector(selectLoading);

  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [isModalSendScoreSo, setIsModalSendScoreSo] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [titleDecisionNo, setTitleDecisionNo] = useState<string>('');
  const [detail, setDetail] = useState<string>('');
  const toast = useToast();

  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenUploadFile, setIsOpenUploadFile] = useState<boolean>(false);
  const [isCreateInitiative, setIsCreateInitiative] = useState<boolean>(false);
  const [isCreateInitiativeDKSK, setIsCreateInitiativeDKSK] = useState<boolean>(false);
  const [isOpenNoti, setIsOpenNoti] = useState<boolean>(false);
  const [isPenPrivilegeTeacher, setIsPenPrivilegeTeacher] = useState<boolean>(false);
  const [isShowSendScoreSo, setIsShowSendScoreSo] = useState<boolean>(false);
  const [isOpenNotiFile, setIsOpenNotiFile] = useState<boolean>(false);
  const [isOpenNotiDecisionNo, setIsOpenNotiDecisionNo] = useState<boolean>(false);
  const [isOpenViewDecisionNo, setIsOpenViewDecisionNo] = useState<boolean>(false);
  const [isLoginSSO, setIsLoginSSO] = useState<boolean>(false);
  const [valueDecisionNo, setValueDecisionNo] = useState<any>();
  const [score, setScore] = useState<string>('0');
  const [type, setType] = useState<number>(1);
  const [dateUnlockSend, setDateUnlockSend] = useState<any>();

  const listCourseBase: any = useSelector(selectListCoursesBase);
  const createReviewHttp = new InitiativeAchievementHttp();

  const [yearSchool, setYearSchool] = useState<number>(YEAR_ID_DEFAULT);
  const [titleReview, setTitleReview] = useState<string>('');
  const [infoSchool, setInfoSchool] = useState<any>();
  const [updateTime, setUpdateTime] = useState<any>();
  const [filterData, setFilterData] = useState({
    key_search: '',
    courseId: YEAR_ID_DEFAULT,
    perPage: 10,
    pageId: 1
  });

  //const { filters, setFilters } = useAuth();
  //const createReviewHttp = new CreateReviewHttp();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    //filters.courseId = 0;
    dispatch(common.actions.getCoursesBase(filterData));
    dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filterData));
  });

  useEffect(() => {
    const filters = {
      courseId: YEAR_ID_DEFAULT,
      type: 3
    };
    dispatch(evaluationList.actions.getLockDayRegisterEvaluationPersonal(filters));
    const infoSchool: any = localStorageService.getItem(LocalStorageKey.infoSchools);
    setInfoSchool(infoSchool);
    if (!_.isEmpty(dataUser.user?.user_logintype_code)) {
      setIsLoginSSO(true);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataTimeRegisterEvaluationPersonal)) {
      setDateUnlockSend(dataTimeRegisterEvaluationPersonal.evaluation_config);
    }
  }, [dataTimeRegisterEvaluationPersonal]);

  useEffect(() => {
    if (!_.isEmpty(listReview)) {
      const statusSo = listReview.some((item) => item.status_so === 0);
      if (statusSo) {
        setIsShowSendScoreSo(false);
      } else {
        setIsShowSendScoreSo(true);
      }
      const filters = {
        courseId: YEAR_ID_DEFAULT,
        type: 4,
        schoolId: infoSchool?.school_id || 0
      };
      evaluationHttp.getLockDayRegisterSchool(filters).then((res) => {
        if (res.ok && res.status === 200) {
          setUpdateTime(res.data.data.evaluation_config);
        }
      });
    } else {
      setIsShowSendScoreSo(false);
    }
  }, [listReview]);

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const onClickConfirm = () => {
    var currentDay = moment(dateNow).format('YYYY-MM-DD 00:00:00');
    var startDateSchool = moment(updateTime?.from_date).format('YYYY-MM-DD 00:00:00');
    var endDateSchool = moment(updateTime?.to_date).format('YYYY-MM-DD 00:00:00');
    var startDate = moment(dateUnlockSend?.from_date).format('YYYY-MM-DD 00:00:00');
    var endDate = moment(dateUnlockSend?.to_date).format('YYYY-MM-DD 00:00:00');
    if (!_.isEmpty(updateTime)) {
      if (startDateSchool <= currentDay && currentDay <= endDateSchool) {
        setIsModalConfirm(!isModalConfirm);
        return;
      }
    }
    if (!_.isEmpty(dateUnlockSend)) {
      if (startDate <= currentDay && currentDay <= endDate) {
        setIsModalConfirm(!isModalConfirm);
        return;
      }
    }
    setIsOpenNoti(true);
  };

  const onClickScoreConfirm = () => {
    const valFiles = listReview.filter((item) => _.isEmpty(item.certificates));
    const decisionSchool = listReview.filter(
      (item) => item.decision_school_id === '00000000-0000-0000-0000-000000000000'
    );
    const valFilesDescription = listReview.filter((item) =>
      _.isEmpty(item.certificates_description)
    );
    if (valFiles.length > 0 || valFilesDescription.length > 0) {
      setIsOpenNotiFile(true);
      return;
    }

    if (decisionSchool.length > 0) {
      setIsOpenNotiDecisionNo(true);
      return;
    }

    var currentDay = moment(dateNow).format('YYYY-MM-DD 00:00:00');
    var startDateSchool = moment(updateTime?.from_date).format('YYYY-MM-DD 00:00:00');
    var endDateSchool = moment(updateTime?.to_date).format('YYYY-MM-DD 00:00:00');
    var startDate = moment(dateUnlockSend?.from_date).format('YYYY-MM-DD 00:00:00');
    var endDate = moment(dateUnlockSend?.to_date).format('YYYY-MM-DD 00:00:00');
    if (!_.isEmpty(updateTime)) {
      if (startDateSchool <= currentDay && currentDay <= endDateSchool) {
        setIsModalSendScoreSo(true);
        return;
      }
    }
    if (!_.isEmpty(dateUnlockSend)) {
      if (startDate <= currentDay && currentDay <= endDate) {
        setIsModalSendScoreSo(true);
        return;
      }
    }
    setIsOpenNoti(true);
  };

  const onClickDetail = (item) => {
    setIdReview(item.id);
    setTitle(item.name);
    setIsOpenDetail(!isOpenDetail);
  };
  // const handleDel = () => {
  //   const request = {
  //     id: idReview
  //   };
  //   createReviewHttp.deleteReview(request).then((res) => {
  //     if (res.ok && res.status === 200) {
  //       toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_REVIEW_SUCCESS);
  //       handleRefresh();
  //     } else {
  //       toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_FAIL);
  //     }
  //     setIsModalConfirmDel(!isModalConfirmDel);
  //   });
  // };

  const handleSendToSo = () => {
    if (yearSchool === null) {
      toast?.pushWarning('Năm học không tồn tại. Vui lòng chọn năm học.');
      return false;
    }
    const request = {
      courseId: yearSchool,
      ma_cong_chuc: titleReview,
      id_num: '',
      name: titleReview,
      user_fullname: titleReview,
      action: 'dang_ky', // dang_ky hoặc huy_dang_ky
      page_id: -1,
      per_page: -1
    };
    createReviewHttp.sendInitiativesToSo(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Gửi danh sách sáng kiến thành công.');
        handleRefresh();
      } else {
        toast?.pushError('Gửi danh sách sáng kiến không thành công.');
      }
      setIsModalConfirm(!isModalConfirm);
    });
  };

  const handleSendScoreToSo = () => {
    const request = {
      course_id: yearSchool
    };
    createReviewHttp.sendInitiativesScoreToSo(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Gửi điểm sáng kiến thành công.');
        handleRefresh();
      }
      setIsModalSendScoreSo(false);
    });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filterData.perPage) return true;
      return false;
    }
    return true;
  }, [listReview]);

  const handleRefresh = () => {
    dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filterData));
  };

  const onChangeValue = (e) => {
    setTitleReview(e.target.value);
    // if(e.target.key == 'Enter'){
    //   handleSearch();
    // }
  };

  const onChangeScore = (e) => {
    setScore(e.target.value);
  };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  const onChangeOption = (value) => {
    const newFilter = Object.assign({}, filterData, {
      courseId: parseInt(value),
      key_search: titleReview
    });
    setNewFilterData(newFilter);
    setYearSchool(value);
  };

  const handleSearch = (e) => {
    const newFilter = Object.assign({}, filterData, {
      key_search: titleReview,
      perPage: filterData.perPage,
      pageId: filterData.pageId
    });
    setNewFilterData(newFilter);
  };

  const handleUpdateScore = (id, yearSchool, title, effectScopeId, description, objFile) => {
    //const obj = Object.assign({}, filePath); // array to object
    ///
    if (id === '') {
      toast?.pushWarning('Mã đăng ký không tồn tại. Vui lòng kiểm tra lại thông tin.');
      return;
    }
    const request = {
      title: title,
      courseId: parseInt(yearSchool), //courseId.id! ? courseId.id : 0,
      description: description,
      effect_scope_id: parseInt(effectScopeId.toString()),
      files: objFile,
      score: parseInt(score),
      initiativeId: id
    };
    createReviewHttp.updateInitiative(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Lưu điểm thành công');
        handleRefresh();
      } else {
        toast?.pushError('Lưu điểm không thành công.');
      }
    });
  };
  const handleCancelPrivilegeTeacher = () => {
    setIsPenPrivilegeTeacher(false);
    handleRefresh();
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'CMND/CCCD',
      dataIndex: 'id_num',
      key: 'id_num',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 150,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // if (record.course_id === YEAR_ID_DEFAULT) {
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // }
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        // if (infoSchool?.is_vp_so === true) {
        //   if (record.published === true) {
        //     return <span style={{ color: '#1890ff' }}>{scope}</span>;
        //   }
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // } else {
        //   return <span style={{ color: '#1890ff' }}>{scope}</span>;
        // }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Điểm',
      //dataIndex: 'score',
      key: 'score',
      //width: '10%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff', textAlign: 'center' }
        };
      },
      children: [
        {
          title: 'Giám khảo 1',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (infoSchool?.is_vp_so === true) {
              if (record.published === true) {
                return (
                  <span style={{ color: '#1890ff' }}>
                    {record.status_cham_diem === 1 ? record.score : '--.--'}
                  </span>
                );
              }
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            } else {
              if (record.course_id === YEAR_ID_DEFAULT && record.status_cham_diem === 0) {
                return <span style={{ color: '#1890ff' }}>--.--</span>;
              }
              return (
                <span style={{ color: '#1890ff' }}>
                  {record.status_cham_diem === 1 ? record.score : '--.--'}
                </span>
              );
            }
          }
        },
        {
          title: 'Giám khảo 2',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (infoSchool?.is_vp_so === true) {
              if (record.published === true) {
                return (
                  <span style={{ color: '#1890ff' }}>
                    {record.status_cham_diem2 === 1 ? record.score2 : '--.--'}
                  </span>
                );
              }
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            } else {
              if (record.course_id === YEAR_ID_DEFAULT && record.status_cham_diem2 === 0) {
                return <span style={{ color: '#1890ff' }}>--.--</span>;
              }
              return (
                <span style={{ color: '#1890ff' }}>
                  {record.status_cham_diem2 === 1 ? record.score2 : '--.--'}
                </span>
              );
            }
          }
        },
        {
          title: 'Điểm TB',
          key: 'score',
          //width: '10%',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (infoSchool?.is_vp_so === true) {
              if (record.published === true) {
                return (
                  <span style={{ color: '#1890ff' }}>
                    {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
                      ? (record.score + record.score2) / 2
                      : '--.--'}
                  </span>
                );
              }
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            } else {
              if (
                record.course_id === YEAR_ID_DEFAULT &&
                record.status_cham_diem === 0 &&
                record.status_cham_diem2 === 0
              ) {
                return <span style={{ color: '#1890ff' }}>--.--</span>;
              }
              if (record.course_id === YEAR_ID_DEFAULT) {
                return (
                  <span style={{ color: '#1890ff' }}>
                    {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
                      ? (record.score + record.score2) / 2
                      : '--.--'}
                  </span>
                );
              } else {
                return (
                  <span style={{ color: '#1890ff' }}>
                    {record.status_cham_diem === 1 && record.status_cham_diem2 === 0
                      ? record.score
                      : '--.--'}
                  </span>
                );
              }
            }
          }
        }
      ]
    },
    {
      title: 'Xếp loại',
      key: 'effect_scope_id',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // if (record.course_id === YEAR_ID_DEFAULT) {
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // }
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'Tốt';
          } else if (mediumScore >= 91) {
            scope = 'Xuất sắc';
          } else {
            scope = '--.--';
          }
        } else {
          scope = '--.--';
        }
        if (infoSchool?.is_vp_so === true) {
          if (record.published === true) {
            return <span style={{ color: '#1890ff' }}>{scope}</span>;
          }
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        } else {
          return <span style={{ color: '#1890ff' }}>{scope}</span>;
        }
      }
    },
    {
      title: 'Công nhận sáng kiến',
      key: 'school_decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_school_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_school_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionSchool(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Công nhận Phạm vi ảnh hưởng của sáng kiến',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionDoe(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Trạng thái',
      key: 'status_so',
      width: '8%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>{record.status_so === 1 ? 'Đã gửi' : 'Chưa gửi'}</span>
        );
      }
    },
    {
      title: 'Hành động',
      width: '10%',
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <span className="clone" onClick={() => onClickDetail(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-eye"></i>
                </div>
              </span>
            </Tooltip>
            {!infoSchool?.is_vp_so && (
              <Tooltip title="Thêm file chứng nhận" placement="bottomLeft">
                <span className="clone" onClick={() => handleUploadFiles(record)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-upload"></i>
                  </div>
                </span>
              </Tooltip>
            )}
          </Space>
        );
      }
    }
  ];

  const columnsDeparmaent: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'CMND/CCCD',
      dataIndex: 'id_num',
      key: 'id_num',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 150,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // if (record.course_id === YEAR_ID_DEFAULT) {
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // }
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Điểm',
      //dataIndex: 'score',
      key: 'score',
      //width: '10%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff', textAlign: 'center' }
        };
      },
      children: [
        {
          title: 'Giám khảo 1',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (record.published === true) {
              return (
                <span style={{ color: '#1890ff' }}>
                  {record.status_cham_diem === 1 ? record.score : '--.--'}
                </span>
              );
            }
            return <span style={{ color: '#1890ff' }}>--.--</span>;
          }
        },
        {
          title: 'Giám khảo 2',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (record.published === true) {
              return (
                <span style={{ color: '#1890ff' }}>
                  {record.status_cham_diem2 === 1 ? record.score2 : '--.--'}
                </span>
              );
            }
            return <span style={{ color: '#1890ff' }}>--.--</span>;
          }
        },
        {
          title: 'Điểm TB',
          key: 'score',
          //width: '10%',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (record.published === true) {
              return (
                <span style={{ color: '#1890ff' }}>
                  {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
                    ? (record.score + record.score2) / 2
                    : '--.--'}
                </span>
              );
            }
            return <span style={{ color: '#1890ff' }}>--.--</span>;
          }
        }
      ]
    },
    {
      title: 'Xếp loại',
      key: 'effect_scope_id',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // if (record.course_id === YEAR_ID_DEFAULT) {
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // }
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'Tốt';
          } else if (mediumScore >= 91) {
            scope = 'Xuất sắc';
          } else {
            scope = '--.--';
          }
        } else {
          scope = '--.--';
        }
        if (record.published === true) {
          return <span style={{ color: '#1890ff' }}>{scope}</span>;
        }
        return <span style={{ color: '#1890ff' }}>--.--</span>;
      }
    },
    {
      title: 'Công nhận Phạm vi ảnh hưởng của sáng kiến',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionDoe(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Trạng thái',
      key: 'status_so',
      width: '8%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>{record.status_so === 1 ? 'Đã gửi' : 'Chưa gửi'}</span>
        );
      }
    },
    {
      title: 'Hành động',
      width: '10%',
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <span className="clone" onClick={() => onClickDetail(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-eye"></i>
                </div>
              </span>
            </Tooltip>
            {!infoSchool?.is_vp_so && (
              <Tooltip title="Thêm file chứng nhận" placement="bottomLeft">
                <span className="clone" onClick={() => handleUploadFiles(record)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-upload"></i>
                  </div>
                </span>
              </Tooltip>
            )}
          </Space>
        );
      }
    }
  ];

  const handlePrivilegeScoreUser = () => {
    setIsPenPrivilegeTeacher(true);
  };
  const handleDownloadFile = () => {
    if (_.isEmpty(listReview)) {
      toast?.pushWarning('Trường chưa có sáng kiến để xuất file!');
      return;
    }
    createReviewHttp.exportFileSchool().then((res) => {
      if (res.status === 200) {
        const url = res.config.baseURL + res.config.url;
        let a = document.createElement('a');
        a.target = '_self';
        a.href = url;
        a.click();
      }
    });
  };

  const handleUploadFiles = (item) => {
    setDetail(item);
    setIsOpenUploadFile(true);
  };

  const handleOpenDecisionSchool = (record) => {
    setValueDecisionNo(record);
    setTitleDecisionNo('Công nhận sáng kiến');
    setIsOpenViewDecisionNo(true);
    setType(1);
  };

  const handleOpenDecisionDoe = (record) => {
    setTitleDecisionNo('Công nhận Phạm vi ảnh hưởng của sáng kiến');
    setValueDecisionNo(record);
    setIsOpenViewDecisionNo(true);
    setType(2);
  };

  return (
    <div className="pb-0">
      <Helmet>
        <title>Danh sách sáng kiến</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>      
      <div className="px-15">
      <NavBar title={TITLE_NAVBAR.LIST_ACHIEVAMENT_SCHOOL} />
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-2 gap-15 sm:gap-10">
              <div className="grid sm:grid-6 gap-15 sm:gap-10">
                <div className="md:span-2">
                  <Select
                    placeholder="Năm học"
                    style={{
                      width: '100%'
                    }}
                    onChange={($event) => onChangeOption($event)}
                    value={yearSchool}
                    options={
                      !_.isEmpty(listCourseBase) &&
                      listCourseBase.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })
                    }
                  />
                </div>
                <div className="md:span-3">
                  <Input.Search
                    allowClear
                    placeholder="Mã công chức, họ tên, tên sáng kiến"
                    onChange={(e) => onChangeValue(e)}
                    value={titleReview}
                    onPressEnter={(e) => handleSearch(e)}
                    onSearch={handleSearch}
                    enterButton
                  />
                </div>
                {/* <ButtonSubmit className="md:span-2" text="Tìm kiếm" handleClick={handleSearch} /> */}
              </div>
              <ContentButton>
                <ButtonCreate icon="fa-paper-plane" text="Gửi về Sở" handleClick={onClickConfirm} />
                {isShowSendScoreSo && !infoSchool?.is_vp_so ? (
                  <ButtonCreate
                    icon="fa-paper-plane"
                    text="Nộp điểm về sở"
                    handleClick={onClickScoreConfirm}
                  />
                ) : (
                  <></>
                )}
                {!infoSchool?.is_vp_so ? (
                  <ButtonCreate
                    icon="fa-user-plus"
                    text="Phân quyền chấm"
                    handleClick={handlePrivilegeScoreUser}
                  />
                ) : (
                  <></>
                )}
                <ButtonCreate icon="fa-file" text="Xuất file" handleClick={handleDownloadFile} />
              </ContentButton>
            </div>
          </Card>
        </ContainerHeader>

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={infoSchool?.is_vp_so === true ? columnsDeparmaent : columns}
                dataSource={!_.isEmpty(listReview) && listReview}
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isModalConfirm && (
        <ModalConfirmBasic
          isOpen={isModalConfirm}
          handleOk={handleSendToSo}
          handleCancel={() => setIsModalConfirm(!isModalConfirm)}
          titleName={'Thầy/Cô có đồng ý gửi danh sách sáng kiến về Sở GD&ĐT TP.HCM?'}
        />
      )}

      {isModalSendScoreSo && (
        <ModalConfirmBasic
          isOpen={isModalSendScoreSo}
          handleOk={handleSendScoreToSo}
          handleCancel={() => setIsModalSendScoreSo(!isModalSendScoreSo)}
          titleName={'Thầy/Cô có đồng ý gửi điểm sáng kiến về Sở GD&ĐT TP.HCM?'}
        />
      )}

      {isCreateInitiative && (
        <ModalCreateReview
          isOpen={isCreateInitiative}
          handleCancel={() => setIsCreateInitiative(!isCreateInitiative)}
          handleRefresh={handleRefresh}
        />
      )}
      {isCreateInitiativeDKSK && (
        <ModalCreateReviewDKSK
          isOpen={isCreateInitiativeDKSK}
          handleCancel={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenEdit && (
        <ModalEdit
          isOpen={isOpenEdit}
          handleCancel={() => setIsOpenEdit(!isOpenEdit)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenNoti && (
        <ModalNoti
          isOpen={isOpenNoti}
          titleName='Thời hạn đăng ký sáng kiến đã kết thúc. Thầy/ cô vui lòng liên hệ <a href="/support">Tại đây</a> để được hỗ trợ'
          handleCancel={() => setIsOpenNoti(!isOpenNoti)}
        />
      )}
      {isOpenNotiFile && (
        <ModalNoti
          isOpen={isOpenNotiFile}
          titleName="Vui lòng nhập đủ các file chứng nhận cho giáo viên trước khi gửi về sở!"
          handleCancel={() => setIsOpenNotiFile(!isOpenNotiFile)}
        />
      )}
      {isOpenNotiDecisionNo && (
        <ModalNoti
          isOpen={isOpenNotiDecisionNo}
          titleName="Vui lòng cấp số quyết định và ngày quyết định cho sáng kiến trước khi nộp điểm về sở!"
          handleCancel={() => setIsOpenNotiDecisionNo(!isOpenNotiDecisionNo)}
        />
      )}
      {isPenPrivilegeTeacher && (
        <ModalPrivilegeTeacher
          isOpen={isPenPrivilegeTeacher}
          handleCancel={handleCancelPrivilegeTeacher}
        />
      )}
      {isOpenUploadFile && (
        <ModalUploadFile
          isOpen={isOpenUploadFile}
          handleCancel={() => setIsOpenUploadFile(!isOpenUploadFile)}
          detail={detail}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenViewDecisionNo && (
        <ModalViewDecisionNo
          isOpen={isOpenViewDecisionNo}
          detail={valueDecisionNo}
          title={titleDecisionNo}
          type={type}
          handleCancel={() => setIsOpenViewDecisionNo(!isOpenViewDecisionNo)}
        />
      )}
    </div>
  );
}
