import { createSlice } from 'store/core/@reduxjs/toolkit';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants/createReview.constants';

export interface CreateReviewState {
  loading?: boolean;
  error?: any;
  success?: any;
  listReview?: any;
  detailReview: any;
  listTeacherReview: any;
  detailEvaluationByTeacher: any;
}

export const initialState: CreateReviewState = {
  loading: false,
  error: {},
  success: {},
  listReview: {},
  detailReview: {},
  listTeacherReview: {},
  detailEvaluationByTeacher: {}
};

const CreateReviewSlice = createSlice({
  name: 'createReview',
  initialState,
  reducers: {
    getListReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListReviewSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getListReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    addReviewSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ADD_REVIEW_SUCCESS,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_REVIEW_SUCCESS,
        data: action.payload
      };
    },
    addReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    deleteReviewSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.DELETE_REVIEW_SUCCESS,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_REVIEW_SUCCESS,
        data: action.payload
      };
    },
    deleteReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    detailReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    detailReviewSuccess(state, action) {
      state.loading = false;
      state.detailReview = action.payload;
    },
    detailReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    listTeacherReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    listTeacherReviewSuccess(state, action) {
      state.loading = false;
      state.listTeacherReview = action.payload;
    },
    listTeacherReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDetailEvaluationByTeacher(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDetailEvaluationByTeacherSuccess(state, action) {
      state.loading = false;
      state.detailEvaluationByTeacher = action.payload;
    },
    getDetailEvaluationByTeacherFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    copyReview(state, action) {
      state.loading = true;
      state.error = null;
    },
    copyReviewSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.COPY_REVIEW_SUCCESS,
        message: RESPONSE_MESSAGE.SUCCESS.COPY_REVIEW_SUCCESS,
        data: action.payload
      };
    },
    copyReviewFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetData(state) {
      state.listReview = {};
    }
  }
});

export const { actions, reducer, name: sliceKey } = CreateReviewSlice;
