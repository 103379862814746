import { Button, Card, Tooltip } from 'antd';
import ButtonCreate from 'components/ButtonCreate';
import ButtonSubmit from 'components/ButtonSubmit';
import React from 'react';
import styled from 'styled-components';
const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
const divbtn = styled.div`
  width: auto;
`;

export function NavBarTTSK(props) {
  const {
    title,
    isButton,
    isButtonSend,
    btnTitle,
    icon,
    handleClickSend,
    handleClick,
    isButtonDKSK,
    handleClickDKSK,
    isButtonReward,
    handleClicReward,
    gridNumber,
    isButtonProviso,
    handleClicProviso
  } = props;
  return (
    <ContainerHeader>
      <Card>
        <ContentNav>
          <div>
            <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
              <i className="fas fa-home" />
            </div>
            <span style={{ fontSize: '16px', color: 'rgb(24, 144, 255)', fontWeight: 500 }}>
              {title}
            </span>
          </div>
          <div className={`grid sm:grid-${gridNumber} gap-15 sm:gap-20`}>
            {isButton && <ButtonCreate text="Cập nhật sáng kiến" handleClick={handleClick} />}
            {isButtonSend && (
              <ButtonCreate icon={icon} text={btnTitle} handleClick={handleClickSend} />
            )}
            {isButtonDKSK && (
              <ButtonCreate text="Đăng ký sáng kiến" handleClick={handleClickDKSK} />
            )}
            {isButtonReward && (
              <ButtonCreate text="Đăng ký thi đua" handleClick={handleClicReward} />
            )}
            {isButtonProviso && (
              <ButtonCreate
                icon="fa-file"
                text="Xem điều kiện ĐKTĐ"
                handleClick={handleClicProviso}
              />
            )}
          </div>
        </ContentNav>
      </Card>
    </ContainerHeader>
  );
}

export function NavBarLSK(props) {
  const { title, isButton, handleClick } = props;
  return (
    <ContainerHeader>
      <Card>
        <ContentNav>
          <div>
            <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
              <i className="fas fa-home" />
            </div>
            <span style={{ fontSize: '18px' }}>{title}</span>
          </div>

          {/* {isButton && <ButtonCreate text="BỔ SUNG SÁNG KIẾN" handleClick={handleClick} />} */}
          {isButton && (
            <ButtonSubmit text="Gửi danh sách sáng kiến về Sở" handleClick={handleClick} />
          )}
        </ContentNav>
      </Card>
    </ContainerHeader>
  );
}
