import { AppConfig } from 'index';
import config from './pgd.config';

export enum PGD_KEY {
  general = 'general',
  nhabe = 'nhabe',
  q3 = 'q3',
  q7 = 'q7',
  binhtan = 'binhtan',
  tanphu = 'tanphu',
  cantho = 'cantho'
}

export const PGD_LIST: { key: keyof typeof PGD_KEY; id: number }[] = [
  { key: PGD_KEY.general, id: -1 },
  { key: PGD_KEY.tanphu, id: 4945 },
  { key: PGD_KEY.cantho, id: 5101 }, // cantho

  { key: PGD_KEY.nhabe, id: 5092 },
  { key: PGD_KEY.q3, id: 4975 },
  { key: PGD_KEY.q7, id: 5054 },
  { key: PGD_KEY.binhtan, id: 5045 }
];

export type AppConfigType = {
  pages?: { [key: string]: any };
};

export const getPGDInfo = (id: any): { key: keyof typeof PGD_KEY; id: number } | null => {
  if (id == null) {
    return null;
  }

  const info = PGD_LIST.find((item) => item.id === id);
  return info || null;
};

export function mergeConfigs(highOrderConfig: any, config: any) {
  const mergedConfig = {};

  if (highOrderConfig != null) {
    for (const key in highOrderConfig) {
      if (config != null && key in config) {
        if (
          typeof highOrderConfig[key] === 'object' &&
          !Array.isArray(highOrderConfig[key]) &&
          typeof config[key] === 'object' &&
          !Array.isArray(config[key])
        ) {
          mergedConfig[key] = mergeConfigs(highOrderConfig[key], config[key]);
        } else {
          mergedConfig[key] = highOrderConfig[key];
        }
      } else {
        mergedConfig[key] = highOrderConfig[key];
      }
    }
  }

  if (config != null) {
    for (const key in config) {
      // If the key doesn't exist in the merged config, add it with the value from the second config
      if (!(key in mergedConfig)) {
        mergedConfig[key] = config[key];
      }
    }
  }

  return mergedConfig;
}

export const getConfig = (): AppConfigType => {
  const highOrderConfig: AppConfigType = { pages: [] };
  const generalConfig = config.general;

  return mergeConfigs(highOrderConfig, generalConfig);
};

export const assignNewConfigToAppConfig = (newConfig: AppConfigType) => {
  const mergeConfig = mergeConfigs(newConfig, AppConfig);
  Object.assign(AppConfig, mergeConfig);
};
