import React from 'react';
import apisauce, { ApisauceInstance } from 'apisauce';
import { LocalStorageKey, LocalStorageService } from './localStorage.service';
import { isExpired } from 'react-jwt';
import Swal from 'sweetalert2';
import { debug } from 'console';

interface LocationState {
  from: {
    pathname: string;
  };
}

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

export async function request(
  url: string,
  options?: RequestInit
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
export class HttpRequest {
  request: ApisauceInstance;
  localService: any;
  location: any;
  listUrlRemoveNotiError = ['/evaluation_titles'];

  constructor(APIEndpoint) {
    const status = navigator.onLine;
    if (status == false) {
      Toast.fire({
        icon: 'warning',
        title: 'Đường truyền mạng không ổn định. Vui lòng xem lại đường truyền kết nối mạng.'
      });
    }

    this.localService = new LocalStorageService();
    this.request = apisauce.create({
      baseURL: APIEndpoint,
      timeout: 3600000,
      withCredentials: true
    });

    this.request.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = this.localService.getItem(LocalStorageKey.token);
        const isTokenExpired = isExpired(token!);
        if (token) {
          if (isTokenExpired) {
            localStorage.removeItem(LocalStorageKey.token);
            window.open('/login', '_parent');
          } else {
            config.headers.Authorization = token;
          }
        }
        // else if (token== null && window.location.pathname == "/login") {
        //  localStorage.removeItem(LocalStorageKey.token);
        //  // return false;
        // }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.request.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { response } = error;
        let url = '';
        let dataParse: any;
        if (window.location.pathname == '/emulation-title-pgd') {
          url = response?.config.url;
          dataParse = JSON.parse(response?.config?.data);
        }
        const code = response?.data.errors.reduce((item: any) => ({ ...item }))?.code;
        if (response == undefined) {
          return false;
        }
        if (
          response?.status === 401 &&
          (code === 'invalid_credential_exception' || code === 'required_change_password_exception')
        ) {
          // localStorage.removeItem(LocalStorageKey.token);
          localStorage.removeItem(LocalStorageKey.isReLogin);
          localStorage.removeItem(LocalStorageKey.pathName);
          Toast.fire({
            icon: 'warning',
            title: error.response.data.errors[0].message
          });

          return false;
        }

        if (window.location.pathname == '/login' && error.response.status == 401) {
          localStorage.removeItem(LocalStorageKey.token);
          localStorage.removeItem(LocalStorageKey.isReLogin);
          localStorage.removeItem(LocalStorageKey.pathName);
          //this.localService.setItem({ key: LocalStorageKey.isReLogin, value: true });
          //this.localService.setItem({ key: LocalStorageKey.pathName, value: window.location.pathname });
          return false;
        }
        if (response?.status === 401 && code === 'access_denied_exception') {
          localStorage.removeItem(LocalStorageKey.token);
          localStorage.removeItem(LocalStorageKey.isReLogin);
          localStorage.removeItem(LocalStorageKey.pathName);
          window.open('/login', '_parent');
        }

        if (error.response.status == 401) {
          Toast.fire({
            icon: 'warning',
            title: error.response.data.errors[0].message
          });
          return false;
        }
        if (
          error.response.status == 400 ||
          error.response.status == 500 ||
          error.response.status == 403
        ) {
          if (error.response.data == '') {
            return false;
          }
          var msg = error.response.data.errors[0].message;
          if (this.listUrlRemoveNotiError.includes(url.split('?')[0])) {
            if (dataParse.phan_loai === 0) {
              Toast.fire({
                icon: 'warning',
                title: 'Danh hiệu này đã được cập nhật'
              });
              return false;
            }
          }

          Toast.fire({
            icon: 'warning',
            title: msg
          });
          return false;
        }
        return Promise.reject(error);
      }
    );
  }
}
