export const CreateReviewApi = {
  LIST_REVIEW: '/evaluations',
  ADD_REVIEW: '/evaluations',
  DELETE_REVIEW: '/evaluations',
  DETAIL_REVIEW: '/evaluations',
  UPDATE_REVIEW: '/evaluations/save_standard_criteria',
  LIST_REVIEW_TEACHERS: '/evaluations/list_evaluation_teachers',
  DETAIL_EVALUATION_BY_TEACHER: '/evaluations/teacher',
  COPY_REVIEW: '/evaluations/copy'
};

export const RESPONSE_CONSTANT = {
  SUCCESS: {
    ADD_REVIEW_SUCCESS: 'ADD_REVIEW_SUCCESS',
    DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS',
    COPY_REVIEW_SUCCESS: 'COPY_REVIEW_SUCCESS'
  },
  FAIL: {
    ADD_REVIEW_SUCCESS: 'ADD_REVIEW_SUCCESS',
    DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS',
    COPY_REVIEW_FAIL: 'COPY_REVIEW_FAIL'
  },
  WARN: {
    ADD_REVIEW_SUCCESS: 'ADD_REVIEW_SUCCESS',
    DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS'
  }
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ADD_REVIEW_SUCCESS: 'Thêm đánh giá thành công!',
    DELETE_REVIEW_SUCCESS: 'Xóa đánh giá thành công!',
    ADD_RATING_SUCCESS: 'Thêm xếp loaị thành công!',
    ADD_STANDARD_SUCCESS: 'Thêm tiêu chuẩn thành công!',
    EDIT_RATING_SUCCESS: 'Chỉnh sửa xếp loại thành công!',
    REMOVE_RATING_SUCCESS: 'Xóa xếp loại thành công!',
    EDIT_REVIEW_SUCCESS: 'Chỉnh sửa đánh giá thành công!',
    EDIT_STANDARD_SUCCESS: 'Chỉnh sửa tiêu chuẩn, tiêu chí thành công!',
    UPDATE_SELF_ASSESSEMENT_SUCCESS: 'Cập nhật tự đánh giá thành công',
    UPDATE_SELF_FILE_SUCCESS: 'Thêm minh chứng thành công',
    SEND_TO_SO_SELF_ASSESSEMENT_SUCCESS: 'Đã gửi tự đánh giá về sở!',
    CREATE_EVALUATION_BY_ADMIN_SUCCESS: 'Thêm đánh giá từ quản lý thành công!',
    SCORE_EVALUATION_BY_ADMIN_SUCCESS: 'Chấm điểm đánh giá từ quản lý thành công!',
    SCORE_SUCCESS: 'Chấm điểm thi đua thành công!',
    COPY_REVIEW_SUCCESS: 'Copy đánh giá thành công!',
    UPDATE_SUCCESS: 'Cập nhật thành công',
    ADD_SUCCESS: 'Thêm mới thành công',
    DELETE_SUCCESS: 'Xóa thành công',
    SEND_SO_SUCCESS: 'Gửi đề nghị về sở thành công!',
    UPDATE_DECISION_NO: 'Cập nhật số quyết định thành công!'
  },
  FAIL: {
    ADD_REVIEW_FAIL: 'Thêm đánh giá thất bại!',
    ADD_FAIL: 'Thêm mới thất bại!',
    UPDATE_FAIL: 'Cập nhật thất bại!',
    DELETE_REVIEW_FAIL: 'Xóa đánh giá Thất bại!',
    ADD_RATING_FAIL: 'Thêm xếp loại thất bại!',
    ADD_STANDARD_FAIL: 'Thêm tiêu chuẩn thất bại!',
    SEND_STANDARD_FAIL: 'Gửi đánh giá về sở thất bại!',
    EDIT_RATING_FAIL: 'Chỉnh sửa xếp loại thất bại!',
    REMOVE_RATING_FAIL: 'Xóa xếp loại thất bại!',
    EDIT_REVIEW_FAIL: 'Chỉnh sửa đánh giá thất bại!',
    EDIT_STANDARD_FAIL: 'Chỉnh sửa tiêu chuẩn, tiêu chí thất bại!',
    CREATE_EVALUATION_BY_ADMIN_FAIL: 'Thêm đánh giá từ quản lý thất bại!',
    SCORE_GREATER_THAN_DEFAULT:
      'Thầy, cô vui lòng nhập lại điểm, lưu ý điểm số không được cao hơn điểm chuẩn!',
    SORE_CHECK_NEGATIVE: 'Điểm đánh giá không được âm, thầy cô vui lòng nhập điểm lại!',
    COPY_REVIEW_FAIL: 'Copy đánh giá thất bại!'
  },
  WARN: {
    ADD_REVIEW_SUCCESS: 'Vui lòng truyền đầy đủ thông tin đánh giá!',
    WARNING_SCORE: 'Điểm đánh giá không được lớn hơn điểm chuẩn!',
    WARNING_CREATE_STANDARD:
      'Tổng điểm các tiêu chí phải bằng điềm tiêu chuẩn. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING:
      'Số điểm kết thúc không được nhỏ hơn điểm bắt đầu. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING_TOTAL:
      'Số điểm kết thúc không lớn hơn điểm tổng của tiêu chuẩn. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING_EDU:
      'Số điểm kết thúc không được nhỏ hơn hoặc bằng điểm bắt đầu. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_SCORE_FROM_RATING_EDU:
      'Số điểm bắt đầu không được nhỏ hơn hoặc bằng số điểm kết thúc của xếp loại trước!',
    WARNING_SELECT_YEAR: 'Vui lòng chọn năm!'
  }
};
