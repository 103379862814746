import { EyeOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Tooltip, Radio, Select } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherSaga } from 'store/teacher/shared/saga';
import {
  selectListSubjects,
  selectListTeacher,
  selectLoading,
  selectSuccess
} from 'store/teacher/shared/selectors';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
import styled from 'styled-components';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import Pagination from 'components/Pagination';
import { selectDataUser } from 'store/user/shared/selectors';
import { useToast } from 'components/Toast';

const ContainerTable = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface IModalListTeacherSo {
  isOpen: boolean;
  listDepartment: any[];
  listTeacherChoose: any[];
  handleCancel: () => void;
  handleRefresh: () => void;
  handleSuccess: (teacher: any[]) => void;
  school?: any;
  isEdit: boolean;
}

interface DataType {
  teacher_fullname: string;
  id_num: string;
  birthday: Date;
  phone_number: string;
  position_title: string;
  ma_cong_chuc: string;
  subject_name: string;
  action: string;
  school_id: string;
}

function ModalListTeacherSo({
  isOpen,
  listDepartment,
  listTeacherChoose,
  handleCancel,
  handleRefresh,
  handleSuccess,
  school,
  isEdit
}: IModalListTeacherSo) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listTeacher: any = useSelector(selectListTeacher);
  const loading: boolean = useSelector(selectLoading);
  const dataUser: any = useSelector(selectDataUser);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('radio');
  const [filterData, setFilterData] = useState({
    keyword: '',
    subjectId: 0,
    perPage: 10,
    pageId: 1,
    schoolId: 0
  });
  const [valueSearch, setValueSearch] = useState('');
  const [teacherFullname, setTeacherFullname] = useState('');
  const [valueSelect, setValueSelect] = useState<any[]>([]);
  const [valueSelectId, setValueSelectId] = useState<any[]>([]);
  const [userIdCreate, setUserIdCreate] = useState<any>();
  const [isLoginSSO, setIsLoginSSO] = useState<boolean>(false);
  const [idDepartment, setIdDepartment] = useState(18);
  const [schoolObj, setSchoolObj] = useState<any>();
  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    dispatch(FromTeacherSlice.actions.getListTeacher(filterData));
  });

  useEffect(() => {
    if (listTeacherChoose) {
      setUserIdCreate(listTeacherChoose[0].userId_create);
      setValueSelectId(listTeacherChoose.map((item) => item.user_id));
      setTeacherFullname(listTeacherChoose[0].teacher_fullname);
    }
    if (!_.isEmpty(dataUser.user?.user_logintype_code)) {
      setIsLoginSSO(true);
    }
    let schoolId = 0;
    if (isEdit) {
      if (listTeacherChoose[0].user_id) {
        schoolId = listTeacherChoose[0].school_id;
      } else {
        schoolId = school.school_id;
      }
    } else {
      schoolId = school.id;
    }

    setIdDepartment(schoolId);
    const newFilter = Object.assign({}, filterData, {
      schoolId: schoolId
    });
    setFilterData(newFilter);
    setSchoolObj(school);
  }, [listTeacherChoose, listDepartment]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      // const dataSelected = selectedRows.filter((item: any) => item.user_id !== userIdCreate);
      console.log(selectedRowKeys);
      const dataSelected = selectedRows.filter((item: any) => item.user_id !== userIdCreate);
      if (dataSelected.length > 0) {
        setValueSelect(selectedRows);
        setValueSelectId(selectedRows.map((itm: any) => itm.user_id));
      } else {
        toast?.pushWarning('Không phân quyền chuyên viên chấm sáng kiến của mình!');
      }
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.teacher_fullname === teacherFullname, // Column configuration not to be checked
      name: record.teacher_fullname
    })
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: isLoginSSO ? 'Mã định danh bộ' : 'Mã công chức',
      dataIndex: isLoginSSO ? 'teacher_code' : 'ma_cong_chuc',
      key: isLoginSSO ? 'teacher_code' : 'ma_cong_chuc',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#ea5455' }}>--.--</span>;
      }
    },
    {
      title: 'Họ tên giáo viên',
      dataIndex: 'teacher_fullname',
      key: 'teacher_fullname',
      width: 300,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#ea5455' }}>--.--</span>;
      }
    }
  ];

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listTeacher)) {
      if (listTeacher.teacher_records.length < filterData.perPage) return true;
      return false;
    }
  }, [listTeacher]);

  const changeValueSearch = (e) => {
    const { value } = e.target;
    setValueSearch(value.toLowerCase());
    if (value === '') {
      handleSearch();
    }
  };

  const handleSearch = (value?: string): any => {
    const newFilter = Object.assign({}, filterData, {
      keyword: value,
      perPage: 10,
      pageId: 1
    });
    setNewFilters(newFilter);
  };

  const handleSubmit = () => {
    if (valueSelect.length > 0) {
      handleSuccess(valueSelect);
      handleCancel();
    }
  };

  const handleChangeDepartment = (e) => {
    setIdDepartment(e);
    const newFilter = Object.assign({}, filterData, {
      schoolId: e
    });
    setNewFilters(newFilter);
  };
  return (
    <Modal
      title={'Chọn chuyên viên chấm điểm ' + `${schoolObj?.name}`}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={600}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Cập nhật
        </Button>
      ]}>
      <ContainerTable>
        <ConfigProviderCustomize>
          <Table
            rowSelection={{
              type: selectionType,
              selectedRowKeys: valueSelectId,
              ...rowSelection
            }}
            showHeader
            columns={columns}
            style={{ height: ' 50vh', overflow: ' auto' }}
            dataSource={listTeacher?.teacher_records}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (valueSelectId[0] === undefined) {
                    toast?.pushWarning(`Vui lòng không chọn cùng một giám khảo chấm!`);
                  }
                }
              };
            }}
            title={(currentPageData) => {
              return (
                <div className="grid md:grid-2 lg:grid-2 gap-6 grid-container">
                  <div className="md:span-1 grid-container__item2">
                    <Input.Search
                      allowClear
                      placeholder="Họ tên chuyên viên"
                      // onChange={(e) => changeValueSearch(e)}
                      // value={valueSearch}
                      // onPressEnter={(e) => handleSearch()}
                      onSearch={handleSearch}
                      enterButton
                    />
                  </div>
                  <div className="md:span-1 grid-container__item2">
                    <Select
                      placeholder="Chọn phòng ban chấm"
                      style={{
                        width: '100%'
                      }}
                      onChange={($event) => handleChangeDepartment($event)}
                      value={idDepartment}
                      options={listDepartment?.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })}
                    />
                  </div>
                </div>
              );
            }}
            size="middle"
            bordered
            pagination={false}
            loading={loading}
            rowKey={(record: any) => record.user_id}
          />
        </ConfigProviderCustomize>
      </ContainerTable>
      <div className="mt-30">
        <Pagination
          current={filterData.pageId}
          onClickNext={() => handleNext()}
          onClickRev={() => handleRev()}
          rowsPerPage={filterData.perPage}
          onClickPerpage={selectPerpage}
          isDisableNext={isCheckDisable()}
        />
      </div>
    </Modal>
  );
}

export default ModalListTeacherSo;
