import { createSlice } from 'store/core/@reduxjs/toolkit';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants/teacher.constants';

export interface TeacherSoState {
  loading: boolean;
  error: any;
  success: any;
  listTeacherSo: any;
  listSubjects: any;
}

export const initialState: TeacherSoState = {
  loading: false,
  error: {},
  success: {},
  listTeacherSo: {},
  listSubjects: {}
};

const TeacherSoSlice = createSlice({
  name: 'teacherSo',
  initialState,
  reducers: {
    getListTeacherSo(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    getListTeacherSoSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.listTeacherSo = action.payload;
    },
    getListTeacherSoFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSubjects(state: TeacherSoState) {
      state.loading = true;
      state.error = null;
    },
    getListSubjectsSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.listSubjects = action.payload;
    },
    getListSubjectsFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    removeTeacher(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    removeTeacherSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.REMOVE_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.REMOVE_TEACHER,
        data: action.payload
      };
    },
    removeTeacherFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    addTeacher(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    addTeacherSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ADD_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_TEACHER,
        data: action.payload
      };
    },
    addTeacherFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    editTeacher(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    editTeacherSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.EDIT_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.EDIT_TEACHER,
        data: action.payload
      };
    },
    editTeacherFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    resetPassword(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.CHANGE_PASSWORD,
        message: RESPONSE_MESSAGE.SUCCESS.CHANGE_PASSWORD,
        data: action.payload
      };
    },
    resetPasswordFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    changeSubject(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    changeSubjectSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.CHANGE_SUBJECT,
        message: RESPONSE_MESSAGE.SUCCESS.CHANGE_SUBJECT,
        data: action.payload
      };
    },
    changeSubjectFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    roleTeacher(state: TeacherSoState, action: any) {
      state.loading = true;
      state.error = null;
    },
    roleTeacherSuccess(state: TeacherSoState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ROLE_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.ROLE_TEACHER,
        data: action.payload
      };
    },
    roleTeacherFail(state: TeacherSoState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    clearSuccess(state) {
      state.success = {};
    }
  }
});

export const { actions, reducer, name: sliceKey } = TeacherSoSlice;
