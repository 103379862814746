export const CreateInitiativeApi = {
  LIST_INITIATIVE: '/evaluation_initiatives', //Danh sách sáng tạo
  ADD_INITIATIVE: '/evaluation_initiatives', //Thêm sáng tạo
  LIST_EFFECT_SCOPE: '/evaluation_effect_scopes', //Danh sách phạm vị ảnh hưởng
  ADD_EFFECT_SCOPE: '/evaluation_effect_scopes', //Thêm phạm vi ảnh hưởng
  DETAIL_INITIATIVE: '/evaluation_initiatives',
  UPDATE_INITIATIVE: '/evaluation_initiatives',
  SEND_INITIATIVE_TO_SO: '/evaluation_initiatives/request_to_so',
  SEND_INITIATIVE_SCORE_TO_SO: '/evaluation_initiatives/request_score_to_so',
  EVALUATION_INITIATIVE_SO: '/so/evaluation_initiatives', // Danh sach sáng kiến bên Sở
  EVALUATION_INITIATIVE_PGD: '/pgd/evaluation_initiatives', // Danh sach sáng kiến bên PGD
  APPROVE_EVALUATION_INITIATIVE_SO: '/so/evaluation_initiatives/approve', // Công nhận sáng kiến
  URL_EXPORT_FILE_EVALUATION_INITIATIVE_SO: '/evaluation_initiatives/download', // Công nhận sáng kiến,
  URL_ROLES_SCORE: '/evaluation_initiatives/grant_permissions',
  EVALUATION_DECISIONS_NO_INITIATIVE: '/evaluation_decisions_no/initiative', // Danh sách danh mục số quyết định của sáng kiến
  PUBLISH_GRADE_28: '/so/evaluation_initiatives/publish_grade_28'
};

export const RESPONSE_CONSTANT = {
  SUCCESS: {
    ADD_INITIATIVE_SUCCESS: 'ADD_INITIATIVE_SUCCESS',
    DELETE_INITIATIVE_SUCCESS: 'DELETE_INITIATIVE_SUCCESS',
    COPY_INITIATIVE_SUCCESS: 'COPY_INITIATIVE_SUCCESS'
  },
  FAIL: {
    ADD_INITIATIVE_SUCCESS: 'ADD_INITIATIVE_SUCCESS',
    DELETE_INITIATIVE_SUCCESS: 'DELETE_INITIATIVE_SUCCESS',
    COPY_INITIATIVE_FAIL: 'COPY_INITIATIVE_FAIL'
  },
  WARN: {
    ADD_INITIATIVE_SUCCESS: 'ADD_INITIATIVE_SUCCESS',
    DELETE_INITIATIVE_SUCCESS: 'DELETE_INITIATIVE_SUCCESS'
  }
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ADD_INITIATIVE_SUCCESS: 'Thêm sáng kiến thành công!',
    PLUS_INITIATIVE_SUCCESS: 'Thêm bổ sung sáng kiến thành công!',
    DELETE_INITIATIVE_SUCCESS: 'Xóa sáng kiến thành công!',
    ADD_RATING_SUCCESS: 'Thêm xếp loaị thành công!',
    ADD_STANDARD_SUCCESS: 'Thêm tiêu chuẩn thành công!',
    EDIT_RATING_SUCCESS: 'Chỉnh sửa xếp loại thành công!',
    REMOVE_RATING_SUCCESS: 'Xóa xếp loại thành công!',
    EDIT_INITIATIVE_SUCCESS: 'Chỉnh sửa sáng kiến thành công!',
    EDIT_STANDARD_SUCCESS: 'Chỉnh sửa tiêu chuẩn, tiêu chí thành công!',
    UPDATE_SELF_ASSESSEMENT_SUCCESS: 'Cập nhật tự sáng kiến thành công',
    CREATE_EVALUATION_BY_ADMIN_SUCCESS: 'Thêm sáng kiến từ quản lý thành công!',
    COPY_INITIATIVE_SUCCESS: 'Copy sáng kiến thành công!',
    DELETE_SUCCESS: 'Xóa thành công!',
    PUBLISH_GRADE_28_SUCCESS: 'Công bố thành công!'
  },
  FAIL: {
    ADD_INITIATIVE_FAIL: 'Thêm sáng kiến thất bại!',
    PLUS_INITIATIVE_FAIL: 'Thêm bổ sung sáng kiến thất bại!',
    DELETE_INITIATIVE_FAIL: 'Xóa sáng kiến Thất bại!',
    ADD_RATING_FAIL: 'Thêm xếp loại thất bại!',
    ADD_STANDARD_FAIL: 'Thêm tiêu chuẩn thất bại!',
    EDIT_RATING_FAIL: 'Chỉnh sửa xếp loại thất bại!',
    REMOVE_RATING_FAIL: 'Xóa xếp loại thất bại!',
    EDIT_INITIATIVE_FAIL: 'Chỉnh sửa sáng kiến thất bại!',
    EDIT_STANDARD_FAIL: 'Chỉnh sửa tiêu chuẩn, tiêu chí thất bại!',
    CREATE_EVALUATION_BY_ADMIN_FAIL: 'Thêm sáng kiến từ quản lý thất bại!',
    SCORE_GREATER_THAN_DEFAULT:
      'Thầy, cô vui lòng nhập lại điểm, lưu ý điểm số không được cao hơn điểm chuẩn!',
    SORE_CHECK_NEGATIVE: 'Điểm sáng kiến không được âm, thầy cô vui lòng nhập điểm lại!',
    COPY_INITIATIVE_FAIL: 'Copy sáng kiến thất bại!',
    PUBLISH_GRADE_28_FAIL: 'Công bố thất bại!'
  },
  WARN: {
    ADD_INITIATIVE_SUCCESS: 'Vui lòng truyền đầy đủ thông tin sáng kiến!',
    WARNING_SCORE: 'Điểm sáng kiến không được lớn hơn điểm chuẩn!',
    WARNING_CREATE_STANDARD:
      'Tổng điểm các tiêu chí phải bằng điềm tiêu chuẩn. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING:
      'Số điểm kết thúc không được nhỏ hơn điểm bắt đầu. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_EFFECT_AREA: "Vui lòng chọn phạm vi ảnh hưởng!"
  }
};
