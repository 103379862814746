import { call, put, takeLatest, all } from 'redux-saga/effects';
import FunctionUtils from 'utils/FunctionUtils';
import { EvaluationHttp } from '../services/evaluation.http';
import { actions } from './slice';

const evaluationServiceHttp = new EvaluationHttp();

export function* getApi(api, action) {
  const response = yield call(api.getApi, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getApiSuccess(response.data.data));
    } else {
      yield put(actions.getApiFail(response));
    }
  } catch (error) {
    yield put(actions.getApiFail(error));
  }
}

export function* getListEvaluationTitleTearch(api, action) {
  const response = yield call(api.getListEvaluationTitleTearch, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitleTearchSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitleTearchFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitleTearchFail(error));
  }
}

export function* getDoeListEvaluationTitleTearch(api, action) {
  const response = yield call(api.getDoeListEvaluationTitleTearch, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDoeListEvaluationTitleTearchSuccess(response.data.data));
    } else {
      yield put(actions.getDoeListEvaluationTitleTearchFail(response));
    }
  } catch (error) {
    yield put(actions.getDoeListEvaluationTitleTearchFail(error));
  }
}

export function* getDetailApi(api, action) {
  const response = yield call(api.getDetailApi, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDetailApiSuccess(response.data.data));
    } else {
      yield put(actions.getDetailApiFail(response));
    }
  } catch (error) {
    yield put(actions.getDetailApiFail(error));
  }
}

export function* getListEvaluationEmulationTypes(api, action) {
  const response = yield call(api.getListEvaluationEmulationTypes, action.payload);
  try {
    if (response.ok) {
      yield put(
        actions.getListEvaluationEmulationTypesSuccess(response.data.data.evaluation_titles)
      );
    } else {
      yield put(actions.getListEvaluationEmulationTypesFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationEmulationTypesFail(error));
  }
}

export function* getListEvaluationTitleTypes(api, action) {
  const response = yield call(api.getListEvaluationTitleTypes, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitleTypesSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitleTypesFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitleTypesFail(error));
  }
}

export function* getListEvaluationDecisionsNo(api, action) {
  const response = yield call(api.getListEvaluationDecisionsNo, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationDecisionsNoSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationDecisionsNoFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationDecisionsNoFail(error));
  }
}

export function* getListEvaluationTitleGroup(api, action) {
  const response = yield call(api.getListEvaluationTitleGroup, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitleGroupSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitleGroupFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitleGroupFail(error));
  }
}

export function* getListEvaluationLibraries(api, action) {
  const response = yield call(api.getListEvaluationlibraries, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationLibrariesSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationLibrariesFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationLibrariesFail(error));
  }
}

export function* getListEvaluationTitlesPersonal(api, action) {
  const response = yield call(api.getListEvaluationTitlesPersonal, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesPersonalSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesPersonalFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesPersonalFail(error));
  }
}

export function* getListEvaluationTitlesGroup(api, action) {
  const response = yield call(api.getListEvaluationTitlesGroup, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesGroupSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesGroupFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesGroupFail(error));
  }
}

export function* getListEvaluationTitlesPgdQ3Personal(api, action) {
  const response = yield call(api.getListEvaluationTitlesPersonalPgdQ3, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesPgdQ3PersonalSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesPgdQ3PersonalFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesPgdQ3PersonalFail(error));
  }
}

export function* getListEvaluationTitlesPgdQ3PersonalNew(api, action) {
  const response = yield call(api.getListEvaluationTitlesPersonalPgdQ3New, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesPgdQ3PersonalNewSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesPgdQ3PersonalNewFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesPgdQ3PersonalNewFail(error));
  }
}

export function* getListEvaluationTitlesPgdQ3Group(api, action) {
  const response = yield call(api.getListEvaluationTitlesGroupPgdQ3, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesPgdQ3GroupSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesPgdQ3GroupFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesPgdQ3GroupFail(error));
  }
}

export function* getListEvaluationTitlesPgdQ3GroupNew(api, action) {
  const response = yield call(api.getListEvaluationTitlesGroupPgdQ3New, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationTitlesPgdQ3GroupNewSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationTitlesPgdQ3GroupNewFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationTitlesPgdQ3GroupNewFail(error));
  }
}

export function* getListAdminEvaluationTitlesGroup(api, action) {
  const response = yield call(api.getListAdminEvaluationTitleGroup, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListAdminEvaluationTitleGroupSuccess(response.data.data));
    } else {
      yield put(actions.getListAdminEvaluationTitleGroupFail(response));
    }
  } catch (error) {
    yield put(actions.getListAdminEvaluationTitleGroupFail(error));
  }
}

export function* getListEvaluationFileReport(api, action) {
  const response = yield call(api.getListEvaluationFileReport, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationFileReportSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationFileReportFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationFileReportFail(error));
  }
}

export function* getListEvaluationDecisionNo(api, action) {
  const response = yield call(api.getListEvaluationDecisionNo, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationDecisionNoSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationDecisionNoFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationDecisionNoFail(error));
  }
}

export function* getListEvaluationDecisionNoSo(api, action) {
  const response = yield call(api.getListEvaluationDecisionNoSo, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationDecisionNoSoSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationDecisionNoSoFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationDecisionNoSoFail(error));
  }
}

export function* getListEvaluationInitiativesScoreMe(api, action) {
  const response = yield call(api.getEvaluationInitiativesScoreMe, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationInitiativesScoreMeSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationInitiativesScoreMeFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationInitiativesScoreMeFail(error));
  }
}

export function* getLockDayRegisterEvaluationPersonal(api, action) {
  const response = yield call(api.getLockDayRegisterEvaluationPersonal, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getLockDayRegisterEvaluationPersonalSuccess(response.data.data));
    } else {
      yield put(actions.getLockDayRegisterEvaluationPersonalFail(response));
    }
  } catch (error) {
    yield put(actions.getLockDayRegisterEvaluationPersonalFail(error));
  }
}

export function* getListEvaluationResultEndYear(api, action) {
  const response = yield call(api.getListEvaluationResultEndYear, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationResultEndYearSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationResultEndYearFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationResultEndYearFail(error));
  }
}

export function* getListEvaluationResultEndYearPersonal(api, action) {
  const response = yield call(api.getListEvaluationResultEndYearPersonal, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationResultEndYearPersonalSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationResultEndYearPersonalFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationResultEndYearPersonalFail(error));
  }
}

export function* getListEvaluationResultEndYearGroup(api, action) {
  const response = yield call(api.getListEvaluationResultEndYearGroup, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getListEvaluationResultEndYearGroupSuccess(response.data.data));
    } else {
      yield put(actions.getListEvaluationResultEndYearGroupFail(response));
    }
  } catch (error) {
    yield put(actions.getListEvaluationResultEndYearGroupFail(error));
  }
}

export function* evaluationSaga() {
  yield all([
    yield takeLatest(actions.getApi.type, getApi, evaluationServiceHttp),
    yield takeLatest(
      actions.getListEvaluationTitleTearch.type,
      getListEvaluationTitleTearch,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getDoeListEvaluationTitleTearch.type,
      getDoeListEvaluationTitleTearch,
      evaluationServiceHttp
    ),
    yield takeLatest(actions.getDetailApi.type, getDetailApi, evaluationServiceHttp),
    yield takeLatest(
      actions.getListEvaluationEmulationTypes.type,
      getListEvaluationEmulationTypes,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitleTypes.type,
      getListEvaluationTitleTypes,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationDecisionsNo.type,
      getListEvaluationDecisionsNo,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitleGroup.type,
      getListEvaluationTitleGroup,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationLibraries.type,
      getListEvaluationLibraries,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesPersonal.type,
      getListEvaluationTitlesPersonal,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesGroup.type,
      getListEvaluationTitlesGroup,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesPgdQ3Personal.type,
      getListEvaluationTitlesPgdQ3Personal,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesPgdQ3PersonalNew.type,
      getListEvaluationTitlesPgdQ3PersonalNew,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesPgdQ3Group.type,
      getListEvaluationTitlesPgdQ3Group,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationTitlesPgdQ3GroupNew.type,
      getListEvaluationTitlesPgdQ3GroupNew,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationFileReport.type,
      getListEvaluationFileReport,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationDecisionNo.type,
      getListEvaluationDecisionNo,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationDecisionNoSo.type,
      getListEvaluationDecisionNoSo,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationInitiativesScoreMe.type,
      getListEvaluationInitiativesScoreMe,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getLockDayRegisterEvaluationPersonal.type,
      getLockDayRegisterEvaluationPersonal,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationResultEndYear.type,
      getListEvaluationResultEndYear,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationResultEndYearPersonal.type,
      getListEvaluationResultEndYearPersonal,
      evaluationServiceHttp
    ),
    yield takeLatest(
      actions.getListEvaluationResultEndYearGroup.type,
      getListEvaluationResultEndYearGroup,
      evaluationServiceHttp
    )
  ]);
}
