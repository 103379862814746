import React from 'react';
import './style.css';

export default function ButtonSubmitSearch(props) {
  const { text, handleClick } = props;
  return (
    <div className="button" style={{width:'auto', cursor:'pointer', height:'37px'}} onClick={handleClick}>
      <div className="icon">
        {/* <i className="fas fa-plus"></i> */}
        <span className="sp-text">{text}</span>
      </div>
    </div>
  );
}