import { Button, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { YEAR_ID_DEFAULT } from '_constants/default.constants';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import ModalListTeacherSo from './ModalListTeacherSo';
import ModalListAchievementSo from './ModalListAchievementSo';

const ContainerTable = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface IModalPrivilegeTeacherSo {
  isOpen: boolean;
  listDepartment: any[];
  handleCancel: () => void;
  title?: string;
  school?: any;
  detail?: any;
}

function ModalPrivilegeTeacherSo({
  isOpen,
  listDepartment,
  title,
  handleCancel,
  school,
  detail
}: IModalPrivilegeTeacherSo) {
  const createReviewHttp = new InitiativeAchievementHttp();
  const toast = useToast();
  const [isOpenListTeacher, setIsOpenListTeacher] = useState<boolean>(false);
  const [isOpenListAchievement, setIsOpenListAchievement] = useState<boolean>(false);
  const [valueTeacher, setValueTeacher] = useState<any>();
  const [valueListAchievement, setValueListAchievement] = useState<any>();
  const [teacherNumber, setTeacherNumber] = useState<number>();
  const [teacherInfo1, setTeacherInfo1] = useState<any>();
  const [teacherInfo2, setTeacherInfo2] = useState<any>();
  const [teacherData, setTeacherData] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [schoolObj, setSchoolObj] = useState<any>();

  useEffect(() => {
    setSchoolObj(school);
  }, []);

  const handleOpenListTeacher = (teacherInfo, teacherNumber) => {
    let teacher_fullname = '';
    if (teacherNumber === 1) {
      teacher_fullname = teacherInfo2 ? teacherInfo2.name : '';
    } else {
      teacher_fullname = teacherInfo1 ? teacherInfo1.name : '';
    }
    setTeacherNumber(teacherNumber);
    setIsOpenListTeacher(true);
    const data = [
      {
        user_id: teacherInfo?.user_id,
        teacher_fullname: teacher_fullname,
        school_id: teacherInfo?.school_id
      }
    ];
    setValueTeacher(data);
  };

  const handleSuccess = (data) => {
    console.log('data', data);
    if (teacherNumber === 1) {
      setTeacherInfo1({
        name: `${data[0].teacher_fullname}`,
        user_id: data[0].user_id,
        school_id: data[0].school_id
      });
    } else {
      setTeacherInfo2({
        name: `${data[0].teacher_fullname}`,
        user_id: data[0].user_id,
        school_id: data[0].school_id
      });
    }
    // setValueTeacher(data);
  };

  const handleRefreshChooseTeacher = () => {};

  const handleOpenListAchievement = () => {
    if (_.isEmpty(teacherInfo1) && _.isEmpty(teacherInfo2)) {
      toast?.pushWarning('Vui lòng chọn 2 giám khảo trước khi chọn sáng kiến!');
      return;
    }
    const setTeacherInfo: any = [];
    setTeacherInfo.push(teacherInfo1, teacherInfo2);
    setTeacherData(setTeacherInfo);
    setIsOpenListAchievement(true);
  };

  const handleSuccessListAchievement = (data) => {
    setValueListAchievement(data);
  };

  const handleRefreshChooseListAchievement = () => {};
  const handleSubmit = () => {
    if (!teacherInfo1 || !teacherInfo2) {
      toast?.pushWarning('Vui lòng chọn giáo viên chấm sáng kiến');
      return;
    }
    if (!valueListAchievement) {
      toast?.pushWarning('Vui lòng chọn sáng kiến');
      return;
    }
    const data = {
      teacher_score_1: teacherInfo1?.user_id,
      teacher_score_2: teacherInfo2?.user_id,
      initiative_ids: valueListAchievement.map((item) => item.id)
    };
    setIsSubmit(true);
    createReviewHttp.postRoleScore(data).then((res) => {
      if (res.ok && res.status === 200) {
        setIsSubmit(false);
        handleCancel();
        toast?.pushSuccess('Phân quyền chấm sáng kiến thành công!');
      } else {
        setIsSubmit(false);
      }
    });
  };

  const columnsAchievement: ColumnsType<any> = [
    {
      title: 'STT',
      width: '4%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Mã công chức',
      dataIndex: 'ma_cong_chuc',
      key: 'ma_cong_chuc',
      width: '15%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: '13%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: '18%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    }
  ];

  return (
    <React.Fragment>
      <Modal
        title={'Chọn phân quyền chấm điểm ' + `${schoolObj?.name}`}
        centered
        visible={isOpen}
        onCancel={() => handleCancel()}
        width={800}
        maskClosable={false}
        footer={[
          <Button key="back" size="large" onClick={handleCancel}>
            Đóng
          </Button>,
          <Button
            loading={isSubmit}
            key="submit"
            size="large"
            type="primary"
            onClick={handleSubmit}>
            Cập nhật
          </Button>
        ]}>
        <React.Fragment>
          <div className="d-flex flex-row gap-15 sm:gap-10 mb-10">
            <div className="d-flex flex-row teacher-info gap-15 sm:gap-10">
              <span>{teacherInfo1?.name ? teacherInfo1?.name : 'Giám khảo 1'}</span>
              <i
                className="fa fa-edit"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenListTeacher(teacherInfo1, 1)}
              />
            </div>{' '}
            <div className="d-flex flex-row teacher-info gap-15 sm:gap-10">
              <span>{teacherInfo2?.name ? teacherInfo2?.name : 'Giám khảo 2'}</span>
              <i
                className="fa fa-edit"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenListTeacher(teacherInfo2, 2)}
              />
            </div>
          </div>
        </React.Fragment>
        <div style={{ marginTop: 10 }}>
          <Button onClick={handleOpenListAchievement} style={{ marginBottom: 10, marginTop: 10 }}>
            Chọn sáng kiến
          </Button>
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columnsAchievement}
                dataSource={!_.isEmpty(valueListAchievement) && valueListAchievement}
                size="middle"
                bordered
                pagination={false}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
      </Modal>
      {isOpenListTeacher && (
        <ModalListTeacherSo
          isOpen={isOpenListTeacher}
          school={schoolObj}
          isEdit={false}
          listDepartment={listDepartment}
          listTeacherChoose={valueTeacher}
          handleRefresh={handleRefreshChooseTeacher}
          handleCancel={() => setIsOpenListTeacher(!isOpenListTeacher)}
          handleSuccess={handleSuccess}
        />
      )}
      {isOpenListAchievement && (
        <ModalListAchievementSo
          isOpen={isOpenListAchievement}
          listTeacherChoose={teacherData}
          listItemsChoose={valueListAchievement}
          gradeId={18}
          handleRefresh={handleRefreshChooseListAchievement}
          handleCancel={() => setIsOpenListAchievement(!isOpenListAchievement)}
          handleSuccess={handleSuccessListAchievement}
        />
      )}
    </React.Fragment>
  );
}

export default ModalPrivilegeTeacherSo;
