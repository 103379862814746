import { DownloadOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Space, Table, Tooltip, Upload } from 'antd';
import ButtonCreate from 'components/ButtonCreate';
import { NavBar } from 'components/NavBar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherSaga } from 'store/teacher/shared/saga';
import {
  selectListSubjects,
  selectListTeacherPgd,
  selectLoading,
  selectSuccess
} from 'store/teacher/shared/selectors';
import styled from 'styled-components';
import { APP_CONSTANT, TITLE_NAVBAR } from '_constants/default.constants';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
import InputSearch from '../components/InputSearch';
import _ from 'lodash';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
  TeacherApi
} from 'store/teacher/constants/teacher.constants';
import { AppHelper } from 'utils/app.helpers';
import { ColumnsType } from 'antd/lib/table';
import ModalConfirm from '../components/ModalConfirm';
import { selectCourseId } from 'store/common/shared/selectors';
import { useToast } from 'components/Toast';
import CreateTeacher from '../components/CreateTeacher';
import FunctionUtils from 'utils/FunctionUtils';
import EditTeacher from '../components/EditTeacher';
import ChangePassword from '../components/ChangePassword';
import Pagination from 'components/Pagination';
import ChangeSubject from '../components/ChangeSubject';
import TeacherRole from '../components/TeacherRole';
import { UploadFile, UploadListType } from 'antd/lib/upload/interface';
import { TeacherHttp } from 'store/teacher/services/teacher.http';
import useAuth from 'hooks/useAuth';
import ModalDetailAdd from '../components/ModalDetailAdd';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ModalUpload from '../components/ModalUpload';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface DataType {
  teacher_fullname: string;
  id_num: string;
  birthday: Date;
  phone_number: string;
  position_title: string;
  ma_cong_chuc: string;
  subject_name: string;
  action: string;
}

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const StyleSelect: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;
const { Option } = Select;

const IconSuccess = styled.span`
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 30px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: #48b842;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  & .anticon.anticon-check-circle {
    color: #48b842;
  }
`;

// const StyledTable = styled((props) => <Table {...props} />)`
//   && tbody > tr {
//     &:hover > td {
//       transform: scale(1.1);
//       transition: all 0.3s linear;
//     }
//   }
// `;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ListTeacherPgdQ3() {
  const { id } = useParams();
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const query = useQuery();
  const loading: boolean = useSelector(selectLoading);
  const listSubjects: any = useSelector(selectListSubjects);
  const listTeacher: any = useSelector(selectListTeacherPgd);
  const courseId: any = useSelector(selectCourseId);
  const [isConfirmRemove, setIsConfirmRemove] = useState<boolean>(false);
  const [isCreateTeacher, setIsCreateTeacher] = useState<boolean>(false);
  const [filterData, setFilterData] = useState({
    keyword: '',
    subjectId: 0,
    perPage: 10,
    pageId: 1,
    school_id: id,
    key_search: ''
  });
  const [nameTeacher, setNameTeacher] = useState<string>('');
  const [idTeacher, setIdTeacher] = useState<any>();
  const success: any = useSelector(selectSuccess);
  const [isEditTeacher, setIsEditTeacher] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>();
  const [isChangePass, setIsChangePass] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>();
  const [isChangeSubject, setIsChangeSubject] = useState<boolean>(false);
  const [subIdTeacher, setSubIdTeacher] = useState<any>();
  const [isTeacherRole, setIsTeacherRole] = useState<boolean>(false);
  const [base64, setBase64] = useState<any>();
  const teacherHttp = new TeacherHttp();
  const [isDetailAdd, setIsDetailAdd] = useState<boolean>(false);
  const [dataAdd, setDataAdd] = useState<any[]>();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isSingle, setIsSingle] = useState<boolean>(false);
  const [isUpFile, setIsUpFile] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(success)) {
      if (success.key === RESPONSE_CONSTANT.SUCCESS.REMOVE_TEACHER) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.REMOVE_TEACHER);
      } else if (success.key === RESPONSE_CONSTANT.SUCCESS.ADD_TEACHER) {
        if (!_.isEmpty(success.data) && success.data?.teacher_records.length) {
          const { teacher_records } = success.data;
          const exists = teacher_records.reduce((val) => ({ ...val }))?.exists;
          const fullName = teacher_records.reduce((val) => ({ ...val }))?.teacher_fullname;
          const subId = teacher_records.reduce((val) => ({ ...val }))?.subject_id;
          if (exists) {
            toast?.pushError(
              `${
                RESPONSE_MESSAGE.FAIL.SAME_PHONE_NUMBER
              } ${fullName} ở bộ môn: ${FunctionUtils.titleSubject(
                subId,
                listSubjects?.edu_subjects
              )}`
            );
          } else {
            toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ADD_TEACHER);
            setIsDetailAdd(!isDetailAdd);
            setIsSingle(!isSingle);
            setDataAdd(teacher_records);
          }
        }
      } else if (success.key === RESPONSE_CONSTANT.SUCCESS.EDIT_TEACHER) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.EDIT_TEACHER);
      } else if (success.key === RESPONSE_CONSTANT.SUCCESS.CHANGE_PASSWORD) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.CHANGE_PASSWORD);
      } else if (success.key === RESPONSE_CONSTANT.SUCCESS.CHANGE_SUBJECT) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.CHANGE_SUBJECT);
      } else if (success.key === RESPONSE_CONSTANT.SUCCESS.ROLE_TEACHER) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ROLE_TEACHER);
      }
      dispatch(FromTeacherSlice.actions.clearSuccess());
      refreshData();
    }
  }, [success]);

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    dispatch(FromTeacherSlice.actions.getListTeacherPgd(filterData));
    // teacherHttp.getListTeacherPgd(filterData).then(rs => {

    // })
  });

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const renderOpion = () => {
    if (!_.isEmpty(listSubjects) && listSubjects?.edu_subjects.length) {
      return listSubjects.edu_subjects.map((item) => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ));
    }
  };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  const onChangeOption = (value) => {
    const newFilter = Object.assign({}, filterData, {
      subjectId: parseInt(value)
    });
    setNewFilterData(newFilter);
  };

  const handleSearch = (keyword: string): any => {
    const newFilter = Object.assign({}, filterData, {
      //keyword: keyword,
      key_search: keyword,
      perPage: 10,
      pageId: 1
    });
    setNewFilterData(newFilter);
  };

  const refreshData = () => {
    dispatch(FromTeacherSlice.actions.getListTeacherPgd(filterData));
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listTeacher)) {
      if (listTeacher.teacher_records.length < filterData.perPage) return true;
      return false;
    }
  }, [listTeacher]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Mã công chức',
      dataIndex: 'ma_cong_chuc',
      key: 'ma_cong_chuc',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Họ và tên giáo viên',
      dataIndex: 'teacher_fullname',
      key: 'teacher_fullname',
      width: 300,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'CMND/CCCD',
      dataIndex: 'id_num',
      key: 'id_num',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Giới tính',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record) => {
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Ngày tháng năm sinh',
      dataIndex: 'birthday',
      key: 'birthday',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Chức vụ',
      dataIndex: 'position_title',
      key: 'position_title',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Loại hợp đồng',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record) => {
        // if (!_.isEmpty(record)) {
        //   return <span style={{ color: '#1890ff' }}>{text}</span>;
        // }
        return <span style={{ color: '#1890ff' }}></span>;
      }
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 200,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          (record.subject_name !== 'Môn đã bị xóa' && record.phone_number !== '') ||
          record.teacher_fullname !== ''
        ) {
          return (
            <Space>
              {/* <Tooltip title="Xem chi tiết" placement="bottomLeft">
                <Link
                  to={`detail/${record.id}/${record.period_id}`}
                  className="detail"
                  style={{ fontSize: '16px' }}>
                  <span className="h-14 mx-auto flex items-center justify-center font-normal">
                    <i className="fas fa-edit mr-5"></i>
                  </span>
                </Link>
              </Tooltip> */}
              <Tooltip title="Xem chi tiết" placement="bottomLeft">
                <Link
                  //to={`/list-teacher/detail/${record.user_id}`}
                  to={`/list-school-pgd/detail/${record.user_id}`}
                  className="detail"
                  style={{ fontSize: '16px' }}>
                  <span className="h-14 mx-auto flex items-center justify-center font-normal">
                    <EyeOutlined />
                  </span>
                </Link>
              </Tooltip>
            </Space>
          );
        } else {
          return <span style={{ color: '#ea5455' }}>Không có chức năng</span>;
        }
      }
    }
  ];
  const goBack = () => {
    if (parseInt(query.get('type')!) === 1) {
      navigate(
        `/result-competition-end-year?grade_id=${query.get('grade_id')}&type=${query.get('type')}`
      );
    } else if (query.get('type') === 'agencies') {
      navigate(-1);
    } else {
      navigate(
        `/list-personal-competition-school-pgd?grade_id=${query.get('grade_id')}&yearId=${query.get('yearId')}&titleId=${query.get('titleId')}`
      );
    }
  };
  return (
    <div className="pb-30">
      <Helmet>
        <title>{TITLE_NAVBAR.LIST_TEACHER_SO}</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      <div className="px-15">
        <NavBar
          title={TITLE_NAVBAR.LIST_TEACHER_SO}
          //   isButton
          //   handleClick={() => setIsCreateReview(!isCreateReview)}?
        />
        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                showHeader
                columns={columns}
                dataSource={listTeacher?.teacher_records}
                title={(currentPageData) => {
                  return (
                    <Row wrap align="middle" gutter={[8, 10]}>
                      <Col>
                        <div className="flex justify-start align-start flex-column flex-wrap">
                          <div className="flex justify-start flex-wrap gap-10">
                            <div className="relative mr-10">
                              <ButtonCreate
                                icon="fa-arrow-left"
                                text="Quay lại"
                                handleClick={goBack}
                                style={{ height: '35px' }}
                              />
                            </div>
                            <InputSearch handleSearch={handleSearch} />
                            {/* <ButtonSubmitSearch text="Tìm kiếm"  handleClick={handleSearch} /> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  );
                }}
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.id}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
          <ButtonCreate
            icon="fa-arrow-left"
            text="Quay lại"
            handleClick={goBack}
            style={{ marginTop: 10 }}
          />
        </div>
        <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>

      {isCreateTeacher && (
        <CreateTeacher
          isOpen={isCreateTeacher}
          handleCancel={() => setIsCreateTeacher(!isCreateTeacher)}
          handleOk={() => {}}
          subjectId={filterData.subjectId}
        />
      )}
      {isEditTeacher && (
        <EditTeacher
          isOpen={isEditTeacher}
          handleCancel={() => setIsEditTeacher(!isEditTeacher)}
          detail={detail}
        />
      )}
      {isChangePass && (
        <ChangePassword
          isOpen={isChangePass}
          handleCancel={() => setIsChangePass(!isChangePass)}
          handleOk={() => {}}
          userId={userId}
          teacher={nameTeacher}
        />
      )}
      {isChangeSubject && (
        <ChangeSubject
          isOpen={isChangeSubject}
          handleCancel={() => setIsChangeSubject(!isChangeSubject)}
          teacher={nameTeacher}
          subIdTeacher={subIdTeacher}
          detail={detail}
        />
      )}
      {isTeacherRole && (
        <TeacherRole
          isOpen={isTeacherRole}
          handleCancel={() => setIsTeacherRole(!isTeacherRole)}
          detail={detail}
        />
      )}
      {isDetailAdd && (
        <ModalDetailAdd
          isOpen={isDetailAdd}
          handleCancel={() => {
            setIsDetailAdd(!isDetailAdd);
            setIsSingle(!isSingle);
          }}
          data={dataAdd}
          handleRefresh={refreshData}
          isSingle={isSingle}
        />
      )}
      {isUpFile && (
        <ModalUpload
          isOpen={isUpFile}
          handleCancel={() => setIsUpFile(!isUpFile)}
          setDataAdd={setDataAdd}
          setIsDetailAdd={setIsDetailAdd}
          id={courseId.id}
        />
      )}
    </div>
  );
}
