import { Button, Card, Modal, Select, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListEffectScopes
} from 'store/common/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import { selectDataUser } from 'store/user/shared/selectors';
import * as common from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import Tooltip from 'antd/es/tooltip';
import ModalViewFilesDetails from 'components/ViewFiles';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalViewFile {
  isOpen: boolean;
  handleCancel: () => void;
  detail: any;
}
const { Option } = Select;
function ModalViewFile({ isOpen, handleCancel, detail }: IModalViewFile) {
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [filePathScore, setFilePathScore] = useState<any[]>([]);

  //pop up view file
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();

  useEffect(() => {
    if (!_.isEmpty(detail.certificates) && !_.isEmpty(detail.certificates_description)) {
      setFilePaths(_.values(detail.certificates));
      setFilePathScore(_.values(detail.certificates_description));
    }
  }, []);

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };

  return (
    <Modal
      title={'Thêm giấy chứng nhận'}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="grid sm:grid-2" style={{ alignItems: 'start' }}>
            <div className="grid sm:grid-1 gap-10 sm:gap-10">
              <label>Giấy chứng nhận</label>
              <div className="grid sm:grid-3 gap-15 sm:gap-20">
                <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                  {!_.isEmpty(filePath) ? (
                    filePath?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <div
                          style={{ cursor: 'pointer' }}
                          className="flex items-center justify-between gap-10">
                          <Space>
                            <Tooltip title="Xem chi tiết" placement="bottomLeft">
                              <span
                                //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                onClick={() => openFile(item)}
                                className="detail">
                                <div className="flex items-center justify-center">
                                  <i className="fas fa-eye" />
                                </div>
                              </span>
                            </Tooltip>
                          </Space>
                          <Space>
                            <Tooltip title="Tải file" placement="bottomLeft">
                              <span
                                //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                onClick={() => handleDownLoad(item)}
                                className="detail">
                                <div className="flex items-center justify-center">
                                  <i className="fas fa-download mr-5"></i>
                                </div>
                              </span>
                            </Tooltip>
                          </Space>
                        </div>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
                <span></span>
              </div>
            </div>
            <div className="grid sm:grid-1 gap-10 sm:gap-10">
              <label>Phiếu chấm điểm</label>
              <div className="grid sm:grid-3 gap-15 sm:gap-20">
                <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                  {!_.isEmpty(filePathScore) ? (
                    filePathScore?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <div
                          style={{ cursor: 'pointer' }}
                          className="flex items-center justify-between gap-10">
                          <Space>
                            <Tooltip title="Xem chi tiết" placement="bottomLeft">
                              <span
                                //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                onClick={() => openFile(item)}
                                className="detail">
                                <div className="flex items-center justify-center">
                                  <i className="fas fa-eye" />
                                </div>
                              </span>
                            </Tooltip>
                          </Space>
                          <Space>
                            <Tooltip title="Tải file" placement="bottomLeft">
                              <span
                                //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                onClick={() => handleDownLoad(item)}
                                className="detail">
                                <div className="flex items-center justify-center">
                                  <i className="fas fa-download mr-5"></i>
                                </div>
                              </span>
                            </Tooltip>
                          </Space>
                        </div>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
                <span></span>
              </div>
            </div>
          </div>
        </Card>
      </Container>
      {isOpenModalViewFileDetails && (
        <ModalViewFilesDetails
          isOpen={isOpenModalViewFileDetails}
          detail={detailFile}
          handleCancel={() => setIsOpenModalViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}
export default ModalViewFile;
