export const sortDateArray = (array: any, comparator: any) => {
  if (!Array.isArray(array) || array.length < 2) return array;

  const sortedArray = [...array];
  sortedArray.sort(comparator);

  return sortedArray;
};

export function isAvailableArray<T>(value: T | readonly T[]): value is T[] {
  return Array.isArray(value) && value.length > 0;
}
