import { UploadOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, Upload, UploadFile } from 'antd';
import { UploadListType } from 'antd/lib/upload/interface';
import { useToast } from 'components/Toast';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherHttp } from 'store/teacher/services/teacher.http';
import { AppHelper } from 'utils/app.helpers';

interface IUpload {
  isOpen: boolean;
  handleCancel: () => void;
  setDataAdd: any;
  setIsDetailAdd: any;
  id: any;
}
function ModalUpload({ isOpen, handleCancel, setDataAdd, setIsDetailAdd, id }: IUpload) {
  const teacherHttp = new TeacherHttp();
  const toast = useToast();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [base64, setBase64] = useState<any>();
  //   const loading: boolean = useSelector(selectLoading);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddTeacherByFile = () => {
    if (base64) {
      const request = {
        course_id: id,
        file: { base64 }
      };
      setLoading(true);
      teacherHttp.addTeacherByFile(request).then((res) => {
        setLoading(false);
        try {
          if (res.ok) {
            setIsDetailAdd(true);
            const { teacher_records } = res.data?.data;
            setDataAdd(teacher_records);
            setFiles([]);
          }
        } catch (error) {}
        handleCancel();
      });
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title="Thêm file giáo viên"
      footer={null}>
      <div
        className="container flex modal__file"
        style={{
          margin: '0 auto',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Upload
          beforeUpload={async (file) => {
            if (file.size > 10485760) {
              toast?.pushWarning('Tập tin quá lớn. Vui lòng chọn tập tin khác!');
              return true;
            } else {
              setFiles([...files, file]);
              const base64 = await AppHelper.convertBase64(file);
              setBase64(base64);
            }
            return false;
          }}
          onRemove={() => setFiles([])}
          fileList={files}
          maxCount={1}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
          <Button
            type="primary"
            size="middle"
            style={{
              boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2)',
              borderRadius: '5px'
            }}
            icon={<UploadOutlined />}>
            Tải File lên
          </Button>
        </Upload>
        <Button
          style={{
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: '5px',
            marginTop: '10px'
          }}
          onClick={handleAddTeacherByFile}
          icon={<UsergroupAddOutlined />}
          disabled={files.length === 0}
          loading={loading}>
          Thêm File
        </Button>
      </div>
    </Modal>
  );
}

export default ModalUpload;
