import { ContactsOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherSaga } from 'store/teacher/shared/saga';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';

interface EditTeacher {
  isOpen: boolean;
  handleCancel: () => void;
  detail: any;
}

interface IFormInput {
  nameTeacher: string;
  phone: number;
  subject: string;
}

function EditTeacher({ isOpen, handleCancel, detail }: EditTeacher) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>();

  const onSubmit = (data: IFormInput) => {
    const request = {
      fullname: getValues('nameTeacher'),
      subjectId: detail?.subject_id,
      teacherRecordId: detail?.id
    };
    dispatch(FromTeacherSlice.actions.editTeacher(request));
    handleCancel();
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Thông tin giáo viên: ${detail?.teacher_fullname}`}
      footer={null}>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row wrap justify="space-between" align="middle">
            <Col span={24} className="mb-5">
              <h4>Họ và tên</h4>
              <Controller
                name="nameTeacher"
                control={control}
                defaultValue={detail?.teacher_fullname}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors?.nameTeacher?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập họ và tên"
                    type="text"
                    prefix={<UserOutlined />}
                  />
                )}
              />
              {errors?.nameTeacher?.type === 'required' && (
                <span className="text-error">Họ và tên không được để trống!</span>
              )}
            </Col>
            <Col span={24} className="mb-5">
              <h4>Số điện thoại</h4>
              <Controller
                name="phone"
                control={control}
                defaultValue={detail?.phone_number}
                render={({ field }) => (
                  <Input
                    {...field}
                    readOnly
                    disabled
                    size="large"
                    placeholder="Nhập số điện thoại"
                    prefix={<i className="fas fa-mobile-alt" />}
                  />
                )}
              />
            </Col>
            <Col span={24} className="mb-5">
              <h4>Bộ môn</h4>
              <Controller
                name="subject"
                defaultValue={detail?.subject_name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} size="large" readOnly disabled prefix={<ContactsOutlined />} />
                )}
              />
            </Col>
          </Row>
          <div className="flex justify-end mt-20">
            <Button key="back" size="large" onClick={handleCancel}>
              Đóng
            </Button>
            <button type="submit" className="btn btn-add ml-10">
              Cập nhật
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default React.memo(EditTeacher);
