import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectCommon = (state: RootState) => state?.common || initialState;

export const selectLoading = createSelector([selectCommon], (common) => common.loading);

export const selectSucccess = createSelector([selectCommon], (common) => common.success);

export const selectError = createSelector([selectCommon], (common) => common.error);

export const selectListPeriod = createSelector([selectCommon], (common) => common.listPeriods);

export const selectListCourses = createSelector([selectCommon], (common) => common.listCourses);

export const selectCourseId = createSelector([selectCommon], (common) => common.courseId);

export const selectPeriodId = createSelector([selectCommon], (common) => common.periodId);

export const selectStatusId = createSelector([selectCommon], (common) => common.statusId);

export const selectListPeriodId = createSelector([selectCommon], (common) => common.listPeriodId);

export const selectListEffectScopes = createSelector(
  [selectCommon],
  (common) => common.listEffectScopes
);

export const selectEffectScopeId = createSelector([selectCommon], (common) => common.effectScopeId);

export const selectListCoursesBase = createSelector(
  [selectCommon],
  (common) => common.listCoursesBase
);

export const selectListProvinces = createSelector([selectCommon], (common) => common.listProvinces);

export const selectListDistricts = createSelector([selectCommon], (common) => common.listDistricts);

export const selectListWards = createSelector([selectCommon], (common) => common.listWards);

export const selectListSchools = createSelector([selectCommon], (common) => common.listSchools);
export const selectListSchoolsPgd = createSelector(
  [selectCommon],
  (common) => common.listSchoolsPgd
);

export const selectListSchoolsOption = createSelector(
  [selectCommon],
  (common) => common.listSchools
);

export const selectSchoolDetail = createSelector([selectCommon], (common) => common.schoolDetail);

export const selectGradeId = createSelector([selectCommon], (common) => common.gradeId);
