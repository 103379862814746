import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectCreateReview = (state: RootState) => state?.createReview || initialState;
export const selectLoading = createSelector([selectCreateReview], (review) => review.loading);
export const selectError = createSelector([selectCreateReview], (review) => review.error);
export const selectSuccess = createSelector([selectCreateReview], (review) => review.success);

export const selectListReview = createSelector([selectCreateReview], (review) => review.listReview);

export const selectDetailReview = createSelector(
  [selectCreateReview],
  (review) => review.detailReview
);

export const selectTeacherReview = createSelector(
  [selectCreateReview],
  (review) => review.listTeacherReview
);

export const selectDataState = createSelector(
  [selectCreateReview],
  (review) => review.detailEvaluationByTeacher
);
