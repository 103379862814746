import { Button, Card, Modal, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCourseId, selectListCourses, selectListPeriod, selectListEffectScopes } from 'store/common/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalEdit({ isOpen, title, handleCancel, id, handleRefresh, detail, handleSuccess }: IModalEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState(0);
  const [score, setScore] = useState<Number>(0);
  const [description, setDescription] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();

  useEffect(() => {
    getDetailReview();
  }, []);

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        setTitleReview(data.evaluation_intiative_detail.name);
        setYearSchool(data.evaluation_intiative_detail.course_id);
        setDescription(data.evaluation_intiative_detail.description);
        setEffectScopeId(data.evaluation_intiative_detail.effect_scope_id);
        setScore(data.evaluation_intiative_detail.score);
        
        var arr = _.values(data.evaluation_intiative_detail.files); // convert obj to arr
        setFilePaths(arr);
      } else {
      }
    });
  };
  const onSelectYear = (value: string): void => {
    setYearSchool(value);
  };

  const onSelectPeriod = (value: string[]): void => {
    setPeriod(value);
  };

  const handleUpload = () => {
    if (!_.isEmpty(filePath)) {
      commonHttp.uploadFile(filePath).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files));
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    if (handleSuccess) {
      handleSuccess(filePath, note, detail);
    }
    //toast?.pushSuccess('Dữ liệu đã được lưu tạm!');
    //handleCancel();
  };

  const handleSubmit = () => {
    if (titleReview === '' || effectScopeId === 0 || description === '') {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_INITIATIVE_SUCCESS);
      return;
    }
    if (yearSchool == null || yearSchool == "0") {
      toast?.pushWarning("Khóa học không tồn tại. Vui lòng kiểm tra lại thông tin.");
      return;
    }
    const obj = Object.assign({}, filePath); // array to object
    //upload
    handleUpload();
    ///
    const request = {
      title: titleReview,
      courseId: parseInt(yearSchool), //courseId.id! ? courseId.id : 0,
      description: description,
      effect_scope_id: parseInt(effectScopeId.toString()),
      files: obj,
      score: score,
      initiativeId: id,
    };
    createReviewHttp.updateInitiative(request).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.EDIT_INITIATIVE_SUCCESS);
        handleCancel();
        handleRefresh();
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.EDIT_INITIATIVE_FAIL);
      }
    });
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };

  // upload
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {      
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        let key = UploadFile.getIdFile(file, detail);
        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePaths(newFilePath);
  };

  
  const handleRemoveFile = (file: any) => {
    const newFilePath = filePath.filter((item) => item !== file);
    setFilePaths(newFilePath);
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  // const onSelectEffect = (value: Number): void => {
  //   setEffectScopeId(value);
  // };
  const onChangeValue = (e) => {
    if(e.target.name == 'title'){
      setTitleReview(e.target.value);
    }
    if(e.target.name == 'description'){
      setDescription(e.target.value);
    }
    if(e.target.name == 'score'){
      setScore(e.target.value);
    }
    if(e.target.name == 'effectScope'){
      setEffectScopeId(e.target.value);
    }
  };
  //////

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Cập nhật
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>
            <input
              name="title"
              id="title"
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Tóm tắt mô tả sáng kiến
              </label>
              <textarea
                //defaultValue={dataDetail?.self_evaluation_detail?.evaluation_self?.comment_strong}
                defaultValue={description}
                id="description"
                name="description"
                onChange={ onChangeValue}
                cols={30}
                rows={3}
                className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                placeholder="Nhập thông tin">
              </textarea>
          </div>          
          <div className="grid sm:grid-1 gap-15 sm:gap-20">
            <div className="relative">
              <label htmlFor="" className="font-bold mb-10 block"> Chọn phạm vi ảnh hưởng </label>
              <select onChange={onChangeValue} id="effectScope" name="effectScope"
                  className="select-no-arrow h-44 w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none"
              >
                <option value={0} selected={effectScopeId==0?true:false}>--- chọn ---</option>
                <option value={1} selected={effectScopeId==1?true:false}> Cấp Quận</option>
                <option value={2} selected={effectScopeId==2?true:false}> Thành phố</option>
              </select>
               {/* <div className="relative">
                <StyleSelectCourse
                    style={{
                      width: '100%'
                    }}
                    id="effectScope"
                    defaultValue={effectScopeId}
                  > 
                     <Option value={0}> --- Chọn --- </Option>
                    <Option value={1}> Cơ sở</Option>
                    <Option value={2}> Thành phố</Option>
                   </StyleSelectCourse>
              </div> */}
            </div>
          </div>
          {/* <div className="grid sm:grid-2 gap-15 sm:gap-20">
            <div>
              <label htmlFor="" className="font-bold mb-10 block"></label>
               <label htmlFor="inputTag">
                Chọn file<br/>
                <i className="fa fa-2x fa-camera"></i>
                <input type="file" id="inputTag" multiple onChange={handleImage} />
                <br/>
                <span id="imageName"></span>
              </label>
              
              <input type="file" multiple onChange={handleImage} />
                <ul className="mt-20 flex flex-column gap-10">
                  {!_.isEmpty(filePath) ? (
                    filePath?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span title='Tải file' onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-download mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                          style={{ fontSize: '14px' }}
                          onClick={() => handleRemoveFile(item)}>
                          <i className="fas fa-trash text-ea5" />
                        </button>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
            </div>
          </div> */}
          <div className="grid sm:grid-1 pt-20 gap-15 sm:gap-20">
              <input type="file" multiple onChange={handleImage} />
          </div>
          <div className="grid sm:grid-3 gap-15 sm:gap-20">
            <ul className="mt-20 flex flex-column gap-10 sm:span-2">
              {!_.isEmpty(filePath) ? (
                filePath?.map((item: any, index) => (
                  <li
                    className="flex items-center justify-between text-45a"
                    key={index}
                    style={{ cursor: 'pointer' }}>
                    <span onClick={() => handleDownLoad(item)} className="text-wrap">
                      <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                    </span>
                    <button
                      className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                      style={{ fontSize: '14px' }}
                      onClick={() => handleRemoveFile(item)}>
                      <i className="fas fa-trash text-ea5" />
                    </button>
                  </li>
                ))
              ) : (
                <span>Không có file đính kèm</span>
              )}
            </ul>
            <span></span>
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalEdit;
