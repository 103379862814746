import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface LoginState {
  loading: boolean;
  error?: any;
  success?: any;
  login?: any;
}

export const initialState: LoginState = {
  loading: false,
  error: {},
  success: {},
  login: {}
};

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state, action) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.login = action.payload;
    },
    loginFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    }
  }
});

export const { actions, reducer, name: sliceKey } = LoginSlice;
