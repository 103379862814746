import { Card } from 'antd';
import { RightContainer } from 'components/styles/FilterLayoutStyle';
import styled from 'styled-components';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface HeaderProps {
  title: string;
  buttons: JSX.Element[];
}

function Header({ title, buttons }: HeaderProps) {
  return (
    <>
      <ContainerHeader>
        <Card>
          <ContentNav>
            <div>
              <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
                <i className="fas fa-home" />
              </div>
              <span style={{ fontSize: '16px', color: 'rgb(24, 144, 255)', fontWeight: 500 }}>
                {title}
              </span>
            </div>

            <RightContainer>{buttons.map((button) => button)}</RightContainer>
          </ContentNav>
        </Card>
      </ContainerHeader>
    </>
  );
}

export default Header;
