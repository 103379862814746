import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectSelfAssessment = (state: RootState) => state?.selfAssessment || initialState;
export const selectLoading = createSelector([selectSelfAssessment], (review) => review.loading);
export const selectError = createSelector([selectSelfAssessment], (review) => review.error);
export const selectSuccess = createSelector([selectSelfAssessment], (review) => review.success);
export const selectDataGetApi = createSelector(
  [selectSelfAssessment],
  (review) => review.dataGetApi
);
export const selectDataDetailGetApi = createSelector(
  [selectSelfAssessment],
  (review) => review.dataDetail
);
export const selectDataGetApiSchool = createSelector(
  [selectSelfAssessment],
  (review) => review.dataGetApiSchool
);
export const selectDataDetailSchool = createSelector(
  [selectSelfAssessment],
  (review) => review.dataDetailSchool
);
