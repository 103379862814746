import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AppHelper } from 'utils/app.helpers';

const ContainerInput = styled.div`
  position: relative;
  &:hover span {
    opacity: 1;
  }
`;
const Input = styled.input`
  width: 240px;
`;

const IconSearch = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 100;
`;
const IconClear = styled.span`
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  opacity: 0;
  width: 12px;
  height: 12px;
  z-index: 100;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;
interface IInputSearch {
  handleSearch: (keyword: string) => any;
}
export default function InputSearch({ handleSearch }: IInputSearch) {
  const [keySearch, setKeySearch] = useState<string>('');

  const onChangeKey = (e) => {
    setKeySearch(e.target.value);
  };

  const handleSearchKey = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      handleSearch(keySearch);
    }
  };

  const handleClearKeyWord = () => {
    setKeySearch('');
    handleSearch('');
  };

  return (
    <ContainerInput>
      <Input
        className="w-full h-40 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 pl-15 pr-45"
        placeholder="Tìm kiếm"
        value={keySearch}
        onChange={onChangeKey}
        onKeyDown={handleSearchKey}
      />
      {keySearch && (
        <IconClear onClick={() => handleClearKeyWord()}>
          <i className="fas fa-times-circle"></i>
        </IconClear>
      )}
      <IconSearch onClick={(e) => handleSearchKey(e)}>
        <SearchOutlined />
      </IconSearch>
    </ContainerInput>
  );
}
