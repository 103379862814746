export const ListAgenciesSoApi = {
  LIST_AGENCIES_SO: '/so/manage_users',
  LIST_AGENCIES_SO_DEPARTMENTS: '/so/manage_users/departments',
  LIST_AGENCIES_SO_DETAIL: '/so/evaluations/list_evaluation_schools',
  ADD_AGENCIES_SO: '/so/manage_users/add',
  UPDATE_AGENCIES_SO: '/so/manage_users/update',
  EVALUATION_GRANT_PERMISSTION: '/so/evaluations/grant_permissions',
  DELETE_GRANT_PERMISSTION: '/so/evaluations/grant_permissions',
  CHANGE_PASS_AGENCIES_SO: '/so/manage_users/reset_password',
  DELETE_AGENCIES_SO: '/so/manage_users',
  DETAIL_AGENCIES_SO: '/so/manage_users',
  EDIT_AGENCIES_SO: '/so/manage_users',
  CHANGE_PASSWORD: '/so/manage_users/add'
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ADD_AGENCIES_SO_SUCCESS: 'Thêm cơ quan phụ trách thành công!',
    DELETE_AGENCIES_SO_SUCCESS: 'Xóa cơ quan phụ trách thành công!',
    CHANGE_PASS_SUCCESS: 'Đổi mật khẩu thành công!',
    ASSIGNMENT_SUCCESS: 'Phân quyền chấm sáng kiến thành công!'
  },
  FAIL: {
    ADD_AGENCIES_SO_FAIL: 'Thêm cơ quan phụ trách thành công!',
    DELETE_AGENCIES_SO_FAIL: 'Xóa cơ quan phụ trách thành công!'
  },
  WARN: {}
};
