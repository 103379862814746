import { FORMAT_DATE, STATUS_EVALUATION, YEAR_DEFAULT } from '_constants/default.constants';
import { AppHelper } from './app.helpers';
import _ from 'lodash';
class FunctionUtils {
  static configDataEvaluation(dataConfig: any[], dataDefault: any[]) {
    let totalSelf: number = 0;
    let total: number = 0;
    let totalRe: number = 0;
    let newCriterial: any[];
    const newArr: any[] = dataConfig.map((config: any, idx) => {
      let scoreStandardDefault: number = 0;
      let scoreCriterialTotal: number = 0;
      let scoreCriterialSelfTotal: number = 0;
      let scoreReCriterialTotal: number = 0;
      let nameStandard: string = '';
      let standardId: string = '';
      dataDefault.forEach((item: any) => {
        if (config.evaluation_standard_id === item.id) {
          scoreStandardDefault = item?.score;
          nameStandard = item?.name;
          let scoreCriterial: number;
          let nameCriterial: string;
          newCriterial = config.criterias.map((configItem: any, index) => {
            scoreCriterialTotal += configItem.score;
            scoreCriterialSelfTotal += configItem.score_self;
            scoreReCriterialTotal += configItem.re_score;
            standardId = configItem.standard_id;
            item.criterias.forEach((child: any) => {
              if (configItem.criteria_id === child.id) {
                scoreCriterial = child.score;
                nameCriterial = child.name;
                return;
              }
            });
            return {
              ...configItem,
              score_criterial_default: scoreCriterial,
              name_criterial: nameCriterial,
              keyIndex: `${idx + 1}.${index + 1}`,
              evaluation_standard_self_id: config.id
            };
          });

          return newCriterial;
        }
      });
      total += scoreCriterialTotal;
      totalSelf += scoreCriterialSelfTotal;
      totalRe += scoreReCriterialTotal;
      return {
        ...config,
        score_standard_default: scoreStandardDefault,
        name_standard: nameStandard,
        score_criterial_total: scoreCriterialTotal,
        score_criterial_self_total: scoreCriterialSelfTotal,
        re_score_criterial_total: scoreReCriterialTotal,
        criterias: newCriterial,
        standardId: standardId
      };
    });

    return { newArr, total_self_final: totalSelf, total_final: total };
  }

  static parseTitlePeriod = (data: any[], id: number) => {
    if (id && data && data.length > 0) {
      var obj = data.find((item) => item.id === id);
      return obj?.title;
    }
    return '';
  };
  static parseTitleCourse = (data: any[], id: number) => {
    if (id && data && data.length > 0) {
      var obj = data.find((item) => item.id === id);
      return obj?.description;
    }
    return '';
  };

  static parseTitleType = (data: any[], id: number) => {
    var obj = data.find((item) => item.id === id);
    return obj?.description;
  };

  static parseName = (data: any[], user_id: number) => {
    if (_.isEmpty(data)) {
      return;
    }
    var obj = data.find((item) => item.user_id === user_id);
    return obj?.teacher_fullname;
  };

  // static TileImg = (name: string) => {
  //   return name.replace(name.substring(0, name.lastIndexOf('/') + 1), '');
  // };

  static TileImg = (name: string) => {
    if (name === '') {
      return;
    }
    name = name.replace(name.substring(0, name.lastIndexOf('/') + 1), '');
    if (name.lastIndexOf('?') !== -1) {
      // not exists
      name = name.substring(0, name.lastIndexOf('?'));
    }
    name = decodeURIComponent(name);
    return name;
  };

  static converPathImage = (url: string) => {
    let name = url.replace(url.substring(0, url.lastIndexOf('/danhgia') + 1), '');
    if (name.lastIndexOf('?') !== -1) {
      // not exists
      name = name.substring(0, name.lastIndexOf('?'));
    }
    name = decodeURIComponent(name);
    return name;
  };

  static converPathImageProfile = (url: string) => {
    let name = url.replace(url.substring(0, url.lastIndexOf('/profile') + 1), '');
    if (name.lastIndexOf('?') !== -1) {
      // not exists
      name = name.substring(0, name.lastIndexOf('?'));
    }
    name = decodeURIComponent(name);
    return name;
  };

  static configResponseTeacherEvaluation = (response: any, pageId: number, perPage: number) => {
    const newArr = response?.evaluation_teachers.map((res, index: number) => {
      if (res.evaluation_detail) {
        let timeSelf: string = AppHelper.formatDate(
          res.evaluation_detail?.evaluation_self?.date_evaluation_self,
          FORMAT_DATE.DAY_TIME
        );
        let time: string = AppHelper.formatDate(
          res.evaluation_detail.evaluation_self.date_evaluation,
          FORMAT_DATE.DAY_TIME
        );

        let periodIdSelf: number = res.evaluation_detail.evaluation_self.period_id;
        let finalScoreSelf: number = res.evaluation_detail.evaluation_self.final_score_self;
        let statusSelf: string = res.evaluation_detail.evaluation_self.status;
        let finalScore: number = res.evaluation_detail.evaluation_self.final_score;
        let finalClassification: number =
          res.evaluation_detail.evaluation_self.final_classification;

        if (statusSelf === STATUS_EVALUATION.NOT_EVALUATION) {
          return {
            key: (pageId - 1) * perPage + index + 1,
            teacherFullName: res.teacher_fullname,
            userId: res.user_id,
            timeSelf: null,
            periodIdSelf: null,
            finalScoreSelf: null,
            statusSelf: statusSelf,
            finalScore: null,
            finalClassification: null
          };
        } else if (statusSelf === STATUS_EVALUATION.HAVE_EVALUATION) {
          return {
            key: (pageId - 1) * perPage + index + 1,
            teacherFullName: res.teacher_fullname,
            userId: res.user_id,
            timeSelf: timeSelf,
            periodIdSelf: periodIdSelf,
            finalScoreSelf: finalScoreSelf,
            statusSelf: statusSelf,
            finalScore: null,
            finalClassification: null,
            time: null
          };
        } else if (statusSelf === STATUS_EVALUATION.HAVE_EVALUATED) {
          return {
            key: (pageId - 1) * perPage + index + 1,
            teacherFullName: res.teacher_fullname,
            userId: res.user_id,
            timeSelf: timeSelf,
            periodIdSelf: periodIdSelf,
            finalScoreSelf: finalScoreSelf,
            statusSelf: statusSelf,
            finalScore: finalScore,
            finalClassification: finalClassification,
            time: time
          };
        } else if (statusSelf === STATUS_EVALUATION.EVALUATING) {
          return {
            key: (pageId - 1) * perPage + index + 1,
            teacherFullName: res.teacher_fullname,
            userId: res.user_id,
            timeSelf: timeSelf,
            periodIdSelf: periodIdSelf,
            finalScoreSelf: finalScoreSelf,
            statusSelf: statusSelf,
            finalScore: finalScore,
            finalClassification: finalClassification,
            time: time
          };
        }
      }
      return { ...res };
    });
    return newArr;
  };

  // static configResponseSchoolEvaluation = (response: any, pageId: number, perPage: number) => {
  //   console.log('data response', response);
  //   const newArr = response?.evaluation_schools.map((res, index: number) => {
  //     if (res.evaluation_detail) {
  //       let timeSelf: string = AppHelper.formatDate(
  //         res.evaluation_detail?.evaluation_self?.date_evaluation_self,
  //         FORMAT_DATE.DAY_TIME
  //       );
  //       let time: string = AppHelper.formatDate(
  //         res.evaluation_detail.evaluation_self.date_evaluation,
  //         FORMAT_DATE.DAY_TIME
  //       );

  //       let periodIdSelf: number = res.evaluation_detail.evaluation_self.period_id;
  //       let finalScoreSelf: number = res.evaluation_detail.evaluation_self.final_score_self;
  //       let statusSelf: string = res.evaluation_detail.evaluation_self.status;
  //       let finalScore: number = res.evaluation_detail.evaluation_self.final_score;
  //       let finalClassification: number =
  //         res.evaluation_detail.evaluation_self.final_classification;

  //       if (statusSelf === STATUS_EVALUATION.NOT_EVALUATION) {
  //         return {
  //           key: (pageId - 1) * perPage + index + 1,
  //           teacherFullName: res.teacher_fullname,
  //           userId: res.user_id,
  //           timeSelf: null,
  //           periodIdSelf: null,
  //           finalScoreSelf: null,
  //           statusSelf: statusSelf,
  //           finalScore: null,
  //           finalClassification: null
  //         };
  //       } else if (statusSelf === STATUS_EVALUATION.HAVE_EVALUATION) {
  //         return {
  //           key: (pageId - 1) * perPage + index + 1,
  //           teacherFullName: res.teacher_fullname,
  //           userId: res.user_id,
  //           timeSelf: timeSelf,
  //           periodIdSelf: periodIdSelf,
  //           finalScoreSelf: finalScoreSelf,
  //           statusSelf: statusSelf,
  //           finalScore: null,
  //           finalClassification: null,
  //           time: null
  //         };
  //       } else if (statusSelf === STATUS_EVALUATION.HAVE_EVALUATED) {
  //         return {
  //           key: (pageId - 1) * perPage + index + 1,
  //           teacherFullName: res.teacher_fullname,
  //           userId: res.user_id,
  //           timeSelf: timeSelf,
  //           periodIdSelf: periodIdSelf,
  //           finalScoreSelf: finalScoreSelf,
  //           statusSelf: statusSelf,
  //           finalScore: finalScore,
  //           finalClassification: finalClassification,
  //           time: time
  //         };
  //       } else if (statusSelf === STATUS_EVALUATION.EVALUATING) {
  //         return {
  //           key: (pageId - 1) * perPage + index + 1,
  //           teacherFullName: res.teacher_fullname,
  //           userId: res.user_id,
  //           timeSelf: timeSelf,
  //           periodIdSelf: periodIdSelf,
  //           finalScoreSelf: finalScoreSelf,
  //           statusSelf: statusSelf,
  //           finalScore: finalScore,
  //           finalClassification: finalClassification,
  //           time: time
  //         };
  //       }
  //     }
  //     return { ...res };
  //   });
  //   return newArr;
  // };

  static configDetailEvaluation = (
    data: any[],
    detailevaluationGrantPermissions?,
    evaluationSelf?
  ) => {
    const newData = data.map((item: any, index: number) => {
      let titleStandard: string = item.name_standard;
      let scoreStandardDefault: number = item.score_standard_default;
      let scoreCriterialSelfTotal: number = item.score_criterial_self_total;
      let scoreCriterialTotal: number = item.score_criterial_total;
      let scoreReCriterialTotal: number = item.re_score_criterial_total;
      let giveReScoringTotal: number = item.re_score_criterial_total;
      let isEnabled: boolean = detailevaluationGrantPermissions
        ? detailevaluationGrantPermissions?.some((itm) => itm === item.evaluation_standard_id)
        : false;
      const newCriterias = item.criterias.map((it) => {
        let isEnabled: boolean = detailevaluationGrantPermissions
          ? detailevaluationGrantPermissions?.some((x) => x === it.standard_id)
          : false;
        return {
          ...it,
          evidence_content_old: it.evidence_content,
          re_score: it.re_score,
          re_evidence_content: it.re_evidence_content,
          evidence_content_self: it.evidence_content_self.startsWith(' ')
            ? ''
            : it.evidence_content_self,
          enabled: isEnabled
        };
      });
      return {
        key: index + 1,
        titleStandard: titleStandard,
        scoreStandardDefault: scoreStandardDefault,
        scoreStandardSelfTotal: scoreCriterialSelfTotal,
        scoreCriterialTotal: scoreCriterialTotal,
        scoreReCriterialTotal: scoreReCriterialTotal,
        giveReScoringTotal: giveReScoringTotal,
        userId: item.user_id,
        criterias: newCriterias,
        id: item.id,
        standardId: item.standardId,
        enabled: isEnabled
      };
    });
    return newData;
  };

  static configDetailEvaluationDepartment = (
    data: any[],
    detailevaluationGrantPermissions?,
    evaluationSelf?
  ) => {
    const newData = data.map((item: any, index: number) => {
      let titleStandard: string = item.name_standard;
      let scoreStandardDefault: number = item.score_standard_default;
      let scoreCriterialSelfTotal: number = item.score_criterial_self_total;
      let scoreCriterialTotal: number =
        evaluationSelf.status === STATUS_EVALUATION.HAVE_EVALUATION
          ? item.score_criterial_self_total
          : item.score_criterial_total;
      let scoreReCriterialTotal: number = item.re_score_criterial_total;
      let giveReScoringTotal: number =
        evaluationSelf.status === STATUS_EVALUATION.RECHECKING
          ? item.re_score_criterial_total
          : item.score_criterial_total;
      let isEnabled: boolean = detailevaluationGrantPermissions
        ? detailevaluationGrantPermissions?.some((itm) => itm === item.evaluation_standard_id)
        : false;
      const newCriterias = item.criterias.map((it) => {
        let isEnabledCriterias: boolean = detailevaluationGrantPermissions
          ? detailevaluationGrantPermissions?.some((x) => x === it.standard_id)
          : false;
        let score: number = 0;
        if (evaluationSelf.status === STATUS_EVALUATION.GRADED_AGAIN) {
          return {
            ...it,
            scoreGive: it.score,
            score: it.score,
            reScore: it.re_score,
            evidence_content_have_evaluated: it.evidence_content,
            evidence_content_self: it.evidence_content_self.startsWith(' ')
              ? ''
              : it.evidence_content_self,
            enabled: isEnabledCriterias
          };
        }
        if (evaluationSelf.status === STATUS_EVALUATION.RECHECKING) {
          return {
            ...it,
            scoreGive: it.score,
            score: it.score,
            reScore: it.re_score,
            evidence_content_have_evaluated: it.evidence_content,
            evidence_content_self: it.evidence_content_self.startsWith(' ')
              ? ''
              : it.evidence_content_self,
            enabled: isEnabledCriterias
          };
        }
        if (
          evaluationSelf.status === STATUS_EVALUATION.RECOMMEND_RE_RATING ||
          evaluationSelf.status === STATUS_EVALUATION.RECEIPT
        ) {
          return {
            ...it,
            scoreGive: it.score,
            score: it.score,
            reScore: it.score,
            evidence_content_have_evaluated: it.evidence_content,
            evidence_content_self: it.evidence_content_self.startsWith(' ')
              ? ''
              : it.evidence_content_self,
            enabled: isEnabledCriterias
          };
        } else {
          if (evaluationSelf.status === STATUS_EVALUATION.HAVE_EVALUATION) {
            score = it.score_self;
          } else if (
            evaluationSelf.status === STATUS_EVALUATION.EVALUATING ||
            evaluationSelf.status === STATUS_EVALUATION.HAVE_EVALUATED
          ) {
            score = it.score;
          } else {
            score = it.score_criterial_default;
          }
          return {
            ...it,
            score: score,
            evidence_content_self: it.evidence_content_self.startsWith(' ')
              ? ''
              : it.evidence_content_self,
            enabled: isEnabledCriterias
          };
        }
      });
      return {
        key: index + 1,
        titleStandard: titleStandard,
        scoreStandardDefault: scoreStandardDefault,
        scoreStandardSelfTotal: scoreCriterialSelfTotal,
        scoreCriterialTotal: scoreCriterialTotal,
        scoreReCriterialTotal: scoreReCriterialTotal,
        giveReScoringTotal: giveReScoringTotal,
        userId: item.user_id,
        criterias: newCriterias,
        id: item.id,
        standardId: item.standardId,
        enabled: isEnabled
      };
    });
    return newData;
  };

  static configResponseListEvaluationsSelf = (response: any, pageId: number, perPage: number) => {
    const newArr = response.map((res, index: number) => {
      let date_evaluation_self: any = res?.evaluation_self?.evaluation_self?.date_evaluation_self
        ? AppHelper.formatDate(
            res?.evaluation_self?.evaluation_self?.date_evaluation_self,
            FORMAT_DATE.DAY_TIME
          )
        : null;
      let dateEvaluation: any = res?.evaluation_self?.evaluation_self?.date_evaluation
        ? AppHelper.formatDate(
            res?.evaluation_self?.evaluation_self?.date_evaluation,
            FORMAT_DATE.DAY_TIME
          )
        : null;
      console.log(res?.evaluation_self?.evaluation_self?.final_re_score);
      let periodIdSelf: number = res?.period_id;
      let periodTitle: string = res?.period_name;
      let finalScoreSelf: number = res?.evaluation_self?.evaluation_self?.final_score_self || 0;
      let statusSelf: string = res?.evaluation_self?.evaluation_self?.status;
      let finalScore: number = res?.evaluation_self?.evaluation_self?.final_score || 0;
      let finalReScore: number = res?.evaluation_self?.evaluation_self?.final_re_score;
      let reNote: string = res?.evaluation_self?.evaluation_self?.note;
      let finalClassification: number =
        res?.evaluation_self?.evaluation_self?.final_classification || null;
      let score_total: number = res?.evaluation_self?.score_total
        ? res?.evaluation_self?.score_total
        : 0;
      let isResult: boolean = res?.evaluation_self?.evaluation_self?.is_result;
      let isReResult: boolean = res?.evaluation_self?.evaluation_self?.is_result2;
      return {
        key: (pageId - 1) * perPage + index + 1,
        title: res.title,
        scoreTotal: score_total,
        id: res.id,
        date_evaluation_self: date_evaluation_self,
        dateEvaluation: dateEvaluation,
        periodIdSelf: periodIdSelf,
        finalScoreSelf: finalScoreSelf,
        statusSelf: statusSelf,
        finalScore: finalScore,
        finalClassification: finalClassification,
        finalReScore: finalReScore,
        periodTitle: periodTitle,
        reNote: reNote,
        isResult: isResult,
        isReResult: isReResult
      };
    });
    return newArr;
  };

  static configYear = (year: any[]) => {
    const newYear: any[] = year.filter((item) => parseInt(item.name) >= YEAR_DEFAULT);
    return newYear;
  };

  static titleSubject = (id, Subjects: any[]) => {
    const title = Subjects.filter((item) => item.id === id)?.reduce((val) => ({ ...val }))?.name;
    return title;
  };

  static configResponseListEvaluation = (evaluations: any[]) => {
    if (!_.isEmpty(evaluations)) {
      return evaluations.map((item) => {
        return { ...item, keyUid: AppHelper.generateUUIDV4() };
      });
    }
    return [];
  };

  static parseTitleProvice = (data: any[], id: number) => {
    if (id === 0 || id === -1) {
      return '';
    }
    var obj = data
      .filter((item) => item.id === id)
      ?.reduce((val) => {
        return { ...val };
      });
    return obj?.title;
  };

  static parseTitleDistricts = (data: any[], id: number) => {
    if (id === 0 || id === -1) {
      return '';
    }
    if (data === undefined) {
      return '';
    }
    // console.log(data);
    // return;
    var obj = data
      ?.filter((item) => item.id === id)
      ?.reduce((val) => {
        return { ...val };
      });
    return obj?.title;
  };

  static parseTitleDepartment = (data: any[], id: number) => {
    if (id === 0 || id === -1) {
      return '';
    }
    var obj = data
      ?.filter((item) => item.id === id)
      ?.reduce((val) => {
        return { ...val };
      });
    return obj?.name;
  };

  static SumItem = (data, paramRequest) => {
    data.reduce((sum, val) => {
      for (let i in sum) {
        if (sum[i][paramRequest] === val[paramRequest]) {
          return sum;
        }
      }
      sum.push(val);
      return sum;
    }, []);
  };
}

export default FunctionUtils;
