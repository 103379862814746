import { Button, Card, Modal, Select, Tag, Form, DatePicker, Space, Tooltip, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListEffectScopes
} from 'store/common/shared/selectors';
import moment from 'moment';
import _ from 'lodash';
import { useToast } from 'components/Toast';
import styled from 'styled-components';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import { Link } from 'react-router-dom';
import ModalViewFilesDetails from '../../../components/ViewFiles/index';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalDetail({
  isOpen,
  title,
  handleCancel,
  id,
  handleRefresh,
  detail,
  handleSuccess
}: IModalEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState<Number>(0);
  const [score, setScore] = useState(0);
  const [isTeacherScore, setIsTeacherScore] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();
  const [valueDecision, setValueDecision] = useState('');
  const [dayAppellation, setDayAppellation] = useState<any>();
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [effective, setEffective] = useState<string>('');
  //pop up view file
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();
  //const detailFile = 'https://equestions-test.s3.ap-southeast-1.amazonaws.com/danhgia/1012475/57b943ad-21d8-4f93-9017-bebee4248d8d/THPT%20-%206-12.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASOAOVFOEUQNE7WRB%2F20230213%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230213T022418Z&X-Amz-Expires=4200&X-Amz-SignedHeaders=host&X-Amz-Signature=d93f2198d5ec2a319a24178c34eb878f3918875f74e553aaaedaaad516aa98d4'
  //

  const dateFormat = 'DD/MM/YYYY';

  useEffect(() => {
    getDetailReview();
  }, []);

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        setTitleReview(data.evaluation_intiative_detail.name);
        setYearSchool(data.evaluation_intiative_detail.course_id);
        setDescription(data.evaluation_intiative_detail.description);
        setEffectScopeId(data.evaluation_intiative_detail.effect_scope_id);
        setScore(data.evaluation_intiative_detail.score);
        setEffective(data.evaluation_intiative_detail.effective);
        var arr = _.values(data.evaluation_intiative_detail.files); // convert obj to arr
        setFilePaths(arr);

        // get qd
        setValueDecision(data.evaluation_intiative_detail.decision_no);
        if (!_.isEmpty(data.evaluation_intiative_detail.decision_date)) {
          var inputstartdate = moment(data.evaluation_intiative_detail.decision_date); //"2016-10-19"
          if (inputstartdate.isValid()) {
            setDayAppellation(moment(data.evaluation_intiative_detail.decision_date, 'DD/MM/YYYY'));
          } else {
            var inputstartdate1 = moment(
              data.evaluation_intiative_detail.decision_date,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD');
            if (inputstartdate1 == 'Invalid date') {
              inputstartdate1 = data.evaluation_intiative_detail.decision_date;
            }
            setDayAppellation(moment(inputstartdate1));
          }
        }
      } else {
      }
    });
  };
  // upload
  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  //////

  const onChangeAffect = (e: CheckboxChangeEvent, type) => {
    if (type === 'cs') {
      if (e.target.checked) {
        setValueAffectCS(e.target.checked);
        setDisabled(false);
      } else {
        setDisabled(true);
        setValueAffectTP(false);
        setValueAffectCS(e.target.checked);
      }
    } else {
      setValueAffectTP(e.target.checked);
    }
  };

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>

            <input
              disabled
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              value={titleReview}
            />
          </div>
          {/* <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Đăng ký phạm vi ảnh hưởng
            </label>
            <Checkbox checked={valueAffectCS} onChange={(e) => onChangeAffect(e, 'cs')}>
              Phạm vi ảnh hưởng cấp sở
            </Checkbox>
            <Checkbox
              checked={valueAffectTP}
              disabled={disabled}
              onChange={(e) => onChangeAffect(e, 'tp')}>
              Phạm vi ảnh hưởng cấp Thành phố
            </Checkbox>
          </div> */}
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tóm tắt mô tả sáng kiến
            </label>

            <textarea
              disabled
              defaultValue={description}
              cols={30}
              rows={3}
              className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
              placeholder="Nhập thông tin"></textarea>
          </div>
          <div className="grid sm:grid-1 gap-15 sm:gap-20 mb-10">
            <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Hiệu quả
              </label>
              <textarea
                defaultValue={effective}
                disabled
                id="effective"
                name="effective"
                cols={30}
                rows={3}
                maxLength={500}
                className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                placeholder="Nhập thông tin"></textarea>
              <small>Vui lòng nhập dưới 500 kí tự: {effective.length}/500</small>
            </div>
            {/* <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Điểm
              </label>
              <input
                disabled
                value={score}
                id="score"
                name="score"
                className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                placeholder="0"
              />
            </div> */}
            <div className="grid sm:grid-1 gap-15 sm:gap-20">
              <div className="relative">
                <label htmlFor="" className="font-bold mb-10 block">
                  {' '}
                  Phạm vi ảnh hưởng{' '}
                </label>
                <select
                  disabled
                  className="select-no-arrow h-44 w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none">
                  <option value={0} selected={effectScopeId == 0 ? true : false}>
                    {' '}
                    cấp Quận
                  </option>
                  <option value={1} selected={effectScopeId == 1 ? true : false}>
                    {' '}
                    Thành phố
                  </option>
                </select>
                <label htmlFor="id" className="absolute top-3/4 right-15 translate-y--3/4">
                  <i className="fas fa-caret-down" />
                </label>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-2 mt-10">
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn số quyết định
              </label>
              <Form.Item name="decision">
                <Select
                  disabled
                  placeholder={valueDecision}
                  style={{ width: 'calc(97%)', height: 40, fontWeight: 'bold', color: 'revert' }}
                  value={valueDecision}
                />
              </Form.Item>
            </div>
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 ml-10 block">
                Ngày quyết định
              </label>
              <DatePicker
                allowClear={false}
                disabled
                value={dayAppellation}
                format={dateFormat}
                placeholder="Ngày quyết định"
                style={{
                  width: 'calc(97%)',
                  height: 32,
                  marginLeft: 10,
                  fontWeight: 'bold',
                  color: 'revert'
                }}
              />
            </div>
          </div>
          <div className="grid sm:grid-1 gap-15 sm:gap-20">
            <div>
              <label htmlFor="" className="font-bold mb-10 block"></label>
              <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                {!_.isEmpty(filePath) ? (
                  filePath?.map((item: any, index) => (
                    <li className="flex items-center justify-between text-45a" key={index}>
                      <span className="text-wrap">
                        <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                      </span>
                      <div
                        style={{ cursor: 'pointer' }}
                        className="flex items-center justify-between gap-10">
                        <Space>
                          <Tooltip title="Xem chi tiết" placement="bottomLeft">
                            <span
                              //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                              onClick={() => openFile(item)}
                              className="detail">
                              <div className="flex items-center justify-center">
                                <i className="fas fa-eye" />
                              </div>
                            </span>
                          </Tooltip>
                        </Space>
                        {isOpenModalViewFileDetails && (
                          <ModalViewFilesDetails
                            isOpen={isOpenModalViewFileDetails}
                            detail={detailFile}
                            handleCancel={() => setIsOpenModalViewFileDetails(false)}
                          />
                        )}
                        <Space>
                          <Tooltip title="Tải file" placement="bottomLeft">
                            <span
                              //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                              onClick={() => handleDownLoad(item)}
                              className="detail">
                              <div className="flex items-center justify-center">
                                <i className="fas fa-download mr-5"></i>
                              </div>
                            </span>
                          </Tooltip>
                        </Space>
                      </div>
                    </li>
                  ))
                ) : (
                  <span>Không có file đính kèm</span>
                )}
              </ul>
            </div>
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalDetail;
