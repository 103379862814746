import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectLogin = (state: RootState) => state?.login || initialState;

export const selectLoading = createSelector([selectLogin], (login) => login.loading);

export const selectSuccess = createSelector([selectLogin], (login) => login.success);

export const selectLoginSuccess = createSelector([selectLogin], (login) => login.login);

export const selectError = createSelector([selectLogin], (login) => login.error);
