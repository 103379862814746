import { createSlice } from 'store/core/@reduxjs/toolkit';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants/teacher.constants';

export interface TeacherState {
  loading: boolean;
  error: any;
  success: any;
  listTeacher: any;
  listSubjects: any;
  listTeacherPgd?: any;
}

export const initialState: TeacherState = {
  loading: false,
  error: {},
  success: {},
  listTeacher: {},
  listSubjects: {},
  listTeacherPgd: {}
};

const TeacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    getListTeacher(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    getListTeacherSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.listTeacher = action.payload;
    },
    getListTeacherFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    getListTeacherPgd(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    getListTeacherPgdSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.listTeacherPgd = action.payload;
    },
    getListTeacherPgdFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    getListSubjects(state: TeacherState) {
      state.loading = true;
      state.error = null;
    },
    getListSubjectsSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.listSubjects = action.payload;
    },
    getListSubjectsFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    removeTeacher(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    removeTeacherSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.REMOVE_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.REMOVE_TEACHER,
        data: action.payload
      };
    },
    removeTeacherFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    addTeacher(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    addTeacherSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ADD_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_TEACHER,
        data: action.payload
      };
    },
    addTeacherFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    editTeacher(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    editTeacherSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.EDIT_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.EDIT_TEACHER,
        data: action.payload
      };
    },
    editTeacherFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    resetPassword(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.CHANGE_PASSWORD,
        message: RESPONSE_MESSAGE.SUCCESS.CHANGE_PASSWORD,
        data: action.payload
      };
    },
    resetPasswordFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    changeSubject(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    changeSubjectSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.CHANGE_SUBJECT,
        message: RESPONSE_MESSAGE.SUCCESS.CHANGE_SUBJECT,
        data: action.payload
      };
    },
    changeSubjectFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    roleTeacher(state: TeacherState, action: any) {
      state.loading = true;
      state.error = null;
    },
    roleTeacherSuccess(state: TeacherState, action: any) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ROLE_TEACHER,
        message: RESPONSE_MESSAGE.SUCCESS.ROLE_TEACHER,
        data: action.payload
      };
    },
    roleTeacherFail(state: TeacherState, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    clearSuccess(state) {
      state.success = {};
    }
  }
});

export const { actions, reducer, name: sliceKey } = TeacherSlice;
