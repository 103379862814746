import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { RESPONSE_MESSAGE } from 'store/createReview/constants/createReview.constants';
import { useToast } from 'components/Toast';
import * as _ from 'lodash';
// get service
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import { YEAR_DEFAULT, YEAR_ID_DEFAULT } from '_constants/default.constants';
interface IModalRegister {
  isOpen: boolean;
  dataEmulationTypes: any;
  dataUpdateEmulationTitle: any;
  userType: number | undefined;
  dataCourses: any;
  handleCancel: () => void;
  handleRefresh: () => void;
  handleSuccess?: () => void;
}
const { Option } = Select;
function ModalRegister({
  isOpen,
  dataEmulationTypes,
  dataUpdateEmulationTitle,
  userType,
  dataCourses,
  handleCancel,
  handleRefresh,
  handleSuccess
}: IModalRegister) {
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  const evaluationHttp = new EvaluationHttp();
  const toast = useToast();
  const [form] = Form.useForm();
  const [appellation, setaAppellation] = useState<any[]>([]);
  const [valueEmulation, setValueEmulation] = useState();
  const [itemId, setItemId] = useState('');
  const [courseId, setcourseId] = useState(YEAR_ID_DEFAULT);
  const [currentYear, setCurrentYear] = useState(YEAR_DEFAULT);
  const [valueAppellation, setValueAppellation] = useState<any[]>();
  const [isNoti, setIsNoti] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (dataUpdateEmulationTitle && Object.entries(dataUpdateEmulationTitle)) {
      const { emulation_type_id, title_type_id, id, course_id } = dataUpdateEmulationTitle;
      handleChangeEmulation(emulation_type_id);
      setValueAppellation(title_type_id);
      setcourseId(course_id);
      setItemId(id);
      form.setFieldsValue({
        emulation: emulation_type_id,
        appellation: title_type_id
      });
    }
  }, [dataUpdateEmulationTitle]);

  const handleChangeEmulation = (e?) => {
    setValueEmulation(e);
    setaAppellation([]);
    form.setFieldsValue({
      appellation: 'Tên danh hiệu'
    });
    const newFilter = {
      pageId: 1,
      perPage: 999,
      evaluationTypeId: e,
      courseId: courseId,
      currentYear: currentYear,
      userType: 1
    };
    if (userType === 1) {
      evaluationHttp.getListEvaluationTitleTypes(newFilter).then((res) => {
        if (res.ok && res.status === 200) {
          if (!_.isEmpty(res.data.data.evaluation_titles)) {
            setIsNoti(false);
            const filterData: any[] = res.data.data.evaluation_titles.map((item) => {
              return {
                value: item.id,
                label: item.name
              };
            });
            const removeValFrom: any = filterData
              .filter((itm) => itm.label.includes('Cờ'))
              .map((x) => {
                return x.value;
              });
            setaAppellation(
              filterData.filter((item) => {
                return removeValFrom.indexOf(item.value) === -1;
              })
            );
          } else {
            setIsNoti(true);
          }
        }
      });
    } else {
      evaluationHttp.getListEvaluationTitleTypesConstraint(newFilter).then((res) => {
        if (res.ok && res.status === 200) {
          if (!_.isEmpty(res.data.data.evaluation_titles)) {
            setIsNoti(false);
            const filterData: any[] = res.data.data.evaluation_titles.map((item) => {
              return {
                value: item.id,
                label: item.name
              };
            });
            const removeValFrom: any = filterData
              .filter((itm) => itm.label.includes('Cờ'))
              .map((x) => {
                return x.value;
              });
            setaAppellation(
              filterData.filter((item) => {
                return removeValFrom.indexOf(item.value) === -1;
              })
            );
          } else {
            setIsNoti(true);
          }
        }
      });
    }
  };

  const handleChangeaApellation = (e) => {
    setValueAppellation(e);
  };

  const handleSave = (values) => {
    const data: any = {
      course_id: courseId,
      emulation_type_id: valueEmulation,
      title_type_id: valueAppellation,
      decision_date: '',
      phan_loai: 1
    };
    setIsSubmit(true);
    if (itemId) {
      evaluationHttp.updateEvaluationTitle(data, itemId).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      evaluationHttp.createdEvaluationTitle(data).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };

  const onFinish = (values: any) => {
    handleSave(values);
  };

  const handleDownloadFile = () => {
    const url = '/assets/docs/1_ĐIỀU_KIỆN_ĐĂNG_KÝ_DANH_HIỆU_THI_ĐUA_KHEN_THƯỞNG_CÁ_NHÂN_1.pdf';
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  };

  return (
    <Modal
      title="Đăng ký thi đua"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={600}
      maskClosable={false}
      footer={false}>
      <Form form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">
        <Form.Item
          name="emulation"
          rules={[{ required: true, message: 'Vui lòng chọn hình thức thi đua!' }]}>
          <Select
            placeholder="Hình thức thi đua"
            style={{ width: '100%' }}
            onChange={($event) => handleChangeEmulation($event)}
            value={valueEmulation}
            options={dataEmulationTypes}
          />
        </Form.Item>
        <Form.Item
          name="appellation"
          rules={[{ required: true, message: 'Vui lòng chọn tên danh hiệu!' }]}>
          <Select
            placeholder="Tên danh hiệu"
            style={{ width: '100%' }}
            disabled={isNoti}
            onChange={($event) => handleChangeaApellation($event)}
            value={valueAppellation}
            options={appellation}
          />
          {isNoti && (
            <span>
              Thầy/ cô chưa đủ điều kiện bắt buộc để đăng ký danh hiệu này, xem điều kiện đăng ký{' '}
              <a href="#" target="_blank" onClick={handleDownloadFile}>
                "Tại đây"
              </a>
            </span>
          )}
        </Form.Item>
        <Form.Item className="footer-modal">
          <Button key="back" size="large" onClick={handleCancel} style={{ marginRight: 10 }}>
            Đóng
          </Button>
          <Button loading={isSubmit} type="primary" size="large" htmlType="submit">
            {itemId ? 'Cập nhật' : 'Đăng ký'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default ModalRegister;
