import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface AddressState {
  loading?: boolean;
  error?: any;
  success?: any;
  listProvinces: any;
  listDistricts: any;
  listWards: any;
}

export const initialState: AddressState = {
  loading: false,
  error: null,
  success: {},
  listProvinces: {},
  listDistricts: {},
  listWards: {},
};

const AddressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    getProvinces(state) {
      state.loading = true;
      state.error = null;
    },
    getProvincesSuccess(state, action) {
      state.loading = false;
      state.listProvinces = action.payload;
    },
    getProvincesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDistricts(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDistrictsSuccess(state, action) {
      state.loading = false;
      state.listDistricts = action.payload;
    },
    getDistrictsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getWards(state, action) {
      state.loading = true;
      state.error = null;
    },
    getWardsSuccess(state, action) {
      state.loading = false;
      state.listWards = action.payload;
    },
    getWardsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = AddressSlice;
