import { Button, Card, Modal, Select, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListEffectScopes
} from 'store/common/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import { selectDataUser } from 'store/user/shared/selectors';
import * as common from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import ModalViewFilesDetails from 'components/ViewFiles';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalUploadFile {
  isOpen: boolean;
  handleCancel: () => void;
  detail: any;
  handleRefresh: () => void;
}
const { Option } = Select;
function ModalUploadFile({ isOpen, handleCancel, detail, handleRefresh }: IModalUploadFile) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [fileScore, setFileScore] = useState<any[]>([]);
  const [filePathScore, setFilePathScore] = useState<any[]>([]);
  const [fileUpload, setFileUpload] = useState<any[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();
  const [note, setNote] = useState<string>('');
  const commonHttp = new CommonHttp();
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();
  const dataUser: any = useSelector(selectDataUser);
  const inputRef = useRef<any>(null);
  const inputRefFile = useRef<any>(null);

  var filePathNew: any[];
  var filePathScoreNew: any[];

  useEffect(() => {
    if (!_.isEmpty(detail.certificates) && !_.isEmpty(detail.certificates_description)) {
      setFilePaths(_.values(detail.certificates));
      setFilePathScore(_.values(detail.certificates_description));
    }
  }, []);

  const handleUpload = () => {
    if (_.isEmpty(fileScore) && _.isEmpty(files)) {
      submitDataApi();
      return false;
    }
    let filePathUpload = filePath.concat(filePathScore);
    let fileUpload = files.concat(fileScore);
    if (!_.isEmpty(filePathUpload)) {
      commonHttp.uploadFile(filePathUpload).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            if (valueOfKeys && valueOfKeys.length > 0) {
              handlePromiseAll(valueOfKeys, fileUpload);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const handlePromiseAll = async (valueOfKeys, files) => {
    var valueUpload: any = [];
    for (var i = 0; i < valueOfKeys.length; i++) {
      var a = Object.keys(valueOfKeys[i])[0];
      if (!a.includes('https:')) {
        valueUpload.push(valueOfKeys[i]);
      }
    }
    // end Xử lý những file vừa upload
    //debugger
    const response = await Promise.all(
      valueUpload.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files))
    );
    if (response && response.length) {
      for (var j = 0; j < response.length; j++) {
        if (response[j] === undefined) {
          toast?.pushWarning('Upload File bị lỗi. Vui lòng kiểm tra lại!');
          return;
        }
      }
      // const response = await Promise.all(
      //   valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files))
      // );
      // if (response && response.length) {
      submitDataApi();
    } else {
      toast?.pushWarning('Upload File bị lỗi. Vui lòng kiểm tra lại!');
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    if (filePath.length === 0) {
      toast?.pushWarning('Vui lòng cập nhật giấy chứng nhận!');
      setIsSubmit(false);
      return;
    }
    if (filePathScore.length === 0) {
      toast?.pushWarning('Vui lòng cập nhật phiếu chấm điểm!');
      setIsSubmit(false);
      return;
    }
    handleUpload();
  };

  const submitDataApi = () => {
    // filePath.unshift(filePathOld);
    // filePathScore.unshift(filePathInitiativeOld);
    filePathNew = [];
    filePathScoreNew = [];
    // convert name
    filePath.forEach((name) => {
      var filename = FunctionUtils.converPathImage(name);
      filePathNew.push(filename);
    });

    filePathScore.forEach((name1) => {
      var filename1 = FunctionUtils.converPathImage(name1);
      filePathScoreNew.push(filename1);
    });
    //

    const obj = Object.assign({}, filePathNew); // array to object
    const objFilesScore = Object.assign({}, filePathScoreNew); // array to object

    const request = {
      certificates: obj,
      certificates_description: objFilesScore
    };
    createReviewHttp.uploadFileCertificates(request, detail.id).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        setIsSubmit(false);
        toast?.pushSuccess('Thêm giấy tờ chứng minh thành công!');
        handleCancel();
        handleRefresh();
      } else {
        setIsSubmit(false);
      }
    });
  };

  // upload
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        let key = UploadFile.getIdFileNoDetail(file, dataUser);
        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePaths(newFilePath);
  };

  const handleImageScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePathScore];
    setFileScore([
      ...fileScore,
      ...fileArr.map((file) => {
        let key = UploadFile.getIdFileNoDetail(file, dataUser);
        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePathScore(newFilePath);
  };

  const handleRemoveFile = (file: any) => {
    const newFilePath = filePath.filter((item) => item !== file);
    setFilePaths(newFilePath);
    inputRefFile.current.value = null;
  };

  const handleRemoveFileScore = (file: any) => {
    const newFilePathScore = filePathScore.filter((item) => item !== file);
    setFilePathScore(newFilePathScore);
    inputRef.current.value = null;
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };

  return (
    <Modal
      title={'Thêm giấy chứng nhận'}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button loading={isSubmit} key="submit" size="large" type="primary" onClick={handleSubmit}>
          Cập nhật
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="grid sm:grid-2" style={{ alignItems: 'start' }}>
            <div className="grid sm:grid-1 gap-10 sm:gap-10">
              <label>Giấy chứng nhận</label>
              <div className="grid sm:grid-1 pt-20 gap-15 sm:gap-20">
                <input ref={inputRefFile} type="file" multiple onChange={handleImage} />
              </div>
              <div className="grid sm:grid-3 gap-15 sm:gap-20">
                <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                  {!_.isEmpty(filePath) ? (
                    filePath?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                          style={{ fontSize: '14px' }}
                          onClick={() => openFile(item)}>
                          <i className="fas fa-eye text-45a" />
                        </button>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                          style={{ fontSize: '14px' }}
                          onClick={() => handleRemoveFile(item)}>
                          <i className="fas fa-trash text-ea5" />
                        </button>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
                <span></span>
              </div>
            </div>
            <div className="grid sm:grid-1 gap-10 sm:gap-10">
              <label>Phiếu chấm điểm</label>
              <div className="grid sm:grid-1 pt-20 gap-15 sm:gap-20">
                <input ref={inputRef} type="file" multiple onChange={handleImageScore} />
              </div>
              <div className="grid sm:grid-3 gap-15 sm:gap-20">
                <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                  {!_.isEmpty(filePathScore) ? (
                    filePathScore?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent detail"
                          style={{ fontSize: '14px' }}
                          onClick={() => openFile(item)}>
                          <i className="fas fa-eye text-45a" />
                        </button>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                          style={{ fontSize: '14px' }}
                          onClick={() => handleRemoveFileScore(item)}>
                          <i className="fas fa-trash text-ea5" />
                        </button>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
                <span></span>
              </div>
            </div>
          </div>
        </Card>
      </Container>
      {isOpenModalViewFileDetails && (
        <ModalViewFilesDetails
          isOpen={isOpenModalViewFileDetails}
          detail={detailFile}
          handleCancel={() => setIsOpenModalViewFileDetails(!isOpenModalViewFileDetails)}
        />
      )}
    </Modal>
  );
}

export default ModalUploadFile;
