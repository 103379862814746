import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { RESPONSE_MESSAGE } from 'store/createReview/constants/createReview.constants';
import { useToast } from 'components/Toast';
import { AppHelper } from 'utils/app.helpers';

// get service
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import {
  selectDataListEvaluationDecisionsNo,
  selectDataListEvaluationTitleTypes
} from 'store/evaluation/shared/selectors';
import useAuth from 'hooks/useAuth';
import { YEAR_ID_DEFAULT, YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';
interface IModalAdditional {
  id?: string;
  isOpen: boolean;
  dataCourses: any;
  dataEmulationTypes: any;
  dataUpdateEmulationTitle?: any;
  handleCancel: () => void;
  handleRefresh: () => void;
  handleSuccess?: (data) => void;
}

const dateFormat = 'DD/MM/YYYY';

function ModalAdditional({
  id,
  isOpen,
  dataCourses,
  dataEmulationTypes,
  dataUpdateEmulationTitle,
  handleCancel,
  handleRefresh
}: IModalAdditional) {
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const listEvaluationTitleTypes: any = useSelector(selectDataListEvaluationTitleTypes);
  const listEvaluationDecisionsNo: any = useSelector(selectDataListEvaluationDecisionsNo);
  const [valueYear, setValueYear] = useState<number | any>();
  const [valueEmulation, setValueEmulation] = useState();
  const [valueDecision, setValueDecision] = useState<any>();
  const [valueId, setValueId] = useState();
  const [appellation, setaAppellation] = useState<any[]>([]);
  const [valueAppellation, setValueAppellation] = useState();
  const [valueDecisionEdit, setValueDecisionEdit] = useState();
  const [dayAppellation, setDayAppellation] = useState<any>();
  const [valueCourses, setValueCourses] = useState<any[]>([]);
  const [inPutdayAppellation, setInPutdayAppellation] = useState<any>();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { filters, setFilters } = useAuth();
  const toast = useToast();
  const evaluationHttp = new EvaluationHttp();

  useEffect(() => {
    if (dataUpdateEmulationTitle && Object.entries(dataUpdateEmulationTitle)) {
      const { id, course_id, emulation_type_id, title_type_id, decision_no, decision_date } =
        dataUpdateEmulationTitle;
      if (course_id && emulation_type_id && title_type_id) {
        setValueYear(course_id);
        setValueId(id);
        handleChangeEmulation(emulation_type_id);
        loadDataDecisionNo(emulation_type_id, title_type_id, course_id);
        setValueDecisionEdit(decision_no);
        setValueAppellation(title_type_id);
        setDayAppellation(decision_date ? moment(decision_date) : '');
        setIsDisable(true);
        setIsEdit(true);
        if (title_type_id === 142 || title_type_id === 151) {
          form.setFieldsValue({
            decisionNo: decision_no
          });
        }
        form.setFieldsValue({
          year: course_id,
          emulation: emulation_type_id,
          appellation: title_type_id
        });
      }
    }
    if (!_.isEmpty(dataCourses)) {
      const idYearRemove = [YEAR_ID_DEFAULT];
      const courses: any = dataCourses.filter((itm) => !idYearRemove.includes(itm.value));
      setValueCourses(courses);
    }
  }, [dataUpdateEmulationTitle, dataCourses]);

  useEffect(() => {
    if (valueId) {
      const decisionNo = listEvaluationDecisionsNo?.evaluation_decisions_no?.find(
        (item) => item.decision_no === valueDecisionEdit
      );
      if (!_.isEmpty(decisionNo)) {
        form.setFieldsValue({
          decision: decisionNo.id
        });
        setDayAppellation(moment(decisionNo.decision_date, 'DD/MM/yyyy'));
      }
    }
  }, [listEvaluationDecisionsNo]);

  const handleChangeYear = (e) => {
    setValueYear(e);
    if (valueEmulation && valueAppellation && valueYear) {
      loadDataDecisionNo(valueAppellation);
    }
  };

  const handleChangeEmulation = (e) => {
    setValueEmulation(e);
    const newFilter = {
      pageId: 1,
      perPage: 999,
      evaluationTypeId: e,
      userType: 1
    };
    evaluationHttp.getListEvaluationTitleTypes(newFilter).then((res) => {
      if (res.ok && res.status === 200) {
        const filterData: any[] = res.data.data.evaluation_titles.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });
        setaAppellation(filterData);
      }
    });
  };

  const handleChangeaApellation = (e) => {
    if (valueYear === undefined) {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.WARNING_SELECT_YEAR);
    } else {
      setValueAppellation(e);
      setDayAppellation('');
      setValueDecision('');
      form.setFieldsValue({
        decision: ''
      });
      loadDataDecisionNo(valueEmulation, e);
    }
  };

  const loadDataDecisionNo = (valueEmulation?, titleTypeId?, course_id?) => {
    filters.pageId = 1;
    filters.courseId = valueYear ? valueYear : course_id;
    filters.emulationTypeId = valueEmulation;
    filters.titleTypeId = titleTypeId;
    filters.userType = 1;
    dispatch(evaluationList.actions.getListEvaluationDecisionsNo(filters));
  };

  const onChangeDatepicker: DatePickerProps['onChange'] = (date, dateString) => {
    setInPutdayAppellation(dateString);
    setDayAppellation(moment(date, 'DD/MM/yyyy'));
  };

  const onChangeDecision = (e) => {
    const decision = listEvaluationDecisionsNo.evaluation_decisions_no.find(
      (item) => item.id === e
    );
    setValueDecision(decision.decision_no);
    setDayAppellation(moment(decision.decision_date, 'DD/MM/yyyy'));
  };

  const handleSave = (values) => {
    const exitsTitleTypeId = [142, 151];
    let decisionDate;
    if (!exitsTitleTypeId.includes(values.appellation)) {
      decisionDate = dayAppellation ? AppHelper.formatDate(dayAppellation, 'yyyy-MM-DD') : '';
    } else {
      decisionDate = inPutdayAppellation;
    }
    const data: any = {
      course_id: values.year,
      emulation_type_id: values.emulation,
      title_type_id: values.appellation,
      decision_no: !exitsTitleTypeId.includes(values.appellation)
        ? valueDecision
        : values.decisionNo,
      decision_date: decisionDate,
      phan_loai: 0
    };
    setIsSubmit(true);
    if (isEdit) {
      evaluationHttp.updateEvaluationTitle(data, valueId).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      evaluationHttp.createdEvaluationTitle(data).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };

  const onFinish = (values: any) => {
    handleSave(values);
  };

  return (
    <Modal
      title="Cập nhật danh hiệu thi đua"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={600}
      maskClosable={false}
      footer={null}>
      <Form form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">
        <Form.Item name="year" rules={[{ required: true, message: 'Vui lòng chọn năm học!' }]}>
          <Select
            placeholder="Chọn năm học"
            style={{ width: '100%' }}
            onChange={($event) => handleChangeYear($event)}
            value={valueYear}
            options={valueCourses}
          />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="emulation"
            style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
            rules={[{ required: true, message: 'Vui lòng chọn hình thức thi đua!' }]}>
            <Select
              placeholder="Chọn hình thức thi đua"
              style={{ width: '100%' }}
              onChange={($event) => handleChangeEmulation($event)}
              value={valueEmulation}
              options={dataEmulationTypes}
            />
          </Form.Item>
          <Form.Item
            name="appellation"
            style={{ width: 'calc(50% - 10px)', marginLeft: 10 }}
            rules={[{ required: true, message: 'Vui lòng chọn hình thức thi đua!' }]}>
            <Select
              placeholder="Chọn danh hiệu"
              style={{ width: '100%' }}
              onChange={($event) => handleChangeaApellation($event)}
              value={valueAppellation}
              options={appellation}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }}>
          {valueAppellation === 142 || valueAppellation === 151 ? (
            <Form.Item
              name="decisionNo"
              style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
              rules={[{ required: true, message: 'Vui lòng nhập số quyết định!' }]}>
              <Input name="decisionNo" />
            </Form.Item>
          ) : (
            <Form.Item
              name="decision"
              style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
              rules={[{ required: true, message: 'Vui lòng chọn số quyết định!' }]}>
              <Select
                placeholder="Chọn số quyết định"
                style={{ width: '100%' }}
                value={valueDecision}
                onChange={onChangeDecision}
                options={
                  !_.isEmpty(listEvaluationDecisionsNo) &&
                  listEvaluationDecisionsNo.evaluation_decisions_no.map((item) => {
                    return {
                      value: item.id,
                      label: item.decision_no
                    };
                  })
                }
              />
            </Form.Item>
          )}
          {valueAppellation === 142 || valueAppellation === 151 ? (
            <DatePicker
            allowClear={false}
              value={dayAppellation}
              format={dateFormat}
              onChange={onChangeDatepicker}
              placeholder="Ngày quyết định"
              style={{ width: 'calc(50% - 10px)', height: 32, marginLeft: 10, color: '#000' }}
            />
          ) : (
            <DatePicker
            allowClear={false}
              disabled
              value={dayAppellation}
              format={dateFormat}
              placeholder="Ngày quyết định"
              style={{ width: 'calc(50% - 10px)', height: 32, marginLeft: 10, color: '#000' }}
            />
          )}
        </div>
        <Form.Item className="footer-modal">
          <Button key="back" size="large" onClick={handleCancel} style={{ marginRight: 10 }}>
            Đóng
          </Button>
          <Button loading={isSubmit} type="primary" size="large" htmlType="submit">
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default ModalAdditional;
