import { Button, Card, Input, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import { useToast } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { RESPONSE_MESSAGE } from 'store/competionRatingEdu/constants/competitionRatingEdu.constants';
import { Controller, useForm } from 'react-hook-form';
import '../styles.css';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import useAuth from 'hooks/useAuth';

interface IFormInput {
  fullname: string;
  username: string;
  password: string;
  rePassword: string;
  department: number;
}

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

interface IModal {
  isOpen: boolean;
  handleCancel: () => void;
  handleRefresh: () => void;
}

const { Option } = Select;
function ModalCreateAgencies({ isOpen, handleCancel, handleRefresh }: IModal) {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>();
  const toast = useToast();
  const [department, setDepartment] = useState<any>();
  const [valueDepartment, setValueDepartment] = useState<any>();
  const [valueRole, setValueRole] = useState<number>(0);
  const { filters, setFilters } = useAuth();

  const listAgenciesSoHttp = new ListAgenciesSoHttp();

  useEffect(() => {
    loadListUserDepartments();
  }, []);

  const loadListUserDepartments = () => {
    listAgenciesSoHttp.getListAgenciesSoDepartments(filters).then((res) => {
      if (res.ok && res.status === 200) {
        setDepartment(res.data.data.edu_schools);
      }
    });
  };

  const handleChangeRole = (e: RadioChangeEvent) => {
    setValueRole(e.target.value);
  };
  const onSubmit = (data: IFormInput) => {
    const request = {
      fullname: data.fullname,
      phone_number: data.username,
      password: data.password,
      school_id: data.department,
      is_save_permission: valueRole === 0 ? true : false
    };
    listAgenciesSoHttp.addAgenciesSo(request).then((res) => {
      if (res.ok && res.status === 200) {
        console.log(res);
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ADD_SUCCESS);
        handleCancel();
        handleRefresh();
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.ADD_EDU_FAIL);
      }
    });
  };

  return (
    <Modal
      title="Thêm cơ quan phụ trách mới"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={null}>
      <Container>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Nhập họ tên
              </label>
              <Controller
                name="fullname"
                control={control}
                rules={{ required: true, minLength: 4 }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Họ và tên"
                    // onChange={onChangeFullName}
                  />
                )}
              />
              {errors?.fullname?.type === 'required' && (
                <span className="text-error">Họ tên không được để trống!</span>
              )}
            </div>
            <div className="mb-15 select-angencies">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn cơ quan phụ trách
              </label>
              <Controller
                name="department"
                control={control}
                rules={{ required: true, minLength: 4 }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Chọn cơ quan phụ trách"
                    style={{
                      width: '100%'
                    }}
                    // onChange={($event) => onChangeOptionDepartment($event)}
                    value={valueDepartment}
                    options={
                      !_.isEmpty(department) &&
                      department.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })
                    }
                  />
                )}
              />
              {errors?.department?.type === 'required' && (
                <span className="text-error">Vui lòng chọn cơ quan phụ trách!</span>
              )}
            </div>
            <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn vai trò
              </label>
              <Radio.Group onChange={handleChangeRole} value={valueRole}>
                <Radio value={0}>Trưởng phòng</Radio>
                <Radio value={1}>Chuyên viên</Radio>
              </Radio.Group>
            </div>
            <div className="grid sm:grid-1 mb-10 gap-10 sm:gap-10">
              <div>
                <label htmlFor="" className="font-bold mb-10 block">
                  Tên đăng nhập
                </label>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true, minLength: 4 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Tên đăng nhập"
                      // onChange={onChangeUserName}
                    />
                  )}
                />
                {errors?.username?.type === 'required' && (
                  <span className="text-error">Tên đăng nhập không được để trống!</span>
                )}
                {errors?.username?.type === 'minLength' && (
                  <span className="text-error">Tên đăng nhập có ít nhất 4 ký tự!</span>
                )}
              </div>
              <div>
                <label className="font-bold mb-10 block">Mật khẩu</label>
                <div className="form-password w-full">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true, minLength: 4, pattern: /^[a-zA-Z0-9]*$/ }}
                    render={({ field }) => (
                      <Input.Password
                        {...field}
                        status={errors?.password?.type === 'required' ? 'error' : ''}
                        placeholder="Nhập mật khẩu"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    )}
                  />
                  {errors?.password?.type === 'required' && (
                    <span className="text-error">Mật khẩu không được để trống!</span>
                  )}
                  {errors?.password?.type === 'minLength' && (
                    <span className="text-error">Mật khẩu có ít nhất 4 ký tự!</span>
                  )}
                  {errors?.password?.type === 'pattern' && (
                    <span className="text-error">
                      Mật khẩu không được có dấu hoặc khoảng trống!
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="font-bold mb-10 block">Nhập lại mật khẩu</label>
                <div className="form-password w-full">
                  <Controller
                    name="rePassword"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value === getValues('password')
                    }}
                    render={({ field }) => (
                      <Input.Password
                        {...field}
                        status={errors?.rePassword?.type === 'required' ? 'error' : ''}
                        placeholder="Nhập mật khẩu"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    )}
                  />
                  {errors?.rePassword?.type === 'required' && (
                    <span className="text-error">Nhập lại mật khẩu không được để trống!</span>
                  )}
                  {errors?.rePassword?.type === 'validate' && (
                    <span className="text-error">Nhập lại mật khẩu không trùng khớp!</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-20">
              <button type="submit" className="btn btn-add ml-10">
                Thêm mới
              </button>
            </div>
          </form>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalCreateAgencies;
