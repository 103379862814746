import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectevaluation = (state: RootState) => state?.evaluationTitles || initialState;
export const selectLoading = createSelector([selectevaluation], (review) => review.loading);
export const selectError = createSelector([selectevaluation], (review) => review.error);
export const selectSuccess = createSelector([selectevaluation], (review) => review.success);
export const selectDataGetApi = createSelector([selectevaluation], (review) => review.dataGetApi);
export const selectDataListEvaluationTitleTearch = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesTeacher
);
export const selectDataDoeListEvaluationTitleTearch = createSelector(
  [selectevaluation],
  (review) => review.dataDoeListEvaluationTitlesTeacher
);
export const selectDataDetailGetApi = createSelector(
  [selectevaluation],
  (review) => review.dataDetail
);
export const selectDataListEvaluationEmulationTypes = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationEmulationTypes
);
export const selectDataListEvaluationTitleTypes = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitleTypes
);
export const selectDataListEvaluationDecisionsNo = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationDecisionsNo
);
export const selectDataListEvaluationTitleGroup = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitleGroup
);
export const selectDataListAdminEvaluationTitleGroup = createSelector(
  [selectevaluation],
  (review) => review.dataListAdminEvaluationTitlesGroup
);
export const selectDataListEvaluationLibraries = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationLibraries
);
export const selectDataListEvaluationTitlesPersonal = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesPersonal
);
export const selectDataListEvaluationTitlesGroup = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesGroup
);
export const selectDataListEvaluationFileReport = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationFileReport
);
export const selectDataListEvaluationDecisionNo = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationDecisionNo
);
export const selectDataListEvaluationDecisionNoSo = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationDecisionNoSo
);
export const selectDataListEvaluationInitiativesScoreMe = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationInitiativesScoreMe
);
export const selectDataDayRegisterEvaluationPersonal = createSelector(
  [selectevaluation],
  (review) => review.dataDayRegisterEvaluationPersonal
);
export const selectDataListEvaluationResultEndYear = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationResultEndYear
);
export const selectDataListEvaluationResultEndYearPersonal = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationResultEndYearPersonal
);
export const selectDataListEvaluationResultEndYearGroup = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationResultEndYearGroup
);
export const selectDataListEvaluationTitlesPgdQ3Personal = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesPgdQ3Personal
);
export const selectDataListEvaluationTitlesPgdQ3PersonalNew = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesPgdQ3PersonalNew
);
export const selectDataListEvaluationTitlesPgdQ3Group = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesPgdQ3Group
);
export const selectDataListEvaluationTitlesPgdQ3GroupNew = createSelector(
  [selectevaluation],
  (review) => review.dataListEvaluationTitlesPgdQ3GroupNew
);


