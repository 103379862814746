import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Button, Modal, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';
interface IModalConfirmBasic {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  titleName: string;
}

function ModalConfirmBasic({ isOpen, handleOk, handleCancel, titleName }: IModalConfirmBasic) {
  return (
    <Modal
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      footer={null}
      maskClosable={false}>
      <div className="p-18">
        <Space wrap>
          <ExclamationCircleOutlined style={{ fontSize: 25, color: '#FAAD13' }} />
          <span style={{ fontSize: 16 }}>{titleName}</span>
        </Space>
        <div className="flex justify-end mt-30">
          <Space align="baseline">
            <Button key="back" size="large" onClick={handleCancel}>
              Không
            </Button>
            <Button key="submit" size="large" type="primary" onClick={handleOk}>
              Đồng ý
            </Button>
          </Space>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(ModalConfirmBasic);
