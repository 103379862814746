export const CompetitionRatingEduSoApi = {
  LIST_COMOETITION_REVIEW_SO: '/so/evaluations',
  LIST_DEPARTMENT_SO: '/so/evaluations',
  LIST_DEPARTMENT_SO_DETAIL: '/so/evaluations/list_evaluation_schools',
  ADD_DEPARTMENT_SO: '/users',
  DELETE_DEPARTMENT_SO: '/so/evaluations',
  DELETE_REVIEW_SO: '/so/evaluations',
  DELETE_REVIEW_PGD: '/evaluations',
  DETAIL_DEPARTMENT_SO: '/so/evaluations',
  EDIT_DEPARTMENT_SO: '/so/evaluations/',
  URL_EVALUATION_SO_SEND_RESULT: '/so/evaluations/send_result',
  URL_EVALUATION_SO_PUBLISH: '/so/evaluations/publish',
  URL_EVALUATION_PGD_PUBLISH: '/pgd/evaluations/publish',
  URL_EVALUATION_PGD_DOWNLOAD: '/pgd/self_evaluations/summary/export'
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ADD_DEPARTMENT_SO_SUCCESS: 'Thêm cơ quan phụ trách thành công!',
    DELETE_DEPARTMENT_SO_SUCCESS: 'Xóa cơ quan phụ trách thành công!'
  },
  FAIL: {
    ADD_DEPARTMENT_SO_FAIL: 'Thêm cơ quan phụ trách thành công!',
    DELETE_DEPARTMENT_SO_FAIL: 'Xóa cơ quan phụ trách thành công!'
  },
  WARN: {}
};
