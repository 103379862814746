import AppLoading from 'components/Loading';
import { NavBarTTSK } from 'components/Common/ttsk';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { selectListReview, selectLoading } from 'store/initiativeAchievement/shared/selectors';
import { INIT_PAGE, IPagi, TITLE_NAVBAR, YEAR_ID_DEFAULT } from '_constants/default.constants';
import * as FromCreateInitiativeSlice from '../../../store/initiativeAchievement/shared/slice';
import * as common from '../../../store/common/shared/slice';
import _ from 'lodash';
import ModalConfirm from 'components/Modal/ModalConfirm';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from '../../../store/initiativeAchievement/constants/initiativeAchievement.constants';
import { Space, Tooltip } from 'antd';
import Pagination from 'components/Pagination';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  selectCourseId,
  selectListCoursesBase,
  selectListEffectScopes,
  selectListPeriod,
  selectListPeriodId,
  selectPeriodId
} from 'store/common/shared/selectors';

import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import {
  selectDataGetApi,
  selectDataListEvaluationEmulationTypes,
  selectDataListEvaluationTitleTypes
} from 'store/evaluation/shared/selectors';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';

import ModalCreateReview from './ModalCreateReview';
import ModalCreateReviewDKSK from './ModalCreateReviewDKSK';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import styled from 'styled-components';
import ModalEdit from './ModalEdit';
import ModalDKSKEdit from './ModalDKSKEdit';

import ModalDetail from './ModalDetail';
import FunctionUtils from 'utils/FunctionUtils';
import useAuth from 'hooks/useAuth';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import { Helmet } from 'react-helmet-async';
import * as FromCommonSlice from 'store/common/shared/slice';
import moment from 'moment';
import { CommonSaga } from 'store/common/shared/saga';
import ModalRegister from 'views/EmulationTitlePgdQ3/components/ModalRegister';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import { ModalViewDecisionNo } from 'views/ListAchievementSchoolPgdQ3/components/ModalViewDecisionNo';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}

export function TableReviewTTSKPgdQ3() {
  const location = useLocation();
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });
  const dispatch = useDispatch();

  const ListEvaluationEmulationTypes: any = useSelector(selectDataListEvaluationEmulationTypes);
  const listReview: any = useSelector(selectListReview);
  const loading: any = useSelector(selectLoading);
  const localStorageService = new LocalStorageService();

  const [isModalConfirmDel, setIsModalConfirmDel] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const toast = useToast();
  const [isCreateInitiative, setIsCreateInitiative] = useState<boolean>(false);
  const [isCreateInitiativeDKSK, setIsCreateInitiativeDKSK] = useState<boolean>(false);
  const [isOpenClone, setIsOpenClone] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenDKSKEdit, setIsOpenDKSKEdit] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenModalRegister, setIsOpenModalRegister] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isViewUpdate, setIsViewUpdate] = useState<boolean>(false);
  const [dataYears, setDataYears] = useState<any>([]);
  const [idDelete, setIdDelete] = useState<any>('');
  const [dataEvaluationEmulationTypes, setDataEvaluationEmulationTypes] = useState([]);
  const [dataUpdateEmulationTitle, setDataUpdateEmulationTitle] = useState<any>();
  const [isOpenViewDecisionNo, setIsOpenViewDecisionNo] = useState<boolean>(false);
  const [valueDecisionNo, setValueDecisionNo] = useState<any>();
  const [score, setScore] = useState<string>('0');
  const [type, setType] = useState<number>(1);
  const [titleDecisionNo, setTitleDecisionNo] = useState<string>('');
  const [infoSchool, setInfoSchool] = useState<any>();

  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listPeriod: any = useSelector(selectListPeriod);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const navigate = useNavigate();

  const { filters, setFilters } = useAuth();
  const initiativeAchievementHttp = new InitiativeAchievementHttp();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filters]);
  };

  useEffectOnMount(() => {
    filters.courseId = 0;
    dispatch(common.actions.getCoursesBase(filters));

    filters.perPage = -1; // get all, chia 2 table
    filters.gradeId = 0;
    dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filters));
    filters.perPage = 20;
    dispatch(evaluationList.actions.getListEvaluationEmulationTypes(filters));
    const infoSchool: any = localStorageService.getItem(LocalStorageKey.infoSchools);
    setInfoSchool(infoSchool);
    //dispatch(FromCreateInitiativeSlice.actions.getListEffectScope(filters));
  });

  // useEffect(() => {
  //   if (!_.isEmpty(listReview)) {
  //     console.log('listReview', listReview);
  //     console.log(
  //       listReview?.filter((item) => item.phan_loai == 0).sort((a, b) => b?.year - a?.year)
  //     );
  //   }
  // }, [listReview]);

  const setNewFilters = (newFilter) => {
    setFilters(newFilter);
  };

  const onClickDel = (record: any) => {
    setIdReview(record.id);
    setIsModalConfirmDel(!isModalConfirmDel);
  };

  const onClickClone = (id: string) => {
    setIdReview(id);
    setIsOpenClone(!isOpenClone);
  };
  const onClickEdit = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenEdit(!isOpenEdit);
  };
  const onClickDKSKEdit = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenDKSKEdit(!isOpenDKSKEdit);
    setIsViewUpdate(false);
  };

  const onClickDetail = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenDetail(!isOpenDetail);
  };

  // const handleDel = () => {
  //   const request = {
  //     id: idReview
  //   };
  //   createInitiativeHttp.deleteReview(request).then((res) => {
  //     if (res.ok && res.status === 200) {
  //       toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_REVIEW_SUCCESS);
  //       handleRefresh();
  //     } else {
  //       toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_FAIL);
  //     }
  //     setIsModalConfirmDel(!isModalConfirmDel);
  //   });
  // };

  const handleNext = () => {
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filters.pageId === 1) return;
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filters, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filters.perPage) return true;
      return false;
    }
    return true;
  }, [listReview]);

  const handleRefresh = () => {
    dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filters));
  };

  const handleRefreshRegisterEmulation = () => {
    navigate('/emulation-title');
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return (
            <span style={{ color: '#1890ff' }}>
              {!_.isEmpty(listCourseBase) &&
                listCourseBase.map((ls) => (ls.id == record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCourseBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    // {
    //   title: 'Xếp loại',
    //   key: 'effect_scope_id',
    //   width: 100,
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     let scope = '';
    //     if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
    //       const mediumScore = (record.score + record.score2) / 2;
    //       if (mediumScore >= 81 && mediumScore < 91) {
    //         scope = 'Tốt';
    //       } else if (mediumScore >= 91) {
    //         scope = 'Xuất sắc';
    //       } else {
    //         scope = '--.--';
    //       }
    //     } else {
    //       scope = '--.--';
    //     }
    //     if (infoSchool?.is_vp_so === true) {
    //       if (record.published === true) {
    //         return <span style={{ color: '#1890ff' }}>{scope}</span>;
    //       }
    //       return <span style={{ color: '#1890ff' }}>--.--</span>;
    //     } else {
    //       return <span style={{ color: '#1890ff' }}>{scope}</span>;
    //     }
    //   }
    // },
    {
      title: 'Quyết định công nhận của trường',
      key: 'school_decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_school_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_school_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionSchool(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Công nhận Phạm vi ảnh hưởng của sáng kiến',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionDoe(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        // if (infoSchool?.is_vp_so === true) {
        //   if (record.published === true) {
        //     return <span style={{ color: '#1890ff' }}>{scope}</span>;
        //   }
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // } else {
        //   return <span style={{ color: '#1890ff' }}>{scope}</span>;
        // }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Trạng thái',
      //dataIndex: 'status',
      key: 'status',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return record.phan_loai == 1 ? (
          <span>Đã đăng ký</span>
        ) : record.phan_loai == 0 ? (
          <span style={{ color: '#1890ff' }}>Đã cấp quyết định</span> // cập nhật
        ) : (
          <span style={{ color: '#1890ff' }}>--.--</span>
        );
      }
    },
    {
      title: 'Hành động',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      key: 'action',
      align: 'center',
      render: (record) => {
        return (
          <Space>
            {record.phan_loai == 1 ? ( // đăng ký
              <Space>
                <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                  <span className="edit" onClick={() => onClickDKSKEdit(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-edit"></i>
                    </div>
                  </span>
                </Tooltip>
                {record.status_so === 1 && (
                  <Tooltip title="Nhập nội dung sáng kiến" placement="bottomLeft">
                    <span className="clone" onClick={() => handleUpdateData(record)}>
                      <div
                        className="flex items-center justify-center rounded-2"
                        style={{ color: '#1890ff', cursor: 'pointer' }}>
                        {/* Xem chi tiết */}
                        <i className="fas fa-upload"></i>
                      </div>
                    </span>
                  </Tooltip>
                )}
              </Space>
            ) : record.phan_loai == 0 ? ( // cập nhật
              <Space>
                <Tooltip title="Xem chi tiết" placement="bottomLeft">
                  <span onClick={() => onClickDetail(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-eye"></i>
                    </div>
                  </span>
                </Tooltip>
                <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                  <span className="edit" onClick={() => onClickEdit(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-edit"></i>
                    </div>
                  </span>
                </Tooltip>
              </Space>
            ) : (
              ''
            )}
            {record.teacher_info_1 === '' && record.teacher_info_2 === '' && (
              <Tooltip title="Xóa" placement="bottomLeft">
                <span className="clone" onClick={() => handleDelete(record)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-trash"></i>
                  </div>
                </span>
              </Tooltip>
            )}
          </Space>
        );
      }
    }
  ];

  const columnsDeparment: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return (
            <span style={{ color: '#1890ff' }}>
              {!_.isEmpty(listCourseBase) &&
                listCourseBase.map((ls) => (ls.id == record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCourseBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Xếp loại',
      key: 'effect_scope_id',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'Tốt';
          } else if (mediumScore >= 91) {
            scope = 'Xuất sắc';
          } else {
            scope = '--.--';
          }
        } else {
          scope = '--.--';
        }
        if (record.published === true) {
          return <span style={{ color: '#1890ff' }}>{scope}</span>;
        }
        return <span style={{ color: '#1890ff' }}>--.--</span>;
      }
    },
    {
      title: 'Công nhận Phạm vi ảnh hưởng của sáng kiến',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionDoe(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        // if (infoSchool?.is_vp_so === true) {
        //   if (record.published === true) {
        //     return <span style={{ color: '#1890ff' }}>{scope}</span>;
        //   }
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // } else {
        //   return <span style={{ color: '#1890ff' }}>{scope}</span>;
        // }
        // if (record.published === true) {
        //   return <span style={{ color: '#1890ff' }}>{scope}</span>;
        // }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Trạng thái',
      //dataIndex: 'status',
      key: 'status',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return record.phan_loai == 1 ? (
          <span>Đã đăng ký</span>
        ) : record.phan_loai == 0 ? (
          <span style={{ color: '#1890ff' }}>Đã cấp quyết định</span> // cập nhật
        ) : (
          <span style={{ color: '#1890ff' }}>--.--</span>
        );
      }
    },
    {
      title: 'Hành động',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      key: 'action',
      align: 'center',
      render: (record) => {
        return (
          <Space>
            {record.phan_loai == 1 ? ( // đăng ký
              <Space>
                <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                  <span className="edit" onClick={() => onClickDKSKEdit(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-edit"></i>
                    </div>
                  </span>
                </Tooltip>
                {record.status_so === 1 && (
                  <Tooltip title="Nhập nội dung sáng kiến" placement="bottomLeft">
                    <span className="clone" onClick={() => handleUpdateData(record)}>
                      <div
                        className="flex items-center justify-center rounded-2"
                        style={{ color: '#1890ff', cursor: 'pointer' }}>
                        {/* Xem chi tiết */}
                        <i className="fas fa-upload"></i>
                      </div>
                    </span>
                  </Tooltip>
                )}
              </Space>
            ) : record.phan_loai == 0 ? ( // cập nhật
              <Space>
                <Tooltip title="Xem chi tiết" placement="bottomLeft">
                  <span onClick={() => onClickDetail(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-eye"></i>
                    </div>
                  </span>
                </Tooltip>
                <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                  <span className="edit" onClick={() => onClickEdit(record.id, record.name)}>
                    <div
                      className="flex items-center justify-center rounded-2"
                      style={{ color: '#1890ff', cursor: 'pointer' }}>
                      <i className="fas fa-edit"></i>
                    </div>
                  </span>
                </Tooltip>
              </Space>
            ) : (
              ''
            )}
            {record.teacher_info_1 === '' && record.teacher_info_2 === '' && (
              <Tooltip title="Xóa" placement="bottomLeft">
                <span className="clone" onClick={() => handleDelete(record)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-trash"></i>
                  </div>
                </span>
              </Tooltip>
            )}
          </Space>
        );
      }
    }
  ];

  const columnsHaves: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return (
            <span style={{ color: '#1890ff' }}>
              {!_.isEmpty(listCourseBase) &&
                listCourseBase.map((ls) => (ls.id == record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCourseBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    // {
    //   title: 'Xếp loại',
    //   key: 'effect_scope_id',
    //   width: 100,
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     let scope = '';
    //     if (record.status_cham_diem === 1) {
    //       const mediumScore = (record.score + record.score2) / 2;
    //       if (record.score >= 81 && record.score < 91) {
    //         scope = 'Tốt';
    //       } else if (record.score >= 91) {
    //         scope = 'Xuất sắc';
    //       } else {
    //         scope = '--.--';
    //       }
    //     } else {
    //       scope = '--.--';
    //     }
    //     return <span style={{ color: '#1890ff' }}>{scope}</span>;
    //   }
    // },
    {
      title: 'Số quyết định',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.decision_no)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return <span style={{ color: '#1890ff' }}>{record.decision_no}</span>;
      }
    },
    {
      title: 'Ngày quyết định',
      key: 'decision_date',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.decision_date)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return <span style={{ color: '#1890ff' }}>{record.decision_date}</span>;
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.is_coso && record.is_tp) {
          scope = 'cấp Quận, Thành phố';
        } else if (record.is_tp) {
          scope = 'Thành phố';
        } else if (record.is_coso) {
          scope = 'cấp Quận';
        } else {
          scope = '--.--';
        }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Trạng thái',
      //dataIndex: 'status',
      key: 'status',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return record.phan_loai == 1 ? (
          <span>Đã đăng ký</span>
        ) : record.phan_loai == 0 ? (
          <span style={{ color: '#1890ff' }}>Đã cấp quyết định</span> // cập nhật
        ) : (
          <span style={{ color: '#1890ff' }}>--.--</span>
        );
      }
    },
    {
      title: 'Hành động',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      key: 'action',
      align: 'center',
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <span onClick={() => onClickDetail(record.id, record.name)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-eye"></i>
                </div>
              </span>
            </Tooltip>
            <Tooltip title="Chỉnh sửa" placement="bottomLeft">
              <span className="edit" onClick={() => onClickEdit(record.id, record.name)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-edit"></i>
                </div>
              </span>
            </Tooltip>
            <Tooltip title="Xóa" placement="bottomLeft">
              <span className="clone" onClick={() => handleDelete(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-trash"></i>
                </div>
              </span>
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  const handleOpenDecisionSchool = (record) => {
    setValueDecisionNo(record);
    setTitleDecisionNo('Công nhận sáng kiến');
    setIsOpenViewDecisionNo(true);
    setType(1);
  };

  const handleOpenDecisionDoe = (record) => {
    setTitleDecisionNo('Công nhận Phạm vi ảnh hưởng của sáng kiến');
    setValueDecisionNo(record);
    setIsOpenViewDecisionNo(true);
    setType(2);
  };

  const handleOpenReward = (type?) => {
    if (type === 'isAddNew') {
      setDataUpdateEmulationTitle('');
    }
    const dataEmulationTypes = ListEvaluationEmulationTypes.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setDataEvaluationEmulationTypes(dataEmulationTypes);
    setIsOpenModalRegister(true);
  };

  const handleClicProviso = () => {
    const url = '/assets/docs/1_ĐIỀU_KIỆN_ĐĂNG_KÝ_DANH_HIỆU_THI_ĐUA_KHEN_THƯỞNG_CÁ_NHÂN_1.pdf';
    // const url =
    //   'https://danhgiaq3.gat.edu.vn/assets/docs/1_ĐIỀU_KIỆN_ĐĂNG_KÝ_DANH_HIỆU_THI_ĐUA_KHEN_THƯỞNG_CÁ_NHÂN_1.pdf';
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  };

  const handleDelete = (item) => {
    setIdDelete(item.id);
    setIsOpenModalConfirm(true);
  };

  const handleConfirmDelete = () => {
    initiativeAchievementHttp.deleteInitiative(idDelete).then((res) => {
      toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_SUCCESS);
      setIsOpenModalConfirm(false);
      dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filters));
    });
  };

  const handleUpdateData = (item) => {
    setIdReview(item.id);
    setTitle(item.name);
    setIsOpenDKSKEdit(!isOpenDKSKEdit);
    setIsViewUpdate(true);
  };
  // const handleConfirmDelete = () => {
  //   toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_SUCCESS);
  //   setIsOpenModalConfirm(false);
  // };

  return (
    <div className="pb-0">
      <Helmet>
        <title>Thành tích sáng kiến</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      <div className="px-15">
        <NavBarTTSK
          gridNumber={3}
          title="Đăng ký sáng kiến"
          isButtonDKSK
          handleClickDKSK={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
          isButtonReward
          handleClicReward={() => handleOpenReward('isAddNew')}
          isButtonProviso
          handleClicProviso={() => handleClicProviso()}
        />
        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={infoSchool?.is_vp_so ? columnsDeparment : columns}
                dataSource={
                  !_.isEmpty(listReview) && listReview.filter((item) => item.phan_loai == 1)
                }
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        {/* <div className="mt-30">
          <Pagination
            current={filters.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filters.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div> */}
      </div>

      <div className="px-15">
        <NavBarTTSK
          title="Thành tích sáng kiến đã có"
          isButton
          handleClick={() => setIsCreateInitiative(!isCreateInitiative)}
          gridNumber={1}
          //isButtonDKSK
          //handleClickDKSK={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
        />
        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columnsHaves}
                dataSource={
                  !_.isEmpty(listReview) &&
                  listReview.filter((item) => item.phan_loai == 0).sort((a, b) => b?.year - a?.year)
                }
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        {/* <div className="mt-30">
          <Pagination
            current={filters.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filters.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div> */}
      </div>
      <div className="bg-overlay" />
      {isCreateInitiative && (
        <ModalCreateReview
          isOpen={isCreateInitiative}
          handleCancel={() => setIsCreateInitiative(!isCreateInitiative)}
          handleRefresh={handleRefresh}
        />
      )}
      {isCreateInitiativeDKSK && (
        <ModalCreateReviewDKSK
          isOpen={isCreateInitiativeDKSK}
          handleCancel={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenEdit && (
        <ModalEdit
          isOpen={isOpenEdit}
          handleCancel={() => setIsOpenEdit(!isOpenEdit)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDKSKEdit && (
        <ModalDKSKEdit
          isOpen={isOpenDKSKEdit}
          handleCancel={() => setIsOpenDKSKEdit(!isOpenDKSKEdit)}
          title={title}
          id={idReview}
          isViewUpdate={isViewUpdate}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenModalRegister && (
        <ModalRegister
          isOpen={isOpenModalRegister}
          dataCourses={dataYears}
          userType={2}
          dataUpdateEmulationTitle={dataUpdateEmulationTitle}
          dataEmulationTypes={dataEvaluationEmulationTypes}
          handleRefresh={handleRefreshRegisterEmulation}
          handleCancel={() => setIsOpenModalRegister(!isOpenModalRegister)}
        />
      )}
      {isOpenModalConfirm && (
        <ModalConfirmBasic
          isOpen={isOpenModalConfirm}
          titleName="Bạn có chắc chắn muốn xóa mục này?"
          handleOk={handleConfirmDelete}
          handleCancel={() => setIsOpenModalConfirm(!isOpenModalConfirm)}
        />
      )}
      {isOpenViewDecisionNo && (
        <ModalViewDecisionNo
          isOpen={isOpenViewDecisionNo}
          detail={valueDecisionNo}
          title={titleDecisionNo}
          type={type}
          handleCancel={() => setIsOpenViewDecisionNo(!isOpenViewDecisionNo)}
        />
      )}
    </div>
  );
}
