import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectTeacherSo = (state: RootState) => state?.teacherSo || initialState;

export const selectLoading = createSelector([selectTeacherSo], (teacherSo) => teacherSo.loading);

export const selectListTeacherSo = createSelector([selectTeacherSo], (teacherSo) => teacherSo.listTeacherSo);

export const selectError = createSelector([selectTeacherSo], (teacherSo) => teacherSo.error);

export const selectSuccess = createSelector([selectTeacherSo], (teacherSo) => teacherSo.success);

export const selectListSubjects = createSelector(
  [selectTeacherSo],
  (teacherSo) => teacherSo.listSubjects
);
