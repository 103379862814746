import { Button, Card, Checkbox, Modal, Select, Tag } from 'antd';
import { useToast } from 'components/Toast';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { selectCourseId, selectListCourses, selectListPeriod } from 'store/common/shared/selectors';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { DownOutlined } from '@ant-design/icons';
import { getCourseId } from 'store/common/shared/saga';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const ContainerQuill = styled.div`
  .ql-container {
    height: 200px !important;
`;
const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

interface IModal {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  courseId?: number;

  file?: any;
  comment?: string;
  idUser?: number;
  detail?: any;
  proofValue?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}

const { Option } = Select;
function ModalCreateReviewDKSK({
  isOpen,
  title,
  handleCancel,
  handleRefresh,
  detail,
  handleSuccess
}: IModal) {
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<any[]>([]);
  const courseId: any = useSelector(selectCourseId);

  const [description, setDescription] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [effectScopeId, setEffectScopeId] = useState<Number>(0);
  const [score, setScore] = useState<Number>(0);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const commonHttp = new CommonHttp();

  const navigate = useNavigate();
  const localStorage = new LocalStorageService();
  const initiativeAchievementHttp = new InitiativeAchievementHttp();

  const onSelectYear = (value: string): void => {
    setYearSchool(value);
  };

  const onSelectEffect = (value: Number): void => {
    setEffectScopeId(value);
  };
  const onChangeValue = (e) => {
    if (e.target.name == 'title') {
      setTitleReview(e.target.value);
    }
    if (e.target.name == 'description') {
      setDescription(e.target.value);
    }
    if (e.target.name == 'content_id') {
      setContent(e.target.value);
    }
  };

  const onChangeValueContent = (data) => {
    setContent(data);
  };

  const handleUpload = () => {
    if (!_.isEmpty(filePath)) {
      commonHttp.uploadFile(filePath).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files));
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    if (handleSuccess) {
      handleSuccess(filePath, note, detail);
    }
    //toast?.pushSuccess('Dữ liệu đã được lưu tạm!');
    //handleCancel();
  };

  const onChangeAffect = (e: CheckboxChangeEvent, type) => {
    if (type === 'cs') {
      if (e.target.checked) {
        setValueAffectCS(e.target.checked);
        setDisabled(false);
      } else {
        setDisabled(true);
        setValueAffectTP(false);
        setValueAffectCS(e.target.checked);
      }
    } else {
      setValueAffectTP(e.target.checked);
    }
  };

  const handleSubmit = (e) => {
    if (titleReview === '') {
      // ||  description === '') {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_INITIATIVE_SUCCESS);
      return;
    }
    if (courseId == null) {
      toast?.pushWarning('Khóa học không tồn tại. Vui lòng kiểm tra lại thông tin.');
      return;
    }
    //var file = _.keyBy(filePath);
    const obj = Object.assign({}, filePath); // array to object
    //upload
    handleUpload();
    ///
    // var content1 = content; // bo sung them content
    const request = {
      name: titleReview,
      course_id: courseId.id! ? courseId.id : 0,
      description: description,
      effect_scope_id: effectScopeId,
      is_coso: valueAffectCS,
      is_tp: valueAffectTP,
      files: obj,
      score: parseInt(score.toString()),
      phan_loai: 1 // 0 bổ sung, 1 đăng ký
    };

    initiativeAchievementHttp.addInitiativeUser(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ADD_INITIATIVE_SUCCESS);
        handleCancel();
        handleRefresh();
        //navigate(`/manager-review/create-standard/${res.data.data.evaluation.id}/${0}`);
        //localStorage.setItem({ key: LocalStorageKey.isVisible, value: 'show' });
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.ADD_INITIATIVE_FAIL);
      }
    });
  };

  const handleChange = (value: string[]) => {
    setPeriod(value);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        let key = UploadFile.getIdFile(file, detail);
        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePaths(newFilePath);
  };

  const handleRemoveFile = (file: any) => {
    const newFilePath = filePath.filter((item) => item !== file);
    setFilePaths(newFilePath);
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  //////
  return (
    <Modal
      title="Đăng ký sáng kiến"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Đăng ký
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>
            <input
              name="title"
              id="title"
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Đăng ký phạm vi ảnh hưởng
            </label>
            <Checkbox checked={valueAffectCS} onChange={(e) => onChangeAffect(e, 'cs')}>
              Phạm vi ảnh hưởng cấp Quận
            </Checkbox>
            <Checkbox
              checked={valueAffectTP}
              disabled={disabled}
              onChange={(e) => onChangeAffect(e, 'tp')}>
              Phạm vi ảnh hưởng cấp Thành phố
            </Checkbox>
          </div>
          <div className="mb-15" hidden>
            <label htmlFor="" className="font-bold mb-10 block">
              Tóm tắt mô tả sáng kiến
            </label>
            <textarea
              //defaultValue={dataDetail?.self_evaluation_detail?.evaluation_self?.comment_strong}
              //maxLength={300}
              id="description"
              name="description"
              onChange={onChangeValue}
              cols={30}
              rows={8}
              className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
              placeholder="Nhập thông tin"></textarea>
          </div>
          <div className="mb-15" hidden>
            <label htmlFor="" className="font-bold mb-10 block">
              Nội dung sáng kiến
            </label>
            <ContainerQuill>
              <ReactQuill
                defaultValue=""
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],
                    ['clean'] // remove formatting button
                  ]
                }}
                id="content_id"
                key="content_id"
                onChange={(content, delta, source, quill) => {
                  const data = quill.getHTML();
                  setContent(data);
                }}
              />
            </ContainerQuill>
            <input type="hidden" id={`${'content_id'}`} name={`${'content_id'}`} />
          </div>
          <div className="grid sm:grid-1 gap-15 sm:gap-20" hidden>
            <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn phạm vi ảnh hưởng
              </label>
              <div className="relative">
                <StyleSelectCourse
                  style={{
                    width: '100%'
                  }}
                  id="effectScope"
                  placeholder="--- Chọn --- "
                  onChange={onSelectEffect}>
                  <Option value={1} key={1}>
                    {' '}
                    cấp Quận
                  </Option>
                  <Option value={2} key={2}>
                    {' '}
                    Thành phố
                  </Option>
                </StyleSelectCourse>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-1 pt-20 gap-15 sm:gap-20" hidden>
            <input type="file" multiple onChange={handleImage} />
          </div>
          <div className="grid sm:grid-3 gap-15 sm:gap-20" hidden>
            <ul className="mt-20 flex flex-column gap-10 sm:span-2">
              {!_.isEmpty(filePath) ? (
                filePath?.map((item: any, index) => (
                  <li
                    className="flex items-center justify-between text-45a"
                    key={index}
                    style={{ cursor: 'pointer' }}>
                    <span onClick={() => handleDownLoad(item)} className="text-wrap">
                      <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                    </span>
                    <button
                      className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                      style={{ fontSize: '14px' }}
                      onClick={() => handleRemoveFile(item)}>
                      <i className="fas fa-trash text-ea5" />
                    </button>
                  </li>
                ))
              ) : (
                <span>Không có file đính kèm</span>
              )}
            </ul>
            <span></span>
          </div>
          {/* <div className="grid sm:grid-1 gap-15 sm:gap-20">
            <div>
              <label htmlFor="" className="font-bold mb-10 block">
              </label>
              <input type="file" multiple onChange={handleImage} />
                <ul className="mt-20 flex flex-column gap-10">
                  {!_.isEmpty(filePath) ? (
                    filePath?.map((item: any, index) => (
                      <li
                        className="flex items-center justify-between text-45a"
                        key={index}
                        style={{ cursor: 'pointer' }}>
                        <span onClick={() => handleDownLoad(item)} className="text-wrap">
                          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                        </span>
                        <button
                          className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                          style={{ fontSize: '14px' }}
                          onClick={() => handleRemoveFile(item)}>
                          <i className="fas fa-trash text-ea5" />
                        </button>
                      </li>
                    ))
                  ) : (
                    <span>Không có file đính kèm</span>
                  )}
                </ul>
            </div>
          </div> */}
        </Card>
      </Container>
      {/* {isIOpenModalConfirm && (
        <ModalConfirmEdit
          isOpen={isIOpenModalConfirm}
          handleCancel={() => setIsOpenModalConfirm(!isIOpenModalConfirm)}
          handleOk={handleSubmit}
        />
      )} */}
    </Modal>
  );
}

export default ModalCreateReviewDKSK;
