import { APP_CONSTANT, YEAR_ID_DEFAULT } from '_constants/default.constants';
import { HttpRequest } from 'services/api';
import { ListAgenciesSoApi } from '../constants/ListAgenciesSo.constants';

export class ListAgenciesSoHttp {
  request: any;
  request2: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListAgenciesSo = ({ pageId, perPage, courseId, periodId }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${ListAgenciesSoApi.LIST_AGENCIES_SO}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}`
    );
  };

  public getListAgenciesSoDepartments = ({ pageId, perPage, courseId }): Promise<any> => {
    if (courseId === null) {
      courseId = YEAR_ID_DEFAULT;
    }
    return this.request.get(
      `${ListAgenciesSoApi.LIST_AGENCIES_SO_DEPARTMENTS}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}`
    );
  };

  public addAgenciesSo = (dataAgencies): Promise<any> => {
    return this.request.post(ListAgenciesSoApi.ADD_AGENCIES_SO, dataAgencies);
  };

  public updateAgenciesSo = (dataAgencies): Promise<any> => {
    return this.request.post(ListAgenciesSoApi.UPDATE_AGENCIES_SO, dataAgencies);
  };

  public evaluationGrantPermissions = (dataAgencies): Promise<any> => {
    return this.request.post(ListAgenciesSoApi.EVALUATION_GRANT_PERMISSTION, dataAgencies);
  };

  public getDetailevaluationGrantPermissions = (evaluationId): Promise<any> => {
    return this.request.get(`${ListAgenciesSoApi.EVALUATION_GRANT_PERMISSTION}/${evaluationId}`);
  };

  public deleteEvaluationGrantPermissions = (grantPermissions): Promise<any> => {
    console.log(grantPermissions);
    return this.request.delete(ListAgenciesSoApi.DELETE_GRANT_PERMISSTION, grantPermissions);
  };

  public resetPasswordAgenciesSo = (dataAgencies): Promise<any> => {
    return this.request.post(ListAgenciesSoApi.CHANGE_PASS_AGENCIES_SO, dataAgencies);
  };

  public deleteAgenciesSo = ({ id }): Promise<any> => {
    return this.request.delete(`${ListAgenciesSoApi.DELETE_AGENCIES_SO}/${id}`);
  };

  public changePassWordAgenciesSo = ({
    password,
    newPassword,
    confirmedNewPassword
  }): Promise<any> => {
    return this.request.post(ListAgenciesSoApi.CHANGE_PASSWORD, {
      confirmed_new_password: confirmedNewPassword,
      new_password: newPassword,
      password
    });
  };

  public editAgenciesSo = ({ id }): Promise<any> => {
    return this.request.patch(`${ListAgenciesSoApi.EDIT_AGENCIES_SO}/${id}`);
  };
}
