import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCourseId } from 'store/common/shared/selectors';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherHttp } from 'store/teacher/services/teacher.http';
import { TeacherSaga } from 'store/teacher/shared/saga';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
import _ from 'lodash';
import styled from 'styled-components';

interface TeacherRole {
  isOpen: boolean;
  handleCancel: () => void;
  detail: any;
}

const StyleSelectGrades: typeof Select = styled(Select)`
height: 40px;
box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
border-radius: 5px;
& .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
  & .ant-select-selection-search {
      height: 40px !important;
   }
   & .ant-select-selection-search-input {
      height: 40px !important;
   }
   & .ant-select-selection-item {
      line-height: 40px !important;
   }
   & .ant-select-selection-placeholder {
      line-height: 40px !important;
   }
  }
}
` as any;
const StyleSelectClass: typeof Select = styled(Select)`
height: 40px;
box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
border-radius: 5px;
& .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
  & .ant-select-selection-search {
      height: 40px !important;
   }
   & .ant-select-selection-search-input {
      height: 40px !important;
   }
   & .ant-select-selection-item {
      line-height: 40px !important;
   }
   & .ant-select-selection-placeholder {
      line-height: 40px !important;
   }
  }
}
` as any;
const { Option } = Select;
function TeacherRole({ isOpen, handleCancel, detail }: TeacherRole) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const teacherHttp = new TeacherHttp();
  const courseId: any = useSelector(selectCourseId);
  const [listGrades, setListGrades] = useState<any>();
  const [grades, setGrades] = useState<any>();
  const [listClass, setListClass] = useState<any>();
  const [classes, setClasses] = useState<any>();

  useEffect(() => {
    if (!_.isEmpty(courseId)) {
      const request = {
        courseId: courseId?.id
      };
      teacherHttp
        .getListGrades(request)
        .then((res) => {
          try {
            if (res.ok) {
              const { grades } = res.data?.data;
              setListGrades(grades);
            }
          } catch (error) {}
        })
        .catch();
    }
  }, [courseId]);

  const handleSubmit = () => {
    const request = {
      classes_id: [classes],
      grade_id: grades,
      user_id: detail.user_id
    };
    dispatch(FromTeacherSlice.actions.roleTeacher(request));
    handleCancel();
  };
  const handleChangeGrades = (value: string) => {
    setGrades(value);
    const request = {
      course_id: courseId?.id,
      grade_id: value
    };
    teacherHttp.getListClass(request).then((res) => {
      try {
        if (res.ok) {
          const { classes } = res.data?.data;
          setListClass(classes);
        }
      } catch (error) {}
    });
  };

  const handleChangeClass = (value: string) => {
    setClasses(value);
  };

  const renderGrades = () => {
    if (!_.isEmpty(listGrades)) {
      return listGrades.map((item) => {
        return (
          <Option value={item.ID} key={item.ID}>
            {item.title}
          </Option>
        );
      });
    }
  };

  const renderClass = () => {
    if (!_.isEmpty(listClass)) {
      return listClass.map((item) => {
        return (
          <Option value={item.ID} key={item.ID}>
            {item.title}
          </Option>
        );
      });
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Phân quyền quản lý lớp cho giáo viên: ${detail.teacher_fullname}`}
      footer={null}>
      <div className="container">
        <Row wrap justify="center" align="middle">
          <Col span={24} className="mb-5">
            <h4>Chọn khối</h4>
            <StyleSelectGrades
              style={{ width: '100%', height: 40 }}
              placeholder="Chọn khối"
              maxTagCount={'responsive'}
              onChange={handleChangeGrades}>
              {renderGrades()}
            </StyleSelectGrades>
          </Col>
          <Col span={24} className="mb-5">
            <h4>Chọn lớp</h4>
            <StyleSelectClass
              style={{ width: '100%', height: 40 }}
              placeholder="Chọn lớp"
              maxTagCount={'responsive'}
              onChange={handleChangeClass}>
              {renderClass()}
            </StyleSelectClass>
          </Col>
        </Row>
        <div className="flex justify-end mt-20">
          <Button key="back" size="large" onClick={handleCancel}>
            Đóng
          </Button>
          <button type="button" className="btn btn-add ml-10" onClick={handleSubmit}>
            Cập nhật
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TeacherRole;
