import { Button, Col, Input, Modal, Row } from 'antd';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { TeacherSaga } from 'store/teacher/shared/saga';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
interface ChangePassword {
  isOpen: boolean;
  handleCancel: () => void;
  handleOk: () => void;
  userId: any;
  teacher: string;
}
interface IFormInput {
  password: string;
  rePassword: string;
}
function ChangePassword({ isOpen, handleOk, handleCancel, userId, teacher }: ChangePassword) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>();
  const onSubmit = (data: IFormInput) => {
    const request = {
      userId: userId,
      password: data.rePassword
    };
    dispatch(FromTeacherSlice.actions.resetPassword(request));
    handleCancel();
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Đặt lại mật khẩu cho giáo viên: ${teacher}`}
      footer={null}>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row wrap justify="space-between" align="middle">
            <Col span={24} className="mb-5">
              <h4>Mật khẩu mới</h4>
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 4, pattern: /^[a-zA-Z0-9]*$/ }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.password?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.password?.type === 'required' && (
                <span className="text-error">Mật khẩu không được để trống!</span>
              )}
              {errors?.password?.type === 'minLength' && (
                <span className="text-error">Mật khẩu có ít nhất 4 ký tự!</span>
              )}
              {errors?.password?.type === 'pattern' && (
                <span className="text-error">Mật khẩu không được có dấu hoặc khoảng trống!</span>
              )}
            </Col>
            <Col span={24}>
              <h4>Nhập lại mật khẩu</h4>
              <Controller
                name="rePassword"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => value === getValues('password')
                }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.rePassword?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập lại mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.rePassword?.type === 'required' && (
                <span className="text-error">Nhập lại mật khẩu không được để trống!</span>
              )}
              {errors?.rePassword?.type === 'validate' && (
                <span className="text-error">Nhập lại mật khẩu không trùng khớp!</span>
              )}
            </Col>
          </Row>
          <div className="flex justify-end mt-20">
            <Button key="back" size="large" onClick={handleCancel}>
              Đóng
            </Button>
            <button type="submit" className="btn btn-add ml-10">
              Cập nhật
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePassword;
