// import './wdyr';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RootStore } from 'store/configureStore';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/style.css';
import './assets/css/main.css';
import './assets/css/normalize.css';
import 'antd/dist/antd.css';
import './assets/css/custom.css';
import { App } from 'App';
import reportWebVitals from 'reportWebVitals';
import ProviderContext from 'containers/ProviderContext';
import { AuthProvider } from 'context/AuthProvider';
import { getConfig } from 'config';

export const AppConfig = getConfig();

const el = document.getElementById('root');
if (el === null) throw new Error('Root container missing in index.html');
const root = ReactDOM.createRoot(el);
root.render(
  <React.StrictMode>
    <Provider store={RootStore}>
      <BrowserRouter>
        <HelmetProvider>
          <ProviderContext>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ProviderContext>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
