import { Tooltip } from 'antd';
import React from 'react';
import './style.css';
export default function ButtonCreate(props) {
  const { text, handleClick, icon, titleToolTip, style, isDisable } = props;
  return (
    <div
      className={isDisable ? 'button disable' : 'button'}
      style={style ? style : { width: 'auto', cursor: 'pointer' }}
      onClick={handleClick}>
      <div className="icon">
        {icon ? <i className={`fas ${icon}`}></i> : <i className="fas fa-plus"></i>}
        {titleToolTip ? (
          <Tooltip placement="bottom" title={titleToolTip}>
            <span className="sp-text">{text}</span>
          </Tooltip>
        ) : (
          <span className="sp-text">{text}</span>
        )}
      </div>
    </div>
  );
}
