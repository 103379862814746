import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Form, Select, DatePicker } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import ModalListTeacher from 'views/ListAchievementSchool/components/ModalListTeacher';
import { YEAR_ID_DEFAULT } from '_constants/default.constants';
import Table, { ColumnsType } from 'antd/lib/table';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import ModalListTeacherSo from './ModalListTeacherSo';
interface IModalEditDecentralized {
  isOpen: boolean;
  dataDetail: any;
  dataUser: any;
  handleCancel: () => void;
  handleRefresh: () => void;
  school?: any;
  listDepartment: any[];
}

const ContainerTable = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

function ModalEditDecentralized({
  isOpen,
  dataDetail,
  dataUser,
  handleCancel,
  handleRefresh,
  school,
  listDepartment
}: IModalEditDecentralized) {
  const createReviewHttp = new InitiativeAchievementHttp();
  const toast = useToast();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isOpenListTeacher, setIsOpenListTeacher] = useState<boolean>(false);
  const [isLoginSSO, setIsLoginSSO] = useState<boolean>(false);
  const [isPublicScore, setIsPublicScore] = useState<boolean>(false);
  const [valueTeacher, setValueTeacher] = useState<any>();
  const [teacherNumber, setTeacherNumber] = useState<number>();
  const [teacherInfo1, setTeacherInfo1] = useState<any>();
  const [teacherInfo2, setTeacherInfo2] = useState<any>();
  const [schoolObj, setSchoolObj] = useState<any>();
  const [valueListAchievement, setValueListAchievement] = useState<any>();
  useEffect(() => {
    if (dataDetail) {
      getDetailReview(dataDetail.id);
      const arrData: any = [];
      arrData.push(dataDetail);
      setValueListAchievement(arrData);
    }
    if (!_.isEmpty(dataUser.user?.user_logintype_code)) {
      setIsLoginSSO(true);
    }
  }, [dataDetail]);

  useEffect(() => {
    setSchoolObj(school);
  }, []);

  const getDetailReview = (id) => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        setIsPublicScore(data?.evaluation_intiative_detail?.published);
        setTeacherInfo1({
          name: data?.evaluation_intiative_detail.teacher_info_1,
          user_id: data?.evaluation_intiative_detail.teacher_score1,
          school_id: data?.evaluation_intiative_detail.teacher_school_id_1
        });
        setTeacherInfo2({
          name: data?.evaluation_intiative_detail.teacher_info_2,
          user_id: data?.evaluation_intiative_detail.teacher_score2,
          school_id: data?.evaluation_intiative_detail.teacher_school_id_2
        });
      }
    });
  };

  const handleOpenListTeacher = (teacherInfo, teacherNumber) => {
    let teacher_fullname = '';
    if (teacherNumber === 1) {
      teacher_fullname = teacherInfo2 ? teacherInfo2.name : '';
    } else {
      teacher_fullname = teacherInfo1 ? teacherInfo1.name : '';
    }
    setIsOpenListTeacher(true);
    setTeacherNumber(teacherNumber);
    const data = [
      {
        user_id: teacherInfo.user_id,
        teacher_fullname: teacher_fullname,
        userId_create: dataDetail.user_id,
        school_id: teacherInfo.school_id
      }
    ];
    setValueTeacher(data);
  };

  const handleRefreshChooseTeacher = () => {};

  const handleSuccess = (data) => {
    if (teacherNumber === 1) {
      setTeacherInfo1({
        name: `${data[0].teacher_fullname}`,
        user_id: data[0].user_id
      });
    } else {
      setTeacherInfo2({
        name: `${data[0].teacher_fullname}`,
        user_id: data[0].user_id
      });
    }
  };

  const handleSubmit = () => {
    if (teacherInfo1?.user_id === 0) {
      toast?.pushError('Vui lòng chọn giám khảo 1!');
      return;
    }
    if (teacherInfo2?.user_id === 0) {
      toast?.pushError('Vui lòng chọn giám khảo 2!');
      return;
    }
    if (teacherInfo1?.user_id === teacherInfo2?.user_id) {
      toast?.pushError('Vui lòng không phân công cùng một giám khảo!');
      return;
    }
    const data = {
      teacher_score_1: teacherInfo1?.user_id,
      teacher_score_2: teacherInfo2?.user_id,
      initiative_ids: valueListAchievement.map((item) => item.id)
    };
    setIsSubmit(true);
    createReviewHttp.postRoleScore(data).then((res) => {
      if (res.ok && res.status === 200) {
        setIsSubmit(false);
        handleCancel();
        handleRefresh();
        toast?.pushSuccess('Chỉnh sửa phân quyền chấm sáng kiến thành công!');
      } else {
        setIsSubmit(false);
      }
    });
  };

  const columnsAchievement: ColumnsType<any> = [
    {
      title: 'STT',
      width: '4%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: isLoginSSO ? 'Mã định danh bộ' : 'Mã công chức',
      dataIndex: isLoginSSO ? 'teacher_code' : 'ma_cong_chuc',
      key: isLoginSSO ? 'teacher_code' : 'ma_cong_chuc',
      width: '15%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: '13%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: '18%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Đề xuất phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: '16%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Tình trạng',
      key: 'status',
      width: '8%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>
            {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
              ? 'Đã chấm'
              : 'Chưa chấm'}
          </span>
        );
      }
    }
  ];

  return (
    <Modal
      title={'Chỉnh sửa phân quyền chấm điểm ' + `${schoolObj?.name}`}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={900}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Không
        </Button>,
        <Button loading={isSubmit} key="submit" size="large" type="primary" onClick={handleSubmit}>
          Đồng ý
        </Button>
      ]}>
      <React.Fragment>
        <div className="d-flex flex-row gap-15 sm:gap-10 mb-10">
          <div className="d-flex flex-row teacher-info gap-15 sm:gap-10">
            <span>{teacherInfo1?.name ? teacherInfo1?.name : 'Giám khảo 1'}</span>
            <i
              className="fa fa-edit"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenListTeacher(teacherInfo1, 1)}
            />
          </div>{' '}
          <div className="d-flex flex-row teacher-info gap-15 sm:gap-10">
            <span>{teacherInfo2?.name ? teacherInfo2?.name : 'Giám khảo 2'}</span>
            <i
              className="fa fa-edit"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenListTeacher(teacherInfo2, 2)}
            />
          </div>
        </div>
        <ContainerTable>
          <ConfigProviderCustomize>
            <Table
              columns={columnsAchievement}
              dataSource={!_.isEmpty(valueListAchievement) && valueListAchievement}
              size="middle"
              bordered
              pagination={false}
              rowKey={(record: any) => record.keyUid}
            />
          </ConfigProviderCustomize>
        </ContainerTable>
      </React.Fragment>
      {isOpenListTeacher && (
        <ModalListTeacherSo
          isOpen={isOpenListTeacher}
          school={schoolObj}
          isEdit={true}
          listDepartment={listDepartment}
          listTeacherChoose={valueTeacher}
          handleRefresh={handleRefreshChooseTeacher}
          handleCancel={() => setIsOpenListTeacher(!isOpenListTeacher)}
          handleSuccess={handleSuccess}
        />
      )}
    </Modal>
  );
}
export default ModalEditDecentralized;
