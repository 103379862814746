import { createSlice } from 'store/core/@reduxjs/toolkit';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants/initiativeAchievement.constants';

export interface CreateReviewState {
  loading?: boolean;
  error?: any;
  success?: any;
  listReview?: any;
  detailInitiative: any;
  listTeacherReview: any;
  detailEvaluationByTeacher: any;
  listDecisionNo: any;
}

export const initialState: CreateReviewState = {
  loading: false,
  error: {},
  success: {},
  listReview: {},
  detailInitiative: {},
  listTeacherReview: {},
  detailEvaluationByTeacher: {},
  listDecisionNo: {}
};

const CreateReviewSlice = createSlice({
  name: 'createReview',
  initialState,
  reducers: {
    getListInitiative(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListInitiativeSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getListInitiativeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addInitiative(state, action) {
      state.loading = true;
      state.error = null;
    },
    addInitiativeSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.ADD_INITIATIVE_SUCCESS,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_INITIATIVE_SUCCESS,
        data: action.payload
      };
    },
    addInitiativeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteInitiative(state, action) {
      state.loading = true;
      state.error = null;
    },
    deleteInitiativeSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SUCCESS.DELETE_INITIATIVE_SUCCESS,
        message: RESPONSE_MESSAGE.SUCCESS.ADD_INITIATIVE_SUCCESS,
        data: action.payload
      };
    },
    deleteInitiativeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    detailInitiative(state, action) {
      state.loading = true;
      state.error = null;
    },
    detailInitiativeSuccess(state, action) {
      state.loading = false;
      state.detailInitiative = action.payload;
    },
    detailInitiativeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetData(state) {
      state.listReview = {};
    },
    getListEffectScope(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListEffectScopeSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getListEffectScopeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    sendInitiativesToSo(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    sendInitiativesToSoSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    sendInitiativesToSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEvaluationInitiativesSo(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEvaluationInitiativesSoSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getEvaluationInitiativesSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    approveEvaluationInitiativeSo(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    approveEvaluationInitiativeSoSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    approveEvaluationInitiativeSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    evaluationInitiativeDecisionNo(state, action) {
      state.loading = true;
      state.error = null;
    },
    evaluationInitiativeDecisionNoSuccess(state, action) {
      state.loading = false;
      state.listDecisionNo = action.payload;
    },
    evaluationInitiativeDecisionNoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListInitiativeSo(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListInitiativeSoSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getListInitiativeSoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEvaluationInitiativesPgd(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getEvaluationInitiativesPgdSuccess(state, action) {
      state.loading = false;
      state.listReview = action.payload;
    },
    getEvaluationInitiativesPgdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = CreateReviewSlice;
