import React, { useCallback, useEffect, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Tooltip, Radio } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import * as FromCreateInitiativeSlice from 'store/initiativeAchievement/shared/slice';
import styled from 'styled-components';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import Pagination from 'components/Pagination';
import { YEAR_ID_DEFAULT } from '_constants/default.constants';
import { useToast } from 'components/Toast';
import ModalNoti from 'components/Modal/ModalNoti';

const ContainerTable = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface IModalListAchievement {
  isOpen: boolean;
  listItemsChoose: any[];
  listTeacherChoose: any[];
  handleCancel: () => void;
  handleRefresh: () => void;
  handleSuccess: (teacher: any[]) => void;
}

interface DataType {
  id?: any;
  teacher_fullname: string;
  id_num: string;
  birthday: Date;
  phone_number: string;
  position_title: string;
  ma_cong_chuc: string;
  subject_name: string;
  action: string;
}

function ModalListAchievement({
  isOpen,
  listItemsChoose,
  listTeacherChoose,
  handleCancel,
  handleRefresh,
  handleSuccess
}: IModalListAchievement) {
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });
  // let dataSelected: any = [];
  const dispatch = useDispatch();
  const toast = useToast();
  const listReview: any = useSelector(selectListReview);
  const loading: any = useSelector(selectLoading);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const [filterData, setFilterData] = useState({
    keyword: '',
    subjectId: 0,
    courseId: YEAR_ID_DEFAULT,
    perPage: 10,
    pageId: 1,
    statusPermission: 2
  });
  const [valueSearch, setValueSearch] = useState('');
  const [valueSelect, setValueValueSelect] = useState<any[]>([]);
  const [valueSelectId, setValueValueSelectId] = useState<any[]>([]);
  const [teacher, setTeacher] = useState<any[]>([]);
  const [isOpenNoti, setIsOpenNoti] = useState<boolean>(false);
  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    dispatch(FromCreateInitiativeSlice.actions.getListInitiative(filterData));
  });

  useEffect(() => {
    if (listItemsChoose) {
      setValueValueSelectId(listItemsChoose.map((item) => item.id));
    }
  }, [listItemsChoose]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      if (!_.isEmpty(selectedRows)) {
        selectedRows.map((item: any) => {
          isValueItem(item);
        });
      }
      if (isEmptyUser(selectedRows).length > 0) {
        // toast?.pushWarning('Không phân quyền chấm giáo viên chấm sáng kiến của mình!');
        setIsOpenNoti(true);
        return;
      }
      const dataSelected = selectedRows.filter(
        (item: any) =>
          item.description !== '' &&
          item.effective !== '' &&
          !_.isEmpty(item.files) &&
          !_.isEmpty(item.files_description)
      );
      // console.log(dataSelected);
      setValueValueSelect(dataSelected);
      setValueValueSelectId(dataSelected.map((item) => item.id));
    }
  };

  const isValueItem = (data) => {
    if (data.description === '') {
      toast?.pushWarning('Sáng kiến chưa cập nhật mô tả!');
      return true;
    }
    if (data.effective === '') {
      toast?.pushWarning('Sáng kiến chưa cập nhật hiệu quả!');
      return true;
    }
    if (_.isEmpty(data.files)) {
      toast?.pushWarning('Sáng kiến chưa cập nhật File sáng kiến!');
      return true;
    }
    if (_.isEmpty(data.files_description)) {
      toast?.pushWarning('Sáng kiến chưa cập nhật File mô tả sáng kiến!');
      return true;
    }
    return false;
  };

  const isEmptyUser = (data) => {
    const user = listTeacherChoose.filter((item) =>
      data.some((itm) => itm.user_id === item.user_id)
    );
    if (user.length > 0) {
      setTeacher(user);
      return user;
    }
    return [];
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Mã công chức',
      dataIndex: 'ma_cong_chuc',
      key: 'ma_cong_chuc',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: 300,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    }
    // {
    //   title: 'Phạm vi ảnh hưởng',
    //   key: 'effect_scope_id',
    //   width: 100,
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     if (record.course_id === YEAR_ID_DEFAULT) {
    //       return <span style={{ color: '#1890ff' }}>--.--</span>;
    //     }
    //     return (
    //       <span style={{ color: '#1890ff' }}>
    //         {record.effect_scope_id === 1
    //           ? 'Cơ sở'
    //           : record.effect_scope_id === 2
    //           ? 'Thành phố'
    //           : '--.--'}
    //       </span>
    //     );
    //   }
    // },
    // {
    //   title: 'Trạng thái',
    //   key: 'status_so',
    //   width: 100,
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     return (
    //       <span className="flex items-center justify-center rounded-2" style={{ color: '#1890ff' }}>
    //         Đã gửi
    //         {/* {record.status_so === 1 ? <span style={{ color: '#1890ff' }}>Đã gửi</span> : 'Chưa gửi'} */}
    //       </span>
    //     );
    //   }
    // }
  ];

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filterData.perPage) return true;
      return false;
    }
  }, [listReview]);

  const changeValueSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      const newFilter = Object.assign({}, filterData, {
        key_search: ''
      });
      // setNewFilterData(newFilter);
    }
    setValueSearch(value.toLowerCase());
  };

  const handleSearch = (keyword): any => {
    const newFilter = Object.assign({}, filterData, {
      keyword: valueSearch,
      perPage: 10,
      pageId: 1
    });
    setNewFilters(newFilter);
  };

  const handleSubmit = () => {
    if (valueSelect.length > 0) {
      handleSuccess(valueSelect);
      handleCancel();
    }
  };

  return (
    <Modal
      title={'Danh sách sáng kiến'}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={700}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Cập nhật
        </Button>
      ]}>
      <ContainerTable>
        <ConfigProviderCustomize>
          <Table
            rowSelection={{
              type: selectionType,
              selectedRowKeys: valueSelectId,
              ...rowSelection
            }}
            showHeader
            columns={columns}
            dataSource={!_.isEmpty(listReview) && listReview}
            // title={(currentPageData) => {
            //   return (
            //     <div className="grid lg:grid-6 gap-6 grid-container">
            //       <div className="md:span-2 grid-container__item2">
            //         <div className="md:span-2 grid-container__item2-icon">
            //           {/* <InputSearch handleSearch={handleSearch} /> */}
            //           <Input.Search
            //             allowClear
            //             placeholder="Mã định danh cá nhân hoặc Họ tên"
            //             onChange={(e) => changeValueSearch(e)}
            //             value={valueSearch}
            //             onPressEnter={(e) => handleSearch(e)}
            //             onSearch={handleSearch}
            //             enterButton
            //           />
            //         </div>
            //       </div>
            //     </div>
            //   );
            // }}
            size="middle"
            bordered
            pagination={false}
            loading={loading}
            rowKey={(record: any) => record.id}
          />
        </ConfigProviderCustomize>
      </ContainerTable>
      <div className="mt-30">
        <Pagination
          current={filterData.pageId}
          onClickNext={() => handleNext()}
          onClickRev={() => handleRev()}
          rowsPerPage={filterData.perPage}
          onClickPerpage={selectPerpage}
          isDisableNext={isCheckDisable()}
        />
      </div>
      {isOpenNoti && (
        <ModalNoti
          isOpen={isOpenNoti}
          titleName={`Vui lòng không phân quyền cho giáo viên <strong>${teacher[0].name}</strong> tự chấm sáng kiến của mình`}
          handleCancel={() => setIsOpenNoti(!isOpenNoti)}
        />
      )}
    </Modal>
  );
}

export default ModalListAchievement;
