import { Button, Card, Modal, Select, Tag, Space, Tooltip, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListEffectScopes
} from 'store/common/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import ModalViewFilesDetails from '../../../components/ViewFiles/index';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalDetail({
  isOpen,
  title,
  handleCancel,
  id,
  handleRefresh,
  detail,
  handleSuccess
}: IModalEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [filePathInitiative, setFilePathsInitiative] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState<Number>(0);
  const [score, setScore] = useState<Number>(0);
  const [type, setType] = useState<Number>(0);
  const [description, setDescription] = useState<string>('');
  const [effective, setEffective] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();

  //pop up view file
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();

  useEffect(() => {
    getDetailReview();
  }, []);

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        // setType(data.evaluation_intiative_detail.phan_loai);
        setTitleReview(data.evaluation_intiative_detail.name);
        setYearSchool(data.evaluation_intiative_detail.course_id);
        setDescription(data.evaluation_intiative_detail.description);
        setEffective(data.evaluation_intiative_detail.effective);
        setValueAffectCS(data.evaluation_intiative_detail.is_coso);
        setValueAffectTP(data.evaluation_intiative_detail.is_tp);
        // setEffectScopeId(data.evaluation_intiative_detail.effect_scope_id);
        if (
          data.evaluation_intiative_detail.status_cham_diem === 1 &&
          data.evaluation_intiative_detail.status_cham_diem2 === 1
        ) {
          const mediumScore =
            (data.evaluation_intiative_detail.score + data.evaluation_intiative_detail.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            setEffectScopeId(1);
          } else if (mediumScore >= 91) {
            setEffectScopeId(2);
          } else {
            setEffectScopeId(0);
          }
        } else {
          setEffectScopeId(0);
        }
        setScore(data.evaluation_intiative_detail.score);

        var arr = _.values(data.evaluation_intiative_detail.files); // convert obj to arr
        var arrFileIntiative = _.values(data.evaluation_intiative_detail.files_description);
        setFilePaths(arr);
        setFilePathsInitiative(arrFileIntiative);
      } else {
      }
    });
  };

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };
  // upload
  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  //////

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>

            <input
              disabled
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              value={titleReview}
            />
          </div>
          {type === 0 && (
            <React.Fragment>
              <div className="mb-15">
                <label htmlFor="" className="font-bold mb-10 block">
                  Tóm tắt mô tả sáng kiến
                </label>

                <textarea
                  disabled
                  defaultValue={description}
                  cols={30}
                  rows={3}
                  className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                  placeholder="Nhập thông tin"></textarea>
              </div>
              <div className="mb-15">
                <label htmlFor="" className="font-bold mb-10 block">
                  Hiệu quả
                </label>
                <textarea
                  disabled
                  defaultValue={effective}
                  id="effective"
                  name="effective"
                  cols={30}
                  rows={3}
                  maxLength={100}
                  className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                  placeholder="Nhập thông tin"></textarea>
              </div>
              {/* <div className="grid sm:grid-1 gap-15 sm:gap-20">
                <div className="relative">
                  <label htmlFor="" className="font-bold mb-10 block">
                    {' '}
                    Phạm vi ảnh hưởng{' '}
                  </label>
                  <select
                    disabled
                    className="select-no-arrow h-44 w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none">
                    <option value={0} selected={effectScopeId == 0 ? true : false}>
                      --- chọn ---
                    </option>
                    <option value={1} selected={effectScopeId == 1 ? true : false}>
                      {' '}
                      Cơ sở
                    </option>
                    <option value={2} selected={effectScopeId == 2 ? true : false}>
                      {' '}
                      Thành phố
                    </option>
                  </select>
                  <label htmlFor="id" className="absolute top-3/4 right-15 translate-y--3/4">
                    <i className="fas fa-caret-down" />
                  </label>
                </div>
              </div> */}
              <div className="mb-15">
                <label htmlFor="" className="font-bold mb-10 block">
                  Phạm vi ảnh hưởng
                </label>
                <Checkbox checked={valueAffectCS}>Phạm vi ảnh hưởng cấp Quận</Checkbox>
                <Checkbox checked={valueAffectTP}>Phạm vi ảnh hưởng cấp Thành phố</Checkbox>
              </div>
              <div className="grid sm:grid-2 gap-20 sm:gap-20" style={{ alignItems: 'start' }}>
                <div className="grid sm:grid-1 gap-10 sm:gap-10">
                  <label htmlFor="" className="font-bold mb-10 block">
                    Tệp sáng kiến
                  </label>
                  <ul className="mt-10 flex flex-column gap-10">
                    {!_.isEmpty(filePath) ? (
                      filePath?.map((item: any, index) => (
                        <li className="flex items-center justify-between text-45a" key={index}>
                          <span className="text-wrap">
                            <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                          </span>
                          <div
                            style={{ cursor: 'pointer' }}
                            className="flex items-center justify-between gap-10">
                            <Space>
                              <Tooltip title="Xem chi tiết" placement="bottomLeft">
                                <span
                                  //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                  onClick={() => openFile(item)}
                                  className="detail">
                                  <div className="flex items-center justify-center">
                                    <i className="fas fa-eye" />
                                  </div>
                                </span>
                              </Tooltip>
                            </Space>
                            <Space>
                              <Tooltip title="Tải file" placement="bottomLeft">
                                <span
                                  //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                  onClick={() => handleDownLoad(item)}
                                  className="detail">
                                  <div className="flex items-center justify-center">
                                    <i className="fas fa-download mr-5"></i>
                                  </div>
                                </span>
                              </Tooltip>
                            </Space>
                          </div>
                        </li>
                      ))
                    ) : (
                      <span>Không có file đính kèm</span>
                    )}
                  </ul>
                </div>
                <div className="grid sm:grid-1 gap-10 sm:gap-10">
                  <label htmlFor="" className="font-bold mb-10 block">
                    Tệp mô tả sáng kiến
                  </label>
                  <ul className="mt-10 flex flex-column gap-10">
                    {!_.isEmpty(filePathInitiative) ? (
                      filePathInitiative?.map((item: any, index) => (
                        <li className="flex items-center justify-between text-45a" key={index}>
                          <span className="text-wrap">
                            <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                          </span>
                          <div
                            style={{ cursor: 'pointer' }}
                            className="flex items-center justify-between gap-10">
                            <Space>
                              <Tooltip title="Xem chi tiết" placement="bottomLeft">
                                <span
                                  //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                  onClick={() => openFile(item)}
                                  className="detail">
                                  <div className="flex items-center justify-center">
                                    <i className="fas fa-eye" />
                                  </div>
                                </span>
                              </Tooltip>
                            </Space>
                            <Space>
                              <Tooltip title="Tải file" placement="bottomLeft">
                                <span
                                  //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                                  onClick={() => handleDownLoad(item)}
                                  className="detail">
                                  <div className="flex items-center justify-center">
                                    <i className="fas fa-download mr-5"></i>
                                  </div>
                                </span>
                              </Tooltip>
                            </Space>
                          </div>
                        </li>
                      ))
                    ) : (
                      <span>Không có file đính kèm</span>
                    )}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )}
        </Card>
      </Container>
      {isOpenModalViewFileDetails && (
        <ModalViewFilesDetails
          isOpen={isOpenModalViewFileDetails}
          detail={detailFile}
          handleCancel={() => setIsOpenModalViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}

export default ModalDetail;
