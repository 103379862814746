import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { CommonApi } from '../constants/common.constants';

export class CommonHttp {
  request: any;
  requestv2: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT, endPointv2 = APP_CONSTANT.API.ENDPOINTV2) {
    this.request = new HttpRequest(endPoint).request;
    this.requestv2 = new HttpRequest(endPointv2).request;
  }

  public getPeriods = ({ pageId, perPage }): Promise<any> => {
    return this.request.get(`${CommonApi.GET_PERIODS}?page_id=${pageId}&per_page=${perPage}`);
  };

  public getCourses = ({ pageId, perPage }): Promise<any> => {
    return this.request.get(`${CommonApi.GET_COURSES}?page_id=${pageId}&per_page=${100}`);
  };

  public getCourseId = (id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      resolve({
        ok: true,
        data: { id }
      });
    });
  };
  public getPeriodId = (id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      resolve({
        ok: true,
        data: { id }
      });
    });
  };

  public uploadFile = (filePath: any): Promise<any> => {
    return this.request.post(CommonApi.UPLOAD_URL, {
      file_paths: filePath
    });
  };

  public setStatusId = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ ok: true, data: { id } });
      } catch (error) {
        reject(error);
      }
    });
  };

  public getListPeriodId = (periodId: any[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ ok: true, data: periodId });
      } catch (error) {
        reject(error);
      }
    });
  };
  public getEffectScopesourses = ({ pageId, perPage }): Promise<any> => {
    return this.request.get(`${CommonApi.GET_EFFECT_SCOPES}?page_id=${1}&per_page=${100}`);
  };

  public getEffectScopesId = (id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      resolve({
        ok: true,
        data: { id }
      });
    });
  };

  public getCoursesBase = ({ pageId, perPage }): Promise<any> => {
    return this.request.get(`${CommonApi.GET_COURSES}?page_id=${1}&per_page=${1000}`);
  };

  public getProvinces = (): Promise<any> => {
    return this.requestv2.post(`${CommonApi.GET_PROVINCES}`);
  };

  public getDistricts = ({ province_id }): Promise<any> => {
    return this.requestv2.post(`${CommonApi.GET_DISTRICTS}`, { province_id });
  };

  public getListSchool = ({
    pageId,
    perPage,
    provinceId = 1,
    districtId = 0,
    name = '',
    type = 0,
    gradeId = 1,
    isExtraInitiative = false,
    courseId = 12
  }): Promise<any> => {
    return this.request.get(
      // default quan 1
      `${CommonApi.LIST_SCHOOL}?page_id=${pageId}&per_page=${perPage}&province_id=${provinceId}&district_id=${districtId}&name=${name}&type=${type}&grade_id=${gradeId}&is_extra_initiative=${isExtraInitiative}&course_id=${courseId}`
    );
  };

  public getSchoolDetail = ({ schoolId = 0 }): Promise<any> => {
    return this.request.get(`${CommonApi.LIST_SCHOOL}/${schoolId}`);
  };

  public setGradeId = (id: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ ok: true, data: { id } });
      } catch (error) {
        reject(error);
      }
    });
  };

  public getListSchoolPgd = ({
    pageId,
    perPage,
    provinceId = 1,
    districtId = 0,
    name = '',
    type = 0,
    gradeId = 1,
    isExtraInitiative = false,
    courseId = 12
  }): Promise<any> => {
    return this.request.get(
      // quan 3
      `${CommonApi.LIST_SCHOOL_PGD}?page_id=${pageId}&per_page=${perPage}&province_id=${provinceId}&district_id=${districtId}&name=${name}&type=${type}&grade_id=${gradeId}&is_extra_initiative=${isExtraInitiative}&course_id=${courseId}`
    );
  };

  public getWards = ({ province_id, district_id, page_id, per_page }): Promise<any> => {
    return this.requestv2.post(`${CommonApi.GET_WARDS}`, { province_id, district_id, page_id, per_page });
  };
}
