import { Button, Card, Modal, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCourseId, selectListCourses, selectListPeriod, selectListEffectScopes } from 'store/common/shared/selectors';
import _ from 'lodash';
import moment from 'moment';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import useAuth from 'hooks/useAuth';
import { YEAR_ID_DEFAULT } from '_constants/default.constants';
import {
  selectDataListEvaluationDecisionsNo,
} from 'store/evaluation/shared/selectors';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;
interface IModalAllow {
  isOpen: boolean;
  title?: string;
  handleCancel: () => void;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalAllow({ isOpen, title, handleCancel, id, handleRefresh, detail, handleSuccess }: IModalAllow) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });

  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState(0);
  const [score, setScore] = useState<Number>(0);
  const [description, setDescription] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();
  const { filters, setFilters } = useAuth();
  const listEvaluationDecisionsNo: any = useSelector(selectDataListEvaluationDecisionsNo);

  const onSelectPeriod = (value: string[]): void => {
    setPeriod(value);
  };

  useEffect(() => {
    // filters.courseId = YEAR_ID_DEFAULT;
    // filters.emulationTypeId = 0;
    // filters.titleTypeId = 0;
    // filters.userType = 1;
    // dispatch(evaluationList.actions.getListEvaluationDecisionsNo(filters));
  }, []);

  const handleSubmit = () => {
    if (titleReview === '') {
      toast?.pushWarning("Vui lòng nhập số quyết định.");
      return;
    }
    const request = {
      decision_no: titleReview,
      decision_date: moment().format('yyyy-MM-DD'),
    };
    createReviewHttp.approveEvaluationInitiativeSo(request, id).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess("Công nhận sáng kiến thành công.");
        handleCancel();
        handleRefresh();
      } else {
        toast?.pushError("Công nhận sáng kiến không thành công.");
      }
    });
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const onChangeValue = (e) => {
    if(e.target.name == 'title'){
      setTitleReview(e.target.value);
    }
    //setTitleReview(e.target.value);
  };
  //////

  return (
    <Modal
      title=""
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={400}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Lưu
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15" style={{textAlign:'center',color:'limegreen', fontSize:'16px'}}>
            <label htmlFor="" className="font-bold block">
              Thầy/Cô có đồng ý công nhận sáng kiến cho giáo viên {title}?
            </label> 
            <label htmlFor="" className="font-bold mb-10 block">
            Nếu đồng ý mời thầy/cô vui lòng nhập số quyết định và bấm lưu
            </label>
          </div>
          <div className="mb-15" style={{textAlign:'center'}}>
            <label htmlFor="" className="font-bold mb-10 block">
              Nhập số quyêt định
            </label>
            <input
              name="title"
              id="title"
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
            />

            {/* <Select
              id="title"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Chọn số quyết định"
              style={{ width: 'calc(50% - 5px)', marginBottom: 10, marginRight: 5 }}
              onChange={onChangeValue}
              options={
                !_.isEmpty(listEvaluationDecisionsNo) &&
                listEvaluationDecisionsNo.evaluation_decisions_no.map((item) => {
                  return {
                    value: item.id,
                    label: item.decision_no
                  };
                })
              }
            /> */}
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalAllow;
