import { Button, Card, Modal, Select, Tag, Form, DatePicker } from 'antd';
import { useToast } from 'components/Toast';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  selectCourseId,
  selectListCourses,
  selectListCoursesBase,
  selectListPeriod
} from 'store/common/shared/selectors';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import moment from 'moment';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import { selectDataUser } from 'store/user/shared/selectors';
import * as common from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
// import {
//   selectDataListEvaluationDecisionsNo,
//   selectDataListEvaluationTitleTypes
// } from 'store/evaluation/shared/selectors';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { YEAR_ID_DEFAULT, YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  width: 140px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  .ant-select-selector {
    height: 35px !important;
  }
` as any;

interface IModal {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  courseId?: number;

  file?: any;
  comment?: string;
  idUser?: number;
  detail?: any;
  proofValue?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
function ModalCreateReview({
  isOpen,
  title,
  handleCancel,
  handleRefresh,
  detail,
  handleSuccess
}: IModal) {
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listPeriod: any = useSelector(selectListPeriod);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<any[]>([]);
  const courseId: any = useSelector(selectCourseId);
  //const listEvaluationDecisionsNo: any = useSelector(selectDataListEvaluationDecisionsNo);
  const [description, setDescription] = useState<string>('');
  //const [effectScopeId, seteffectScopeId] = useState<Number>(0);
  const [effectScopeId, setEffectScopeId] = useState(0);
  const [effectScopeIdFilter, setEffectScopeIdFilter] = useState(0);
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);
  const [score, setScore] = useState<Number>(0);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isSelectBox, setIsSelectBox] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const dataUser: any = useSelector(selectDataUser);
  const navigate = useNavigate();
  const localStorage = new LocalStorageService();
  const initiativeAchievementHttp = new InitiativeAchievementHttp();
  const { filters, setFilters } = useAuth();

  const createReviewHttp = new InitiativeAchievementHttp();
  const [valueDecisionNoOrigin, setValueDecisionNoOrigin] = useState<any>();
  const [valueDecisionNoDDL, setValueDecisionNoDDL] = useState<any>([]);
  const [valueCourses, setValueCourses] = useState<any[]>([]);
  const [valueDecisionId, setValueDecisionId] = useState<any>();
  const [valueDecisionNo, setValueDecisionNo] = useState('');
  const [dayAppellation, setDayAppellation] = useState<any>();
  const inputRef = useRef<any>(null);
  const dateFormat = 'DD/MM/YYYY';

  const onSelectYear = (value: string): void => {
    setYearSchool(value);
    setValueDecisionId(null);
    //loadDataDecisionNo(value); // mo sau
    getEvaluationInitiativeDecisionNo(value);
  };

  useEffect(() => {
    const idYearRemove = [YEAR_ID_DEFAULT];
    const courses: any = listCourseBase.filter((itm) => !idYearRemove.includes(itm.id));
    setValueCourses(courses);
  }, [listCourseBase]);
  // const onSelectEffect = (value: Number): void => {
  //   setEffectScopeId(value);
  // };
  const onSelectEffect = (value) => {
    setEffectScopeId(value);
  };
  const onChangeValue = (e) => {
    if (e.target.name == 'title') {
      setTitleReview(e.target.value);
    }
    if (e.target.name == 'description') {
      setDescription(e.target.value);
    }
    if (e.target.name == 'score') {
      setValueDecisionId(null);
      setScore(e.target.value);
      loadDecisionNo(e.target.value);
      if (e.target.value < 91) {
        setValueAffectTP(false);
        setValueAffectCS(true);
      } else {
        setValueAffectTP(true);
        setValueAffectCS(false);
      }
    }
    if (e.target.name == 'effectScope') {
      setValueDecisionId(null);
      setEffectScopeId(e.target.value);
      setEffectScopeIdFilter(e.target.value);
      loadDecisionNo(score, e.target.value);
      if (e.target.value == 0) {
        setValueAffectTP(false);
        setValueAffectCS(true);
      } else {
        setValueAffectCS(false);
        setValueAffectTP(true);
      }
    }
  };

  const loadDecisionNo = (score, effectScopeId?) => {
    setDayAppellation('');
    let oldCode = effectScopeId == 0 ? 'NGÀNH' : 'TP';
    if (score >= 81 && score < 91) {
      setEffectScopeId(0); // co so
      setIsSelectBox(false);
      const listDecision = valueDecisionNoOrigin?.filter((item) => item.old_code == 'NGÀNH');
      setValueDecisionNoDDL(listDecision);
    } else if (score >= 91) {
      setEffectScopeId(1); // tp
      setIsSelectBox(true);
      const listDecision = valueDecisionNoOrigin?.filter((item) => item.old_code == oldCode);
      setValueDecisionNoDDL(listDecision);
    }
  };

  const handleUpload = () => {
    if (!_.isEmpty(filePath)) {
      commonHttp.uploadFile(filePath).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files));
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    if (handleSuccess) {
      handleSuccess(filePath, note, detail);
    }
  };

  const handleSubmit = (e) => {
    //if (titleReview == '' || effectScopeId == 0 || description == '' || score == 0) {
    if (Number(score) < 81) {
      toast?.pushWarning('Điểm không được nhỏ hơn 81 điểm');
      return;
    }
    if (titleReview == '' || description == '' || score == 0) {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_INITIATIVE_SUCCESS);
      return;
    }
    if (yearSchool == null) {
      toast?.pushWarning('Khóa học không tồn tại. Vui lòng kiểm tra lại thông tin.');
      return;
    }

    if (valueDecisionNo === '') {
      toast?.pushWarning('Vui lòng chọn lại số quyết định.');
      return;
    }

    //var file = _.keyBy(filePath);
    const obj = Object.assign({}, filePath); // array to object
    //upload
    handleUpload();
    ///

    const request = {
      name: titleReview,
      course_id: parseInt(yearSchool),
      description: description,
      effect_scope_id: parseInt(effectScopeId.toString()),
      files: obj,
      is_coso: valueAffectCS,
      is_tp: valueAffectTP,
      score: parseFloat(score.toString()),
      phan_loai: 0, // 0 bổ sung, 1 đăng ký
      decision_no: valueDecisionNo,
      decision_date: dayAppellation != '' ? AppHelper.formatDate(dayAppellation, 'DD-MM-YYYY') : ''
    };
    initiativeAchievementHttp.addInitiativeUser(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.PLUS_INITIATIVE_SUCCESS);
        handleCancel();
        handleRefresh();
        //navigate(`/manager-review/create-standard/${res.data.data.evaluation.id}/${0}`);
        //localStorage.setItem({ key: LocalStorageKey.isVisible, value: 'show' });
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.PLUS_INITIATIVE_FAIL);
      }
    });
  };

  const handleChange = (value: string[]) => {
    setPeriod(value);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        //let key = UploadFile.getIdFile(file, detail);
        let key = UploadFile.getIdFileNoDetail(file, dataUser);

        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePaths(newFilePath);
  };

  const handleRemoveFile = (file: any) => {
    const newFilePath = filePath.filter((item) => item !== file);
    setFilePaths(newFilePath);
    inputRef.current.value = null;
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  //////

  const onChangeDecision = (e) => {
    setValueDecisionId(e);
    const decision = valueDecisionNoDDL.find((item) => item.id === e);
    setValueDecisionNo(decision.decision_no);
    setDayAppellation(moment(decision.decision_date, 'DD/MM/yyyy'));
  };

  const getEvaluationInitiativeDecisionNo = (course_id) => {
    const request = {
      pageId: 1,
      perPage: -1,
      courseId: parseInt(course_id)
    };
    createReviewHttp.evaluationInitiativeDecisionNo(request).then((res) => {
      if (res.ok && res.status === 200) {
        const data = res.data.data.evaluation_decisions_no;
        setValueDecisionNoOrigin(data);
        if (score) {
          loadDecisionNo(score);
        }
      }
    });
  };

  return (
    <Modal
      title={['Cập nhật sáng kiến']}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={handleSubmit}>
          Lưu
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="grid sm:grid-3 gap-15 sm:gap-20">
            <span></span>
            <div className="relative">
              <StyleSelectCourse
                style={{
                  width: '100%'
                }}
                placeholder="Chọn năm học"
                onChange={onSelectYear}>
                {!_.isEmpty(valueCourses) &&
                  _.orderBy(valueCourses, ['name'], ['desc']).map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.description}
                    </Option>
                  ))}
              </StyleSelectCourse>
            </div>
            <span></span>
          </div>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>
            <input
              name="title"
              id="title"
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tóm tắt mô tả sáng kiến
            </label>
            <textarea
              //defaultValue={dataDetail?.self_evaluation_detail?.evaluation_self?.comment_strong}
              id="description"
              name="description"
              onChange={onChangeValue}
              cols={30}
              rows={3}
              className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
              placeholder="Nhập thông tin"></textarea>
          </div>
          <div className="grid sm:grid-2 gap-15 sm:gap-20 mb-10">
            <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Nhập điểm
              </label>
              <input
                type="number"
                id="score"
                name="score"
                style={{ height: 35 }}
                className="w-full font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                onChange={onChangeValue}
                placeholder="0"
              />
            </div>
            <div className="grid sm:grid-1 gap-15 sm:gap-20 mb-10">
              <div>
                <label htmlFor="" className="font-bold mb-10 block">
                  Phạm vi ảnh hưởng đã được công nhận
                </label>
                <select
                  onChange={onChangeValue}
                  disabled={!isSelectBox}
                  id="effectScope"
                  name="effectScope"
                  style={{ height: 35, padding: 5 }}
                  className="w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none">
                  <option value={0} selected={effectScopeId == 0 ? true : false}>
                    {' '}
                    cấp Quận
                  </option>
                  <option value={1} selected={effectScopeId == 1 ? true : false}>
                    {' '}
                    Thành phố
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-2">
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn số quyết định
              </label>
              <Select
                style={{ width: 'calc(97%)', height: 40, fontWeight: 'bold', color: 'revert' }}
                value={valueDecisionId}
                placeholder="Chọn số quyết định"
                onChange={onChangeDecision}>
                {!_.isEmpty(valueDecisionNoDDL) &&
                  valueDecisionNoDDL.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.decision_no}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 ml-10 block">
                Ngày quyết định
              </label>
              <DatePicker
            allowClear={false}
                disabled
                value={dayAppellation}
                format={dateFormat}
                placeholder="Ngày quyết định"
                style={{
                  width: 'calc(97%)',
                  height: 32,
                  marginLeft: 10,
                  fontWeight: 'bold',
                  color: 'revert'
                }}
              />
            </div>
          </div>
          <div className="grid sm:grid-1 pt-20 gap-15 sm:gap-20">
            <input ref={inputRef} type="file" multiple onChange={handleImage} />
          </div>
          <div className="grid sm:grid-3 gap-15 sm:gap-20">
            <ul className="mt-20 flex flex-column gap-10 sm:span-2">
              {!_.isEmpty(filePath) ? (
                filePath?.map((item: any, index) => (
                  <li
                    className="flex items-center justify-between text-45a"
                    key={index}
                    style={{ cursor: 'pointer' }}>
                    <span onClick={() => handleDownLoad(item)} className="text-wrap">
                      <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                    </span>
                    <button
                      className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                      style={{ fontSize: '14px' }}
                      onClick={() => handleRemoveFile(item)}>
                      <i className="fas fa-trash text-ea5" />
                    </button>
                  </li>
                ))
              ) : (
                <span>Không có file đính kèm</span>
              )}
            </ul>
            <span></span>
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalCreateReview;
