import { call, put, takeLatest, all } from 'redux-saga/effects';
import { CompetitionRatingHttp } from './../services/competitionRatingEdu.http';
import { actions } from './slice';
import FunctionUtils from 'utils/FunctionUtils';

const competitionRatingHttp = new CompetitionRatingHttp();
export function* getListReviewEdu(api, action) {
  const response = yield call(api.getListReviewEdu, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations
      );
      yield put(actions.getListReviewEduSuccess(configResponse));
    } else {
      yield put(actions.getListReviewEduFail(response));
    }
  } catch (error) {
    yield put(actions.getListReviewEduFail(error));
  }
}
export function* getListReviewPgd(api, action) {
  const response = yield call(api.getListReviewPgd, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations
      );
      yield put(actions.getListReviewPgdSuccess(configResponse));
    } else {
      yield put(actions.getListReviewPgdFail(response));
    }
  } catch (error) {
    yield put(actions.getListReviewPgdFail(error));
  }
}
export function* addReviewEdu(api, action) {
  const response = yield call(api.addReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.addReviewEduSuccess(response.data));
    } else {
      yield put(actions.addReviewEduFail(response));
    }
  } catch (error) {
    yield put(actions.addReviewEduFail(error));
  }
}
export function* deleteReviewEdu(api, action) {
  const response = yield call(api.deleteReview, action.payload);
  try {
    if (response.ok) {
      yield put(actions.deleteReviewEduSuccess(response.data));
    } else {
      yield put(actions.deleteReviewEduFail(response));
    }
  } catch (error) {
    yield put(actions.deleteReviewEduFail(error));
  }
}
export function* listSchoolReviewEdu(api, action) {
  const response = yield call(api.listSchoolReviewEdu, action.payload);
  try {
    if (response.ok) {
      yield put(actions.listSchoolReviewEduSuccess(response.data.data));
    } else {
      yield put(actions.listSchoolReviewEduFail(response));
    }
  } catch (error) {
    yield put(actions.listSchoolReviewEduFail(error));
  }
}

export function* getListManageGrades(api, action) {
  const response = yield call(api.getListManageGrades, action.payload);
  try {
    if (response.ok) {
      const getGradeId = response?.data?.data?.grades[0];
      competitionRatingHttp.setGradeId(getGradeId);
      yield put(actions.getListManageGradesSuccess(response.data.data));
    } else {
      yield put(actions.getListManageGradesFail(response));
    }
  } catch (error) {
    yield put(actions.getListManageGradesFail(error));
  }
}

export function* setGradeId(api, action) {
  const response = yield call(api.setGradeId, action.payload);

  try {
    if (response.ok) {
      yield put(actions.setGradeIdSuccess(response.data));
    } else {
      yield put(actions.setGradeIdFail(response));
    }
  } catch (error) {
    yield put(actions.setGradeIdFail(error));
  }
}

export function* CompetitionRatingSaga() {
  yield all([
    yield takeLatest(actions.getListReviewEdu.type, getListReviewEdu, competitionRatingHttp),
    yield takeLatest(actions.getListReviewPgd.type, getListReviewPgd, competitionRatingHttp),
    yield takeLatest(actions.addReviewEdu.type, addReviewEdu, competitionRatingHttp),
    yield takeLatest(actions.deleteReviewEdu.type, deleteReviewEdu, competitionRatingHttp),
    yield takeLatest(actions.listSchoolReviewEdu.type, listSchoolReviewEdu, competitionRatingHttp),
    yield takeLatest(actions.getListManageGrades.type, getListManageGrades, competitionRatingHttp),
    yield takeLatest(actions.setGradeId.type, setGradeId, competitionRatingHttp)
  ]);
}
