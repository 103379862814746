import {
  TITLE_NAVBAR,
  YEAR_ID_DEFAULT,
  YEAR_ID_DEFAULT_PGDQ3,
  groupUnitPgd
} from '_constants/default.constants';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import _ from 'lodash';
import { Table, Space, Tooltip, Button } from 'antd';
import { selectListCourses, selectListCoursesBase } from 'store/common/shared/selectors';
import type { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '../../../../components/Pagination/index';

import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../../store/core/@reduxjs/redux-injectors';

import { useToast } from '../../../../components/Toast/index';
import ModalConfirm from 'components/Modal/ModalConfirm';
import { CompetitionRatingSoHttp } from 'store/competitionRatingSo/services/competitionRatingSo.http';
import { selectListReviewPgd, selectLoading } from 'store/competionRatingEdu/shared/selectors';
import * as competionRatingEdu from 'store/competionRatingEdu/shared/slice';
import { CompetitionRatingSaga } from 'store/competionRatingEdu/shared/saga';
import * as FromCreateReviewSlice from 'store/createReview/shared/slice';
import { CreateReviewSaga } from 'store/createReview/shared/saga';
import { RESPONSE_MESSAGE } from 'store/competionRatingEdu/constants/competitionRatingEdu.constants';
import ModalCreateReviewEdu from '../../components/ModalCreateReviewEdu';
import ModalCloneReviewRoleEdu from 'views/CompetitionRating/components/ModalCloneReviewRoleEdu';
import './style.css';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import * as SAList from '../../../../store/SelfAssessment/shared/slice';
import { SelfAssessmentSaga } from 'store/SelfAssessment/shared/saga';
import { selectDataGetApiSchool } from 'store/SelfAssessment/shared/selectors';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';

import * as FromCommonSlice from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import ModalEditReviewRoleEdu from 'views/CompetitonRatingPgd/components/ModalEditReviewRoleEdu';
import Header from './segments/Header';
import Filter from './segments/Filter';
import ModelExportFiles from './components/ModelExportFIles';
import ButtonCreate from 'components/ButtonCreate';
import ButtonSubmit from 'components/ButtonSubmit';
import { AppConfig } from 'index';
import { isAvailableArray } from 'utils/arrayUtils';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { AppHelper } from 'utils/app.helpers';

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface DataType {
  key: React.Key;
  title: string;
  action: string;
  id?: string;
  period_id?: number;
}

const competitionRatingSoHttp = new CompetitionRatingSoHttp();

const downloadFileStatic = (url) => {
  if (typeof url === 'string') {
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  }
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ManagerRatingPgd() {
  const location = useLocation();
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateReviewSaga });
  useInjectReducer({ key: SAList.sliceKey, reducer: SAList.reducer });
  useInjectSaga({ key: SAList.sliceKey, saga: SelfAssessmentSaga });
  useInjectReducer({ key: FromCommonSlice.sliceKey, reducer: FromCommonSlice.reducer });
  useInjectSaga({ key: FromCommonSlice.sliceKey, saga: CommonSaga });
  useInjectReducer({ key: competionRatingEdu.sliceKey, reducer: competionRatingEdu.reducer });
  useInjectSaga({ key: competionRatingEdu.sliceKey, saga: CompetitionRatingSaga });
  const dispatch = useDispatch();

  const listCompetitionReviewPgd: any = useSelector(selectListReviewPgd);
  const listData: any = useSelector(selectDataGetApiSchool);
  const loading: any = useSelector(selectLoading);
  const listCourse: any = useSelector(selectListCourses);
  const listCoursesBase: any = useSelector(selectListCoursesBase);

  const navigate = useNavigate();
  const query = useQuery();
  const [idReview, setIdReview] = useState<string>('');
  const [isModalConfirmDel, setIsModalConfirmDel] = useState<boolean>(false);
  const [isCreateReview, setIsCreateReview] = useState<boolean>(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false);
  const [isOpenModalConfirmPublish, setIsOpenModalConfirmPublish] = useState<boolean>(false);
  const [isOpenNoti, setIsOpenNoti] = useState<boolean>(false);
  const [defaultValueYear, setDefaultValueYear] = useState<number>(
    query.get('yearId') ? parseInt(query.get('yearId')!) : YEAR_ID_DEFAULT_PGDQ3
  );

  const [isOpenModalConfirmPublishItem, setIsOpenModalConfirmPublishItem] =
    useState<boolean>(false);
  const [valueEvaluationItemPushList, setValueEvaluationItemPushList] = useState<any>();

  const [listUnit, setListUnit] = useState<any>();
  const [valueItem, setValueItem] = useState<any>();
  const [valueUnit, setValueUnit] = useState<any>(-1);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const [filters, setFilters] = useState<any>({
    pageId: 1,
    perPage: 10,
    courseId: defaultValueYear,
    periodId: [],
    periodIdArr: null,
    statusId: '',
    evaluationId: '',
    gradeId: 1
  });

  localStorage.removeItem('filterValueUnit');
  localStorage.removeItem('filterGradeIds');
  localStorage.removeItem('filterDonVi');

  useEffect(() => {
    if (location.pathname !== `/review-management`) {
      dispatch(FromCommonSlice.actions.getCoursesBase(filters));
      dispatch(competionRatingEdu.actions.getListReviewPgd(filters));
      dispatch(competionRatingEdu.actions.getListManageGrades(filters));
    } else {
      dispatch(SAList.actions.getApiSchool(filters));
    }
    var newFilter = {};
    newFilter = Object.assign({}, filters, {
      departmentId: 0,
      evaluationCodeId: parseInt(query.get('filterevaluation')!)
        ? parseInt(query.get('filterevaluation')!)
        : -1,
      pageId: 1,
      perPage: 10
    });
    setFilters(newFilter);
    setValueUnit(
      parseInt(query.get('filterevaluation')!) ? parseInt(query.get('filterevaluation')!) : -1
    );
  }, []);
  useEffect(() => {
    if (location.pathname !== `/review-management`) {
      dispatch(FromCommonSlice.actions.getCoursesBase(filters));
      dispatch(competionRatingEdu.actions.getListReviewPgd(filters));
      dispatch(competionRatingEdu.actions.getListManageGrades(filters));
    } else {
      dispatch(SAList.actions.getApiSchool(filters));
    }
  }, [filters]);

  useEffect(() => {
    const listDonVi = [{ id: -1, title: 'Tất cả đơn vị' }, ...groupUnitPgd];
    setListUnit(listDonVi);
  }, [groupUnitPgd]);

  const setNewFilters = (newFilter) => {
    setFilters(newFilter);
  };

  const onClickDel = (record: any) => {
    setIdReview(record.id);
    setIsModalConfirmDel(!isModalConfirmDel);
  };

  const handleRefresh = () => {
    if (location.pathname !== `/review-management`) {
      dispatch(FromCommonSlice.actions.getCoursesBase(filters));
      dispatch(competionRatingEdu.actions.getListManageGrades(filters));
      dispatch(competionRatingEdu.actions.getListReviewPgd(filters));
    } else {
      dispatch(SAList.actions.getApiSchool(filters));
    }
  };

  const handleDel = () => {
    const request = {
      id: idReview
    };
    competitionRatingSoHttp
      .deleteReviewtPgd(request)
      .then((res) => {
        if (res.ok && res.status === 200) {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_REVIEW_EDU_SUCCESS);
          handleRefresh();
        } else {
          toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_EDU_FAIL);
        }
        setIsModalConfirmDel(!isModalConfirmDel);
      })
      .catch((error) => {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_EDU_FAIL);
        setIsModalConfirmDel(!isModalConfirmDel);
      });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filters.pageId === 1) return;
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filters, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listCompetitionReviewPgd)) {
      if (listCompetitionReviewPgd.length < filters.perPage) return true;
      return false;
    }
    return true;
  }, [listCompetitionReviewPgd]);

  const handleChooseDonVi = (e) => {
    const newFilter = Object.assign({}, filters, {
      pageId: 1,
      evaluationCodeId: e
    });
    setValueUnit(e);
    setNewFilters(newFilter);
    if (e === -1) {
      navigate(`/manager-rating-pgd`);
    }
  };

  const handleEdit = (items) => {
    setValueItem(items);
    setIsOpenModalEdit(true);
  };

  const handlePublished = useCallback(() => {
    setIsOpenModalConfirmPublish(true);
    setIsOpenModalConfirmPublishItem(false);
    setValueEvaluationItemPushList(null);
  }, []);

  const handleSendRecordPublish = () => {
    const data = {
      evaluation_id: valueEvaluationItemPushList ? valueEvaluationItemPushList.id : '',
      published: valueEvaluationItemPushList
        ? valueEvaluationItemPushList.published
          ? false
          : true
        : true
    };
    competitionRatingSoHttp.evaluationsPublishPgd(data).then((res) => {
      if (res.ok && res.status === 200) {
        setIsOpenModalConfirmPublish(false);
        if (!_.isEmpty(valueEvaluationItemPushList) && valueEvaluationItemPushList.published) {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.CANCEL_PUBLISH_EVALUATION);
        } else {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.PUBLISH_EVALUATION);
        }
        handleRefresh();
      }
    });
  };
  const handlePublishIem = (item) => {
    setValueEvaluationItemPushList(item);
    setIsOpenModalConfirmPublishItem(true);
    setIsOpenModalConfirmPublish(true);
  };

  const handleRouter = (record) => {
    if (valueUnit !== -1) {
      navigate(
        `${location.pathname}/${record.id}/${record.period_id}?evaluation_id=${record.evaluation_code_id}&filterevaluation=${valueUnit}&yearId=${defaultValueYear}`
      );
    } else if (valueUnit !== -1 && defaultValueYear !== YEAR_ID_DEFAULT_PGDQ3) {
      navigate(
        `${location.pathname}/${record.id}/${record.period_id}?evaluation_id=${record.evaluation_code_id}&filterevaluation=${valueUnit}&yearId=${defaultValueYear}`,
        record
      );
    } else {
      navigate(
        `${location.pathname}/${record.id}/${record.period_id}?evaluation_id=${record.evaluation_code_id}&filterevaluation=${valueUnit}&yearId=${defaultValueYear}`,
        record
      );
    }
  };

  // const handleDownLoad = (filePath: string) => {
  //   AppHelper.createDownloadFile(filePath);
  // };

  const handleDownLoad = (filePath) => {
    competitionRatingSoHttp.downloadFilePgd(filePath).then((res) => {
      if (res.status === 200) {
        const url = res.config.baseURL + res.config.url;
        let a = document.createElement('a');
        a.target = '_self';
        a.href = url;
        a.click();
      }
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: '4%',
      align: 'center',
      key: 'id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#FCF3CF' }
        };
      },
      render: (text, record, index) => {
        return <span key={record.id}>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Tên đánh giá',
      dataIndex: 'title',
      key: 'title',
      width: '27%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => navigate(`/manager-rating-pgd/detail-review-role-pgd/${record?.id}`)
        };
      },
      render: (text, record) => {
        return (
          <span style={{ color: '#1890ff', cursor: 'pointer' }} key={record.id}>
            {text}
          </span>
        );
      }
    },
    {
      title: 'Năm học',
      width: 100,
      key: 'course_id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listCoursesBase)) {
          return (
            <span style={{ color: '#2f54eb' }} key={record.id}>
              {listCoursesBase.map((ls) => (ls.id === record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCourse, record.course_id)} */}
            </span>
          );
        }
        return (
          <span style={{ color: '#2f54eb' }} key={record.id}>
            --.--
          </span>
        );
      }
    },
    {
      title: 'Đơn vị',
      width: 100,
      key: 'evaluation_code_id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#d3adf7' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record.evaluation_code_id)) {
          return (
            <span style={{ color: '#2f54eb' }} key={record.id}>
              --.--
            </span>
          );
        }
        const nameDonVi = groupUnitPgd.find((item) => item.id === record.evaluation_code_id);
        return (
          <span style={{ color: '#722ed1' }} key={record.id}>
            {nameDonVi?.title}
          </span>
        );
      }
    },
    {
      title: 'Xem điểm',
      width: 100,
      key: 'evaluation_code_id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#d3adf7' }
        };
      },
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem điểm thi đua" placement="bottomLeft">
              <span
                className="mx-auto flex items-center justify-center"
                onClick={() => handleRouter(record)}
                style={{ color: '#1890ff', cursor: 'pointer' }}>
                Xem điểm thi đua
              </span>
            </Tooltip>
          </Space>
        );
      }
    },
    {
      title: 'Chức năng',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#FCF3CF' }
        };
      },
      key: 'keyUid',
      align: 'center',
      width: 100,
      render: (record) => {
        if (location.pathname !== `/review-management`) {
          return (
            <Space key={record.id}>
              {record.course_id === YEAR_ID_DEFAULT && (
                <>
                  <Tooltip title="Công bố đánh giá" placement="bottomLeft">
                    <button
                      className="detail cursor"
                      onClick={() => handlePublishIem(record)}
                      style={{ fontSize: '16px', color: record.published ? '#ea5455' : '#1890ff' }}>
                      <span className="h-14 mx-auto flex items-center justify-center font-normal">
                        <i className="fas fa-bullhorn mr-5"></i>
                      </span>
                    </button>
                  </Tooltip>
                  <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                    <button
                      className="detail cursor"
                      onClick={() => handleEdit(record)}
                      style={{ fontSize: '16px' }}>
                      <span className="h-14 mx-auto flex items-center justify-center font-normal">
                        <i className="fas fa-edit mr-5"></i>
                      </span>
                    </button>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Xem và sửa chi tiết" placement="bottomLeft">
                <Link
                  to={`/manager-rating-pgd/detail-review-role-pgd/${record.id}`}
                  className="detail">
                  <div className="flex items-center justify-center rounded-2">
                    <i className="fas fa-eye" />
                  </div>
                </Link>
              </Tooltip>
              {record.course_id === YEAR_ID_DEFAULT && (
                <>
                  <Tooltip title="Tạo tiêu chuẩn" placement="bottomLeft">
                    <Link
                      className="edit"
                      to={`/manager-rating-pgd/create-standard-role-pgd/${record.id}/${1}`}>
                      <div className="flex items-center justify-center rounded-2">
                        <i className="fas fa-thumbs-up text-add" />
                      </div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Tạo xếp loại" placement="bottomLeft">
                    <Link
                      className="edit"
                      to={`/manager-rating-pgd/create-rating-role-pgd/${record.id}`}>
                      <div className="flex items-center justify-center rounded-2">
                        <i className="fas fa-sort text-add" />
                      </div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Xóa đánh giá" placement="bottomLeft">
                    <span className="remove" onClick={() => onClickDel(record)}>
                      <div className="flex items-center justify-center rounded-2">
                        <i className="fas fa-trash text-ea5" />
                      </div>
                    </span>
                  </Tooltip>
                  <Tooltip title="Tải file" placement="bottomLeft">
                    {/* <a
                      className="edit"
                      target='_blank'
                      href={`/pgd/self_evaluations/summary/export?course_id=${record.course_id}&evaluation_code_id=${record.evaluation_code_id}&evaluation_id=${record.id}`} download>
                      <div className="flex items-center justify-center rounded-2">
                        <i className="fas fa-download mr-5" />
                      </div>
                    </a> */}
                    <Tooltip title="Tải file" placement="bottomLeft">
                      <span
                        //to = {`/pgd/self_evaluations/summary/export?course_id=${record.course_id}&evaluation_code_id=${record.evaluation_code_id}&evaluation_id=${record.id}`}
                        // onClick={() => downloadFileStatic(`/pgd/self_evaluations/summary/export?course_id=${record.course_id}&evaluation_code_id=${record.evaluation_code_id}&evaluation_id=${record.id}`)}
                        onClick={() =>
                          handleDownLoad(
                            `course_id=${record.course_id}&evaluation_code_id=${record.evaluation_code_id}&evaluation_id=${record.id}`
                          )
                        }
                        className="detail">
                        <div className="flex items-center justify-center">
                          <i className="fas fa-download mr-5"></i>
                        </div>
                      </span>
                    </Tooltip>
                  </Tooltip>
                </>
              )}
            </Space>
          );
        }
      }
    }
  ];

  const handleChangeCourses = (e) => {
    const newFilter = Object.assign({}, filters, {
      pageId: 1,
      courseId: e
    });
    setDefaultValueYear(e);
    setNewFilters(newFilter);
  };

  const handleOk = () => {
    setIsLoading(true);
    const data = {
      is_re_evaluation: false
    };

    competitionRatingSoHttp.soSendResult(data).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Đã công bố điểm về các trường!');
        setIsLoading(false);
        setIsOpenNoti(false);
      } else {
        setIsLoading(false);
        setIsOpenNoti(false);
      }
    });
  };

  const [openExportFile, setOpenExportFile] = useState(false);
  const pageConfig = useMemo(() => {
    const key = location.pathname;
    return AppConfig.pages?.[key] ?? {};
  }, [location.pathname]);

  const buttonMap = useMemo(
    () => ({
      publish: (
        <Button
          type="primary"
          disabled={defaultValueYear !== YEAR_ID_DEFAULT_PGDQ3}
          icon={
            <span className="mr-4">
              <i className="fa fa-bullhorn"></i>
            </span>
          }
          onClick={handlePublished}>
          Công bố đánh giá
        </Button>
      ),
      publishScore: (
        <Button
          type="primary"
          onClick={() => setIsOpenNoti(true)}
          disabled={defaultValueYear !== YEAR_ID_DEFAULT_PGDQ3}>
          Công bố điểm
        </Button>
      ),
      createReview: (
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateReview(true)}>
          Thêm đánh giá
        </Button>
      ),
      exportFile: (
        <Button type="primary" icon={<DownloadOutlined />} onClick={() => setOpenExportFile(true)}>
          Xuất bảng điểm tổng hợp
        </Button>
      )
    }),
    [defaultValueYear, handlePublished]
  );

  const [headerButtons, filterButtons] = useMemo(() => {
    const headerButtons: JSX.Element[] = [];
    const filterButtons: JSX.Element[] = [];

    const headerButtonKeys = pageConfig.header?.buttons;
    if (isAvailableArray(headerButtonKeys)) {
      headerButtons.push(...headerButtonKeys.map((key) => buttonMap[key]));
    }

    const filterButtonKeys = pageConfig.filter?.buttons;
    if (isAvailableArray(filterButtonKeys)) {
      filterButtons.push(...filterButtonKeys.map((key) => buttonMap[key]));
    }

    return [headerButtons, filterButtons];
  }, [buttonMap, pageConfig]);

  return (
    <div className="pb-0">
      <div className="px-15">
        <Header title={pageConfig.name} buttons={headerButtons} />

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                dataSource={
                  location.pathname !== `/review-management`
                    ? !_.isEmpty(listCompetitionReviewPgd) && listCompetitionReviewPgd
                    : !_.isEmpty(listData) && listData
                }
                size="middle"
                bordered
                title={() => (
                  <Filter
                    defaultValueYear={defaultValueYear}
                    listCoursesBase={listCoursesBase}
                    handleChangeCourses={handleChangeCourses}
                    valueUnit={valueUnit}
                    listUnit={listUnit}
                    handleChooseDonVi={handleChooseDonVi}
                    buttons={filterButtons}
                  />
                )}
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        <div className="mt-30">
          <Pagination
            current={filters.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filters.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isModalConfirmDel && (
        <ModalConfirm
          isOpen={isModalConfirmDel}
          handleOk={handleDel}
          handleCancel={() => setIsModalConfirmDel(!isModalConfirmDel)}
        />
      )}
      {isCreateReview && (
        <ModalCreateReviewEdu
          isOpen={isCreateReview}
          handleCancel={() => setIsCreateReview(!isCreateReview)}
          handleRefresh={handleRefresh}
        />
      )}

      {isOpenModalEdit && (
        <ModalEditReviewRoleEdu
          isOpen={isOpenModalEdit}
          handleCancel={() => setIsOpenModalEdit(!isOpenModalEdit)}
          data={valueItem}
          title={valueItem?.title}
          id={valueItem.id}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenModalConfirmPublish && (
        <ModalConfirmBasic
          isOpen={isOpenModalConfirmPublish}
          titleName={
            isOpenModalConfirmPublishItem
              ? valueEvaluationItemPushList.published
                ? 'Đánh giá này đã được công bố, bạn có muốn hủy công bố?'
                : 'Bạn có chắc chắn muốn công bố đánh giá này?'
              : 'Bạn có chắc chắn muốn công bố các đánh giá đã tạo?'
          }
          handleCancel={() => setIsOpenModalConfirmPublish(!isOpenModalConfirmPublish)}
          handleOk={() => {
            handleSendRecordPublish();
          }}
        />
      )}
      {isOpenNoti && (
        <ModalConfirmBasic
          isOpen={isOpenNoti}
          handleOk={handleOk}
          handleCancel={() => setIsOpenNoti(!isOpenNoti)}
          titleName={'Bạn có chắc chắn muốn công bố điểm?'}
        />
      )}

      <ModelExportFiles
        isOpen={openExportFile}
        handleClose={() => setOpenExportFile(false)}
        handleExportMamNonScorecard={() => downloadFileStatic(pageConfig.scorecard?.mam_non)}
        handleExportTieuHocScorecard={() => downloadFileStatic(pageConfig.scorecard?.tieu_hoc)}
        handleExportTHCSScorecard={() => downloadFileStatic(pageConfig.scorecard?.thcs)}
      />
    </div>
  );
}
