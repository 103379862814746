import { ApartmentOutlined, ContactsOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useToast } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { RESPONSE_MESSAGE } from 'store/ListAgenciesSo/constants/ListAgenciesSo.constants';
import { ListAgenciesSoHttp } from 'store/ListAgenciesSo/services/ListAgenciesSo.http';
import { TeacherSaga } from 'store/teacher/shared/saga';
import * as FromTeacherSlice from '../../../store/teacher/shared/slice';
import _ from 'lodash';
import useAuth from 'hooks/useAuth';

interface ModalEditDecentralization {
  isOpen: boolean;
  handleCancel: () => void;
  handleRefresh: () => void;
  detail: any;
}

function ModalEditDecentralization({
  isOpen,
  handleCancel,
  handleRefresh,
  detail
}: ModalEditDecentralization) {
  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const listAgenciesSoHttp = new ListAgenciesSoHttp();
  const [department, setDepartment] = useState<any>();
  const [fullname, setFullname] = useState<string>('');
  const [valueDepartment, setValueDepartment] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [valueRole, setValueRole] = useState<number>(0);
  const { filters, setFilters } = useAuth();

  useEffect(() => {
    console.log('detail', detail);
    if (!_.isEmpty(detail)) {
      setValueRole(detail?.schools[0]?.is_save_permission ? 0 : 1);
      setFullname(detail.fullname);
      setValueDepartment(detail?.schools[0]?.school_id);
    }
  }, [detail]);

  useEffect(() => {
    loadListUserDepartments();
  }, []);

  const loadListUserDepartments = () => {
    listAgenciesSoHttp.getListAgenciesSoDepartments(filters).then((res) => {
      if (res.ok && res.status === 200) {
        setDepartment(res.data.data.edu_schools);
      }
    });
  };

  const handleChangeRole = (e: RadioChangeEvent) => {
    setValueRole(e.target.value);
  };

  const onChangeOptionDepartment = (e) => {
    setValueDepartment(e);
  };

  const onChangeFullName = (e) => {
    setFullname(e);
  };

  const onSubmit = () => {
    const request = {
      user_id: detail?.id,
      fullname: fullname,
      school_id: valueDepartment,
      is_save_permission: valueRole === 0 ? true : false
    };
    setIsSubmit(true);
    listAgenciesSoHttp.updateAgenciesSo(request).then((res) => {
      if (res.ok && res.status === 200) {
        setIsSubmit(false);
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.CHANGE_PASS_SUCCESS);
        handleCancel();
        // handleRefresh();
      } else {
        setIsSubmit(false);
      }
    });
    handleCancel();
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      title={`Cập nhật thông tin`}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button key="submit" size="large" type="primary" onClick={onSubmit}>
          Cập nhật
        </Button>
      ]}>
      <div className="container">
        <div className="mb-15">
          <label htmlFor="" className="font-bold mb-10 block">
            Nhập họ tên
          </label>
          <Input value={fullname} type="text" placeholder="Họ và tên" onChange={onChangeFullName} />
        </div>
        <div className="mb-15 select-angencies">
          <label htmlFor="" className="font-bold mb-10 block">
            Chọn cơ quan phụ trách
          </label>
          <Select
            placeholder="Chọn cơ quan phụ trách"
            style={{
              width: '100%'
            }}
            onChange={($event) => onChangeOptionDepartment($event)}
            value={valueDepartment}
            options={
              !_.isEmpty(department) &&
              department.map((item) => {
                return {
                  value: item.id,
                  label: item.name
                };
              })
            }
          />
        </div>
        <div className="mb-15">
          <label htmlFor="" className="font-bold mb-10 block">
            Chọn vai trò
          </label>
          <Radio.Group onChange={handleChangeRole} value={valueRole}>
            <Radio value={0}>Trưởng phòng</Radio>
            <Radio value={1}>Chuyên viên</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
}

export default ModalEditDecentralization;
