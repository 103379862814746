import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, MenuProps, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
`;
interface IPagination {
  current: number;
  onClickNext: () => void;
  onClickRev: () => void;
  rowsPerPage: number;
  onClickPerpage: (e) => void;
  isDisableNext?: boolean;
}

export default function Pagination({
  current,
  onClickRev,
  onClickNext,
  rowsPerPage,
  onClickPerpage,
  isDisableNext
}: IPagination) {
  const [isActive, setIsActive] = useState<string>('10');
  const handleMenuCick: MenuProps['onClick'] = (e) => {
    setIsActive(e.key);
    onClickPerpage(e);
  };
  const menu = (
    <Menu
      onClick={handleMenuCick}
      selectedKeys={[isActive]}
      items={[
        {
          label: '10 / Trang',
          key: '10'
        },
        {
          label: '20 / Trang',
          key: '20'
        },
        {
          label: '30 / Trang',
          key: '30'
        },
        {
          label: '40 / Trang',
          key: '40'
        },
        {
          label: '50 / Trang',
          key: '50'
        }
      ]}
    />
  );
  return (
    <ContainerPagination>
      <Space wrap align="center" size="middle">
        <Button size="middle" disabled={current === 1 ? true : false} onClick={onClickRev}>
          <i className="fas fa-angle-left" />
        </Button>
        <Button size="middle" type="primary" disabled>
          {current}
        </Button>
        <Button size="middle" onClick={onClickNext} disabled={isDisableNext}>
          <i className="fas fa-angle-right" />
        </Button>
        <Dropdown overlay={menu} placement="top">
          <Button size="middle">
            <Space wrap>
              {rowsPerPage} / Trang
              <DownOutlined style={{ fontSize: 12 }} />
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </ContainerPagination>
  );
}
