import Toast from 'components/Toast';
import React, { useContext, useState } from 'react';

export const GlobalContext = React.createContext<any>(undefined);

export const useGlobalState = () => useContext(GlobalContext);

type ProvidersProps = {
  children: React.ReactNode;
};

const ProviderContext = ({ children }: ProvidersProps) => {
  const [position, setPosition] = useState({
    variant: 'top_right'
  });

  return (
    <GlobalContext.Provider
      value={{
        position: position,
        setPosition: setPosition
      }}>
      <Toast>{children}</Toast>
    </GlobalContext.Provider>
  );
};
export default ProviderContext;
