import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCourseId, selectListPeriodId } from 'store/common/shared/selectors';
import _ from 'lodash';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import { YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const courseId: any = useSelector(selectCourseId);
  const listPeriodId: any = useSelector(selectListPeriodId);

  const localStorage = new LocalStorageService();
  const roles: any = localStorage.getItem(LocalStorageKey.roles);
  const [isExpiredAuth, setIsExpiredAuth] = useState<boolean>(false);
  const [auth, setAuth] = useState<any>(roles);

  const [filters, setFilters] = useState<any>({
    pageId: 1,
    perPage: 10,
    courseId: null,
    periodId: [],
    periodIdArr: null,
    statusId: '',
    evaluationId: '',
    gradeId: 1
  });

  useEffect(() => {
    // if (!_.isEmpty(courseId) && !_.isEmpty(listPeriodId)) {
    if (!_.isEmpty(listPeriodId)) {
      let stringUrl: string = '';
      listPeriodId.forEach((item) => {
        let strPeriod: string = `&period_ids=${item}`;
        return (stringUrl += strPeriod);
      });
      const newFilter = Object.assign({}, filters, {
        pageId: 1,
        perPage: 10,
        // courseId: courseId.id! ? courseId.id : null,
        courseId: YEAR_ID_DEFAULT_PGDQ3,
        periodId: stringUrl ? stringUrl : `&period_ids=0`,
        periodIdArr: listPeriodId,
        isLoađing: true
      });
      setFilters(newFilter);
    }
  }, [listPeriodId]);

  // useEffect(() => {
  //   console.log('rung 2');
  //   if (!_.isEmpty(statusId)) {
  //     const newFilter = Object.assign({}, filters, {
  //       statusId: statusId.id,
  //       pageId: 1,
  //       perPage: 10
  //     });
  //     setFilters(newFilter);
  //     // dispatch(FromCreateReviewSlice.actions.getListReview(request));
  //   }
  // }, [statusId]);

  // const login = (userDetail) => {
  //   setAuth(userDetail);
  //   navigate('/');
  // };

  // const logout = () => {
  //   setAuth({});
  //   navigate('/login');
  // };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        filters,
        setFilters,
        isExpiredAuth,
        setIsExpiredAuth
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
