import { Layout } from 'containers';
import { RouteObject, useRoutes } from 'react-router-dom';
import { lazyLoad } from 'utils/loadable';
import { TableReview } from 'views/ManagerReview/components/TableReview';
import { TableReviewTTSK } from 'views/InitiativeAchievement/components/TableReviewTTSK';
import { TableReviewLSK } from 'views/ListAchievementSchool/components/TableReviewLSK';
import { TableReviewLSKSo } from 'views/ListAchievementSo/Pages/TableReviewLSKSo';
import { LoaderFallBack } from './components/Loading';
import LOGO_DASH_BOARD from './assets/images/menu-icon-3.png';
import LOGO_MANAGER_REVIEW from './assets/images/menu-icon-1.png';
import LOGO_LIST from './assets/images/menu-icon-2.png';
import { RequireAuthSo } from 'hooks/RequireAuthSo';
import { RequireAuthSchool } from 'hooks/RequireAuthSchool';
import { RequireAuthSchoolSo } from 'hooks/RequireAuthSchoolSo';
import { RequireAuthTeacher } from 'hooks/RequireAuthTeacher';
import { ListAchievementSo } from 'views/ListAchievementSo';
import { TableReviewLSKSoDetail } from 'views/ListAchievementSo/Pages/TableReviewLSKSoDetail';
import { TableReviewListSchoolSo } from 'views/ListSchoolSo/Pages/TableReviewListSchoolSo';
import { ListSchoolSo } from 'views/ListSchoolSo';
import { ListTeacherSo } from 'views/ListSchoolSo/Pages/ListTeacherSo';
import { ListTeacherSoDetail } from 'views/ListSchoolSo/Pages/ListTeacherSoDetail';
import { TableReviewChamLSKSo } from 'views/ListAchievementSo/Pages/TableReviewChamLSKSo';
import { TableReviewChamLSKSoDetail } from 'views/ListAchievementSo/Pages/TableReviewChamLSKSoDetail';
import { DecentralizedManagemenSo } from 'views/ListAchievementSo/Pages/DecentralizedManagemenSo';
import { TableReviewTTSKPgdQ3 } from 'views/InitiativeAchievementPgdQ3/components/TableReviewTTSKPgdQ3';
import { TableReviewLSKPgdQ3 } from 'views/ListAchievementSchoolPgdQ3/components/TableReviewLSKPgdQ3';
import { ListSchoolPgdQ3 } from 'views/ListSchoolPgdQ3';
import { TableReviewListSchoolPgdQ3 } from 'views/ListSchoolPgdQ3/Pages/TableReviewListSchoolPgdQ3';
import { ListTeacherPgdQ3 } from 'views/ListSchoolPgdQ3/Pages/ListTeacherPgdQ3';
import { ListTeacherPgdQ3Detail } from 'views/ListSchoolPgdQ3/Pages/ListTeacherPgdQ3Detail';
import { ListAchievementPgd } from 'views/ListAchievementPgd';
import { TableReviewLSKPgd } from 'views/ListAchievementPgd/Pages/TableReviewLSKPgd';
import { TableReviewLSKPgdDetail } from 'views/ListAchievementPgd/Pages/TableReviewLSKPgdDetail';
import { ManagerRatingPgd } from 'views/CompetitonRatingPgd/pages/ManagerRatingPgd';

export interface SidebarNavItem {
  id?: number;
  name: string;
  path?: string;
  src?: string;
  childrenId?: number;
  parentId?: number;
  className: string;
  childrenName?: string;
  children?: SidebarNavItem[];
  isChecked?: boolean;
}
export interface IRoute {
  path: RoutePath;
  name: string;
  Component: React.ComponentType;
  children?: any[];
  sidebarNavItems?: SidebarNavItem[];
}

const CreateRating = lazyLoad(
  () => import('./views/ManagerReview/components/CreateRating'),
  (module) => module.CreateRating,
  LoaderFallBack()
);

const ManagerReview = lazyLoad(
  () => import('./views/ManagerReview'),
  (module) => module.ManagerReview,
  LoaderFallBack()
);

const Statistical = lazyLoad(
  () => import('./views/Statistical'),
  (module) => module.Statistical,
  LoaderFallBack()
);

const SelfAssessmentList = lazyLoad(
  () => import('./views/ManagerSelfAssessment/pages/SelfAssessmentList'),
  (module) => module.SelfAssessmentList,
  LoaderFallBack()
);

const ListOfEvaluationResults = lazyLoad(
  () => import('./views/ListOfEvaluationResults'),
  (module) => module.ListOfEvaluationResults,
  LoaderFallBack()
);

const SelfAssessmentDetails = lazyLoad(
  () => import('./views/ManagerSelfAssessment/pages/SelfAssessmentDetails'),
  (module) => module.SelfAssessmentDetails,
  LoaderFallBack()
);

const DetailedResultsAreEvaluated = lazyLoad(
  () => import('./views/DetailedResultsAreEvaluated'),
  (module) => module.DetailedResultsAreEvaluated,
  LoaderFallBack()
);

const DetailedResultsAreEvaluatedSchool = lazyLoad(
  () => import('./views/DetailedResultsAreEvaluatedSchool'),
  (module) => module.DetailedResultsAreEvaluatedSchool,
  LoaderFallBack()
);

const SelfAssessmentListSchool = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchool/pages/SelfAssessmentListSchool'),
  (module) => module.SelfAssessmentListSchool,
  LoaderFallBack()
);

const ListOfEvaluationResultsSchool = lazyLoad(
  () => import('./views/ListOfEvaluationResultsSchool'),
  (module) => module.ListOfEvaluationResultsSchool,
  LoaderFallBack()
);

const SelfAssessmentDetailsSchool = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchool/pages/SelfAssessmentDetailsSchool'),
  (module) => module.SelfAssessmentDetailsSchool,
  LoaderFallBack()
);

// phòng ban

export const ManagerSelfAssessmentDepartment = lazyLoad(
  () => import('./views/ManagerSelfAssessmentDepartment'),
  (module) => module.ManagerSelfAssessmentDepartment,
  LoaderFallBack()
);

const SelfAssessmentListDepartment = lazyLoad(
  () => import('./views/ManagerSelfAssessmentDepartment/pages/SelfAssessmentListDepartment'),
  (module) => module.SelfAssessmentListDepartment,
  LoaderFallBack()
);

const ListOfEvaluationResultsDepartment = lazyLoad(
  () => import('./views/ListOfEvaluationResultsDepartment'),
  (module) => module.ListOfEvaluationResultsDepartment,
  LoaderFallBack()
);

const SelfAssessmentDetailsDepartment = lazyLoad(
  () => import('./views/ManagerSelfAssessmentDepartment/pages/SelfAssessmentDetailsDepartment'),
  (module) => module.SelfAssessmentDetailsDepartment,
  LoaderFallBack()
);

// const DetailedResultsAreEvaluatedSchool = lazyLoad(
//   () => import('./views/DetailedResultsAreEvaluatedSchool'),
//   (module) => module.DetailedResultsAreEvaluated,
//   LoaderFallBack()
// );

export const Login = lazyLoad(
  () => import('./views/Login'),
  (module) => module.Login,
  LoaderFallBack()
);

export const CreateStandard = lazyLoad(
  () => import('./views/ManagerReview/components/CreateStandard'),
  (module) => module.CreateStandard,
  LoaderFallBack()
);

export const DetailReview = lazyLoad(
  () => import('./views/ManagerReview/components/DetailReview'),
  (module) => module.DetailReview,
  LoaderFallBack()
);

export const ListTeacherReview = lazyLoad(
  () => import('./views/ManagerTeacherReview/pages/ListTeacherReview'),
  (module) => module.ListTeacherReview,
  LoaderFallBack()
);

export const ManagerSelfAssessment = lazyLoad(
  () => import('./views/ManagerSelfAssessment'),
  (module) => module.ManagerSelfAssessment,
  LoaderFallBack()
);

export const ManagerSelfAssessmentSchool = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchool'),
  (module) => module.ManagerSelfAssessmentSchool,
  LoaderFallBack()
);

export const ManagerTeacherReview = lazyLoad(
  () => import('./views/ManagerTeacherReview'),
  (module) => module.ManagerTeacherReview,
  LoaderFallBack()
);

export const RatedList = lazyLoad(
  () => import('./views/ManagerTeacherReview/pages/RatedList'),
  (module) => module.RatedList,
  LoaderFallBack()
);

export const DetailEvaluation = lazyLoad(
  () => import('./views/ManagerTeacherReview/pages/DetailEvaluation'),
  (module) => module.DetailEvaluation,
  LoaderFallBack()
);

export const EditEvaluation = lazyLoad(
  () => import('./views/ManagerTeacherReview/pages/EditEvaluation'),
  (module) => module.EditEvaluation,
  LoaderFallBack()
);

export const ListTeacher = lazyLoad(
  () => import('./views/ListTeacher'),
  (module) => module.ListTeacher,
  LoaderFallBack()
);
export const List = lazyLoad(
  () => import('./views/ListTeacher/page/List'),
  (module) => module.List,
  LoaderFallBack()
);

export const DetailTeacher = lazyLoad(
  () => import('./views/ListTeacher/page/DetailTeacher'),
  (module) => module.DetailTeacher,
  LoaderFallBack()
);

export const Profile = lazyLoad(
  () => import('./views/Profile/index'),
  (module) => module.Profile,
  LoaderFallBack()
);

export const ProfilePGD = lazyLoad(
  () => import('./views/ProfilePGD/index'),
  (module) => module.ProfilePGD,
  LoaderFallBack()
);

export const InitiativeAchievement = lazyLoad(
  () => import('./views/InitiativeAchievement'),
  (module) => module.InitiativeAchievement,
  LoaderFallBack()
);
export const ListAchievementSchool = lazyLoad(
  () => import('./views/ListAchievementSchool'),
  (module) => module.ListAchievementSchool,
  LoaderFallBack()
);

export const EmulationTitle = lazyLoad(
  () => import('./views/EmulationTitle/index'),
  (module) => module.EmulationTitle,
  LoaderFallBack()
);

export const CompetitionList = lazyLoad(
  () => import('./views/CompetitionList/index'),
  (module) => module.CompetitionList,
  LoaderFallBack()
);

export const PersonalCompetitionList = lazyLoad(
  () => import('./views/CompetitionList/pages/PersonalCompetitionList'),
  (module) => module.PersonalCompetitionList,
  LoaderFallBack()
);

export const SummaryComprtitionEndYear = lazyLoad(
  () => import('./views/CompetitionList/pages/SummaryComprtitionEndYear'),
  (module) => module.SummaryComprtitionEndYear,
  LoaderFallBack()
);

export const ResultCompetitionEndYearSchool = lazyLoad(
  () => import('./views/CompetitionList/pages/ResultCompetitionEndYear'),
  (module) => module.ResultCompetitionEndYear,
  LoaderFallBack()
);

export const CollectiveCompetitionList = lazyLoad(
  () => import('./views/CompetitionList/pages/CollectiveCompetitionList'),
  (module) => module.CollectiveCompetitionList,
  LoaderFallBack()
);

export const BrowseTheCompetitionList = lazyLoad(
  () => import('./views/BrowseTheCompetitionList/index'),
  (module) => module.BrowseTheCompetitionList,
  LoaderFallBack()
);

export const DefaulList = lazyLoad(
  () => import('./views/BrowseTheCompetitionList/pages/DefaulList'),
  (module) => module.DefaulList,
  LoaderFallBack()
);

export const PersonalCompetitionListBySchool = lazyLoad(
  () => import('./views/BrowseTheCompetitionList/pages/PersonalCompetitionListBySchool'),
  (module) => module.PersonalCompetitionListBySchool,
  LoaderFallBack()
);

export const CollectiveCompetitionListBySchool = lazyLoad(
  () => import('./views/BrowseTheCompetitionList/pages/CollectiveCompetitionList'),
  (module) => module.CollectiveCompetitionList,
  LoaderFallBack()
);

export const Library = lazyLoad(
  () => import('./views/Library/index'),
  (module) => module.Library,
  LoaderFallBack()
);

export const DepartmentOfEducation = lazyLoad(
  () => import('./views/Library/pages/DepartmentOfEducation'),
  (module) => module.DepartmentOfEducation,
  LoaderFallBack()
);

export const Documents = lazyLoad(
  () => import('./views/Library/pages/Documents'),
  (module) => module.Documents,
  LoaderFallBack()
);

export const Support = lazyLoad(
  () => import('./views/support/index'),
  (module) => module.Support,
  LoaderFallBack()
);

export const EmulationDecision = lazyLoad(
  () => import('./views/CompetitionList/pages/EmulationDecision/index'),
  (module) => module.EmulationDecision,
  LoaderFallBack()
);
const CompetitionRating = lazyLoad(
  () => import('./views/CompetitionRating'),
  (module) => module.CompetitionRating,
  LoaderFallBack()
);

const ManagerReviewRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ManagerReviewRoleEdu/ManagerReviewRoleEdu'),
  (module) => module.ManagerReviewRoleEdu,
  LoaderFallBack()
);

const CreateStandardRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ManagerReviewRoleEdu/CreateStandardRoleEdu'),
  (module) => module.CreateStandardRoleEdu,
  LoaderFallBack()
);

const ListSchoolReviewRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ListSchoolReview/ListSchoolReviewRoleEdu'),
  (module) => module.ListSchoolReviewRoleEdu,
  LoaderFallBack()
);

const ListSchoolReviewRoleEduDetail = lazyLoad(
  () => import('./views/CompetitionRating/pages/ListSchoolReview/ListSchoolReviewDetail'),
  (module) => module.ListSchoolReviewDetail,
  LoaderFallBack()
);

const DetailReviewRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ManagerReviewRoleEdu/DetailReviewRoleEdu'),
  (module) => module.DetailReviewRoleEdu,
  LoaderFallBack()
);

const CreateRatingRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ManagerReviewRoleEdu/CreateRatingRoleEdu'),
  (module) => module.CreateRatingRoleEdu,
  LoaderFallBack()
);

const DetailEvaluationRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ListSchoolReview/DetailEvaluationRoleEdu'),
  (module) => module.DetailEvaluationRoleEdu,
  LoaderFallBack()
);

export const EditEvaluationRoleEdu = lazyLoad(
  () => import('./views/CompetitionRating/pages/ListSchoolReview/EditEvaluationRoleEdu'),
  (module) => module.EditEvaluationRoleEdu,
  LoaderFallBack()
);

export const GiveAReScoring = lazyLoad(
  () => import('./views/CompetitionRating/pages/ListSchoolReview/GiveAReScoring'),
  (module) => module.GiveAReScoring,
  LoaderFallBack()
);

// Đánh giá thi đua cấp cơ sở
const CompetitionRatingSo = lazyLoad(
  () => import('./views/CompetitonRatingSo'),
  (module) => module.CompetitionRatingSo,
  LoaderFallBack()
);

const ManagerDecentralizationSo = lazyLoad(
  () => import('./views/CompetitonRatingSo/pages/ManagerDecentralizationSo'),
  (module) => module.ManagerDecentralizationSo,
  LoaderFallBack()
);

const ManagerRatingSo = lazyLoad(
  () => import('./views/CompetitonRatingSo/pages/ManagerRatingSo'),
  (module) => module.ManagerRatingSo,
  LoaderFallBack()
);

const CompetitionReviewSo = lazyLoad(
  () => import('./views/CompetitonRatingSo/pages/CompetitionReviewSo/index'),
  (module) => module.CompetitionReviewSo,
  LoaderFallBack()
);

const ListCompetitionReviewSoDetail = lazyLoad(
  () =>
    import('./views/CompetitonRatingSo/pages/CompetitionReviewSo/ListCompetitionReviewSoDetail'),
  (module) => module.ListCompetitionReviewSoDetail,
  LoaderFallBack()
);

// phòng ban
const CompetitionRatingDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment'),
  (module) => module.CompetitionRatingDepartment,
  LoaderFallBack()
);

const ManagerDecentralizationDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/ManagerDecentralizationDepartment'),
  (module) => module.ManagerDecentralizationDepartment,
  LoaderFallBack()
);

const ManagerRatingDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/ManagerRatingDepartment'),
  (module) => module.ManagerRatingDepartment,
  LoaderFallBack()
);

export const ListSchoolRantingDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/ListSchoolRantingDepartment'),
  (module) => module.ListSchoolRantingDepartment,
  LoaderFallBack()
);

const CompetitionReviewDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/CompetitionReviewDepartment/index'),
  (module) => module.CompetitionReviewDepartment,
  LoaderFallBack()
);

const ListCompetitionReviewDepartmentDetail = lazyLoad(
  () =>
    import(
      './views/CompetitonRatingDepartment/pages/CompetitionReviewDepartment/ListCompetitionReviewDepartmentDetail'
    ),
  (module) => module.ListCompetitionReviewDepartmentDetail,
  LoaderFallBack()
);

const DetailEvaluationRoleDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/ListSchoolReview/DetailEvaluationRoleEdu'),
  (module) => module.DetailEvaluationRoleEdu,
  LoaderFallBack()
);

export const EditEvaluationRoleDepartment = lazyLoad(
  () => import('./views/CompetitonRatingDepartment/pages/ListSchoolReview/EditEvaluationRoleEdu'),
  (module) => module.EditEvaluationRoleEdu,
  LoaderFallBack()
);

const ManagerRatingEdu = lazyLoad(
  () => import('./views/CompetitonRatingSo/pages/ManagerRatingSo'),
  (module) => module.ManagerRatingSo,
  LoaderFallBack()
);
//
export const DecentralizedManagement = lazyLoad(
  () => import('./views/DecentralizedManagement/index'),
  (module) => module.DecentralizedManagement,
  LoaderFallBack()
);

export const GivePointsInitiative = lazyLoad(
  () => import('./views/GivePointsInitiative/index'),
  (module) => module.GivePointsInitiative,
  LoaderFallBack()
);

export const GradedList = lazyLoad(
  () => import('./views/GivePointsInitiative/pages/GradedList'),
  (module) => module.GradedList,
  LoaderFallBack()
);

export const ScoreInitiative = lazyLoad(
  () => import('./views/GivePointsInitiative/pages/ScoreInitiative'),
  (module) => module.ScoreInitiative,
  LoaderFallBack()
);

export const DecisionLevel = lazyLoad(
  () => import('./views/DecisionLevel/index'),
  (module) => module.DecisionLevel,
  LoaderFallBack()
);

export const LevelOfIndividualDecision = lazyLoad(
  () => import('./views/DecisionLevel/pages/LevelOfIndividualDecision'),
  (module) => module.LevelOfIndividualDecision,
  LoaderFallBack()
);

export const CollectiveDecisionLevel = lazyLoad(
  () => import('./views/DecisionLevel/pages/CollectiveDecisionLevel'),
  (module) => module.CollectiveDecisionLevel,
  LoaderFallBack()
);

export const InitiativeDecisionLevel = lazyLoad(
  () => import('./views/DecisionLevel/pages/InitiativeDecisionLevel'),
  (module) => module.InitiativeDecisionLevel,
  LoaderFallBack()
);

export const InitiativeDecisionLevelDepartment = lazyLoad(
  () => import('./views/DecisionLevel/pages/InitiativeDecisionLevelDepartment'),
  (module) => module.InitiativeDecisionLevelDepartment,
  LoaderFallBack()
);

export const InitiativeDecisionLevelSchool = lazyLoad(
  () => import('./views/DecisionLevel/pages/InitiativeDecisionLevelSchool'),
  (module) => module.InitiativeDecisionLevelSchool,
  LoaderFallBack()
);

export const ListAgenciesSo = lazyLoad(
  () => import('./views/ListAgenciesSo/ListAgenciesSo'),
  (module) => module.ListAgenciesSo,
  LoaderFallBack()
);

export const ListSchoolRantingSo = lazyLoad(
  () => import('./views/CompetitonRatingSo/pages/ListSchoolRantingSo'),
  (module) => module.ListSchoolRantingSo,
  LoaderFallBack()
);

export const ResultCompetitionEndYear = lazyLoad(
  () => import('./views/BrowseTheCompetitionList/pages/ResultCompetitionEndYear'),
  (module) => module.ResultCompetitionEndYear,
  LoaderFallBack()
);

export const SummaryCompetitionYear = lazyLoad(
  () => import('./views/SummaryCompetitionYear/index'),
  (module) => module.SummaryCompetitionYear,
  LoaderFallBack()
);

export const SummaryCompetitionYearPersonal = lazyLoad(
  () => import('./views/SummaryCompetitionYear/pages/personal'),
  (module) => module.Personal,
  LoaderFallBack()
);

export const SummaryCompetitionYearPersonalDetail = lazyLoad(
  () => import('./views/SummaryCompetitionYear/pages/personal/PersonalDetail'),
  (module) => module.PersonalDetail,
  LoaderFallBack()
);

export const SummaryCompetitionYearCollective = lazyLoad(
  () => import('./views/SummaryCompetitionYear/pages/collective'),
  (module) => module.Collective,
  LoaderFallBack()
);

// chức năng riêng Pgd quận 3
export const InitiativeAchievementPgdQ3 = lazyLoad(
  () => import('./views/InitiativeAchievementPgdQ3'),
  (module) => module.InitiativeAchievementPgdQ3,
  LoaderFallBack()
);

export const EmulationTitlePgdQ3 = lazyLoad(
  () => import('./views/EmulationTitlePgdQ3/index'),
  (module) => module.EmulationTitlePgdQ3,
  LoaderFallBack()
);

export const CompetitionListPgdq3 = lazyLoad(
  () => import('./views/CompetitionListPgdq3/index'),
  (module) => module.CompetitionListPgdq3,
  LoaderFallBack()
);

export const PersonalCompetitionListPgdQ3 = lazyLoad(
  () => import('./views/CompetitionListPgdq3/pages/PersonalCompetitionListPgdQ3'),
  (module) => module.PersonalCompetitionListPgdQ3,
  LoaderFallBack()
);

export const CollectiveCompetitionListPgdQ3 = lazyLoad(
  () => import('./views/CompetitionListPgdq3/pages/CollectiveCompetitionListPgdQ3'),
  (module) => module.CollectiveCompetitionListPgdQ3,
  LoaderFallBack()
);

export const ListAchievementSchoolPgdQ3 = lazyLoad(
  () => import('./views/ListAchievementSchoolPgdQ3'),
  (module) => module.ListAchievementSchoolPgdQ3,
  LoaderFallBack()
);

export const BrowseTheCompetitionListPgdQ3 = lazyLoad(
  () => import('./views/BrowseTheCompetitionListPgdQ3/index'),
  (module) => module.BrowseTheCompetitionListPgdQ3,
  LoaderFallBack()
);

export const PersonalCompetitionListBySchoolPgdQ3 = lazyLoad(
  () => import('./views/BrowseTheCompetitionListPgdQ3/pages/PersonalCompetitionListBySchoolPgdQ3'),
  (module) => module.PersonalCompetitionListBySchoolPgdQ3,
  LoaderFallBack()
);
export const NewPersonalCompetitionListBySchoolPgdQ3 = lazyLoad(
  () =>
    import('./views/BrowseTheCompetitionListPgdQ3/pages/NewPersonalCompetitionListBySchoolPgdQ3'),
  (module) => module.NewPersonalCompetitionListBySchoolPgdQ3,
  LoaderFallBack()
);
export const CollectiveCompetitionListBySchoolPgdQ3 = lazyLoad(
  () => import('./views/BrowseTheCompetitionListPgdQ3/pages/CollectiveCompetitionListPgdQ3'),
  (module) => module.CollectiveCompetitionListPgdQ3,
  LoaderFallBack()
);
export const NewCollectiveCompetitionListBySchoolPgdQ3 = lazyLoad(
  () => import('./views/BrowseTheCompetitionListPgdQ3/pages/NewCollectiveCompetitionListPgdQ3'),
  (module) => module.NewCollectiveCompetitionListPgdQ3,
  LoaderFallBack()
);

const CompetitionRatingPgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd'),
  (module) => module.CompetitionRatingPgd,
  LoaderFallBack()
);

const CreateStandardRolePgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ManagerReviewRoleEdu/CreateStandardRoleEdu'),
  (module) => module.CreateStandardRoleEdu,
  LoaderFallBack()
);

const DetailReviewRolePgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ManagerReviewRoleEdu/DetailReviewRoleEdu'),
  (module) => module.DetailReviewRoleEdu,
  LoaderFallBack()
);

const CreateRatingRolePgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ManagerReviewRoleEdu/CreateRatingRoleEdu'),
  (module) => module.CreateRatingRoleEdu,
  LoaderFallBack()
);

const CompetitionReviewPgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/CompetitionReviewPgd/index'),
  (module) => module.CompetitionReviewPgd,
  LoaderFallBack()
);

const ListCompetitionReviewPgdDetail = lazyLoad(
  () =>
    import('./views/CompetitonRatingPgd/pages/CompetitionReviewPgd/ListCompetitionReviewPgdDetail'),
  (module) => module.ListCompetitionReviewPgdDetail,
  LoaderFallBack()
);

const ListCompetitionReviewPgdDetailForCV = lazyLoad(
  () =>
    import(
      './views/CompetitonRatingPgd/pages/CompetitionReviewPgd/ListCompetitionReviewPgdDetailForCV'
    ),
  (module) => module.ListCompetitionReviewPgdDetailForCV,
  LoaderFallBack()
);

const ManagerDecentralizationPgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ManagerDecentralizationPgd'),
  (module) => module.ManagerDecentralizationPgd,
  LoaderFallBack()
);

export const ListSchoolRantingPgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ListSchoolRantingPgd'),
  (module) => module.ListSchoolRantingPgd,
  LoaderFallBack()
);

export const ManagerSelfAssessmentSchoolPgd = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchoolPgd'),
  (module) => module.ManagerSelfAssessmentSchoolPgd,
  LoaderFallBack()
);

const SelfAssessmentListSchoolPgd = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchoolPgd/pages/SelfAssessmentListSchoolPgd'),
  (module) => module.SelfAssessmentListSchoolPgd,
  LoaderFallBack()
);

const SelfAssessmentDetailsSchoolPgd = lazyLoad(
  () => import('./views/ManagerSelfAssessmentSchoolPgd/pages/SelfAssessmentDetailsSchoolPgd'),
  (module) => module.SelfAssessmentDetailsSchoolPgd,
  LoaderFallBack()
);

const ListOfEvaluationResultsSchoolPgd = lazyLoad(
  () => import('./views/ListOfEvaluationResultsSchoolPgd'),
  (module) => module.ListOfEvaluationResultsSchoolPgd,
  LoaderFallBack()
);

const DetailEvaluationRolePgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ListSchoolReview/DetailEvaluationRoleEdu'),
  (module) => module.DetailEvaluationRoleEdu,
  LoaderFallBack()
);

export const EditEvaluationRolePgd = lazyLoad(
  () => import('./views/CompetitonRatingPgd/pages/ListSchoolReview/EditEvaluationRoleEdu'),
  (module) => module.EditEvaluationRoleEdu,
  LoaderFallBack()
);

export enum RoutePath {
  DASHBOARD = '/',
  STATISTICAL = '/statistical',
  SELF_ASSESSMENT_LIST = '/self-assessment-list',
  LIST_OF_EVALUATION_RESULTS = '/list-of-evaluation-results',
  DETAIL_RESULTS_ARE_EVALUATED = '/detailed-results-are-evaluated/:id/:periodId',
  CREATE_RATING = 'create-rating/:id',
  CREATE_STANDARD = 'create-standard/:id/:check',
  DETAIL_REVIEW = 'detail/:id',
  MANAGER_LIST_REVIEW = '/manager-review',
  MANAGER_SELF_ASSESSMENT = '/manager-self-assessment',
  SELF_ASSESSMENT_DETAIL = '/manager-self-assessment/self-assessment-details/:id/:periodId',
  MANAGER_TEACHER_REVIEW = '/manager-teacher-review',
  RATED_LIST_TEACHER_DETAIL = ':id/:periodId',
  DETAIL_EVALUATION = ':id/detail/:userId/:periodId',
  DETAIL_TEACHER = 'detail/:id',
  DETAIL_EXPERT = 'detail/:id',
  EDIT_EVALUATION = ':id/edit/:userId/:periodId',
  LIST_TEACHER = '/list-teacher',
  LIST_EXPERT = '/list-expert',
  LIST_TEACHER_ID = '/list-teacher:id', // bổ sung thêm id trường
  LIST_EXPERT_ID = '/list-expert:id', // bổ sung thêm id trường
  PRO_FILE_USER = '/profile',
  PRO_FILE_USER_Q3 = '/profile-pgd',
  EMULATION_TITLE = '/emulation-title',
  EMULATION_TITLE_PGD_Q3 = '/emulation-title-pgd',
  INTITIATIVE_ACHIEVAMENT = '/initiative-achievement', // thành tích sáng kiến
  INTITIATIVE_ACHIEVAMENT_Q3 = '/initiative-achievement-q3', // thành tích sáng kiến Quận 3
  LIST_ACHIEVAMENT_SCHOOL = '/list-achievement-school', // danh sách sáng kiến
  LIST_ACHIEVAMENT_SCHOOL_PGDQ3 = '/list-achievement-school-pgdq3', // danh sách sáng kiến
  LIST_ACHIEVAMENT_SO = '/list-initiative-mark-so/list-achievement-so', // công nhận danh sách sáng kiến
  LIST_ACHIEVAMENT_SO_DETAIL = '/list-initiative-mark-so/list-achievement-so/:id', // chi tiết công nhận danh sách sáng kiến
  LIST_ACHIEVAMENT_PGD = '/list-initiative-mark-pgd/list-achievement-pgd', // công nhận danh sách sáng kiến pgd
  LIST_ACHIEVAMENT_PGD_DETAIL = '/list-initiative-mark-pgd/list-achievement-pgd/:id', // chi tiết công nhận danh sách sáng kiến pgd
  LIST_SCHOOL_SO = 'list-school-so', // Danh sách trường trực thuộc Sở
  LIST_SCHOOL_SO_ID = ':id', // Danh sách GV thuộc trường
  LIST_SCHOOL_SO_DETAIL_ID = 'detail/:id', // Chi tiết GV thuộc trường
  LIST_SCHOOL_PGD = 'list-school-pgd', // Danh sách trường trực thuộc Sở
  LIST_SCHOOL_PGD_ID = ':id', // Danh sách GV thuộc trường
  LIST_SCHOOL_PGD_DETAIL_ID = 'detail/:id', // Chi tiết GV thuộc trường
  LIST_INTITIATIVE_MARK_SO = '/list-initiative-mark-so', // danh sách sáng kiến
  LIST_INTITIATIVE_MARK_PGD = '/list-initiative-mark-pgd', // danh sách sáng kiến
  MARK_INTITIATIVE_DEPARTMENT = '/list-initiative-mark-so/mark-initiative-department', // danh sách sáng kiến
  LIST_ACHIEVAMENT_MARK_SO_DETAIL = '/list-initiative-mark-so/mark-initiative-department/:id', // chi tiết công nhận danh sách sáng kiến
  DECENTRALIZED_MANAGEMENT_SO = '/list-initiative-mark-so/decentralized-management-so',

  //LIST_COMPETITION = '/list-competition',
  LIST_COMPETITION = '#',
  LIST_COMPETITION_SCHOOL_PGDQ = '/list-competition-pgdq',
  LIST_COMPETITION_PGD = '/list-competition-pgd',
  SELF_ASSESSMENT_COMPETITION = '/self-assessment-competition',
  SELF_ASSESSMENT_COMPETITION_DEPARTMENT = '/self-assessment-competition-department',
  SELF_ASSESSMENT_COMPETITION_SHOOL = '/self-assessment-competition-school',
  SELF_ASSESSMENT_COMPETITION_SHOOL_PGD = '/self-assessment-competition-school-pgd',
  SELF_ASSESSMENT_COMPETITION_PGD = '/self-assessment-competition-pgd',
  MANAGER_SELF_ASSESSMENT_SCHOOL = '/manager-self-assessment-school',
  MANAGER_SELF_ASSESSMENT_SCHOOL_PGD = '/manager-self-assessment-school-pgd',
  MANAGER_SELF_ASSESSMENT_DEPARTMENT = '/manager-self-assessment-department',
  SELF_ASSESSMENT_DETAIL_SCHOOL = '/manager-self-assessment-school/self-assessment-details-school/:id/:periodId',
  SELF_ASSESSMENT_DETAIL_SCHOOL_PGD = '/manager-self-assessment-school-pgd/self-assessment-details-school-pgd/:id/:periodId',
  SELF_ASSESSMENT_DETAIL_DEPARTMENT = '/manager-self-assessment-department/self-assessment-details-department/:id/:periodId',
  LIST_OF_EVALUATION_RESULTS_SCHOOL = '/list-of-evaluation-results-school',
  LIST_OF_EVALUATION_RESULTS_SCHOOL_PGD = '/list-of-evaluation-results-school-pgd',
  LIST_OF_EVALUATION_RESULTS_DEPARTMENT = '/list-of-evaluation-results-department',
  DETAIL_RESULTS_ARE_EVALUATED_SCHOOL = '/detailed-results-are-evaluated-school/:id/:periodId',
  DETAIL_RESULTS_ARE_EVALUATED_SCHOOL_RE_RATING = '/detailed-results-are-evaluated-school/:id/:periodId/re-rating',
  LIST_PERSONAL_COMPETITION = '/list-personal-competition', // thi đua cá nhân
  LIST_COLLECTIVE_COMPETITION = '/list-collective-competition', // thi đua tập thể
  LIST_PERSONAL_COMPETITION_PGDQ3 = '/list-personal-competition-pgd', // thi đua cá nhân trường quận 3
  LIST_COLLECTIVE_COMPETITION_PGDQ3 = '/list-collective-competition-pgdq3', // thi đua tập thể trường quận 3
  RESULT_COMPETITION_END_YEAR_SCHOOL = '/result-competition-end-year-school',
  LIST_COLLECTIVE_COMPETITION_ID = '/list-collective-competition/:id', // thi đua tập thể theo id trường
  LIST_COLLECTIVE_COMPETITION_PGD_ID = '/list-collective-competition-pgd/:id', // thi đua tập thể theo id trường
  LIST_BROWSE_THE_COMPETITION = '/browse-the-competition-list', // thi đua tập thể theo id trường
  LIST_BROWSE_THE_COMPETITION_PGDQ3 = '/browse-the-competition-list-pgdq3', // thi đua tập thể theo id trường
  EMULATION_AND_REWARD = '/emulation-and-reward',
  EMULATION_AND_REWARD_Q3 = '/emulation-and-reward_q3',
  LIST_PERSONAL_COMPETITION_BY_SCHOOL = '/list-personal-competition-by-school', // thi đua cá theo id trường
  LIST_LIST_COLLECTIVE_COMPETITION_BY_SCHOOL = '/list-collective-competition-by-school', // thi đua tập thể theo id trường
  LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3 = '/list-personal-competition-school-pgd', // thi đua cá theo id trường thuộc pgdq3
  NEW_LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3 = '/new-list-personal-competition-school-pgd', // làm mới q3 => thi đua cá theo id trường thuộc pgdq3
  LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3 = '/list-collective-competition-school-pgd', // thi đua tập thể theo id trường thuộc pgdq3
  NEW_LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3 = '/new-list-collective-competition-school-pgd', // làm mới q3 =>thi đua tập thể theo id trường thuộc pgdq3
  LIBRARY_DOE = '/list-library-doe', // thư viện sở
  DECIDE_TO_COMPENSATE = '/decide-to-compensate',
  EMULATION_DECIDE = '/emulation-decision',
  LIST_DOCUMENT = '/documents',
  SUPPORT = '/support',
  DECENTRALIZED_MANAGEMENT = '/decentralized-management',
  GIVE_POINTS_INITIATIVE = '/give-points-initiative',
  SCORE_INITIATIVE = '/give-points-initiative/score-initiative/:id',
  DECISION_LEVEL = '/decision-level',
  LEVEL_OF_INDIVIDUAL_DECISION = '/decision-level/level-of-individual-decision',
  COLLECTIVE_DECISION_LEVEL = '/decision-level/collective-decision-level',
  INITIATIVE_DECISION_LEVEL = '/decision-level/initiative-decision-level',
  INITIATIVE_DECISION_LEVEL_DEPARTMENT = '/decision-level/initiative-decision-level-department',
  MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN = '/manager-list-achievement-school-admin',
  MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN_PGDQ = '/manager-list-achievement-school-admin-pgdq',
  MANAGER_LIST_ACHIEVAMENT_SCHOOL_PB = '/manager-list-achievement-school-pb',
  INITIATIVE_DECISION_LEVEL_SCHOOL = '/initiative-decision-level-school',
  RESULT_COMPETITION_END_YEAR = '/result-competition-end-year', // thi đua tập thể theo id trường
  SUMMARY_COMPETITION_END_YEAR = '/summary-competition-end-year', // Tóm tắt tành tích cuối năm
  SUMMARY_COMPETITION_END_YEAR_SO = '/summary-competition-end-year-so', // Tóm tắt tành tích cuối năm sở
  SUMMARY_COMPETITION_END_YEAR_SO_DETAIL = '/summary-competition-end-year-so/:id', // Tóm tắt tành tích cuối năm sở
  SUMMARY_COMPETITION_END_YEAR_SO_PERSONAL = '/summary-competition-end-year-so/personal', // Tóm tắt tành tích cuối năm các nhân
  SUMMARY_COMPETITION_END_YEAR_SO_PERSONAL_DETAIL = '/summary-competition-end-year-so/personal/:id', // Tóm tắt tành tích cuối năm các nhân chi tiết theo trường
  SUMMARY_COMPETITION_END_YEAR_SO_COLLECTIVE = '/summary-competition-end-year-so/collective', // Tóm tắt tành tích cuối năm tập thể

  //COMPETITION RATING

  COMPETITION_RATING = '/competition-rating',
  MANAGER_REVIEW_ROLE_EDU = '/competition-rating-review-edu',
  CREATE_REVIEW_ROLE_EDU = 'create-standard-role-edu/:id/:check',
  LIST_SCHOOL_REVIEW_ROLE_EDU = '/competition-rating-list-school-review-edu',
  DETAIL_EVALUATION_ROLE_EDU = ':id/detail-review-role-edu/:userId/:periodId',
  DETAIL_EVALUATION_ROLE_SO = ':id/detail-review-role-so/:userId/:periodId',
  LIST_SCHOOL_REVIEW_ROLE_EDU_DETAIL = ':id/:periodId',
  DETAIL_REVIEW_ROLE_EDU = 'detail-review-role-edu/:id',
  CREATE_RATING_ROLE_EDU = 'create-rating-role-edu/:id',
  EDIT_EVALUATION_SCHOOL = ':id/edit/:userId/:periodId',

  //COMPETITION RATING SO
  COMPETITION_RATING_SO = 'competition-rating-so',
  COMPETITION_RATING_PGD = 'competition-rating-pgd',
  MARK_RATING_SO = 'mark-rating-so',
  MANAGER_RATING_SO = 'manager-rating-so',
  COMPETITION_REVIEW_SO = 'competition-review-so',
  COMPETITION_REVIEW_SO_DETAIL = ':id/:periodId',
  MANAGER_DECENTRALIZATION_SO = 'manager-decentralization-so',
  LIST_SCHOOL_RANGTING_SO = ':id/:periodId',

  COMPETITION_RATING_DEPARTMENT = 'competition-rating-department',
  REVIEW_MANAGEMENT_DEPARTMENT = 'review-management-department',
  COMPETITION_REVIEW_DEPARTMENT = 'competition-review-department',
  MANAGER_DECENTRALIZATION_EDU = 'manager-decentralization-edu',
  LIST_SCHOOL_RANGTING_EDU = ':id/:periodId',
  COMPETITION_REVIEW_DEPARTMENT_DETAIL = ':departmentId/:id',
  LIST_SCHOOL_REVIEW_ROLE_DEPARTMENT = '/competition-rating-list-school-review-department',
  DETAIL_EVALUATION_ROLE_DEPARTMENT = ':id/detail-review-role-department/:userId/:periodId',
  LIST_SCHOOL_REVIEW_ROLE_DEPARTMENT_DETAIL = ':id/:periodId',
  EDIT_EVALUATION_SCHOOL_DEPARTMENT = ':id/edit/:userId/:periodId',

  // Chuyên viên
  COMPETITION_REVIEW_EXPERT = 'competition-review-expert',
  COMPETITION_REVIEW_EXPERT_DETAIL = ':id/:periodId/:gradeId',
  EDIT_EVALUATION_EXPERT = ':id/edit/:userId/:periodId/:gradeId',
  DETAIL_EVALUATION_EXPERT = ':id/detail-review-role-expert/:userId/:periodId/:gradeId',
  EDIT_EVALUATION_EXPERT_GIVE_A_RE_SCORING = ':id/give-a-re-scoring/:userId/:periodId/:gradeId',
  //LIST DEPARTMENT
  LIST_AGENCIES_SO = 'list-agencies',

  //PGD
  MANAGER_RATING_PGD = 'manager-rating-pgd',
  COMPETITION_REVIEW_PGD_DETAIL = ':id/:periodId',
  CREATE_REVIEW_ROLE_PGD = 'create-standard-role-pgd/:id/:check',
  DETAIL_REVIEW_ROLE_PGD = 'detail-review-role-pgd/:id',
  CREATE_RATING_ROLE_PGD = 'create-rating-role-pgd/:id',
  MARK_RATING_PGD = 'mark-rating-pgd',
  MANAGER_DECENTRALIZATION_PGD = '/manager-decentralization-pgd',
  DETAIL_EVALUATION_ROLE_PGD = '/manager-rating-pgd/:id/detail-evaluation-role-pgd/:userId/:periodId',
  COMPETITION_REVIEW_EXPERT_PGD = 'competition-review-expert-pgd',
  COMPETITION_REVIEW_EXPERT_DETAIL_PGD = ':id/:periodId/:evaluationCodeId/:gradeId',
  EDIT_EVALUATION_EXPERT_PGD = '/competition-review-expert-pgd/:id/edit/:userId/:periodId/:evaluationCodeId/:gradeId',
  DETAIL_EVALUATION_EXPERT_PGD = '/competition-review-expert-pgd/:id/detail-review-role-expert-pgd/:userId/:periodId/:evaluationCodeId/:gradeId',
  DETAIL_EVALUATION_RANGTING_PGD = '/manager-decentralization-pgd/:id/detail-review-role-rangting-pgd/:userId/:periodId/:gradeId',
  LIST_SCHOOL_RANGTING_PGD = ':id/:periodId'
}

export const defaultSidebarNavItems: SidebarNavItem[] = [
  {
    id: 10,
    name: 'Danh sách thi đua',
    path: RoutePath.LIST_COMPETITION,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách thi đua cá nhân',
        path: RoutePath.LIST_PERSONAL_COMPETITION,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách thi đua tập thể',
        path: RoutePath.LIST_COLLECTIVE_COMPETITION,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Cấp quyết định thi đua',
        path: RoutePath.EMULATION_DECIDE,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 50,
    name: 'Danh sách thi đua',
    path: RoutePath.LIST_COMPETITION_SCHOOL_PGDQ,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách thi đua cá nhân',
        path: RoutePath.LIST_PERSONAL_COMPETITION_PGDQ3,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách thi đua tập thể',
        path: RoutePath.LIST_COLLECTIVE_COMPETITION_PGDQ3,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 46,
    name: 'Kết quả thi đua cuối năm',
    path: RoutePath.RESULT_COMPETITION_END_YEAR_SCHOOL,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 47,
    name: 'Tóm tắt thành tích cuối năm',
    path: RoutePath.SUMMARY_COMPETITION_END_YEAR,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 40,
    name: 'Danh sách thi đua tập thể',
    path: RoutePath.LIST_COLLECTIVE_COMPETITION,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 14,
    name: 'Thi đua - khen thưởng',
    path: RoutePath.EMULATION_AND_REWARD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh hiệu thi đua',
        path: RoutePath.EMULATION_TITLE,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Thành tích sáng kiến',
        path: RoutePath.INTITIATIVE_ACHIEVAMENT,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 15,
    name: 'Thi đua - khen thưởng',
    path: RoutePath.EMULATION_AND_REWARD_Q3,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh hiệu thi đua',
        path: RoutePath.EMULATION_TITLE_PGD_Q3,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Thành tích sáng kiến',
        path: RoutePath.INTITIATIVE_ACHIEVAMENT_Q3,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 17,
    name: 'Tự đánh giá thi đua',
    path: RoutePath.SELF_ASSESSMENT_COMPETITION,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách tự đánh giá',
        path: RoutePath.MANAGER_SELF_ASSESSMENT,
        src: LOGO_LIST,
        parentId: 17,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách kết quả đánh giá',
        path: RoutePath.LIST_OF_EVALUATION_RESULTS,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 17,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 33,
    name: 'Quản lý sáng kiến',
    path: RoutePath.MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách sáng kiến',
        path: RoutePath.LIST_ACHIEVAMENT_SCHOOL,
        src: LOGO_LIST,
        parentId: 33,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Cấp quyết định sáng kiến',
        path: RoutePath.INITIATIVE_DECISION_LEVEL_SCHOOL,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 33,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Quản lý phân quyền',
        path: RoutePath.DECENTRALIZED_MANAGEMENT,
        src: LOGO_LIST,
        childrenId: 3,
        parentId: 33,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 51,
    name: 'Quản lý sáng kiến',
    path: RoutePath.MANAGER_LIST_ACHIEVAMENT_SCHOOL_ADMIN_PGDQ,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách sáng kiến',
        path: RoutePath.LIST_ACHIEVAMENT_SCHOOL_PGDQ3,
        src: LOGO_LIST,
        parentId: 33,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 37,
    name: 'Danh sách sáng kiến',
    path: RoutePath.LIST_ACHIEVAMENT_SCHOOL,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ''
    // children: [
    //   {
    //     name: 'Danh sách sáng kiến',
    //     path: RoutePath.LIST_ACHIEVAMENT_SCHOOL,
    //     src: LOGO_LIST,
    //     parentId: 37,
    //     childrenId: 1,
    //     className: '',
    //     childrenName: 'children'
    //   }
    //   {
    //     name: 'Cấp quyết định sáng kiến',
    //     path: RoutePath.INITIATIVE_DECISION_LEVEL_SCHOOL,
    //     src: LOGO_LIST,
    //     childrenId: 2,
    //     parentId: 37,
    //     className: '',
    //     childrenName: 'children'
    //   }
    // ]
  },

  {
    id: 40,
    name: 'Đánh giá thi đua cấp cơ sở',
    path: RoutePath.COMPETITION_RATING_DEPARTMENT,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Quản lý đánh giá thi đua',
        path: RoutePath.REVIEW_MANAGEMENT_DEPARTMENT,
        src: LOGO_LIST,
        parentId: 19,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      // {
      //   name: 'Xem điểm thi đua',
      //   path: RoutePath.COMPETITION_REVIEW_DEPARTMENT,
      //   src: LOGO_LIST,
      //   childrenId: 2,
      //   parentId: 19,
      //   className: '',
      //   childrenName: 'children'
      // },
      {
        name: 'Quản lý phân quyền',
        path: RoutePath.MANAGER_DECENTRALIZATION_EDU,
        src: LOGO_LIST,
        childrenId: 3,
        parentId: 19,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 53,
    name: 'Danh sách đăng ký thi đua',
    path: RoutePath.LIST_BROWSE_THE_COMPETITION_PGDQ3,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh hiệu cá nhân',
        //path: RoutePath.LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3,
        path: RoutePath.NEW_LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh hiệu tập thể',
        //path: RoutePath.LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3,
        path: RoutePath.NEW_LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 54,
    name: 'Danh sách sáng kiến',
    path: RoutePath.LIST_INTITIATIVE_MARK_PGD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách đăng ký sáng kiến',
        path: RoutePath.LIST_ACHIEVAMENT_PGD,
        src: LOGO_LIST,
        parentId: 54,
        childrenId: 2,
        className: '',
        childrenName: 'children'
      }
      // {
      //   name: 'Chấm sáng kiến phòng ban',
      //   path: RoutePath.MARK_INTITIATIVE_DEPARTMENT,
      //   src: LOGO_LIST,
      //   parentId: 26,
      //   childrenId: 1,
      //   className: '',
      //   childrenName: 'children'
      // },
      // {
      //   name: 'Quản lý phân quyền',
      //   path: RoutePath.DECENTRALIZED_MANAGEMENT_SO,
      //   src: LOGO_LIST,
      //   parentId: 26,
      //   childrenId: 3,
      //   className: '',
      //   childrenName: 'children'
      // }
    ]
  },
  {
    id: 57,
    name: 'Đánh giá thi đua cấp cơ sở',
    path: RoutePath.COMPETITION_RATING_PGD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Quản lý đánh giá thi đua',
        path: RoutePath.MANAGER_RATING_PGD,
        src: LOGO_LIST,
        parentId: 19,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Quản lý phân quyền',
        path: RoutePath.MANAGER_DECENTRALIZATION_PGD,
        src: LOGO_LIST,
        childrenId: 3,
        parentId: 57,
        className: '',
        childrenName: 'children'
      }
      // {
      //   name: 'Danh sách kết quả đánh giá',
      //   path: RoutePath.COMPETITION_REVIEW_SO,
      //   src: LOGO_LIST,
      //   parentId: 19,
      //   childrenId: 2,
      //   className: '',
      //   childrenName: 'children'
      // }
    ]
  },
  {
    id: 55,
    name: 'Tự đánh giá thi đua cấp Sở',
    path: RoutePath.SELF_ASSESSMENT_COMPETITION_PGD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách tự đánh giá',
        path: RoutePath.MANAGER_SELF_ASSESSMENT_SCHOOL,
        src: LOGO_LIST,
        parentId: 21,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách kết quả đánh giá',
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_SCHOOL,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 21,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 21,
    name: 'Tự đánh giá thi đua tập thể',
    path: RoutePath.SELF_ASSESSMENT_COMPETITION_SHOOL,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách tự đánh giá',
        path: RoutePath.MANAGER_SELF_ASSESSMENT_SCHOOL,
        src: LOGO_LIST,
        parentId: 21,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách kết quả đánh giá',
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_SCHOOL,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 21,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 58,
    name: 'Tự đánh giá thi đua tập thể',
    path: RoutePath.SELF_ASSESSMENT_COMPETITION_SHOOL_PGD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách tự đánh giá',
        path: RoutePath.MANAGER_SELF_ASSESSMENT_SCHOOL_PGD,
        src: LOGO_LIST,
        parentId: 21,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách kết quả đánh giá',
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_SCHOOL_PGD,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 21,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 42,
    name: 'Tự đánh giá thi đua tập thể',
    path: RoutePath.SELF_ASSESSMENT_COMPETITION_DEPARTMENT,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách tự đánh giá',
        path: RoutePath.MANAGER_SELF_ASSESSMENT_DEPARTMENT,
        src: LOGO_LIST,
        parentId: 42,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách kết quả đánh giá',
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_DEPARTMENT,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 42,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 29,
    name: 'Chấm sáng kiến cá nhân',
    path: RoutePath.GIVE_POINTS_INITIATIVE,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  // {
  //   id: 12,
  //   name: 'Danh sách sáng kiến',
  //   path: RoutePath.LIST_ACHIEVAMENT_SCHOOL,
  //   src: LOGO_LIST,
  //   isChecked: false,
  //   className: ''
  // },
  {
    id: 2,
    name: 'Danh sách giáo viên',
    path: RoutePath.LIST_TEACHER,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 2,
    name: 'Danh sách CBNV',
    path: RoutePath.LIST_EXPERT,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  // {
  //   id: 3,
  //   name: 'Quản lý đánh giá',
  //   path: RoutePath.MANAGER_LIST_REVIEW,
  //   src: LOGO_MANAGER_REVIEW,
  //   className: ''
  // },
  {
    id: 9,
    name: 'Quản lý đánh giá thi đua cá nhân',
    path: RoutePath.MANAGER_LIST_REVIEW,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách đánh giá',
        path: RoutePath.MANAGER_LIST_REVIEW,
        src: LOGO_LIST,
        parentId: 9,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách giáo viên đánh giá',
        path: RoutePath.MANAGER_TEACHER_REVIEW,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 9,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  // {
  //   id: 9,
  //   name: 'Danh sách giáo viên đánh giá',
  //   path: RoutePath.MANAGER_TEACHER_REVIEW,
  //   src: LOGO_LIST,
  //   className: ''
  // },
  {
    id: 8,
    name: 'Thống kê',
    path: RoutePath.DASHBOARD,
    src: LOGO_DASH_BOARD,
    isChecked: false,
    className: ''
  },
  {
    id: 1,
    name: 'Danh sách đăng ký thi đua',
    path: RoutePath.LIST_BROWSE_THE_COMPETITION,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh hiệu cá nhân',
        path: RoutePath.LIST_PERSONAL_COMPETITION_BY_SCHOOL,
        src: LOGO_LIST,
        parentId: 10,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh hiệu tập thể',
        path: RoutePath.LIST_LIST_COLLECTIVE_COMPETITION_BY_SCHOOL,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 10,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 45,
    name: 'Kết quả thi đua cuối năm',
    path: RoutePath.RESULT_COMPETITION_END_YEAR,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 60,
    name: 'Tóm tắt thành tích cuối năm',
    path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
    // children: [
    //   {
    //     name: 'Tóm tắt thành tích BKTP cá nhân',
    //     path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_PERSONAL,
    //     src: LOGO_LIST,
    //     parentId: 10,
    //     childrenId: 1,
    //     className: '',
    //     childrenName: 'children'
    //   },
    //   {
    //     name: 'Tóm tắt thành tích BKTP tập thể',
    //     path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_COLLECTIVE,
    //     src: LOGO_LIST,
    //     childrenId: 2,
    //     parentId: 10,
    //     className: '',
    //     childrenName: 'children'
    //   }
    // ]
  },
  {
    id: 26,
    name: 'Danh sách sáng kiến',
    path: RoutePath.LIST_INTITIATIVE_MARK_SO,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách đăng ký sáng kiến',
        path: RoutePath.LIST_ACHIEVAMENT_SO,
        src: LOGO_LIST,
        parentId: 26,
        childrenId: 2,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Chấm sáng kiến phòng ban',
        path: RoutePath.MARK_INTITIATIVE_DEPARTMENT,
        src: LOGO_LIST,
        parentId: 26,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Quản lý phân quyền',
        path: RoutePath.DECENTRALIZED_MANAGEMENT_SO,
        src: LOGO_LIST,
        parentId: 26,
        childrenId: 3,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 20,
    name: 'Đánh giá thi đua',
    path: RoutePath.COMPETITION_RATING,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Danh sách đánh giá',
        path: RoutePath.MANAGER_REVIEW_ROLE_EDU,
        src: LOGO_LIST,
        parentId: 20,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Danh sách trường đánh giá',
        path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 20,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 30,
    name: 'Cấp quyết định',
    path: RoutePath.DECISION_LEVEL,
    src: LOGO_MANAGER_REVIEW,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Cấp quyết định cá nhân',
        path: RoutePath.LEVEL_OF_INDIVIDUAL_DECISION,
        src: LOGO_LIST,
        parentId: 30,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Cấp quyết định tập thể',
        path: RoutePath.COLLECTIVE_DECISION_LEVEL,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 30,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Cấp quyết định sáng kiến',
        path: RoutePath.INITIATIVE_DECISION_LEVEL,
        src: LOGO_LIST,
        childrenId: 3,
        parentId: 30,
        className: '',
        childrenName: 'children'
      },
      {
        name: 'Cấp quyết định sáng kiến khối 28',
        path: RoutePath.INITIATIVE_DECISION_LEVEL_DEPARTMENT,
        src: LOGO_LIST,
        childrenId: 3,
        parentId: 30,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 19,
    name: 'Đánh giá thi đua cấp cơ sở',
    path: RoutePath.COMPETITION_RATING_SO,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Quản lý đánh giá thi đua',
        path: RoutePath.MANAGER_RATING_SO,
        src: LOGO_LIST,
        parentId: 19,
        childrenId: 1,
        className: '',
        childrenName: 'children'
      }
      // {
      //   name: 'Danh sách kết quả đánh giá',
      //   path: RoutePath.COMPETITION_REVIEW_SO,
      //   src: LOGO_LIST,
      //   parentId: 19,
      //   childrenId: 2,
      //   className: '',
      //   childrenName: 'children'
      // }
    ]
  },
  // {
  //   id: 19,
  //   name: 'Đánh giá thi đua cấp cơ sở',
  //   path: RoutePath.MANAGER_RATING_SO,
  //   src: LOGO_LIST,
  //   isChecked: false,
  //   className: ''
  //   children: [
  //     {
  //       name: 'Quản lý đánh giá thi đua',
  //       path: RoutePath.MANAGER_RATING_SO,
  //       src: LOGO_LIST,
  //       parentId: 19,
  //       childrenId: 1,
  //       className: '',
  //       childrenName: 'children'
  //     }
  //   ]
  // },
  {
    id: 32,
    name: 'Đánh giá thi đua cấp cơ sở',
    path: RoutePath.MARK_RATING_SO,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Chấm điểm thi đua',
        path: RoutePath.COMPETITION_REVIEW_EXPERT,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 32,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  {
    id: 32,
    name: 'Đánh giá thi đua cấp cơ sở',
    path: RoutePath.MARK_RATING_PGD,
    src: LOGO_LIST,
    isChecked: false,
    className: ' dropdown',
    children: [
      {
        name: 'Chấm điểm thi đua',
        path: RoutePath.COMPETITION_REVIEW_EXPERT_PGD,
        src: LOGO_LIST,
        childrenId: 2,
        parentId: 32,
        className: '',
        childrenName: 'children'
      }
    ]
  },
  // {
  //   id: 13,
  //   name: 'Danh sách trường trực thuộc Sở',
  //   path: RoutePath.LIST_SCHOOL_SO,
  //   src: LOGO_LIST,
  //   className: ''
  // },
  {
    id: 20,
    name: 'Danh sách cơ quan phụ trách',
    path: RoutePath.LIST_AGENCIES_SO,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  // {
  //   id: 16,
  //   name: 'Thư viện',
  //   path: RoutePath.LIBRARY_DOE,
  //   src: LOGO_LIST,
  //   isChecked: false,
  //   className: ' dropdown',
  //   children: [
  //     {
  //       name: 'Quyết định khen thưởng',
  //       path: RoutePath.DECIDE_TO_COMPENSATE,
  //       src: LOGO_LIST,
  //       childrenId: 1,
  //       parentId: 16,
  //       className: '',
  //       childrenName: 'children'
  //     },
  //     {
  //       name: 'Văn bản',
  //       path: RoutePath.LIST_DOCUMENT,
  //       src: LOGO_LIST,
  //       childrenId: 2,
  //       parentId: 16,
  //       className: '',
  //       childrenName: 'children'
  //     }
  //   ]
  // },
  {
    id: 6,
    name: 'Thông tin cá nhân',
    path: RoutePath.PRO_FILE_USER,
    src: LOGO_LIST,
    isChecked: false,
    className: ''
  },
  {
    id: 18,
    name: 'Hướng dẫn sử dụng',
    path: RoutePath.SUPPORT,
    src: LOGO_LIST,
    className: ''
  }
];

export const Role = {
  SO: true,
  SCHOOL: true,
  TEACHER: true
};

export const routes: RouteObject[] = [
  {
    path: RoutePath.DASHBOARD,
    element: <Layout />,
    children: [
      // menu teacher
      {
        path: RoutePath.MANAGER_SELF_ASSESSMENT,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <ManagerSelfAssessment />
          </RequireAuthTeacher>
        ),
        children: [
          { index: true, element: <SelfAssessmentList /> },
          {
            path: RoutePath.SELF_ASSESSMENT_DETAIL,
            element: <SelfAssessmentDetails />
          }
        ]
      },
      {
        path: RoutePath.LIST_OF_EVALUATION_RESULTS,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <ListOfEvaluationResults />
          </RequireAuthTeacher>
        )
        // children: [
        //   { index: true, element: <SelfAssessmentList /> },
        //   {
        //     path: RoutePath.SELF_ASSESSMENT_DETAIL,
        //     element: <SelfAssessmentDetails />
        //   }
        // ]
      },
      {
        path: RoutePath.DETAIL_RESULTS_ARE_EVALUATED,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <DetailedResultsAreEvaluated />
          </RequireAuthTeacher>
        )
        // children: [
        //   { index: true, element: <SelfAssessmentList /> },
        //   {
        //     path: RoutePath.SELF_ASSESSMENT_DETAIL,
        //     element: <SelfAssessmentDetails />
        //   }
        // ]
      },
      {
        path: RoutePath.INTITIATIVE_ACHIEVAMENT,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <InitiativeAchievement />
          </RequireAuthTeacher>
        ),
        children: [
          //{ index: true, element: <InitiativeAchievement /> },
          { index: true, element: <TableReviewTTSK /> }
          // {
          //   path: RoutePath.DETAIL_REVIEW,
          //   element: <DetailReview />
          // },
          // {
          //   path: RoutePath.CREATE_RATING,
          //   element: <CreateRating />
          // },
          // {
          //   path: RoutePath.CREATE_STANDARD,
          //   element: <CreateStandard />
          // }
        ]
      },
      {
        path: RoutePath.GIVE_POINTS_INITIATIVE,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <GivePointsInitiative />
          </RequireAuthTeacher>
        ),
        children: [
          //{ index: true, element: <InitiativeAchievement /> },
          { index: true, element: <GradedList /> },
          {
            path: RoutePath.SCORE_INITIATIVE,
            element: <ScoreInitiative />
          }
        ]
      },
      {
        path: RoutePath.COMPETITION_REVIEW_EXPERT,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <CompetitionRatingSo />
          </RequireAuthTeacher>
        ),
        children: [
          { index: true, element: <CompetitionReviewSo /> },
          {
            path: RoutePath.COMPETITION_REVIEW_EXPERT_DETAIL,
            element: <ListCompetitionReviewSoDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_EXPERT,
            element: <DetailEvaluationRoleEdu />
          },
          {
            path: RoutePath.EDIT_EVALUATION_EXPERT,
            element: <EditEvaluationRoleEdu />
          },
          {
            path: RoutePath.EDIT_EVALUATION_EXPERT_GIVE_A_RE_SCORING,
            element: <GiveAReScoring />
          }
        ]
      },
      {
        path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <CompetitionRating />
          </RequireAuthTeacher>
        ),
        children: [
          { index: true, element: <ListSchoolReviewRoleEdu /> },
          {
            path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU_DETAIL,
            element: <ListSchoolReviewRoleEduDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_EXPERT,
            element: <DetailEvaluationRoleEdu />
          },
          {
            path: RoutePath.EDIT_EVALUATION_EXPERT,
            element: <EditEvaluationRoleEdu />
          }
        ]
      },
      // end menu teacher

      // menu school
      {
        index: true,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            {/* <Statistical /> */}
            {/* Tân đổi từ trang thống kê qua Danh hiệu thi đua cá nhân */}
            <PersonalCompetitionListPgdQ3 />
          </RequireAuthSchool>
        )
      },

      {
        path: RoutePath.MANAGER_SELF_ASSESSMENT_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ManagerSelfAssessmentSchool />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <SelfAssessmentListSchool /> },
          {
            path: RoutePath.SELF_ASSESSMENT_DETAIL_SCHOOL,
            element: <SelfAssessmentDetailsSchool />
          }
        ]
      },
      {
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListOfEvaluationResultsSchool />
          </RequireAuthSchool>
        )
        // children: [
        //   { index: true, element: <SelfAssessmentList /> },
        //   {
        //     path: RoutePath.SELF_ASSESSMENT_DETAIL,
        //     element: <SelfAssessmentDetails />
        //   }
        // ]
      },
      {
        path: RoutePath.DETAIL_RESULTS_ARE_EVALUATED_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <DetailedResultsAreEvaluatedSchool />
          </RequireAuthSchool>
        )
      },
      {
        path: RoutePath.DETAIL_RESULTS_ARE_EVALUATED_SCHOOL_RE_RATING,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <DetailedResultsAreEvaluatedSchool />
          </RequireAuthSchool>
        )
      },
      // Phòng ban
      {
        path: RoutePath.MANAGER_SELF_ASSESSMENT_DEPARTMENT,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ManagerSelfAssessmentDepartment />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <SelfAssessmentListDepartment /> },
          {
            path: RoutePath.SELF_ASSESSMENT_DETAIL_DEPARTMENT,
            element: <SelfAssessmentDetailsDepartment />
          }
        ]
      },
      {
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_DEPARTMENT,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListOfEvaluationResultsDepartment />
          </RequireAuthSchool>
        )
        // children: [
        //   { index: true, element: <SelfAssessmentList /> },
        //   {
        //     path: RoutePath.SELF_ASSESSMENT_DETAIL,
        //     element: <SelfAssessmentDetails />
        //   }
        // ]
      },
      {
        path: RoutePath.DETAIL_RESULTS_ARE_EVALUATED_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <DetailedResultsAreEvaluatedSchool />
          </RequireAuthSchool>
        )
      },
      // end phòng ban
      {
        path: RoutePath.MANAGER_LIST_REVIEW,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ManagerReview />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <TableReview /> },
          {
            path: RoutePath.DETAIL_REVIEW,
            element: <DetailReview />
          },
          {
            path: RoutePath.CREATE_RATING,
            element: <CreateRating />
          },
          {
            path: RoutePath.CREATE_STANDARD,
            element: <CreateStandard />
          }
        ]
      },
      {
        path: RoutePath.MANAGER_TEACHER_REVIEW,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ManagerTeacherReview />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <RatedList /> },
          {
            path: RoutePath.RATED_LIST_TEACHER_DETAIL,
            element: <ListTeacherReview />
          },
          {
            path: RoutePath.DETAIL_EVALUATION,
            element: <DetailEvaluation />
          },
          {
            path: RoutePath.EDIT_EVALUATION,
            element: <EditEvaluation />
          }
        ]
      },
      {
        path: RoutePath.LIST_TEACHER,
        element: (
          <RequireAuthSchoolSo role={Role.SCHOOL}>
            <ListTeacher />
          </RequireAuthSchoolSo>
        ),
        children: [
          { index: true, element: <List /> },
          {
            path: RoutePath.DETAIL_TEACHER,
            element: <DetailTeacher />
          },
          {
            path: RoutePath.LIST_TEACHER_ID,
            element: <List /> // bổ sung thêm ID trường
          }
        ]
      },
      {
        path: RoutePath.LIST_EXPERT,
        element: (
          <RequireAuthSchoolSo role={Role.SCHOOL}>
            <ListTeacher />
          </RequireAuthSchoolSo>
        ),
        children: [
          { index: true, element: <List /> },
          {
            path: RoutePath.DETAIL_EXPERT,
            element: <DetailTeacher />
          },
          {
            path: RoutePath.LIST_EXPERT_ID,
            element: <List /> // bổ sung thêm ID trường
          }
        ]
      },
      {
        path: RoutePath.LIST_ACHIEVAMENT_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListAchievementSchool />
          </RequireAuthSchool>
        ),
        children: [{ index: true, element: <TableReviewLSK /> }]
      },
      // Danh sách sáng kiến của các trường thuộc pgd Quận 3
      {
        path: RoutePath.LIST_ACHIEVAMENT_SCHOOL_PGDQ3,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListAchievementSchoolPgdQ3 />
          </RequireAuthSchool>
        ),
        children: [{ index: true, element: <TableReviewLSKPgdQ3 /> }]
      },
      {
        path: RoutePath.INITIATIVE_DECISION_LEVEL_SCHOOL,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <DecisionLevel />
          </RequireAuthSchool>
        ),
        children: [{ index: true, element: <InitiativeDecisionLevelSchool /> }]
      },
      {
        element: (
          <RequireAuthSchoolSo role={Role.SCHOOL}>
            <CompetitionList />
          </RequireAuthSchoolSo>
        ),
        children: [
          {
            path: RoutePath.LIST_PERSONAL_COMPETITION,
            element: <PersonalCompetitionList />
          },
          {
            path: RoutePath.LIST_COLLECTIVE_COMPETITION,
            element: <CollectiveCompetitionList />
          },
          {
            path: RoutePath.LIST_COLLECTIVE_COMPETITION_ID,
            element: <CollectiveCompetitionList />
          },
          {
            path: RoutePath.EMULATION_DECIDE,
            element: <EmulationDecision />
          }
        ]
      },
      // trường pgd quận 3
      {
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionListPgdq3 />
          </RequireAuthSchool>
        ),
        children: [
          {
            path: RoutePath.LIST_PERSONAL_COMPETITION_PGDQ3,
            element: <PersonalCompetitionListPgdQ3 />
          },
          {
            path: RoutePath.LIST_COLLECTIVE_COMPETITION_PGDQ3,
            element: <CollectiveCompetitionListPgdQ3 />
          },
          {
            path: RoutePath.LIST_COLLECTIVE_COMPETITION_PGD_ID,
            element: <CollectiveCompetitionListPgdQ3 />
          }
        ]
      },
      {
        path: RoutePath.RESULT_COMPETITION_END_YEAR_SCHOOL,
        index: true,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ResultCompetitionEndYearSchool />
          </RequireAuthSchool>
        )
      },
      {
        path: RoutePath.SUMMARY_COMPETITION_END_YEAR,
        index: true,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <SummaryComprtitionEndYear />
          </RequireAuthSchool>
        )
      },
      {
        path: RoutePath.DECENTRALIZED_MANAGEMENT,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <DecentralizedManagement />
          </RequireAuthSchool>
        )
      },
      {
        path: RoutePath.REVIEW_MANAGEMENT_DEPARTMENT,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionRatingDepartment />
          </RequireAuthSchool>
        ),
        children: [{ index: true, element: <ManagerRatingDepartment /> }]
      },
      {
        path: RoutePath.MANAGER_DECENTRALIZATION_EDU,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionRatingDepartment />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <ManagerDecentralizationDepartment /> },
          {
            path: RoutePath.LIST_SCHOOL_RANGTING_EDU,
            element: <ListSchoolRantingDepartment />
          }
        ]
      },
      {
        path: RoutePath.COMPETITION_REVIEW_DEPARTMENT,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionRatingDepartment />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <CompetitionReviewDepartment /> },
          {
            path: RoutePath.COMPETITION_REVIEW_DEPARTMENT_DETAIL,
            element: <ListCompetitionReviewDepartmentDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_ROLE_DEPARTMENT,
            element: <DetailEvaluationRoleDepartment />
          },
          {
            path: RoutePath.EDIT_EVALUATION_SCHOOL_DEPARTMENT,
            element: <EditEvaluationRoleDepartment />
          }
        ]
      },
      // {
      //   path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU,
      //   element: (
      //     <RequireAuthSchool role={Role.SCHOOL}>
      //       <CompetitionRatingDepartment />
      //     </RequireAuthSchool>
      //   ),
      //   children: [
      //     { index: true, element: <ListSchoolReviewRoleEdu /> },
      //     {
      //       path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU_DETAIL,
      //       element: <ListSchoolReviewRoleEduDetail />
      //     },
      //     {
      //       path: RoutePath.DETAIL_EVALUATION_ROLE_EDU,
      //       element: <DetailEvaluationRoleDepartment />
      //     },
      //     {
      //       path: RoutePath.EDIT_EVALUATION_SCHOOL,
      //       element: <EditEvaluationRoleDepartment />
      //     }
      //   ]
      // },
      // end  menu school

      //  menu sở
      {
        path: RoutePath.LIST_SCHOOL_SO,
        element: (
          <RequireAuthSo role={Role.SO}>
            <ListSchoolSo />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <TableReviewListSchoolSo /> },
          {
            path: RoutePath.LIST_SCHOOL_SO_ID,
            element: <ListTeacherSo />
          },
          {
            path: RoutePath.LIST_SCHOOL_SO_DETAIL_ID,
            element: <ListTeacherSoDetail />
          }
        ]
      },
      {
        path: RoutePath.LIST_INTITIATIVE_MARK_SO,
        element: (
          <RequireAuthSo role={Role.SO}>
            <ListAchievementSo />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ListAchievementSo /> },
          {
            path: RoutePath.LIST_ACHIEVAMENT_SO,
            element: <TableReviewLSKSo />
          },
          {
            path: RoutePath.LIST_ACHIEVAMENT_SO_DETAIL,
            element: <TableReviewLSKSoDetail />
          },
          {
            path: RoutePath.LIST_ACHIEVAMENT_MARK_SO_DETAIL,
            element: <TableReviewChamLSKSoDetail />
          },
          {
            path: RoutePath.MARK_INTITIATIVE_DEPARTMENT,
            element: <TableReviewChamLSKSo />
          },
          {
            path: RoutePath.DECENTRALIZED_MANAGEMENT_SO,
            element: <DecentralizedManagemenSo />
          }
        ]
      },
      {
        element: (
          <RequireAuthSo role={Role.SO}>
            <BrowseTheCompetitionList />
          </RequireAuthSo>
        ),
        children: [
          // { index: true, element: <DefaulList /> },
          {
            path: RoutePath.LIST_PERSONAL_COMPETITION_BY_SCHOOL,
            element: <PersonalCompetitionListBySchool />
          },
          {
            path: RoutePath.LIST_LIST_COLLECTIVE_COMPETITION_BY_SCHOOL,
            element: <CollectiveCompetitionListBySchool />
          }
        ]
      },
      {
        path: RoutePath.RESULT_COMPETITION_END_YEAR,
        index: true,
        element: (
          <RequireAuthSo role={Role.SO}>
            <ResultCompetitionEndYear />
          </RequireAuthSo>
        )
      },
      {
        path: RoutePath.MANAGER_REVIEW_ROLE_EDU,
        element: (
          <RequireAuthSo role={Role.SO}>
            <CompetitionRating />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ManagerReviewRoleEdu /> },
          {
            path: RoutePath.CREATE_REVIEW_ROLE_EDU,
            element: <CreateStandardRoleEdu />
          },
          {
            path: RoutePath.DETAIL_REVIEW_ROLE_EDU,
            element: <DetailReviewRoleEdu />
          },
          {
            path: RoutePath.CREATE_RATING_ROLE_EDU,
            element: <CreateRatingRoleEdu />
          }
        ]
      },
      {
        path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU,
        element: (
          <RequireAuthSo role={Role.SO}>
            <CompetitionRating />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ListSchoolReviewRoleEdu /> },
          {
            path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU_DETAIL,
            element: <ListSchoolReviewRoleEduDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_ROLE_EDU,
            element: <DetailEvaluationRoleEdu />
          },
          {
            path: RoutePath.EDIT_EVALUATION_SCHOOL,
            element: <EditEvaluationRoleEdu />
          }
        ]
      },
      {
        element: (
          <RequireAuthSo role={Role.SO}>
            <CompetitionRating />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ListSchoolReviewRoleEdu /> },
          {
            path: RoutePath.MANAGER_REVIEW_ROLE_EDU,
            element: <ManagerReviewRoleEdu />
          },
          {
            path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU,
            element: <ListSchoolReviewRoleEdu />
          },
          {
            path: RoutePath.LIST_SCHOOL_REVIEW_ROLE_EDU_DETAIL,
            element: <ListSchoolReviewRoleEduDetail />
          }

          // {
          //   path: RoutePath.EDIT_EVALUATION,
          //   element: <EditEvaluation />
          // }
        ]
      },
      {
        path: RoutePath.DECISION_LEVEL,
        element: (
          <RequireAuthSo role={Role.SO}>
            <DecisionLevel />
          </RequireAuthSo>
        ),
        children: [
          {
            path: RoutePath.LEVEL_OF_INDIVIDUAL_DECISION,
            element: <LevelOfIndividualDecision />
          },
          {
            path: RoutePath.COLLECTIVE_DECISION_LEVEL,
            element: <CollectiveDecisionLevel />
          },
          {
            path: RoutePath.INITIATIVE_DECISION_LEVEL,
            element: <InitiativeDecisionLevel />
          },
          {
            path: RoutePath.INITIATIVE_DECISION_LEVEL_DEPARTMENT,
            element: <InitiativeDecisionLevelDepartment />
          }
        ]
      },
      {
        path: RoutePath.MANAGER_RATING_SO,
        element: (
          <RequireAuthSo role={Role.SO}>
            <CompetitionRatingSo />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ManagerRatingSo /> },
          {
            path: RoutePath.COMPETITION_REVIEW_SO_DETAIL,
            element: <ListCompetitionReviewSoDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_ROLE_SO,
            element: <DetailEvaluationRoleEdu />
          }
        ]
      },
      {
        path: RoutePath.MANAGER_DECENTRALIZATION_SO,
        element: (
          <RequireAuthSo role={Role.SO}>
            <CompetitionRatingSo />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <ManagerDecentralizationSo /> },
          {
            path: RoutePath.LIST_SCHOOL_RANGTING_SO,
            element: <ListSchoolRantingSo />
          }
        ]
      },
      // {
      //   path: RoutePath.COMPETITION_REVIEW_SO,
      //   element: (
      //     <RequireAuthSo role={Role.SO}>
      //       <CompetitionRatingSo />
      //     </RequireAuthSo>
      //   ),
      //   children: [
      //     { index: true, element: <CompetitionReviewSo /> },
      //     {
      //       path: RoutePath.COMPETITION_REVIEW_SO_DETAIL,
      //       element: <ListCompetitionReviewSoDetail />
      //     },
      //     {
      //       path: RoutePath.DETAIL_EVALUATION_ROLE_SO,
      //       element: <DetailEvaluationRoleEdu />
      //     }
      //   ]
      // },
      {
        path: RoutePath.LIST_AGENCIES_SO,
        element: <ListAgenciesSo />
      },
      // end  menu sở
      {
        element: <Library />,
        children: [
          {
            path: RoutePath.DECIDE_TO_COMPENSATE,
            element: <DepartmentOfEducation />
          },
          {
            path: RoutePath.LIST_DOCUMENT,
            element: <Documents />
          }
        ]
        // element: <DepartmentOfEducation />
      },
      {
        path: RoutePath.SUPPORT,
        element: <Support />
      },
      {
        path: RoutePath.PRO_FILE_USER,
        element: <Profile />
      },
      {
        path: RoutePath.PRO_FILE_USER_Q3,
        element: <ProfilePGD />
      },
      {
        path: RoutePath.EMULATION_TITLE,
        element: <EmulationTitle />
      },
      {
        path: RoutePath.EMULATION_TITLE_PGD_Q3,
        element: <EmulationTitlePgdQ3 />
      },
      {
        path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO,
        element: (
          <RequireAuthSo role={Role.SO}>
            <SummaryCompetitionYear />
          </RequireAuthSo>
        ),
        children: [
          { index: true, element: <SummaryCompetitionYearPersonal /> },
          {
            path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_DETAIL,
            element: <SummaryCompetitionYearPersonalDetail />
          }
          // {
          //   path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_PERSONAL,
          //   element: <SummaryCompetitionYearPersonal />
          // },
          // {
          //   path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_PERSONAL_DETAIL,
          //   element: <SummaryCompetitionYearPersonalDetail />
          // },
          // {
          //   path: RoutePath.SUMMARY_COMPETITION_END_YEAR_SO_COLLECTIVE,
          //   element: <SummaryCompetitionYearCollective />
          // }
        ]
      },
      // router pgd quận 3
      {
        path: RoutePath.INTITIATIVE_ACHIEVAMENT_Q3,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <InitiativeAchievementPgdQ3 />
          </RequireAuthTeacher>
        ),
        children: [
          //{ index: true, element: <InitiativeAchievement /> },
          { index: true, element: <TableReviewTTSKPgdQ3 /> }
          // {
          //   path: RoutePath.DETAIL_REVIEW,
          //   element: <DetailReview />
          // },
          // {
          //   path: RoutePath.CREATE_RATING,
          //   element: <CreateRating />
          // },
          // {
          //   path: RoutePath.CREATE_STANDARD,
          //   element: <CreateStandard />
          // }
        ]
      },
      {
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <BrowseTheCompetitionListPgdQ3 />
          </RequireAuthSchool>
        ),
        children: [
          // { index: true, element: <DefaulList /> },
          {
            path: RoutePath.LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3,
            element: <PersonalCompetitionListBySchoolPgdQ3 />
          },
          {
            path: RoutePath.NEW_LIST_PERSONAL_COMPETITION_SCHOOL_PGGQ3,
            element: <NewPersonalCompetitionListBySchoolPgdQ3 />
          },
          {
            path: RoutePath.LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3,
            element: <CollectiveCompetitionListBySchoolPgdQ3 />
          },
          {
            path: RoutePath.NEW_LIST_LIST_COLLECTIVE_COMPETITION_SCHOOL_PGDQ3,
            element: <NewCollectiveCompetitionListBySchoolPgdQ3 />
          }
        ]
      },
      {
        path: RoutePath.LIST_SCHOOL_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListSchoolPgdQ3 />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <TableReviewListSchoolPgdQ3 /> },
          {
            path: RoutePath.LIST_SCHOOL_PGD_ID,
            element: <ListTeacherPgdQ3 />
          },
          {
            path: RoutePath.LIST_SCHOOL_PGD_DETAIL_ID,
            element: <ListTeacherPgdQ3Detail />
          }
        ]
      },
      {
        path: RoutePath.LIST_INTITIATIVE_MARK_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListAchievementPgd />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <ListAchievementPgd /> },
          {
            path: RoutePath.LIST_ACHIEVAMENT_PGD,
            element: <TableReviewLSKPgd />
          },
          {
            path: RoutePath.LIST_ACHIEVAMENT_PGD_DETAIL,
            element: <TableReviewLSKPgdDetail />
          }
          // {
          //   path: RoutePath.LIST_ACHIEVAMENT_MARK_SO_DETAIL,
          //   element: <TableReviewChamLSKSoDetail />
          // },
          // {
          //   path: RoutePath.MARK_INTITIATIVE_DEPARTMENT,
          //   element: <TableReviewChamLSKSo />
          // },
          // {
          //   path: RoutePath.DECENTRALIZED_MANAGEMENT_SO,
          //   element: <DecentralizedManagemenSo />
          // }
        ]
      },
      {
        path: RoutePath.MANAGER_RATING_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionRatingPgd />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <ManagerRatingPgd /> },
          {
            path: RoutePath.CREATE_REVIEW_ROLE_PGD,
            element: <CreateStandardRolePgd />
          },
          {
            path: RoutePath.DETAIL_REVIEW_ROLE_PGD,
            element: <DetailReviewRolePgd />
          },
          {
            path: RoutePath.CREATE_RATING_ROLE_PGD,
            element: <CreateRatingRolePgd />
          },
          {
            path: RoutePath.COMPETITION_REVIEW_PGD_DETAIL,
            element: <ListCompetitionReviewPgdDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_ROLE_PGD,
            element: <DetailEvaluationRolePgd />
          }
          // {
          //   path: RoutePath.MANAGER_DECENTRALIZATION_PGD,
          //   element: <ManagerDecentralizationPgd />
          // }
        ]
      },
      {
        path: RoutePath.MANAGER_DECENTRALIZATION_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <CompetitionRatingPgd />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <ManagerDecentralizationPgd /> },
          {
            path: RoutePath.LIST_SCHOOL_RANGTING_PGD,
            element: <ListCompetitionReviewPgdDetail />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_RANGTING_PGD,
            element: <DetailEvaluationRolePgd />
          }
        ]
      },
      {
        path: RoutePath.COMPETITION_REVIEW_EXPERT_PGD,
        element: (
          <RequireAuthTeacher role={Role.TEACHER}>
            <CompetitionRatingPgd />
          </RequireAuthTeacher>
        ),
        children: [
          { index: true, element: <CompetitionReviewPgd /> },
          {
            path: RoutePath.COMPETITION_REVIEW_EXPERT_DETAIL_PGD,
            element: <ListCompetitionReviewPgdDetailForCV />
          },
          {
            path: RoutePath.DETAIL_EVALUATION_EXPERT_PGD,
            element: <DetailEvaluationRolePgd />
          },
          {
            path: RoutePath.EDIT_EVALUATION_EXPERT_PGD,
            element: <EditEvaluationRolePgd />
          }
          // {
          //   path: RoutePath.EDIT_EVALUATION_EXPERT_GIVE_A_RE_SCORING,
          //   element: <GiveAReScoring />
          // }
        ]
      },
      {
        path: RoutePath.MANAGER_SELF_ASSESSMENT_SCHOOL_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ManagerSelfAssessmentSchoolPgd />
          </RequireAuthSchool>
        ),
        children: [
          { index: true, element: <SelfAssessmentListSchoolPgd /> },
          {
            path: RoutePath.SELF_ASSESSMENT_DETAIL_SCHOOL_PGD,
            element: <SelfAssessmentDetailsSchoolPgd />
          }
        ]
      },
      {
        path: RoutePath.LIST_OF_EVALUATION_RESULTS_SCHOOL_PGD,
        element: (
          <RequireAuthSchool role={Role.SCHOOL}>
            <ListOfEvaluationResultsSchoolPgd />
          </RequireAuthSchool>
        )
        // children: [
        //   { index: true, element: <SelfAssessmentList /> },
        //   {
        //     path: RoutePath.SELF_ASSESSMENT_DETAIL,
        //     element: <SelfAssessmentDetails />
        //   }
        // ]
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
    // children: [{ index: true, element: <ManagerReview /> }]
  }
];
