import { Button, Card, Modal, Select, Tag } from 'antd';
import { useToast } from 'components/Toast';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectListPeriod, selectListCoursesBase } from 'store/common/shared/selectors';
import { RESPONSE_MESSAGE } from 'store/createReview/constants/createReview.constants';
import { CreateReviewHttp } from 'store/createReview/services/createReview.http';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { DownOutlined } from '@ant-design/icons';
import { YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

const StyleSelectPeriod: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  &:hover > div {
    cursor: pointer !important;
  }
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
    
}
` as any;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

interface IModal {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
}

const { Option } = Select;
function ModalCreateReview({ isOpen, title, handleCancel, handleRefresh }: IModal) {
  const toast = useToast();
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listPeriod: any = useSelector(selectListPeriod);
  const [yearSchool, setYearSchool] = useState<number>(YEAR_ID_DEFAULT_PGDQ3);
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<any[]>([]);
  const navigate = useNavigate();
  const localStorage = new LocalStorageService();

  const createReviewHttp = new CreateReviewHttp();

  const onSelectYear = (value: number): void => {
    setYearSchool(value);
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitleReview((e.target as HTMLInputElement).value);
  };

  const handleSubmit = () => {
    if (titleReview === '' || !yearSchool || period.length === 0) {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_REVIEW_SUCCESS);
      return;
    }
    const request = {
      title: titleReview,
      courseId: yearSchool,
      periodIds: period
    };
    createReviewHttp.addReview(request).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ADD_REVIEW_SUCCESS);
        handleCancel();
        handleRefresh();
        navigate(`/manager-review/create-standard/${res.data.data.evaluation.id}/${0}`);
        localStorage.setItem({ key: LocalStorageKey.isVisible, value: 'show' });
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.ADD_RATING_FAIL);
      }
    });
  };

  const handleChange = (value: string[]) => {
    setPeriod(value);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };
  return (
    <Modal
      title="Thêm đánh giá mới"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button
          key="submit"
          size="large"
          type="primary"
          onClick={handleSubmit}
          disabled={yearSchool !== YEAR_ID_DEFAULT_PGDQ3}>
          Thêm mới
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên đánh giá
            </label>
            <input
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          <div className="grid sm:grid-2 gap-15 sm:gap-20">
            <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Năm học
              </label>
              <div className="relative">
                <StyleSelectCourse
                  style={{ width: 350, height: 40 }}
                  placeholder="Chọn năm học"
                  defaultValue={yearSchool}
                  onChange={onSelectYear}>
                  {!_.isEmpty(listCourseBase) &&
                    listCourseBase.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.description}
                      </Option>
                    ))}
                </StyleSelectCourse>
              </div>
            </div>
            <div>
              <label className="font-bold mb-10 block">Quý</label>
              <div className="relative">
                {/* <SelectMuti listOption={listPeriod?.periods} />
                <StyleSelectPeriod
                  value={period}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelectPeriod(e)}>
                  <option value="">-- Chọn --</option>
                  {!_.isEmpty(listPeriod.periods) &&
                    listPeriod.periods.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                </StyleSelectPeriod> */}
                <StyleSelectPeriod
                  mode="multiple"
                  style={{
                    width: 350,
                    height: 40,
                    position: 'relative'
                  }}
                  tagRender={tagRender}
                  placeholder="Chọn quý"
                  onChange={handleChange}
                  // value={period}
                  dropdownStyle={{ width: '350' }}>
                  {!_.isEmpty(listPeriod.periods) &&
                    listPeriod.periods.map((item) => (
                      <Option value={item.id} key={item.id} title={item.title}>
                        {item.title}
                      </Option>
                    ))}
                </StyleSelectPeriod>
                <IconDropdown>
                  <DownOutlined />
                </IconDropdown>
                {/* <label htmlFor="id" className="absolute top-2/4 right-15 translate-y--2/4">
                  <i className="fas fa-caret-down" />
                </label> */}
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalCreateReview;
