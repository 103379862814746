import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface SelfAssessmentState {
  loading?: boolean;
  error?: any;
  success?: any;
  dataGetApi?: any;
  dataGetApiSchool?: any;
  dataDetail?: any;
  dataDetailSchool?: any;
}

export const initialState: SelfAssessmentState = {
  loading: false,
  error: {},
  success: {},
  dataGetApi: {},
  dataDetail: {},
  dataGetApiSchool: {},
  dataDetailSchool: {}
};

const SelfAssessmentSlice = createSlice({
  name: 'selfAssessment',
  initialState,
  reducers: {
    getApi(state, action) {
      state.loading = true;
      state.error = null;
    },
    getApiSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataGetApi = action.payload;
    },
    getApiFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDetailApi(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDetailApiSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataDetail = action.payload;
    },
    getDetailApiFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getApiSchool(state, action) {
      state.loading = true;
      state.error = null;
    },
    getApiSchoolSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataGetApiSchool = action.payload;
    },
    getApiSchoolFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getDetailApiSchool(state, action) {
      state.loading = true;
      state.error = null;
    },
    getDetailApiSchoolSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
      state.dataDetailSchool = action.payload;
    },
    getDetailApiSchoolFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = SelfAssessmentSlice;
