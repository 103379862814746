import { AppHelper } from './app.helpers';
class UploadFile {
  static getIdFile = (file: any, urlImage) => {
    let id: string = urlImage;
    let idFile: string = file.name.replace(file.name.substring(0, file.name.lastIndexOf('.')), id);
    return idFile;
  };

  static getIdFileNoDetail = (file: any, user) => {
    let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    let id: string = `danhgia/${user?.user?.id}/${AppHelper.generateUUIDV4()}/${fileName}`;
    let idFile: string = file.name.replace(file.name.substring(0, file.name.lastIndexOf('.')), id);
    return idFile;
  };

  static uploadFileImgS3 = async (dataUrl: string, files) => {
    // let keyFile: any = Object.keys(dataUrl)?.reduce((key) => {
    //   let string = dataUrl[key];
    //   return string;
    // });
    // let url: any = Object.values(dataUrl)?.reduce((value) => {
    //   let string = value;
    //   return string;
    // });
    // if (files) {
    //   files.map(async (item: any, idx: number) => {
    //     if (item.key === keyFile) {
    //       return await fetch(url, {
    //         method: 'PUT',
    //         body: item.fileContents
    //       }).then((res) => {});
    //     }
    //   });
    // }
    let res;
    if (files) {
      let urls: string = '';
      let fileContents;
      await files.forEach((item) => {
        if (dataUrl[item['key']] !== undefined) {
          urls = dataUrl[item['key']];
          fileContents = item.fileContents;
          return;
        }
      });
      if (urls !== '') {
        res = await fetch(urls, {
          method: 'PUT',
          body: fileContents
        })
          .then((response) => {
            if (response.ok) {
              return response;
            } else {
              res = undefined;
            }
          })
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            res = undefined;
            console.log(error);
          });
      }
    }
    return res;
  };
}

export default UploadFile;
