import AppLoading from 'components/Loading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import * as FromCreateInitiativeSlice from '../../../store/initiativeAchievement/shared/slice';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import { useToast } from 'components/Toast';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { Space, Tooltip, Card, Select, Input } from 'antd';
import Pagination from 'components/Pagination';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as common from '../../../store/common/shared/slice';
import _ from 'lodash';
import {
  selectListCourses,
  selectListPeriod,
  selectListCoursesBase,
  selectListSchools
} from 'store/common/shared/selectors';
import ModalCreateReview from '../components/ModalCreateReview';
import ModalCreateReviewDKSK from '../components/ModalCreateReviewDKSK';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import Swal from 'sweetalert2';

import styled from 'styled-components';
import ModalDetail from '../components/ModalDetail';
import ModalAllow from '../components/ModalAllow';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ButtonSubmit from 'components/ButtonSubmit';
import { Helmet } from 'react-helmet-async';
import { CommonSaga } from 'store/common/shared/saga';
import useAuth from 'hooks/useAuth';
import ButtonCreate from 'components/ButtonCreate';
import { TITLE_NAVBAR, YEAR_ID_DEFAULT } from '_constants/default.constants';
import { ModalViewDecisionNo } from 'views/ListAchievementSchool/components/ModalViewDecisionNo';
import ModalViewFile from '../components/ModalViewFile';
import { CSVLink } from 'react-csv';
import { NavBar } from 'components/NavBar';
import ModalUploadFile from '../components/ModalUploadFile';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
const divbtn = styled.div`
  width: auto;
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerSelect: typeof Select = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    padding-top: 4px;
  }
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;
const { Option } = Select;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TableReviewChamLSKSoDetail() {
  const { id } = useParams();
  const location = useLocation();
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  const dispatch = useDispatch();
  const query = useQuery();

  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const listReview: any = useSelector(selectListReview);
  const loading: any = useSelector(selectLoading);

  const [isModalConfirmDel, setIsModalConfirmDel] = useState<boolean>(false);
  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const toast = useToast();
  const [isCreateReview, setIsCreateReview] = useState<boolean>(false);
  const [isCreateReviewDKSK, setIsCreateReviewDKSK] = useState<boolean>(false);
  const [isOpenClone, setIsOpenClone] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenAllow, setIsOpenAllow] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isCreateInitiative, setIsCreateInitiative] = useState<boolean>(false);
  const [isCreateInitiativeDKSK, setIsCreateInitiativeDKSK] = useState<boolean>(false);
  const [exportExcell, setExportExcel] = useState<string>('');
  const [loading1, setLoading1] = useState<boolean>(false);

  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listSchool: any = useSelector(selectListSchools);

  const createReviewHttp = new InitiativeAchievementHttp();
  const navigate = useNavigate();

  const [schoolId, setSchoolId] = useState<string>('0');
  const [isOpenViewDecisionNo, setIsOpenViewDecisionNo] = useState<boolean>(false);
  const [isOpenViewFile, setIsOpenViewFile] = useState<boolean>(false);
  const [dataViewFile, setDataViewFile] = useState<any>();
  const [valueDecisionNo, setValueDecisionNo] = useState<any>();
  const [dataExportCsv, setDataExportCsv] = useState<any>([]);
  const [type, setType] = useState<number>(1);
  const [titleDecisionNo, setTitleDecisionNo] = useState<string>('');
  const [score, setScore] = useState<string>('0');
  const [yearSchoolSo, setYearSchoolSo] = useState<number>(12);
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<any[]>([]);
  const [school, setSchool] = useState<any[]>([]);
  const { filters, setFilters } = useAuth();
  const [detail, setDetail] = useState<string>('');
  const [isOpenUploadFile, setIsOpenUploadFile] = useState<boolean>(false);
  const [filterData, setFilterData] = useState({
    school_id: id,
    key_search: '',
    provinceId: 1,
    districtId: 0,
    schoolId: 0,
    courseId: YEAR_ID_DEFAULT,
    perPage: 10,
    pageId: 1
  });
  const [filterSchool, setFilterSchool] = useState({
    provinceId: 1,
    districtId: 0,
    pageId: -1,
    perPage: -1
  });

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffect(() => {
    var excel = 'excel?school_id=' + filterData.school_id;
    setExportExcel(excel);
  }, []);

  useEffectOnMount(() => {
    dispatch(FromCreateInitiativeSlice.actions.getEvaluationInitiativesSo(filterData));
    dispatch(common.actions.getCoursesBase(filters));
  });

  const parserDataExportFile = () => {
    setLoading1(true);
    const listFilterData: any = [];
    let i = 1;
    if (!_.isEmpty(listReview)) {
      listReview?.map((item, index) => {
        if (item.status_cham_diem === 1 && item.status_cham_diem2 === 1) {
          const mediumScore = (item.score + item.score2) / 2;
          if (mediumScore >= 91 && item.is_tp) {
            const schoolName = listSchool.find((ls) => ls.id === item.school_id);
            let arena = '';
            if (item.is_tp && item.is_coso) {
              arena = 'Cấp Quận, Thành phố';
            } else {
              arena = 'Thành phố';
            }
            const itm = {
              index: i++,
              id: item.id,
              name: item.name,
              user_fullname: item.user_fullname,
              position_title: item.position_title,
              school_name: schoolName.name,
              description: `1.Tóm tắt sáng kiến: ${item.description.replace(
                /"/g,
                ''
              )}\n2.Hiệu quả: ${item.effective.replace(/"/g, '')}\n3.Phạm vi ảnh hưởng: ${arena}`
            };
            listFilterData.push(itm);
          }
        }
      });
      console.log(listFilterData);
      setDataExportCsv(listFilterData);
      setTimeout(() => {
        setLoading1(false);
        csvInstance?.current?.link.click();
      }, 200);
    }
  };

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const onClickAllow = (id: string, name: string) => {
    setIdReview(id);
    setFullname(name);
    setIsOpenAllow(!isOpenAllow);
  };

  const onClickDetail = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenDetail(!isOpenDetail);
  };

  const onClickFile = (item) => {
    console.log(item);
    setIsOpenViewFile(true);
    setDataViewFile(item);
  };

  const handleSendToSo = () => {
    if (yearSchoolSo === null) {
      toast?.pushWarning('Năm học không tồn tại. Vui lòng chọn năm học.');
      return false;
    }
    const request = {
      courseId: yearSchoolSo,
      ma_cong_chuc: '',
      id_num: '',
      name: '',
      user_fullname: '',
      action: 'dang_ky', // dang_ky hoặc huy_dang_ky
      page_id: -1,
      per_page: -1
    };
    createReviewHttp.sendInitiativesToSo(request).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Gửi danh sách sáng kiến thành công.');
      } else {
        toast?.pushError('Gửi danh sách sáng kiến không thành công.');
      }
      setIsModalConfirm(!isModalConfirm);
    });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filterData.perPage) return true;
      return false;
    }
    return true;
  }, [listReview]);

  const handleRefresh = () => {
    dispatch(FromCreateInitiativeSlice.actions.getEvaluationInitiativesSo(filterData));
  };

  const onChangeValue = (e) => {
    // const newFilter = Object.assign({}, filterData, {
    //   key_search: e.target.value
    // });
    // setNewFilterData(newFilter);
    setTitleReview(e.target.value);
  };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleSearch = () => {
    // setLoading1(true);
    const newFilter = Object.assign({}, filterData, {
      key_search: titleReview,
      perPage: filterData.perPage,
      pageId: filterData.pageId
    });
    setNewFilterData(newFilter);
  };

  const handleExportFile = () => {
    if (_.isEmpty(listReview)) {
      toast?.pushWarning('Trường chưa có sáng kiến để xuất file!');
      return;
    }
    createReviewHttp.exportFile(id).then((res) => {
      if (res.status === 200) {
        const url = res.config.baseURL + res.config.url;
        let a = document.createElement('a');
        a.target = '_self';
        a.href = url;
        a.click();
      }
    });
  };

  const onChangeOption = (e) => {
    const newFilter = Object.assign({}, filterData, {
      courseId: e
    });
    dispatch(FromCreateInitiativeSlice.actions.getEvaluationInitiativesSo(newFilter));
    setYearSchoolSo(e);
  };

  const headersExel = [
    { label: 'STT', key: 'index' },
    { label: 'TÊN SÁNG KIẾN', key: 'name' },
    { label: 'TÁC GIẢ', key: 'user_fullname' },
    { label: 'CHỨC VỤ', key: 'position_title' },
    { label: 'ĐƠN VỊ', key: 'school_name' },
    { label: 'TÓM TẮT SÁNG KIẾN, HIỆU QUẢ, PHẠM VI ẢNH HƯỞNG', key: 'description' }
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Mã giáo viên',
      dataIndex: 'ma_cong_chuc',
      key: 'ma_cong_chuc',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user_fullname',
      key: 'user_fullname',
      width: 180,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      // onCell: (record, rowIndex) => {
      //   return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      // },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Phạm vi ảnh hưởng',
      key: 'effect_scope_id',
      width: 150,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record, text) => {
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'Cấp Quận';
          } else if (mediumScore >= 91 && record.is_coso && record.is_tp) {
            scope = 'Cấp Quận, Thành phố';
          } else if (mediumScore >= 91 && record.is_coso) {
            scope = 'Cấp Quận';
          } else if (mediumScore >= 91) {
            scope = 'Thành phố';
          } else {
            scope = 'Khác';
          }
        } else {
          if (record.is_coso && record.is_tp) {
            scope = 'cấp Quận, Thành phố';
          } else if (record.is_tp) {
            scope = 'Thành phố';
          } else if (record.is_coso) {
            scope = 'cấp Quận';
          } else {
            scope = '--.--';
          }
        }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    {
      title: 'Điểm',
      key: 'score',
      //width: '10%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff', textAlign: 'center' }
        };
      },
      children: [
        {
          title: 'Giám khảo 1',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (record.course_id === YEAR_ID_DEFAULT && record.status_cham_diem === 0) {
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            }
            return (
              <span style={{ color: '#1890ff' }}>
                {record.status_cham_diem === 1 ? record.score : '--.--'}
              </span>
            );
          }
        },
        {
          title: 'Giám khảo 2',
          key: 'score',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (record.course_id === YEAR_ID_DEFAULT && record.status_cham_diem2 === 0) {
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            }
            return (
              <span style={{ color: '#1890ff' }}>
                {record.status_cham_diem2 === 1 ? record.score2 : '--.--'}
              </span>
            );
          }
        },
        {
          title: 'Điểm TB',
          key: 'score',
          //width: '10%',
          align: 'center',
          onHeaderCell: (record) => {
            return {
              record,
              style: { background: '#91d5ff' }
            };
          },
          render: (record) => {
            if (
              record.course_id === YEAR_ID_DEFAULT &&
              record.status_cham_diem === 0 &&
              record.status_cham_diem2 === 0
            ) {
              return <span style={{ color: '#1890ff' }}>--.--</span>;
            }
            return (
              <span style={{ color: '#1890ff' }}>
                {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
                  ? (record.score + record.score2) / 2
                  : '--.--'}
              </span>
            );
          }
        }
      ]
    },
    {
      title: 'Xếp loại',
      key: 'effect_scope_id',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // if (record.course_id === YEAR_ID_DEFAULT) {
        //   return <span style={{ color: '#1890ff' }}>--.--</span>;
        // }
        let scope = '';
        if (record.status_cham_diem === 1 && record.status_cham_diem2 === 1) {
          const mediumScore = (record.score + record.score2) / 2;
          if (mediumScore >= 81 && mediumScore < 91) {
            scope = 'Tốt';
          } else if (mediumScore >= 91) {
            scope = 'Xuất sắc';
          } else {
            scope = '--.--';
          }
        } else {
          scope = '--.--';
        }
        return <span style={{ color: '#1890ff' }}>{scope}</span>;
      }
    },
    // {
    //   title: 'Công nhận sáng kiến',
    //   key: 'school_decision_no',
    //   width: 130,
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     if (
    //       record.decision_school_id === '00000000-0000-0000-0000-000000000000' ||
    //       _.isEmpty(record.decision_school_id)
    //     ) {
    //       return <span style={{ color: '#1890ff' }}>--.--</span>;
    //     }
    //     return (
    //       <span
    //         style={{ color: '#1890ff' }}
    //         className="cursor"
    //         onClick={() => handleOpenDecisionSchool(record)}>
    //         Xem thêm
    //       </span>
    //     );
    //   }
    // },
    {
      title: 'Công nhận Phạm vi ảnh hưởng của sáng kiến',
      key: 'decision_no',
      width: 130,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (
          record.decision_id === '00000000-0000-0000-0000-000000000000' ||
          _.isEmpty(record.decision_id)
        ) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return (
          <span
            style={{ color: '#1890ff' }}
            className="cursor"
            onClick={() => handleOpenDecisionDoe(record)}>
            Xem thêm
          </span>
        );
      }
    },
    {
      title: 'Hành động',
      width: '10%',
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        // setLoading1(false);
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <span onClick={() => onClickDetail(record.id, record.name)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-eye"></i>
                </div>
              </span>
            </Tooltip>
            <Tooltip title="Thêm file chứng nhận" placement="bottomLeft">
              <span className="clone" onClick={() => handleUploadFiles(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-upload"></i>
                </div>
              </span>
            </Tooltip>
            {/* <Tooltip title="Xem file chứng nhận" placement="bottomLeft">
              <span onClick={() => onClickFile(record)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-file"></i>
                </div>
              </span>
            </Tooltip> */}
            {/* {_.isEmpty(record.decision_no) ? (
              <Tooltip title="Công nhận sáng kiến" placement="bottomLeft">
                <span onClick={() => onClickAllow(record.id, record.user_fullname)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    <i className="fa fa-check-circle"></i>
                  </div>
                </span>
              </Tooltip>
            ) : (
              ''
            )} */}
          </Space>
        );
      }
    }
  ];

  const handleUploadFiles = (item) => {
    setDetail(item);
    setIsOpenUploadFile(true);
  };

  const handleOpenDecisionSchool = (record) => {
    setValueDecisionNo(record);
    setTitleDecisionNo('Công nhận sáng kiến');
    setIsOpenViewDecisionNo(true);
    setType(1);
  };

  const handleOpenDecisionDoe = (record) => {
    setTitleDecisionNo('Công nhận Phạm vi ảnh hưởng của sáng kiến');
    setValueDecisionNo(record);
    setIsOpenViewDecisionNo(true);
    setType(2);
  };

  const goBack = () => {
    navigate(`/list-initiative-mark-so/mark-initiative-department`);
  };

  return (
    <div className="pb-0">
      {loading1 && <AppLoading loading={true} />}
      {loading && <AppLoading loading={true} />}
      <Helmet>
        <title>Danh sách sáng kiến chi tiết</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      <NavBar title="Danh sách sáng kiến chi tiết" />
      <div className="px-15">
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-1 gap-15 sm:gap-10">
              {/* <div className="md:span-3">
                <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
                  <i className="fas fa-home" />
                </div>
                <span>Danh sách giáo viên nộp sáng kiến</span>
              </div> */}
              <div className="grid sm:grid-6 md:span-6 gap-15 sm:gap-10">
                <div className="md:span-1">
                  <Select
                    placeholder="Chọn năm học"
                    style={{
                      width: '100%'
                    }}
                    onChange={($event) => onChangeOption($event)}
                    value={yearSchoolSo}
                    options={
                      !_.isEmpty(listCourseBase) &&
                      listCourseBase.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })
                    }
                  />
                  {/* <StyleSelectCourse
                    style={{
                      width: '100%'
                    }}
                    placeholder="Chọn năm học"
                    onChange={onChangeOption}
                    value={yearSchool}>
                    {!_.isEmpty(listCourseBase) &&
                      _.orderBy(listCourseBase, ['name'], ['desc']).map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.description}
                        </Option>
                      ))}
                  </StyleSelectCourse> */}
                </div>
                <div className="md:span-2">
                  <Input.Search
                    allowClear
                    placeholder="Mã giáo viên, tên giáo, tên sáng kiến"
                    onChange={(e) => onChangeValue(e)}
                    onPressEnter={handleSearch}
                    onSearch={handleSearch}
                    enterButton
                  />
                  {/* <input
                    name="keyword"
                    id="keyword"
                    type="text"
                    className="w-full h-40 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                    placeholder="Mã giáo viên, tên giáo, tên sáng kiến"
                    onChange={onChangeValue}
                  /> */}
                </div>
                {/* <ButtonCreate text="Tìm kiếm" handleClick={handleSearch} /> */}
                {/* <div className="md:span-1">
                  <ButtonCreate
                    style={{ width: '100%' }}
                    text="Xuất file"
                    handleClick={handleExportFile}
                  />
                </div>
                <div className="md:span-2">
                  <ButtonCreate
                    style={{ width: '100%' }}
                    icon="fa fa-download"
                    text="Xuất file danh sách sáng kiến cấp TP"
                    handleClick={parserDataExportFile}
                  />
                  <CSVLink
                    ref={csvInstance}
                    filename={'danh-sach-sang-kien.csv'}
                    style={{ display: 'none' }}
                    data={dataExportCsv}
                    headers={headersExel}
                  />
                </div> */}
              </div>
            </div>
          </Card>
        </ContainerHeader>

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                dataSource={!_.isEmpty(listReview) && listReview}
                size="middle"
                title={(currentPageData) => {
                  return (
                    <ButtonCreate
                      icon="fa-arrow-left"
                      text="Quay lại"
                      handleClick={goBack}
                      style={{ height: '35px' }}
                    />
                  );
                }}
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
          {/* <ButtonCreate
            icon="fa-arrow-left"
            text="Quay lại"
            handleClick={goBack}
            style={{ height: '35px', marginTop: 10 }}
          /> */}
        </div>
        <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isModalConfirm && (
        <ModalConfirmBasic
          isOpen={isModalConfirm}
          handleOk={handleSendToSo}
          handleCancel={() => setIsModalConfirm(!isModalConfirm)}
          titleName={'Thầy/Cô có đồng ý gửi danh sách sáng kiến về Sở GD&ĐT TP.HCM?'}
        />
      )}

      {isCreateInitiative && (
        <ModalCreateReview
          isOpen={isCreateInitiative}
          handleCancel={() => setIsCreateInitiative(!isCreateInitiative)}
          handleRefresh={handleRefresh}
        />
      )}
      {isCreateInitiativeDKSK && (
        <ModalCreateReviewDKSK
          isOpen={isCreateInitiativeDKSK}
          handleCancel={() => setIsCreateInitiativeDKSK(!isCreateInitiativeDKSK)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenAllow && (
        <ModalAllow
          isOpen={isOpenAllow}
          title={fullname}
          handleCancel={() => setIsOpenAllow(!isOpenAllow)}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenViewDecisionNo && (
        <ModalViewDecisionNo
          isOpen={isOpenViewDecisionNo}
          detail={valueDecisionNo}
          title={titleDecisionNo}
          type={type}
          handleCancel={() => setIsOpenViewDecisionNo(!isOpenViewDecisionNo)}
        />
      )}
      {isOpenViewFile && (
        <ModalViewFile
          isOpen={isOpenViewFile}
          detail={dataViewFile}
          handleCancel={() => setIsOpenViewFile(!isOpenViewFile)}
        />
      )}
      {isOpenUploadFile && (
        <ModalUploadFile
          isOpen={isOpenUploadFile}
          handleCancel={() => setIsOpenUploadFile(!isOpenUploadFile)}
          detail={detail}
          handleRefresh={handleRefresh}
        />
      )}
    </div>
  );
}
