import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectGradeId,
  selectListCourses,
  selectListPeriod,
  selectPeriodId,
  selectStatusId
} from 'store/common/shared/selectors';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import * as FromCommonSlice from '../store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import Logo from '../assets/images/logotdkt.png';
import LogoUser from '../assets/images/ava-user.png';
import banner_elerning from '../assets/images/banner_elerning.png';
import banner_elearning from '../assets/images/banner_elearning.jpg';
import LogoMini from '../assets/images/logo_so_mini.png';
import { dataStatusAdmin, dataStatusTeacher, groupSchool } from '_constants/default.constants';
import { Button, Popover, Select, Space, Typography } from 'antd';
import SelectCustomize from 'components/Select';
import styled from 'styled-components';
import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AppHelper } from 'utils/app.helpers';
import useAuth from 'hooks/useAuth';
import ChangePasswordUser from 'views/Profile/components/ChangePasswordUser';
import { useToast } from 'components/Toast';
import { selectError, selectLoading, selectSuccess } from 'store/user/shared/selectors';
import * as FromSliceUser from '../store/user/shared/slice';
import { RESPONSE_CONSTANT } from 'store/user/constants/user.constants';
import AppLoading from 'components/Loading';
import logolms360 from '../assets/images/logo-lms-co-nen.png';
import logokiemdinhwhite from '../assets/images/logokiemdinh.png';
import LogoTdktBKT from '../assets/images/logotdkt.png';
import LogoTdktQ3 from '../assets/images/logoq3.jpg';
import LogoTdktQ7 from '../assets/images/logoq7.jpg';
import LogoTdktTanPhu from '../assets/images/logoTanPhu.jpg';
import LogoTdktNhaBe from '../assets/images/logoNhabe.jpg';
import LogoTdktBinhTan from '../assets/images/logoBinhTan.png';
import LogoTdktCanGio from '../assets/images/logoCanGio.png';
import LogoTdktPhuNhuan from '../assets/images/logoPhunhuan.jpg';
import { selectDataUser } from 'store/user/shared/selectors';

export const StyleSelectStatus: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;

const StyleButton: typeof Button = styled(Button)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  width: 220px;
  text-align: left;
`;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  padding: 10px 0;
  margin: 10px 10px 0px 10px;
`;

const ButtonSelect = styled.button`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
`;

const { Text } = Typography;
const { Option } = Select;

function Header() {
  useInjectReducer({ key: FromCommonSlice.sliceKey, reducer: FromCommonSlice.reducer });
  useInjectSaga({ key: FromCommonSlice.sliceKey, saga: CommonSaga });
  // const [isToggle, setIsToggle] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, periodId } = useParams();
  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const navigation = useNavigate();
  const courseId: any = useSelector(selectCourseId);
  const period: any = useSelector(selectPeriodId);
  const statusId: any = useSelector(selectStatusId);
  const localStorage = new LocalStorageService();
  const userName: any = localStorage.getItem(LocalStorageKey.userName);
  const [selectPeriods, setSelectPeriods] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [titleSelect, setTitleSelect] = useState<string[]>([]);
  const [periodIds, setPeriodIds] = useState<any[]>([]);
  const { filters, setFilters } = useAuth();
  const [isChangePass, setIsChangePass] = useState<boolean>(false);
  const loading: boolean = useSelector(selectLoading);
  const arrayShow: any[] = [
    '/manager-teacher-review',
    '/manager-review',
    '/',
    `/manager-teacher-review`,
    '/manager-self-assessment',
    '/list-of-evaluation-results',
    '/competition-rating-review-edu',
    '/competition-rating-list-school-review-edu'
  ];

  const success: any = useSelector(selectSuccess);
  const error: any = useSelector(selectError);
  const toast = useToast();
  const token = localStorage.getItem(LocalStorageKey.token);
  const gradeId: any = useSelector(selectGradeId);
  const dataUser: any = useSelector(selectDataUser);

  useEffect(() => {
    if (!_.isEmpty(listPeriod) && _.isEmpty(selectPeriods)) {
      setSelectPeriods(
        listPeriod?.periods.map((period) => {
          return {
            id: period.id,
            title: period.title,
            isChecked: false
          };
        })
      );
      dispatch(FromCommonSlice.actions.getListPeriodId(listPeriod.periods.map((item) => item.id)));
    }
  }, [listPeriod, selectPeriods]);

  useEffect(() => {
    if (!_.isEmpty(success)) {
      if (success.key === RESPONSE_CONSTANT.SUCCESS.CHANGE_PASSWORD) {
        toast?.pushSuccess(success.message);
        setIsChangePass(false);
        dispatch(FromSliceUser.actions.clearSuccess());
      }
    }
  }, [success]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      const message = error.errors.reduce((item) => ({ ...item }))?.message;
      toast?.pushError(message);
      dispatch(FromSliceUser.actions.clearError());
    }
  }, [error]);

  const handleChangeStatus = (value) => {
    const newFilter = Object.assign({}, filters, {
      statusId: value
    });
    setFilters(newFilter);
    dispatch(FromCommonSlice.actions.setStatusId(value));
  };
  const handleChangeBlock = (value) => {
    const newFilter = Object.assign({}, filters, {
      gradeId: +value
    });
    setFilters(newFilter);
    dispatch(FromCommonSlice.actions.setGradeId(value));
  };

  const logout = () => {
    LocalStorageService.clear();
    navigation('/login');
  };

  const handleSelectButton = (period) => {
    const newFilter: any[] = [...periodIds];
    const filterItem: any[] = newFilter.filter((item) => item === period.id);
    let isFound: boolean = titleSelect.indexOf(period.title) !== -1 ? true : false; //true

    if (isFound) {
      setTitleSelect(titleSelect.filter((string) => string !== period.title));
    } else {
      setTitleSelect([...titleSelect, period.title]);
    }
    if (filterItem.length !== 0) {
      setPeriodIds(periodIds.filter((item) => item !== period.id));
    } else {
      setPeriodIds([...periodIds, period.id]);
    }
    setSelectPeriods(
      selectPeriods.map((item) => {
        if (period.id === item.id) {
          return { ...item, isChecked: !item.isChecked };
        }
        return { ...item };
      })
    );
  };

  const handleUnSelect = () => {
    setVisible(false);
    setSelectPeriods(
      selectPeriods.map((item) => {
        return { ...item, isChecked: false };
      })
    );
    setTitleSelect([]);
    setPeriodIds([]);
    dispatch(FromCommonSlice.actions.getListPeriodId(listPeriod.periods.map((item) => item.id)));
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleChoose = () => {
    dispatch(FromCommonSlice.actions.getListPeriodId(periodIds));
    setVisible(false);
  };

  const handleToggleCanvas = () => {
    const sideOff = document.querySelector('.sidebar-offcanvas');
    if (sideOff) {
      sideOff.classList.toggle('active');
    }
  };

  const renderContentPeriod = () => {
    return (
      <Space direction="vertical">
        <Space wrap>
          {!_.isEmpty(selectPeriods) &&
            selectPeriods.map((period) => (
              <ButtonSelect
                className={period.isChecked ? 'ant-button-active' : ''}
                style={{ margin: '0 10px', borderRadius: '5px' }}
                key={period.id}
                onClick={() => handleSelectButton(period)}>
                {period.title}
              </ButtonSelect>
            ))}
        </Space>
        {!_.isEmpty(periodIds) && (
          <FooterContent>
            <Button danger style={{ width: 120, marginRight: '10px' }} onClick={handleUnSelect}>
              Bỏ chọn
            </Button>
            <Button type="primary" style={{ width: 120 }} onClick={handleChoose}>
              Chọn
            </Button>
          </FooterContent>
        )}
      </Space>
    );
  };
  const checkLogo = () => {
    //let info = localStorage.getItem(LocalStorageKey.infoSchools) as any;
    //let idPhong = info? info.pgd_id : '';
    //dung switch case phong ho truong hop sau nay doi them nhieu logo nua
      // switch (idPhong) {
      //   case 4975: return LogoQ3; break;
      // default: return Logo;
      
      switch (window.location.hostname) {
        case 'q3.thidua.vn': return LogoTdktQ3; break;
        case 'q7.thidua.vn': return LogoTdktQ7; break;
        case 'tanphu.thidua.vn': return LogoTdktTanPhu; break;
        case 'nhabe.thidua.vn': return LogoTdktNhaBe; break;
        case 'binhtan.thidua.vn': return LogoTdktBinhTan; break;
        case 'cangio.thidua.vn': return LogoTdktCanGio; break;
        case 'phunhuan.thidua.vn': return LogoTdktPhuNhuan; break;
      default: return LogoTdktBKT;
    }
   }
  return (
    <React.Fragment>
      {loading && <AppLoading loading={loading} />}
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          {/* <button
            onClick={() => handleHiddenSidebar()}
            className="navbar-toggler align-self-center">
            <span className="icon-menu" />
          </button> */}
          {/* <a className="sidebar-brand brand-logo-mini">
            <img src={LogoMini} alt="logo-mini" width={40} height={40} />
          </a> */}
          <button
            className="navbar-toggler navbar-toggler-hidden d-lg-none align-self-center"
            type="button"
            onClick={handleToggleCanvas}>
            <MenuUnfoldOutlined />
          </button>
          <a className="sidebar-brand brand-logo" style={{width:'auto'}}>
            {/* <img src={Logo} alt="logo" width={151} height={44} /> */}
            <img src={checkLogo() } alt="logo" width={60} />            
            <span
              style={{
                fontWeight: 'bold',
                color: '#ef1c23',
                fontSize: 12,
                textAlign: 'center',
                marginLeft: 5
              }}>
              {/* PHẦN MỀM ĐÁNH GIÁ
              <br />
              THI ĐUA KHEN THƯỞNG{' '} */}
              PHẦN MỀM  <br />
              THI ĐUA KHEN THƯỞNG <br />
              ĐÁNH GIÁ VÀ TỰ ĐÁNH GIÁ
            </span>
          </a>
          <div className="navbar-nav">
            {/* <div className="nav-link mt-2 mt-md-0 d-none d-lg-flex">
            <div style={{ color: '#000', textShadow: '0px 5px 15px rgb(77 75 75 / 73%)' }}>
              <i className="far fa-calendar-alt" />
              <span> {AppHelper.dayNow()}</span>
            </div>
          </div> */}
            {/* <div className="d-flex" style={{ flexWrap: 'wrap' }}>
              {arrayShow.includes(location.pathname) && (
                <React.Fragment>
                  <div className="nav-link mt-2 mt-md-0 d-lg-flex select-year ml-8">
                    {!_.isEmpty(listCourse) && <SelectCustomize listOption={listCourse} />}
                  </div>
                  {!_.isEmpty(listPeriod.periods) && (
                    <div
                      style={{ position: 'relative' }}
                      className="nav-link mt-2 mt-md-0 d-lg-flex ml-8">
                      <Popover
                        content={renderContentPeriod()}
                        title=""
                        trigger="click"
                        placement="bottomLeft"
                        onVisibleChange={handleVisibleChange}
                        visible={visible}>
                        <StyleButton>
                          {!_.isEmpty(titleSelect) ? titleSelect.join(', ') : 'Tất cả quý'}
                        </StyleButton>
                        <IconDropdown>
                          <DownOutlined />
                        </IconDropdown>
                      </Popover>
                    </div>
                  )}
                </React.Fragment>
              )}
              {location.pathname === `/manager-teacher-review/${id}/${periodId}` && (
                <div className="nav-link mt-2 mt-md-0 d-lg-flex ml-8">
                  <StyleSelectStatus
                    style={{ width: 220, height: 40 }}
                    placeholder="Chọn trạng thái"
                    value={statusId && statusId.id}
                    onChange={handleChangeStatus}>
                    <Option value="">Tất cả trạng thái</Option>
                    {!_.isEmpty(dataStatusAdmin) &&
                      dataStatusAdmin.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.title}
                        </Option>
                      ))}
                  </StyleSelectStatus>
                </div>
              )}
              {location.pathname === `/list-of-evaluation-results` && (
                <div className="nav-link mt-2 mt-md-0 d-lg-flex ml-8">
                  <StyleSelectStatus
                    style={{ width: 220, height: 40 }}
                    placeholder="Chọn trạng thái"
                    value={statusId && statusId.id}
                    onChange={handleChangeStatus}>
                    <Option value="">Tất cả trạng thái</Option>
                    {!_.isEmpty(dataStatusTeacher) &&
                      dataStatusTeacher.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.title}
                        </Option>
                      ))}
                  </StyleSelectStatus>
                </div>
              )}
              {location.pathname ===
                `/competition-rating-list-school-review-edu/${id}/${periodId}` && (
                <div className="nav-link mt-2 mt-md-0 d-lg-flex ml-8">
                  <StyleSelectStatus
                    style={{ width: 220, height: 40 }}
                    placeholder="Khối thi đua"
                    defaultValue={1}
                    value={gradeId && gradeId.id}
                    onChange={handleChangeBlock}>
                    {!_.isEmpty(groupSchool) &&
                      groupSchool.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.title}
                        </Option>
                      ))}
                  </StyleSelectStatus>
                </div>
              )}
            </div> */}
          </div>
          <div className="banner-e_content" style={{ margin: 'auto', marginRight: 15 }}>
            {/* <a style={{ marginRight: 15 }} href="https://lms360.edu.vn/" target="_blank">
              <img src={logolms360} alt="logo" width="140px" />
            </a>
            <a style={{ marginLeft: 15 }} href="https://kiemdinh.hcm.edu.vn/" target="_blank">
              <img src={logokiemdinhwhite} alt="logo" width="140px" />
            </a> */}
          </div>
          {/* {location.pathname !== `/list-of-evaluation-results` ? (
            <div
              className="banner-e_content"
              style={{ width: 320, minWidth: 320, margin: 'auto', right: '25%' }}>
              <a
                target="_blank"
                href="https://elearning.ichcm.edu.vn/"
                style={{ width: '100%', margin: 'auto' }}>
                <img src={banner_elearning} alt="logo" style={{ width: '100%' }} />
              </a>
            </div>
          ) : (
            <div
              className="banner-e_content"
              style={{ width: 250, minWidth: 250, margin: 'auto', right: '0%', marginRight: 15 }}>
              <a
                target="_blank"
                href="https://elearning.ichcm.edu.vn/"
                style={{ width: '100%', margin: 'auto' }}>
                <img src={banner_elearning} alt="logo" style={{ width: '100%' }} />
              </a>
            </div>
          )} */}
          {location.pathname !== `/list-of-evaluation-results` ? (
            <div className="navbar-nav navbar-nav-right" style={{ minWidth: 150, marginLeft: '5' }}>
              <Text className="name-user-pc" type="success" strong>
                {!_.isEmpty(userName) && userName}
              </Text>
              <div>
                <div className="h-40 w-1 bg-e7 mx-10" />
              </div>
              <div className="relative nav-user cursor">
                <div className="flex items-center">
                  <div className="w-40 h-40 rounded-full overflow-hidden relative z-1">
                    <img src={LogoUser} alt="" />
                  </div>
                </div>
                <div className="dropdown-content dropdown-sm">
                  <ul className="linklist">
                    <li className="name-user-mobile">
                      <a href="javascript:void(0)">
                        <Text type="success" strong>
                          {!_.isEmpty(userName) && userName}
                        </Text>
                      </a>
                    </li>
                    {
                      (dataUser?.user?.schools[0]?.pgd_id === 4975 && !dataUser?.user?.schools[0]?.is_admin_school) ?
                        <li>
                          <a
                            style={{ color: '#212529b0', fontWeight: '500' }}
                            onClick={() => navigation('/profile-pgd')}>
                            <UserOutlined /> Thông tin cá nhân
                          </a>
                        </li>
                      :
                        <li>
                          <a
                            style={{ color: '#212529b0', fontWeight: '500' }}
                            onClick={() => navigation('/profile')}>
                            <UserOutlined /> Thông tin cá nhân
                          </a>
                        </li>
                    }
                    
                    {/* {token && (
                    <li>
                      <a
                        style={{ color: '#212529b0', fontWeight: '500' }}
                        onClick={() => setIsChangePass(!isChangePass)}>
                        <KeyOutlined /> Đổi mật khẩu
                      </a>
                    </li>
                  )} */}

                    <li>
                      <a style={{ color: '#212529b0', fontWeight: '500' }} onClick={logout}>
                        <LogoutOutlined /> Đăng xuất
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="navbar-nav navbar-nav-right"
              style={{ minWidth: 150, marginLeft: 'auto' }}>
              <Text type="success" strong>
                {!_.isEmpty(userName) && userName}
              </Text>
              <div>
                <div className="h-40 w-1 bg-e7 mx-10" />
              </div>
              <div className="relative nav-user cursor">
                <div className="flex items-center">
                  <div className="w-40 h-40 rounded-full overflow-hidden relative z-1">
                    <img src={LogoUser} alt="" />
                  </div>
                </div>
                <div className="dropdown-content dropdown-sm">
                  <ul className="linklist">
                    {/* <li>
                      <a
                        style={{ color: '#212529b0', fontWeight: '500' }}
                        onClick={() => navigation('/profile')}>
                        <UserOutlined /> Thông tin cá nhân
                      </a>
                    </li> */}
                    {
                      (dataUser?.user?.schools[0]?.pgd_id === 4975 && !dataUser?.user?.schools[0]?.is_admin_school) ?
                        <li>
                          <a
                            style={{ color: '#212529b0', fontWeight: '500' }}
                            onClick={() => navigation('/profile-pgd')}>
                            <UserOutlined /> Thông tin cá nhân
                          </a>
                        </li>
                      :
                        <li>
                          <a
                            style={{ color: '#212529b0', fontWeight: '500' }}
                            onClick={() => navigation('/profile')}>
                            <UserOutlined /> Thông tin cá nhân
                          </a>
                        </li>
                    }
                    {/* {token && (
                  <li>
                    <a
                      style={{ color: '#212529b0', fontWeight: '500' }}
                      onClick={() => setIsChangePass(!isChangePass)}>
                      <KeyOutlined /> Đổi mật khẩu
                    </a>
                  </li>
                )} */}

                    <li>
                      <a style={{ color: '#212529b0', fontWeight: '500' }} onClick={logout}>
                        <LogoutOutlined /> Đăng xuất
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        {isChangePass && (
          <ChangePasswordUser
            isOpen={isChangePass}
            handleCancel={() => setIsChangePass(!isChangePass)}
            handleOk={() => {}}
          />
        )}
      </nav>
    </React.Fragment>
  );
}

export default Header;
