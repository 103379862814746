/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useState } from 'react';
import { toast as ToastLib, ToastContainer } from 'react-toastify';
export type ToastProviderProps = {
  children: React.ReactNode;
};

export type ToastType = {
  message: string | React.ReactNode;
};

export type ToastContextType = {
  pushError(message: string): void;
  pushWarning(message: string): void;
  pushSuccess(message: string): void;
  pushInfo(message: string): void;
};

export const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

export const useToast = () => useContext(ToastContext);

const DEFAULT_INTERVAL = 3000;
export default function Toast({ children }: ToastProviderProps) {
  const PushSuccess = useCallback((message) => {
    ToastLib.success(message, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }, []);

  const PushInfo = useCallback((message) => {
    ToastLib.info(message, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }, []);

  const PushError = useCallback((message) => {
    ToastLib.error(message, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }, []);
  const PushWarning = useCallback((message) => {
    ToastLib.warn(message, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }, []);

  const ToastContexd = useCallback(() => {
    return {
      pushError: PushError,
      pushWarning: PushWarning,
      pushSuccess: PushSuccess,
      pushInfo: PushInfo
    };
  }, [PushSuccess, PushInfo, PushWarning, PushError]);
  return (
    <ToastContext.Provider value={ToastContexd()}>
      <ToastContainer
        position="top-right"
        autoClose={DEFAULT_INTERVAL}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {children}
    </ToastContext.Provider>
  );
}
