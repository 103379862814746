import React, { useEffect, useState } from 'react';
import { Table, Space, Tooltip, Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import * as _ from 'lodash';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
// get service
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import {
  selectLoading,
  selectDataListEvaluationEmulationTypes
} from 'store/evaluation/shared/selectors';
import * as FromCommonSlice from 'store/common/shared/slice';
import { selectListCoursesBase } from 'store/common/shared/selectors';
import useAuth from 'hooks/useAuth';
import { AppHelper } from 'utils/app.helpers';
import moment from 'moment';
import { useToast } from 'components/Toast';
import FunctionUtils from 'utils/FunctionUtils';
import ModalViewFilesDetails from 'components/ViewFiles';
import { YEAR_ID_DEFAULT } from '_constants/default.constants';

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

interface IModalViewDecisionNo {
  isOpen: boolean;
  handleCancel: () => void;
  detail: any;
  title?: string;
  type?: Number;
}

export function ModalViewDecisionNo({
  isOpen,
  handleCancel,
  detail,
  title,
  type
}: IModalViewDecisionNo) {
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  const dispatch = useDispatch();
  const evaluationHttp = new EvaluationHttp();
  const toast = useToast();
  const ListEvaluationEmulationTypes: any = useSelector(selectDataListEvaluationEmulationTypes);
  const listCoursesBase: any = useSelector(selectListCoursesBase);
  const loading: any = useSelector(selectLoading);
  const [defaultValueYear, setDefaultValueYear] = useState<number>(12);
  const [isOpenModalEmulationDecision, setIsOpenModalEmulationDecision] = useState<boolean>(false);
  const [dataEvaluationEmulationTypes, setDataEvaluationEmulationTypes] = useState([]);
  const [dataValueTable, setDataValueTable] = useState<any>([]);
  const [dataYears, setDataYears] = useState<any>([]);
  const [detailValue, setDetailValue] = useState<any>();
  const [isView, setIsView] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const [filePath, setFilePaths] = useState<any[]>([]);
  const { filters } = useAuth();

  //pop up view file
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();

  const [filterData, setFilterData] = useState({
    courseId: YEAR_ID_DEFAULT,
    perPage: 50,
    pageId: 1,
    type: 2
  });

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filters]);
  };

  useEffectOnMount(() => {
    dispatch(FromCommonSlice.actions.getCoursesBase(filters));
    dispatch(evaluationList.actions.getListEvaluationEmulationTypes(filters));
    // handleChangeEmulation(0);
  });

  useEffect(() => {
    let data: any = [];
    let id = '';
    if (type === 1) {
      id = detail.decision_school_id;
    } else {
      id = detail.decision_id;
    }
    evaluationHttp.getEvaluationApproveDecisionNoId(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { decision } = res.data.data;
        console.log(res.data.data.decision);
        setIsOpenModalEmulationDecision(true);
        data.push(decision);
        setDataValueTable(data);
        var arr = _.values(decision.files);
        setFilePaths(arr);
        setIsView(true);
      }
    });
  }, []);

  useEffect(() => {
    let dataYear = [];
    let dataEmulationTypes = [];
    if (!_.isEmpty(listCoursesBase)) {
      dataYear = listCoursesBase?.map((item) => {
        return {
          value: item.id,
          label: item.description
        };
      });
      const listFormatCoursesBase = [{ value: 0, label: 'Tất cả' }, ...dataYear];
      setDataYears(listFormatCoursesBase);
    }
    if (!_.isEmpty(ListEvaluationEmulationTypes)) {
      dataEmulationTypes = ListEvaluationEmulationTypes?.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
      setDataEvaluationEmulationTypes(dataEmulationTypes);
    }
  }, [ListEvaluationEmulationTypes, listCoursesBase]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      key: 'course_id',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listCoursesBase)) {
          return (
            <span style={{ color: '#2f54eb' }}>
              {listCoursesBase.map((ls) => (ls.id == record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCoursesBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Số quyết định',
      dataIndex: 'decision_no',
      key: 'decision_no',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}></span>;
      }
    },
    {
      title: 'Ngày quyết định',
      key: 'decision_date',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (moment(record.decision_date, 'yyyy-MM-DD', true).isValid()) {
          return (
            <span style={{ color: '#1890ff' }}>
              {AppHelper.formatDate(record.decision_date, 'DD/MM/yyyy')}
            </span>
          );
        }
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record.decision_date}</span>;
      }
    }
    // {
    //   title: 'Xem chi tiết',
    //   key: 'detail',
    //   width: 100,
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     return (
    //       <span
    //         style={{ color: '#1890ff', cursor: 'pointer' }}
    //         onClick={() => handleDetail(record)}>
    //         Xem chi tiết
    //       </span>
    //     );
    //   }
    // }
  ];

  const handleDetail = (record) => {
    if (!_.isEmpty(record)) {
      setIsOpenModalEmulationDecision(true);
      setDetailValue(record);
      setIsView(true);
    }
  };

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>
      ]}>
      <ContainerTable>
        <ConfigProviderCustomize>
          <Table
            columns={columns}
            dataSource={!_.isEmpty(dataValueTable) && dataValueTable}
            size="middle"
            bordered
            pagination={false}
            loading={loading}
          />
        </ConfigProviderCustomize>
      </ContainerTable>
      <div className="grid sm:grid-1 mt-20 gap-10 sm:gap-10">
        <label>Tệp đính kèm</label>
        <div className="grid sm:grid-1 gap-15 sm:gap-20">
          <ul className="mt-10 flex flex-column gap-10">
            {!_.isEmpty(filePath) ? (
              filePath?.map((item: any, index) => (
                <li className="flex items-center justify-between text-45a" key={index}>
                  <span className="text-wrap">
                    <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                  </span>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="flex items-center justify-between gap-10">
                    <Space>
                      <Tooltip title="Xem chi tiết" placement="bottomLeft">
                        <span
                          //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                          onClick={() => openFile(item)}
                          className="detail">
                          <div className="flex items-center justify-center">
                            <i className="fas fa-eye" />
                          </div>
                        </span>
                      </Tooltip>
                    </Space>
                    <Space>
                      <Tooltip title="Tải file" placement="bottomLeft">
                        <span
                          //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                          onClick={() => handleDownLoad(item)}
                          className="detail">
                          <div className="flex items-center justify-center">
                            <i className="fas fa-download mr-5"></i>
                          </div>
                        </span>
                      </Tooltip>
                    </Space>
                  </div>
                </li>
              ))
            ) : (
              <span>Không có file đính kèm</span>
            )}
          </ul>
          <span></span>
        </div>
      </div>
      {isOpenModalViewFileDetails && (
        <ModalViewFilesDetails
          isOpen={isOpenModalViewFileDetails}
          detail={detailFile}
          handleCancel={() => setIsOpenModalViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}
