import { Button, Card, Checkbox, Modal, Radio, RadioChangeEvent, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListEffectScopes
} from 'store/common/shared/selectors';
import { selectDataUser } from 'store/user/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import * as common from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import './style.css';
import ButtonCreate from 'components/ButtonCreate';
import ModalViewFilesDetails from 'components/ViewFiles';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

interface IModalDKSKEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  isViewUpdate: boolean;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalDKSKEdit({
  isOpen,
  title,
  handleCancel,
  id,
  isViewUpdate,
  handleRefresh,
  detail,
  handleSuccess
}: IModalDKSKEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  const dispatch = useDispatch();
  const toast = useToast();
  const listCourse: any = useSelector(selectListCourses);
  const listEffectScopes: any = useSelector(selectListEffectScopes);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);
  const [isSubmit, setIsubmit] = useState<boolean>(false);
  const listPeriod = useSelector(selectListPeriod);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [filesInitiative, setFilesInitiative] = useState<any[]>([]);
  const [filePathInitiative, setFilePathsInitiative] = useState<any[]>([]);
  const [fileUpload, setFileUpload] = useState<any[]>([]);
  const [filePathUpload, setFilePathUpload] = useState<any[]>([]);
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [isIOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState(0);
  const [isTeacherScore, setIsTeacherScore] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [score, setScore] = useState<Number>(-1);
  const [description, setDescription] = useState<string>('');
  const [effective, setEffective] = useState<string>('');
  const [affect, setAffect] = useState<string>('');
  const courseId: any = useSelector(selectCourseId);
  const createReviewHttp = new InitiativeAchievementHttp();
  const dataUser: any = useSelector(selectDataUser);
  //pop up view file
  const [isOpenModalViewFileDetails, setIsOpenModalViewFileDetails] = useState<boolean>(false);
  const [detailFile, setDetailFile] = useState<any>();

  useEffect(() => {
    getDetailReview();
  }, []);

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        if (
          data.evaluation_intiative_detail.teacher_score1 > 0 ||
          data.evaluation_intiative_detail.teacher_score2 > 0
        ) {
          setIsTeacherScore(true);
        }
        setTitleReview(data.evaluation_intiative_detail.name);
        setYearSchool(data.evaluation_intiative_detail.course_id);
        setDescription(data.evaluation_intiative_detail.description);
        setEffectScopeId(data.evaluation_intiative_detail.effect_scope_id);
        setScore(data.evaluation_intiative_detail.score);
        setEffective(data.evaluation_intiative_detail.effective);
        setValueAffectCS(data.evaluation_intiative_detail.is_coso);
        if (data.evaluation_intiative_detail.is_coso) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
        setValueAffectTP(data.evaluation_intiative_detail.is_tp);
        var arr = _.values(data.evaluation_intiative_detail.files); // convert obj to arr
        var arrFileIntiative = _.values(data.evaluation_intiative_detail.files_description);
        setFilePaths(arr);
        setFilePathsInitiative(arrFileIntiative);
        let arrFiles: any = [];
        let arrFilesIntiative: any = [];
        arr.map((item: any) => {
          const path: string = FunctionUtils.converPathImage(item);
          arrFiles.push(path);
        });
        arrFileIntiative.map((item: any) => {
          const path: string = FunctionUtils.converPathImage(item);
          arrFilesIntiative.push(path);
        });
        const fileUpload = arrFiles.concat(arrFilesIntiative);
        setFilePathUpload(fileUpload);
      } else {
      }
    });
  };

  const handleUpload = async () => {
    if (_.isEmpty(files) && _.isEmpty(filesInitiative)) {
      console.log('call api');
      submitDataApi();
      return;
    }
    let fileUpload = files.concat(filesInitiative);
    if (!_.isEmpty(filePathUpload)) {
      commonHttp.uploadFile(filePathUpload).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            if (valueOfKeys && valueOfKeys.length > 0) {
              handlePromiseAll(valueOfKeys, fileUpload);
            }
            // valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files));
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    //toast?.pushSuccess('Dữ liệu đã được lưu tạm!');
    //handleCancel();
  };

  const handlePromiseAll = async (valueOfKeys, files) => {
    var valueUpload: any = [];
    for (var i = 0; i < valueOfKeys.length; i++) {
      var a = Object.keys(valueOfKeys[i])[0];
      if (!a.includes('https:')) {
        valueUpload.push(valueOfKeys[i]);
      }
    }
    // end Xử lý những file vừa upload
    //debugger
    const response = await Promise.all(
      valueUpload.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files))
    );
    if (response && response.length) {
      for (var j = 0; j < response.length; j++) {
        if (response[j] === undefined) {
          toast?.pushWarning('Upload File bị lỗi. Vui lòng kiểm tra lại!');
          return;
        }
      }
      // const response = await Promise.all(
      //   valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files))
      // );
      // if (response && response.length) {
      submitDataApi();
    } else {
      toast?.pushWarning('Upload File bị lỗi. Vui lòng kiểm tra lại!');
    }
  };

  const handleSubmit = () => {
    if (titleReview === '') {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_INITIATIVE_SUCCESS);
      return;
    }
    // return;
    //upload
    setIsubmit(true);
    if (isViewUpdate) {
      if (filePath.length === 0) {
        toast?.pushWarning('Vui lòng cập nhật đầy đủ các file sáng kiên');
        setIsubmit(false);
        return;
      }
      if (filePathInitiative.length === 0) {
        toast?.pushWarning('Vui lòng cập nhật đầy đủ các file mô tả sáng kiên');
        setIsubmit(false);
        return;
      }
      handleUpload();
    } else {
      submitDataApi();
    }
  };

  const submitDataApi = () => {
    let arrFiles: any = [];
    let arrFilesIntiative: any = [];
    filePath.map((item: any) => {
      const path: string = FunctionUtils.converPathImage(item);
      arrFiles.push(path);
    });
    filePathInitiative.map((item: any) => {
      const path: string = FunctionUtils.converPathImage(item);
      arrFilesIntiative.push(path);
    });
    const obj = Object.assign({}, arrFiles); // array to object
    const objInitiative = Object.assign({}, arrFilesIntiative);
    const request = {
      name: titleReview,
      course_id: parseInt(yearSchool), //courseId.id! ? courseId.id : 0,
      description: description,
      effect_scope_id: parseInt(effectScopeId.toString()),
      effective: effective,
      is_coso: valueAffectCS,
      is_tp: valueAffectTP,
      files: obj,
      files_description: objInitiative,
      score: -1
    };
    createReviewHttp.updateInitiativeTeacher(request, id).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        setIsubmit(false);
        if (isViewUpdate) {
          toast?.pushSuccess('Cập nhật sáng kiến thành công!');
        } else {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.EDIT_INITIATIVE_SUCCESS);
        }
        handleCancel();
        handleRefresh();
      } else {
        setIsubmit(false);
      }
    });
  };

  // upload
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        //let key = UploadFile.getIdFile(file, detail);
        // let key = UploadFile.getIdFileNoDetail(file, dataUser);
        let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        let id: string = `danhgia/initiative/${
          dataUser?.user?.id
        }/${AppHelper.generateUUIDV4()}/${fileName}`;
        let idFile: string = file.name.replace(
          file.name.substring(0, file.name.lastIndexOf('.')),
          id
        );
        newFilePath.push(idFile);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: idFile
        };
      })
    ]);
    setFilePaths(newFilePath);
    if (filePathInitiative.length > 0) {
      const fileUpload = filePathInitiative.concat(newFilePath);
      setFilePathUpload(fileUpload);
    }
  };

  const handleImageInitiative = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArrInitiative = Array.from(e.target.files || []);
    const newFilePathInitiative: any[] = [...filePathInitiative];
    setFilesInitiative([
      ...filesInitiative,
      ...fileArrInitiative.map((file) => {
        //let key = UploadFile.getIdFile(file, detail);
        let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        let id: string = `danhgia/desription/${
          dataUser?.user?.id
        }/${AppHelper.generateUUIDV4()}/${fileName}`;
        let idFile: string = file.name.replace(
          file.name.substring(0, file.name.lastIndexOf('.')),
          id
        );
        // let key = UploadFile.getIdFileNoDetail(file, dataUser);
        newFilePathInitiative.push(idFile);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: idFile
        };
      })
    ]);
    setFilePathsInitiative(newFilePathInitiative);
    if (filePath.length > 0) {
      const fileUpload = filePath.concat(newFilePathInitiative);
      setFilePathUpload(fileUpload);
    }
  };

  const handleRemoveFile = (file: any) => {
    const newFilePath = filePath.filter((item) => item !== file);
    setFilePaths(newFilePath);
  };

  const handleRemoveFileInitiative = (file: any) => {
    const newFilePathInitiative = filePathInitiative.filter((item) => item !== file);
    setFilePathsInitiative(newFilePathInitiative);
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };

  const onChangeValue = (e) => {
    if (e.target.name == 'title') {
      setTitleReview(e.target.value);
    }
    if (e.target.name == 'description') {
      const shouldSetValue = description.length <= 1500;
      setDescription(e.target.value);
    }
    if (e.target.name == 'effective') {
      const shouldSetValue = effective.length < 100;
      setEffective(e.target.value);
    }
    if (e.target.name == 'score') {
      setScore(e.target.value);
    }
    if (e.target.name == 'effectScope') {
      setEffectScopeId(e.target.value);
    }
  };

  const onChangeAffect = (e: CheckboxChangeEvent, type) => {
    if (type === 'cs') {
      if (e.target.checked) {
        setValueAffectCS(e.target.checked);
        setDisabled(false);
      } else {
        setDisabled(true);
        setValueAffectTP(false);
        setValueAffectCS(e.target.checked);
      }
    } else {
      setValueAffectTP(e.target.checked);
    }
  };

  const openFile = (item) => {
    setIsOpenModalViewFileDetails(true);
    setDetailFile(item);
  };

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button loading={isSubmit} key="submit" size="large" type="primary" onClick={handleSubmit}>
          {isViewUpdate ? 'Nộp sáng kiến' : 'Cập nhật'}
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>
            <input
              name="title"
              id="title"
              type="text"
              disabled={isTeacherScore}
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          {isViewUpdate && (
            <React.Fragment>
              <div className="mb-15">
                <label htmlFor="" className="font-bold mb-10 block">
                  Tóm tắt mô tả sáng kiến
                </label>
                <textarea
                  defaultValue={description}
                  id="description"
                  name="description"
                  onChange={onChangeValue}
                  cols={30}
                  rows={3}
                  maxLength={1500}
                  className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                  placeholder="Nhập thông tin"></textarea>
                <small>Vui lòng nhập dưới 1500 kí tự: {description.length}/1500</small>
              </div>
              <div className="mb-15">
                <label htmlFor="" className="font-bold mb-10 block">
                  Hiệu quả
                </label>
                <textarea
                  defaultValue={effective}
                  // disabled={isTeacherScore}
                  id="effective"
                  name="effective"
                  onChange={onChangeValue}
                  cols={30}
                  rows={3}
                  maxLength={500}
                  className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                  placeholder="Nhập thông tin"></textarea>
                <small>Vui lòng nhập dưới 500 kí tự: {effective.length}/500</small>
              </div>
            </React.Fragment>
          )}
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Đăng ký phạm vi ảnh hưởng
            </label>
            <Checkbox
              checked={valueAffectCS}
              disabled={isTeacherScore}
              onChange={(e) => onChangeAffect(e, 'cs')}>
              Phạm vi ảnh hưởng cấp Quận
            </Checkbox>
            <Checkbox
              checked={valueAffectTP}
              disabled={disabled || isTeacherScore}
              onChange={(e) => onChangeAffect(e, 'tp')}>
              Phạm vi ảnh hưởng cấp Thành phố
            </Checkbox>
          </div>
          {isViewUpdate && (
            <React.Fragment>
              <div className="grid sm:grid-2" style={{ alignItems: 'start' }}>
                <div className="grid sm:grid-1 gap-10 sm:gap-10">
                  <label htmlFor="" className="font-bold mb-10 block">
                    Chọn file sáng kiến
                  </label>
                  <div className="grid sm:grid-1 uploadfile">
                    <input type="file" multiple onChange={handleImage} disabled={isTeacherScore} />
                    <span id="file-upload-btn" className="btn btn-primary">
                      <ButtonCreate
                        disabled={isTeacherScore}
                        icon="fa-cloud-upload"
                        text="Tải tệp lên"
                      />
                    </span>
                  </div>
                  <div className="grid sm:grid-3 gap-15 sm:gap-20">
                    <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                      {!_.isEmpty(filePath) ? (
                        filePath?.map((item: any, index) => (
                          <li
                            className="flex items-center justify-between text-45a"
                            key={index}
                            style={{ cursor: 'pointer' }}>
                            <span onClick={() => handleDownLoad(item)} className="text-wrap">
                              <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                            </span>
                            <span
                              //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                              onClick={() => openFile(item)}
                              className="detail">
                              <div className="flex items-center justify-center">
                                <i className="fas fa-eye" />
                              </div>
                            </span>
                            {!isTeacherScore && (
                              <button
                                className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                                style={{ fontSize: '14px' }}
                                onClick={() => handleRemoveFile(item)}>
                                <i className="fas fa-trash text-ea5" />
                              </button>
                            )}
                          </li>
                        ))
                      ) : (
                        <span>Không có file đính kèm</span>
                      )}
                    </ul>
                    <span></span>
                  </div>
                </div>
                <div className="grid sm:grid-1 gap-10 sm:gap-10">
                  <label htmlFor="" className="font-bold mb-10 block">
                    Chọn file mô tả sáng kiến
                  </label>
                  <div className="grid sm:grid-1 uploadfile">
                    <input
                      type="file"
                      multiple
                      onChange={handleImageInitiative}
                      disabled={isTeacherScore}
                    />
                    <span id="file-upload-btn" className="btn btn-primary">
                      <ButtonCreate
                        disabled={isTeacherScore}
                        icon="fa-cloud-upload"
                        text="Tải tệp lên"
                      />
                    </span>
                  </div>
                  <div className="grid sm:grid-3 gap-15 sm:gap-20">
                    <ul className="mt-20 flex flex-column gap-10 sm:span-2">
                      {!_.isEmpty(filePathInitiative) ? (
                        filePathInitiative?.map((item: any, index) => (
                          <li
                            className="flex items-center justify-between text-45a"
                            key={index}
                            style={{ cursor: 'pointer' }}>
                            <span onClick={() => handleDownLoad(item)} className="text-wrap">
                              <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(item)}
                            </span>
                            <span
                              //to={`/manager-teacher-review/${id}/detail/${edit.userId}/${edit.periodIdSelf}`}
                              onClick={() => openFile(item)}
                              className="detail">
                              <div className="flex items-center justify-center">
                                <i className="fas fa-eye" />
                              </div>
                            </span>
                            {!isTeacherScore && (
                              <button
                                className="border-0 pl-20 text-quinary text-19 bg-transparent remove"
                                style={{ fontSize: '14px' }}
                                onClick={() => handleRemoveFileInitiative(item)}>
                                <i className="fas fa-trash text-ea5" />
                              </button>
                            )}
                          </li>
                        ))
                      ) : (
                        <span>Không có file đính kèm</span>
                      )}
                    </ul>
                    <span></span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Card>
        {isOpenModalViewFileDetails && (
          <ModalViewFilesDetails
            isOpen={isOpenModalViewFileDetails}
            detail={detailFile}
            handleCancel={() => setIsOpenModalViewFileDetails(false)}
          />
        )}
      </Container>
    </Modal>
  );
}

export default ModalDKSKEdit;
