import { createSlice } from '@reduxjs/toolkit';

export interface CompetitionRatingEduState {
  loading: boolean;
  success?: any;
  error?: any;
  listReviewEdu?: any;
  detailReviewEdu: any;
  listSchoolReviewEdu: any;
  detailEvaluationBySchoolEdu: any;
  listManageGrades: any;
  gradeId: any;
  listReviewPgd: any;
}

export const initialState: CompetitionRatingEduState = {
  loading: false,
  success: {},
  error: {},
  listReviewEdu: {},
  detailReviewEdu: {},
  listSchoolReviewEdu: {},
  detailEvaluationBySchoolEdu: {},
  listManageGrades: {},
  gradeId: {},
  listReviewPgd: {}
};

const CompetitionRatingEduSlice = createSlice({
  name: 'competitionRatingEdu',
  initialState,
  reducers: {
    getListReviewEdu(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListReviewEduSuccess(state, action) {
      state.loading = false;
      state.listReviewEdu = action.payload;
    },
    getListReviewEduFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListReviewPgd(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListReviewPgdSuccess(state, action) {
      state.loading = false;
      state.listReviewPgd = action.payload;
    },
    getListReviewPgdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addReviewEdu(state, action) {
      state.loading = true;
      state.error = null;
    },
    addReviewEduSuccess(state, action) {
      state.loading = false;
      state.success = {
        data: action.payload
      };
    },
    addReviewEduFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteReviewEdu(state, action) {
      state.loading = true;
      state.error = null;
    },
    deleteReviewEduSuccess(state, action) {
      state.loading = false;
      state.success = {
        // key: RESPONSE_CONSTANT.SUCCESS.DELETE_REVIEW_SUCCESS,
        // message: RESPONSE_MESSAGE.SUCCESS.ADD_REVIEW_SUCCESS,
        data: action.payload
      };
    },
    deleteReviewEduFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    detailReviewEdu(state, action) {
      state.loading = true;
      state.error = null;
    },
    detailReviewEduSuccess(state, action) {
      state.loading = false;
      state.detailReviewEdu = action.payload;
    },
    detailReviewEduFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    listSchoolReviewEdu(state, action) {
      state.loading = true;
      state.error = null;
    },
    listSchoolReviewEduSuccess(state, action) {
      state.loading = false;
      state.listSchoolReviewEdu = action.payload;
    },
    listSchoolReviewEduFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getListManageGrades(state, action) {
      state.loading = true;
      state.error = null;
    },
    getListManageGradesSuccess(state, action) {
      state.loading = false;
      state.listManageGrades = action.payload;
    },
    getListManageGradesFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setGradeId(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setGradeIdSuccess(state, action) {
      state.loading = false;
      state.gradeId = action.payload;
    },
    setGradeIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = CompetitionRatingEduSlice;
