import React, { useCallback, useEffect, useState } from 'react';

import { routes } from 'Routes';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useRoutes
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginSuccess } from 'store/login/shared/selectors';
import * as _ from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import * as FromCommonSlice from './store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { INIT_PAGE } from '_constants/default.constants';
import { UserHttp } from 'store/user/services/user.http';
import { isExpired } from 'react-jwt';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/useAuth';
import { selectListCourses, selectListPeriod } from 'store/common/shared/selectors';
import ChangePassword from 'views/ChangePassword';
import { Login } from 'views/Login';
import { selectDataUser } from 'store/user/shared/selectors';
import * as FromUserSlice from 'store/user/shared/slice';
import { UserSaga } from 'store/user/shared/saga';
import zaloImg from './assets/images/zalo.svg';
import config from 'config/pgd.config';
import { assignNewConfigToAppConfig, getPGDInfo } from 'config';
import { AppConfig } from 'index';

const localStorage = new LocalStorageService();
const userHttp = new UserHttp();

export function App() {
  let element = useRoutes(routes);
  const param = useParams();
  const dispatch = useDispatch();
  useInjectReducer({ key: FromCommonSlice.sliceKey, reducer: FromCommonSlice.reducer });
  useInjectSaga({ key: FromCommonSlice.sliceKey, saga: CommonSaga });
  useInjectReducer({ key: FromUserSlice.sliceKey, reducer: FromUserSlice.reducer });
  useInjectSaga({ key: FromUserSlice.sliceKey, saga: UserSaga });
  const login: any = useSelector(selectLoginSuccess);
  const navigation = useNavigate();
  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const toast = useToast();
  const { auth, setAuth, setIsExpiredAuth, isExpiredAuth } = useAuth();
  const [isModalChangePass, setIsModalChangePass] = useState<boolean>(false);
  // const location = useLocation();
  // const dataLocation: string[] = ['/login', '/', '/manager-self-assessment'];
  // const token = localStorage.getItem(LocalStorageKey.token);
  const infoUser: any = useSelector(selectDataUser);

  useEffect(() => {
    // assignNewConfigToAppConfig(config.nhabe);
    // return;

    const pgd = getPGDInfo(infoUser?.user?.schools?.[0]?.pgd_id);

    if (pgd != null) {
      const pgd_config = config[pgd.key];
      if (pgd_config != null) {
        assignNewConfigToAppConfig(pgd_config);
        return;
      }
    }

    assignNewConfigToAppConfig(config.general);
  }, [infoUser]);

  useEffect(() => {
    const el = document.getElementById('root');
    if (el === null) {
      throw new Error('Root container missing in index.html');
    } else {
      if (el.firstElementChild?.classList.contains('Toastify')) {
        el.firstElementChild.remove();
      }
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(listCourse)) {
      dispatch(FromCommonSlice.actions.getCourseId(listCourse[0]?.id));
    }
  }, [listCourse]);

  useEffect(() => {
    if (!_.isEmpty(listPeriod) && !_.isEmpty(listPeriod.periods)) {
      dispatch(FromCommonSlice.actions.getPeriodId(0));
      dispatch(FromCommonSlice.actions.getListPeriodId(listPeriod?.periods.map((item) => item.id)));
    }
  }, [listPeriod, listPeriod.periods]);

  useEffect(() => {
    if (!_.isEmpty(login)) {
      // console.log(login, 'login');
      // localStorage.setItem({ key: LocalStorageKey.isReLogin, value: false });
      const { data } = login;
      // if (data.user && data.user.update_progress === 1) {
      //   setIsModalChangePass(!isModalChangePass);
      // }
      localStorage.setItem({ key: LocalStorageKey.token, value: data.access_token });
      getInfoAuth();
    }
  }, [login]);

  const getInfoAuth = async () => {
    await userHttp
      .getInfoUser()
      .then((res) => {
        try {
          if (res.ok && res.status === 200) {
            dispatch(FromUserSlice.actions.getInfoUserSuccess(res?.data?.data));
            if (res?.data?.data?.user?.update_progress === '1') {
              setIsModalChangePass(!isModalChangePass);
              return;
            }
            localStorage.setItem({
              key: LocalStorageKey.roles,
              value: res?.data?.data?.user?.roles
            });
            localStorage.setItem({
              key: LocalStorageKey.userName,
              value: res?.data?.data?.user?.fullname
            });
            localStorage.setItem({
              key: LocalStorageKey.userID,
              value: res?.data?.data?.user?.id
            });
            // localStorage.setItem({
            //   key: LocalStorageKey.isLoginSSO,
            //   value: res?.data?.data?.user?.user_logintype_code
            // });

            // dispatch(FromCommonSlice.actions.getCourses(INIT_PAGE));
            // dispatch(FromCommonSlice.actions.getPeriods(INIT_PAGE));
            setAuth(res?.data?.data?.user?.roles);
            if (res?.data?.data?.user?.roles?.school) {
              setAuth(res?.data?.data?.user?.roles);
              const rePathName = localStorage.getItem(LocalStorageKey.pathName);
              if (rePathName) {
                navigation(rePathName, { replace: true });
              } else {
                if (res?.data?.data?.user?.schools[0]?.is_pgd) {
                  // let idPhong = res?.data?.data?.user?.schools[0]?.pgd_id;
                  // if(idPhong == 4975) // q3
                  // {
                  //   navigation('/new-list-personal-competition-school-pgd', { replace: true });
                  // } else {
                  //   navigation('/list-personal-competition-school-pgd', { replace: true });
                  // }
                  //navigation('/list-personal-competition-school-pgd', { replace: true });
                  navigation('/new-list-personal-competition-school-pgd', { replace: true });
                } else {
                  navigation('/list-personal-competition-pgd', { replace: true });
                }
              }
              localStorage.removeItem(LocalStorageKey.pathName);
            } else if (res?.data?.data?.user?.roles?.teacher) {
              setAuth(res?.data?.data?.user?.roles);
              const rePathName = localStorage.getItem(LocalStorageKey.pathName);
              if (rePathName) {
                navigation(rePathName, { replace: true });
              } else {
                navigation('/emulation-title-pgd', { replace: true });

                //navigation('/manager-self-assessment', { replace: true });
              }
              localStorage.removeItem(LocalStorageKey.pathName);
            } else if (res?.data?.data?.user?.roles?.sgd) {
              setAuth(res?.data?.data?.user?.roles);
              const rePathName = localStorage.getItem(LocalStorageKey.pathName);
              if (rePathName) {
                navigation(rePathName, { replace: true });
              } else {
                navigation('/profile', { replace: true });
                //navigation('/list-achievement-so', { replace: true });
              }
              localStorage.removeItem(LocalStorageKey.pathName);
            } else {
              toast?.pushError('Tài khoản của bạn không có quyền truy cập tài nguyên này!');
            }
          } else {
            setIsModalChangePass(!isModalChangePass);
            // localStorage.setItem({ key: LocalStorageKey.isReLogin, value: true });
          }
        } catch (error) {
        } finally {
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  return (
    <React.Fragment>
      {element}
      {isModalChangePass && (
        <ChangePassword
          isOpen={isModalChangePass}
          handleCancel={() => setIsModalChangePass(!isModalChangePass)}
        />
      )}
      {/* <a
        className="btn__zalo"
        href="https://zalo.me/g/jrxgmw436"
        target="_blank"
        title="Zalo hỗ trợ">
        <img src={zaloImg} alt="" />
      </a> */}
    </React.Fragment>
  );
}
