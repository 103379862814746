import AppLoading from 'components/Loading';
import { NavBarLSK } from 'components/Common/ttsk';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateReviewSaga } from 'store/createReview/shared/saga';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import * as FromCreateInitiativeSlice from '../../../store/initiativeAchievement/shared/slice';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import { INIT_PAGE, IPagi, TITLE_NAVBAR } from '_constants/default.constants';
import ModalConfirm from 'components/Modal/ModalConfirm';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import { CreateReviewHttp } from 'store/createReview/services/createReview.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { Space, Tooltip, Card, Select } from 'antd';
import Pagination from 'components/Pagination';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as common from '../../../store/common/shared/slice';
import _ from 'lodash';
import {
  selectCourseId,
  selectListCourses,
  selectListPeriod,
  selectListPeriodId,
  selectPeriodId,
  selectListCoursesBase,
  selectListSchools,
  selectSchoolDetail,
  selectListSchoolsOption
} from 'store/common/shared/selectors';
import { selectListProvinces, selectListDistricts } from 'store/address/shared/selectors';
import * as address from 'store/address/shared/slice';
import { AddressSaga } from 'store/address/shared/saga';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import Swal from 'sweetalert2';

import styled from 'styled-components';
import ModalDetail from '../components/ModalDetail';
import FunctionUtils from 'utils/FunctionUtils';
import useAuth from 'hooks/useAuth';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ButtonCreate from 'components/ButtonCreate';
import ButtonSubmit from 'components/ButtonSubmit';
import ButtonSubmitSearch from 'components/ButtonSubmitSearch';
import { Helmet } from 'react-helmet-async';
import uploadfile from 'utils/uploadfile';
import { CommonSaga } from 'store/common/shared/saga';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;
const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
const divbtn = styled.div`
  width: auto;
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerSelect: typeof Select = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    padding-top: 4px;
  }
`;

const StyleSelectCourse: typeof Select = styled(Select)`
  height: 40px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  border-radius: 5px;
  & .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    & .ant-select-selection-search {
        height: 40px !important;
     }
     & .ant-select-selection-search-input {
        height: 40px !important;
     }
     & .ant-select-selection-item {
        line-height: 40px !important;
     }
     & .ant-select-selection-placeholder {
        line-height: 40px !important;
     }
    }
  }
` as any;
const { Option } = Select;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
export function TableReviewListSchoolPgdQ3() {
  const location = useLocation();
  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  useInjectReducer({ key: address.sliceKey, reducer: address.reducer });
  useInjectSaga({ key: address.sliceKey, saga: AddressSaga });

  const dispatch = useDispatch();

  const listReview: any = useSelector(selectListReview);
  const loading: any = useSelector(selectLoading);

  const [isModalConfirmDel, setIsModalConfirmDel] = useState<boolean>(false);
  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [gradeId, setGradeId] = useState<number>(1);
  const toast = useToast();
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isCreateInitiative, setIsCreateInitiative] = useState<boolean>(false);
  const [isCreateInitiativeDKSK, setIsCreateInitiativeDKSK] = useState<boolean>(false);

  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listSchool: any = useSelector(selectListSchools);
  const listSchoolOption: any = useSelector(selectListSchoolsOption);
  const schoolDetail: any = useSelector(selectSchoolDetail);
  const listProvinces: any = useSelector(selectListProvinces);
  const listDistricts: any = useSelector(selectListDistricts);

  const createReviewHttp = new InitiativeAchievementHttp();
  const navigate = useNavigate();

  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<any[]>([]);
  const [school, setSchool] = useState<any[]>([]);
  const [schoolId, setSchoolId] = useState(0);
  const [districtId, setDistrictId] = useState(0); // 10 default quan 1
  const [loading1, setLoading1] = useState<boolean>(true);
  const [filterData, setFilterData] = useState({
    key_search: titleReview,
    name: titleReview,
    provinceId: 1,
    districtId: 10,
    schoolId: schoolId,
    courseId: 0,
    perPage: 10,
    pageId: 1
  });
  // const [filterSchool, setFilterSchool] = useState({
  //   provinceId:1,
  //   districtId: 0,
  //   pageId: 1,
  //   perPage: 10,
  // });
  const onSelectYear = (value: string): void => {
    setYearSchool(value);
  };

  //const { filters, setFilters } = useAuth();
  //const createReviewHttp = new CreateReviewHttp();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
    // setTimeout(() => {
    //   var arr = new Array();
    //   var obj = new Object();
    //   if(!_.isEmpty(listSchool)){
    //     listSchool.forEach(ls => {
    //       obj = {value: ls.id, label: ls.name};
    //       arr.push(obj);
    //     });
    //     setSchool(arr);
    //   }
    // }, 500);
  };

  useEffectOnMount(() => {
    dispatch(address.actions.getProvinces());
    dispatch(address.actions.getDistricts({ province_id: 1 }));
    dispatch(common.actions.getListSchool(filterData));
  });

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const onClickDetail = (id: string, name: string) => {
    setIdReview(id);
    setTitle(name);
    setIsOpenDetail(!isOpenDetail);
  };

  const handleSendToSo = () => {
    if (yearSchool == null || yearSchool == '') {
      toast?.pushWarning('Năm học không tồn tại. Vui lòng chọn năm học.');
      return false;
    }
    const request = {
      courseId: yearSchool,
      ma_cong_chuc: '',
      id_num: '',
      name: '',
      user_fullname: '',
      action: 'dang_ky', // dang_ky hoặc huy_dang_ky
      page_id: -1,
      per_page: -1
    };
    createReviewHttp.sendInitiativesToSo(request).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess('Gửi danh sách sáng kiến thành công.');
      } else {
        toast?.pushError('Gửi danh sách sáng kiến không thành công.');
      }
      setIsModalConfirm(!isModalConfirm);
    });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listSchool)) {
      if (listSchool.length < filterData.perPage) return true;
      return false;
    }
    return true;
  }, [listSchool]);

  const handleRefresh = () => {
    //dispatch(common.actions.getListSchool(filterData));
  };

  const onChangeValue = (e) => {
    if (e.target.name == 'keyword') {
      setTitleReview(e.target.value);
    }
  };

  // const onChangeScore = (e) => {
  //   setScore(e.target.value);
  // };

  const setNewFilterData = (newFilter) => {
    setFilterData(newFilter);
  };

  // const setNewFilterDataOption = (newFilter) => {
  //   setFilterSchool(newFilter);
  // };
  // const onChangeOptionDistrict = (value) => {
  //   const newFilter = Object.assign({}, filterSchool, {
  //     districtId: parseInt(value),
  //     pageId: filterSchool.pageId,
  //     perPage: filterSchool.perPage,
  //   });
  //   setNewFilterDataOption(newFilter);
  //   setDistrictId(value);
  //   dispatch(common.actions.getListSchool(newFilter));
  // };

  const onChangeOptionDistrict = (value) => {
    setLoading1(true);
    const newFilter = Object.assign({}, filterData, {
      districtId: parseInt(value),
      pageId: filterData.pageId,
      perPage: filterData.perPage
    });
    setNewFilterData(newFilter);
    setDistrictId(value);
    dispatch(common.actions.getListSchool(newFilter));
  };

  const onChangeOptionSchool = (value) => {
    const newFilter = Object.assign({}, filterData, {
      schoolId: parseInt(value)
    });
    setNewFilterData(newFilter);
    setSchoolId(value);
  };

  const handleSearch = () => {
    setLoading1(true);
    const newFilter = Object.assign({}, filterData, {
      districtId: districtId,
      //schoolId: schoolId,
      name: titleReview,
      perPage: filterData.perPage,
      pageId: filterData.pageId
    });
    setNewFilterData(newFilter);
    //dispatch(common.actions.getSchoolDetail({schoolId}));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: '4%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filterData.pageId - 1) * filterData.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Mã đơn vị',
      dataIndex: 'code',
      key: 'code',
      width: '15%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Tên trường',
      dataIndex: 'name',
      key: 'name',
      width: '31%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{text}</span>;
      }
    },
    {
      title: 'Quận/Huyện',
      //dataIndex: 'district_id',
      key: 'district_id',
      width: '20%',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>
            {!_.isEmpty(listDistricts) &&
              FunctionUtils.parseTitleDistricts(listDistricts, record.district_id)}
          </span>
        );
      }
    },
    {
      title: 'Danh sách thi đua của giáo viên',
      // width: '20%',
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        setLoading1(false);
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <Link to={`/list-school-so/` + record.id + `?grade_id=${gradeId}`}>
                <span style={{ color: '"#1890ff', cursor: 'pointer' }}>Xem chi tiết</span>
              </Link>
            </Tooltip>
          </Space>
        );
      }
    },
    {
      title: 'Danh sách thi đua tập thể',
      // width: '20%',
      key: 'action',
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <Link to={`/list-collective-competition/` + record.id}>
                <span style={{ color: '"#1890ff', cursor: 'pointer' }}>Xem chi tiết</span>
              </Link>
            </Tooltip>
          </Space>
        );
      }
    }
    // {
    //   title: 'Danh sách giáo viên nộp sáng kiến',
    //   // width: '20%',
    //   key: 'action',
    //   align: 'center',
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#91d5ff' }
    //     };
    //   },
    //   render: (record) => {
    //     return (
    //       <Space>
    //         <Tooltip title="Xem chi tiết" placement="bottomLeft">
    //             <Link to={`${record.id}`} target="_blank" >
    //                 <span style={{ color: '"#1890ff', cursor: 'pointer' }}>Xem chi tiết</span>
    //             </Link>
    //           </Tooltip>
    //       </Space>
    //     );
    //   }
    // },
  ];

  return (
    <div className="pb-0">
      {!_.isEmpty(listSchool) && loading1 && <AppLoading loading={true} />}
      <Helmet>
        <title>Danh sách trường trực thuộc Sở</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      {/* {TITLE_NAVBAR.INTITIATIVE_ACHIEVAMENT_SO} */}
      <div className="px-15">
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-9 gap-15 sm:gap-10">
              <div className="md:span-3">
                <div className="rounded-10  text-primary inline-block h-32 w-32 rounded-10 text-center py-8 text-15 mr-10">
                  <i className="fas fa-home" />
                </div>
                <span>Danh sách trường trực thuộc Sở</span>
              </div>
              <div className="md:span-2">
                <StyleSelectCourse
                  style={{
                    width: '100%'
                  }}
                  placeholder="Chọn quận/huyện"
                  onChange={onChangeOptionDistrict}>
                  <Option value={0} key={0}>
                    {' '}
                    Tất cả{' '}
                  </Option>
                  {!_.isEmpty(listDistricts) &&
                    _.orderBy(listDistricts, ['name'], ['desc']).map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.title}
                      </Option>
                    ))}
                </StyleSelectCourse>
              </div>
              {/* <div className="md:span-3">
                    <Select
                    style={{
                      width: '100%'
                    }}
                      onChange={onChangeOptionSchool}
                      showSearch
                      //className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-cbce shadow-lg rounded-5 px-15"
                      placeholder="Chọn hoặc tìm kiếm trường"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={school}
                    />
                </div> */}
              <div className="md:span-3">
                <input
                  name="keyword"
                  id="keyword"
                  type="text"
                  className="w-full h-40 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                  placeholder="Nhập tên trường"
                  onChange={onChangeValue}
                />
              </div>
              <ButtonSubmitSearch text="Tìm kiếm" handleClick={handleSearch} />
            </div>
          </Card>
        </ContainerHeader>

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                //dataSource={!_.isEmpty(listReview) && listReview}
                dataSource={!_.isEmpty(listSchool) && listSchool}
                //dataSource={!_.isEmpty(schoolDetail) && schoolDetail}
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isModalConfirm && (
        <ModalConfirmBasic
          isOpen={isModalConfirm}
          handleOk={handleSendToSo}
          handleCancel={() => setIsModalConfirm(!isModalConfirm)}
          titleName={'Thầy/Cô có đồng ý gửi danh sách sáng kiến về Sở GD&ĐT TP.HCM?'}
        />
      )}

      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
    </div>
  );
}
