import { WarningOutlined } from '@ant-design/icons';
import { Col, Input, Modal, Row } from 'antd';
import { useToast } from 'components/Toast';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RESPONSE_MESSAGE } from 'store/user/constants/user.constants';
import { UserHttp } from 'store/user/services/user.http';
import styled from 'styled-components';

const IconWarning = styled.p`
  text-align: center;
  color: #faad14;
  & .anticon {
    font-size: 3rem;
  }
`;

interface IChangePassword {
  isOpen: boolean;
  handleCancel: () => void;
}
interface IFormInput {
  password: string;
  rePassword: string;
}
function ChangePassword({ isOpen, handleCancel }: IChangePassword) {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>();

  const userHttp = new UserHttp();
  const toast = useToast();

  const onSubmit = (data: IFormInput) => {
    const request = {
      new_password: data.password,
      confirmed_new_password: data.rePassword
    };
    userHttp.activeUser(request).then((res) => {
      try {
        if (res.ok) {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.ACTIVE_ACCOUNT);
          handleCancel();
        } else {
          toast?.pushError(RESPONSE_MESSAGE.FAIL.ACTIVE_ACCOUNT);
          handleCancel();
        }
      } catch (error) {}
    });
  };
  return (
    <Modal
      maskClosable={false}
      centered
      closable={false}
      visible={isOpen}
      title={`Kích hoạt tài khoản`}
      footer={null}>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <IconWarning>
            <WarningOutlined />
          </IconWarning>
          <h3 style={{ textAlign: 'center' }}>
            Vui lòng thay đổi mật khẩu để kích hoạt tài khoản!
          </h3>
          <Row wrap justify="space-between" align="middle">
            <Col span={24} className="mb-5">
              <h4>Mật khẩu mới</h4>
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 4, pattern: /^[a-zA-Z0-9]*$/ }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.password?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.password?.type === 'required' && (
                <span className="text-error">Mật khẩu không được để trống!</span>
              )}
              {errors?.password?.type === 'minLength' && (
                <span className="text-error">Mật khẩu có ít nhất 4 ký tự!</span>
              )}
              {errors?.password?.type === 'pattern' && (
                <span className="text-error">Mật khẩu không được có dấu hoặc khoảng trống!</span>
              )}
            </Col>
            <Col span={24}>
              <h4>Nhập lại mật khẩu</h4>
              <Controller
                name="rePassword"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => value === getValues('password')
                }}
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    status={errors?.rePassword?.type === 'required' ? 'error' : ''}
                    size="large"
                    placeholder="Nhập mật khẩu"
                    prefix={<i className="fas fa-lock" />}
                  />
                )}
              />
              {errors?.rePassword?.type === 'required' && (
                <span className="text-error">Nhập lại mật khẩu không được để trống!</span>
              )}
              {errors?.rePassword?.type === 'validate' && (
                <span className="text-error">Nhập lại mật khẩu không trùng khớp!</span>
              )}
            </Col>
          </Row>
          <div className="flex justify-end mt-20">
            <button type="submit" className="btn btn-add ml-10">
              Kích hoạt
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePassword;
