export const CompetitionRatingEduApi = {
  LIST_REVIEW_EDU: '/so/evaluations',
  LIST_REVIEW_PGD: '/evaluations',
  LIST_REVIEW_EDU_DETAIL: '/so/evaluations/list_evaluation_schools',
  ADD_REVIEW_EDU: '/so/evaluations',
  ADD_REVIEW_PGD: '/evaluations',
  DELETE_REVIEW_EDU: '/so/evaluations',
  DETAIL_REVIEW_EDU: '/so/evaluations',
  DETAIL_REVIEW_PGD: '/evaluations',
  UPDATE_REVIEW_EDU: '/so/evaluations/save_standard_criteria',
  UPDATE_REVIEW_PGD: '/evaluations/save_standard_criteria',
  LIST_REVIEW_SCHOOLS_EDU: '/so/evaluations/list_evaluation_schools',
  STATISTICAL_REVIEW_EDU: '/so/evaluations/stats',
  COPY_REVIEW_EDU: '/so/evaluations/copy',
  DETAIL_EVALUATION_ROLE_EDU_OF_SCHOOL: '/so/evaluations/school',
  URL_EVALUATION_SEND_RESULT_TO_SO: '/so/evaluations/pcm_send_result_to_so',
  URL_EVALUATION_APPROVE_REJECT: '/evaluations/cham_lai/approve_reject',
  URL_MANAGE_GRADES: '/pgd/manage_grades'
};

export const RESPONSE_MESSAGE = {
  SUCCESS: {
    ADD_REVIEW_EDU_SUCCESS: 'Thêm đánh giá thành công!',
    ADD_SUCCESS: 'Thêm mới thành công!',
    DELETE_REVIEW_EDU_SUCCESS: 'Xóa đánh giá thành công!',
    ADD_RATING_EDU_SUCCESS: 'Thêm xếp loaị thành công!',
    ADD_STANDARD_EDU_SUCCESS: 'Thêm tiêu chuẩn thành công!',
    EDIT_RATING_EDU_SUCCESS: 'Chỉnh sửa xếp loại thành công!',
    REMOVE_RATING_EDU_SUCCESS: 'Xóa xếp loại thành công!',
    EDIT_REVIEW_EDU_SUCCESS: 'Chỉnh sửa đánh giá thành công!',
    EDIT_STANDARD_EDU_SUCCESS: 'Chỉnh sửa tiêu chuẩn, tiêu chí thành công!',
    UPDATE_SELF_ASSESSEMENT_EDU_SUCCESS: 'Cập nhật tự đánh giá thành công',
    CREATE_EVALUATION_BY_ADMIN_EDU_SUCCESS: 'Thêm đánh giá từ quản lý thành công!',
    COPY_REVIEW_EDU_SUCCESS: 'Copy đánh giá thành công!',
    UPDATE_EDU_SUCCESS: 'Cập nhật thành công',
    ADD_EDU_SUCCESS: 'Thêm mới thành công',
    DELETE_EDU_SUCCESS: 'Xóa thành công',
    UPDATE_DECISION_EDU_NO: 'Cập nhật số quyết định thành công!',
    PUBLISH_EVALUATION: 'Công bố thành công!',
    CANCEL_PUBLISH_EVALUATION: 'Đã hủy công bố thành công!'
  },
  FAIL: {
    ADD_REVIEW_EDU_FAIL: 'Thêm đánh giá thất bại!',
    ADD_EDU_FAIL: 'Thêm mới thất bại!',
    UPDATE_EDU_FAIL: 'Cập nhật thất bại!',
    DELETE_REVIEW_EDU_FAIL: 'Xóa đánh giá Thất bại!',
    ADD_RATING_EDU_FAIL: 'Thêm xếp loại thất bại!',
    ADD_STANDARD_EDU_FAIL: 'Thêm tiêu chuẩn thất bại!',
    EDIT_RATING_EDU_FAIL: 'Chỉnh sửa xếp loại thất bại!',
    REMOVE_RATING_EDU_FAIL: 'Xóa xếp loại thất bại!',
    EDIT_REVIEW_EDU_FAIL: 'Chỉnh sửa đánh giá thất bại!',
    EDIT_STANDARD_EDU_FAIL: 'Chỉnh sửa tiêu chuẩn, tiêu chí thất bại!',
    CREATE_EVALUATION_BY_ADMIN_EDU_FAIL: 'Thêm đánh giá từ quản lý thất bại!',
    SCORE_GREATER_THAN_DEFAULT_EDU:
      'Thầy, cô vui lòng nhập lại điểm, lưu ý điểm số không được cao hơn điểm chuẩn!',
    SORE_CHECK_NEGATIVE_EDU: 'Điểm đánh giá không được âm, thầy cô vui lòng nhập điểm lại!',
    COPY_REVIEW_EDU_FAIL: 'Copy đánh giá thất bại!'
  },
  WARN: {
    ADD_REVIEW_EDU_SUCCESS: 'Vui lòng truyền đầy đủ thông tin đánh giá!',
    WARNING_SCORE_EDU: 'Điểm đánh giá không được lớn hơn điểm chuẩn!',
    WARNING_CREATE_STANDARD_EDU:
      'Tổng điểm các tiêu chí phải bằng điềm tiêu chuẩn. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_RATING_EDU:
      'Số điểm kết thúc không được nhỏ hơn hoặc bằng điểm bắt đầu. Vui lòng kiểm tra lại thông tin đã nhập!',
    WARNING_CREATE_SCORE_FROM_RATING_EDU:
      'Số điểm bắt đầu không được nhỏ hơn hoặc bằng số điểm kết thúc của xếp loại trước!',
    WARNING_SELECT_YEAR_EDU: 'Vui lòng chọn năm!'
  }
};
