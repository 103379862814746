export const CommonApi = {
  GET_PERIODS: '/periods',
  GET_COURSES: '/courses',
  UPLOAD_URL: '/presigned_upload_url',
  GET_EFFECT_SCOPES: '/evaluation_effect_scopes',
  GET_PROVINCES: '/provinces',
  GET_DISTRICTS: '/districts',
  GET_WARDS: '/wards',
  LIST_SCHOOL: '/so/schools',
  LIST_SCHOOL_PGD: '/pgd/schools'
};
