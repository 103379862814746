import { FrownOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/lib/locale/vi_VN';
import React from 'react';

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: 'center'
    }}>
    <FrownOutlined
      style={{
        fontSize: 24
      }}
    />
    <p>Không tìm thấy dữ liệu</p>
  </div>
);

function ConfigProviderCustomize({ children }) {
  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty} locale={vi_VN}>
      <div className="config-provider">{children}</div>
    </ConfigProvider>
  );
}

export default ConfigProviderCustomize;
