import { Select } from 'antd';
import {
  RightContainer,
  LeftContainer,
  HorizontalFilterLayout
} from 'components/styles/FilterLayoutStyle';
import { useMemo } from 'react';
import { isAvailableArray } from 'utils/arrayUtils';

interface FilterProps {
  defaultValueYear: any;
  listCoursesBase: any;
  handleChangeCourses: (event: any) => void;

  valueUnit: any;
  listUnit: any;
  handleChooseDonVi: (event: any) => void;

  buttons: JSX.Element[];
}

function Filter({
  defaultValueYear,
  listCoursesBase,
  handleChangeCourses,

  valueUnit,
  listUnit,
  handleChooseDonVi,

  buttons
}: FilterProps) {
  // Memories
  const courseBaseOptions = useMemo(() => {
    if (!isAvailableArray(listCoursesBase)) return [];

    return listCoursesBase.map((item) => ({
      value: item.id,
      label: item.name
    }));
  }, [listCoursesBase]);

  const unitOptions = useMemo(() => {
    if (!isAvailableArray(listUnit)) return [];

    return listUnit.map((item) => ({
      value: item.id,
      label: item.title
    }));
  }, [listUnit]);

  return (
    <HorizontalFilterLayout>
      <LeftContainer>
        <Select
          placeholder="Năm học"
          style={{ width: '200px' }}
          onChange={($event) => handleChangeCourses($event)}
          value={defaultValueYear}
          options={courseBaseOptions}
        />
        <Select
          style={{ width: '200px' }}
          onChange={handleChooseDonVi}
          value={valueUnit}
          placeholder="Chọn đơn đơn vị"
          options={unitOptions}
        />
      </LeftContainer>

      <RightContainer>{buttons.map((button) => button)}</RightContainer>
    </HorizontalFilterLayout>
  );
}

export default Filter;
