import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

export const RequireAuthTeacher = ({
  children,
  role
}: {
  children: JSX.Element;
  role: boolean;
}) => {
  const { auth } = useAuth();
  const userHasRequiredRole = auth && auth.teacher === role ? true : false;
  return userHasRequiredRole ? children : <Navigate to="/login" />;
};
