import { HttpRequest } from 'services/api';
import { APP_CONSTANT, YEAR_ID_DEFAULT, YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';
import { evaluationApi } from '../constants/evaluation.constants';

export class EvaluationHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getApi = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.GET_API +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || 0
        }&status=${filters.statusId}&emulation_type_id=${
          filters.evaluationTypeId || ''
        }&title_type_id=${filters.title_type_id || ''}&key_search=${filters.key_search || ''}`
    );
  };

  public deleteApi = (id): Promise<any> => {
    return this.request.delete(evaluationApi.GET_API + `/${id}`);
  };

  public getListEvaluationTitleTearch = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_ADMIN_EVALUATION_TITLES +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || 12
        }&status=${filters.statusId}&emulation_type_id=${
          filters.evaluationTypeId || ''
        }&title_type_id=${filters.title_type_id || ''}&key_search=${
          filters.key_search || ''
        }&school_id=${filters.schoolId || ''}`
    );
  };

  public getDoeListEvaluationTitleTearch = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_DOE_EVALUATION_TITLES +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || ''
        }&status=${filters.statusId}&emulation_type_id=${
          filters.evaluationTypeId || ''
        }&title_type_id=${filters.title_type_id || ''}&key_search=${
          filters.key_search || ''
        }&school_id=${filters.schoolId}`
    );
  };

  public getListEvaluationTitleAminExportFile = (filters, infoSchool): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_ADMIN_EVALUATION_TITLES_EXPORT_FILE +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || ''
        }&emulation_type_id=${filters.evaluationTypeId || ''}&title_type_id=${
          filters.title_type_id || ''
        }&key_search=${filters.key_search || ''}&school_id=${
          infoSchool.school_id
        }&time=${dateNow.getTime()}`
    );
  };

  public getDetailApi = (paramUrl): Promise<any> => {
    return this.request.get(evaluationApi.GET_API + `/${paramUrl.id}/${paramUrl.periodId}`);
  };

  public getDetailUser = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLE_INITIATIVES +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&user_id=${filters.userId}`
    );
  };

  public getListEvaluationEmulationTypes = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_EMULATION_TYPES + `?page_id=1&per_page=20`
    );
  };

  public getListEvaluationTitleGroup = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLE_GROUP +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&is_tap_the=${
          filters.isTapThe
        }&school_id=${filters.schoolId || ''}`
    );
  };

  public getListAdminEvaluationTitleGroup = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_ADMIN_EVALUATION_TITLES +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}
        }&school_id=${filters.schoolId || ''}`
    );
  };

  public getListEvaluationDecisionsNo = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_DECISIONS_NO +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId
        }&emulation_type_id=${filters.emulationTypeId}&title_type_id=${
          filters.titleTypeId
        }&user_type=${filters.userType}&pgd_id=${filters.pgd_id || -1}`
    );
  };

  public getListEvaluationTitleTypes = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLE_TYPES +
        `?page_id=1&per_page=100&emulation_type_id=${filters.evaluationTypeId || 0}&user_type=${
          filters.userType || ''
        }`
    );
  };

  public getListEvaluationTitleTypesConstraint = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLE_TYPES_CONSTRAINT +
        `?page_id=1&per_page=100&emulation_type_id=${filters.evaluationTypeId || 0}&user_type=${
          filters.userType || ''
        }&course_id=${filters.courseId}&current_year=${filters.currentYear}`
    );
  };

  public updateReview = (data): Promise<any> => {
    return this.request.post(evaluationApi.GET_API + '/save_evaluation', data);
  };

  public createdEvaluationTitle = (data): Promise<any> => {
    return this.request.post(evaluationApi.GET_API, data);
  };

  public updateEvaluationTitle = (data, id): Promise<any> => {
    return this.request.patch(evaluationApi.GET_API + '/' + id, data);
  };

  public createdEvaluationTitleType = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_TITLE_TYPES, data);
  };

  public registerAllEvaluationTitle = (data): Promise<any> => {
    return this.request.post(
      `${evaluationApi.GET_API}${evaluationApi.URL_EVALUATION_TITLE_REGISTER_ALL}`,
      data
    );
  };

  public unRegisterEvaluationTitleDoe = (data): Promise<any> => {
    return this.request.post(`${evaluationApi.URL_EVALUATION_UNREGISTER_SO}`, data);
  };

  public unRegisterEvaluationTitlePgd = (data): Promise<any> => {
    return this.request.post(`${evaluationApi.URL_EVALUATION_UNREGISTER_PGD}`, data);
  };

  public requestTosoEvaluationTitle = (data): Promise<any> => {
    return this.request.post(
      `${evaluationApi.GET_API}${evaluationApi.URL_EVALUATION_TITLE_REQUEST_SO}`,
      data
    );
  };

  public registerEvaluationTitleGroup = (data): Promise<any> => {
    return this.request.post(`${evaluationApi.URL_EVALUATION_TITLE_GROUP}`, data);
  };

  public UpdataRegisterEvaluationTitleGroup = (data, id): Promise<any> => {
    return this.request.patch(`${evaluationApi.URL_EVALUATION_TITLE_GROUP}/${id}`, data);
  };

  public registerAdminEvaluationTitleGroup = (data): Promise<any> => {
    return this.request.post(`${evaluationApi.URL_ADMIN_EVALUATION_TITLES}`, data);
  };

  public UpdataAdminRegisterEvaluationTitleGroup = (data, id): Promise<any> => {
    return this.request.patch(`${evaluationApi.URL_ADMIN_EVALUATION_TITLES}/${id}`, data);
  };

  public updataUserInfo = (data, userId): Promise<any> => {
    return this.request.patch(`${evaluationApi.URL_UPDATA_USER_INFO}/${userId}`, data);
  };

  // connect api thư viện
  public getListEvaluationlibraries = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_LIBARIES +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&type=${filters.type || 0}&title=${
          filters.key_search || ''
        }`
    );
  };

  public postEvaluationlibraries = (data): Promise<any> => {
    return this.request.post(`${evaluationApi.URL_LIBARIES}`, data);
  };

  public patchEvaluationlibraries = (data): Promise<any> => {
    return this.request.patch(`${evaluationApi.URL_LIBARIES}/${data.id}`, data);
  };

  public deleteEvaluationlibraries = (libraryId): Promise<any> => {
    return this.request.delete(`${evaluationApi.URL_LIBARIES}/${libraryId}`);
  };

  // connect api danh sách thi đua cá nhân tk sở
  public getListEvaluationTitlesPersonal = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_PERSONAL +
        `?grade_id=${filters.gradeId}&course_id=${filters.courseId}`
    );
  };

  public getListEvaluationTitlesPersonalExportFile = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_SO +
        `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
    );
  };

  // public getListEvaluationTitlesPersonalExportFilePgd = (filters?): Promise<any> => {
  //   const dateNow = new Date();
  //   return this.request.get(
  //     evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD +
  //       `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
  //   );
  // };
  public getListEvaluationTitlesPersonalExportFilePgd = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD +
        `?evaluation_code_id=${filters.evaluationCodeId}&course_id=${
          filters.courseId
        }&time=${dateNow.getTime()}`
    );
  };

  public getListEvaluationTitlesPersonalExportFilePgdNew = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD_NEW +
        `?course_id=${filters.courseId}&is_personal=${filters.IsPersonal}&time=${dateNow.getTime()}`
    );
  };

  public getListEvaluationTitlesGroupExportFile = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_GROUP_SO +
        `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
    );
  };

  // public getListEvaluationTitlesGroupExportFilePgd = (filters?): Promise<any> => {
  //   const dateNow = new Date();
  //   return this.request.get(
  //     evaluationApi.URL_EVALUATION_EXPORT_FILE_GROUP_PGD +
  //       `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
  //   );
  // };
  public getListEvaluationTitlesGroupExportFilePgd = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_GROUP_PGD +
        `?evaluation_code_id=${filters.evaluationCodeId}&course_id=${
          filters.courseId
        }&time=${dateNow.getTime()}`
    );
  };

  public getListEvaluationTitlesGroupExportFilePgdNew = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_PGD_NEW +
        `?course_id=${filters.courseId}&is_personal=${filters.IsPersonal}&time=${dateNow.getTime()}`
    );
  };

  // connect api danh sách thi đua tập thể tk sở
  public getListEvaluationTitlesGroup = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_GROUP +
        `?grade_id=${filters.gradeId}&course_id=${filters.courseId}`
    );
  };

  // lấy danh sách biên bản của trường
  public getListEvaluationFileReport = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_FILE_REPORT +
        `?school_id=${filters.schoolId}&course_id=${filters.courseId}&is_pgd=${
          filters.isPgd || false
        }`
    );
  };

  // start connect api cấp số quyết định của trường
  public getListEvaluationDecisionNo = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${filters.courseId}&type=${filters.type}`
    );
  };

  public getEvaluationApproveDecisionNoId = (DecisionNoId): Promise<any> => {
    return this.request.get(`${evaluationApi.URL_EVALUATION_DECISION_NO}/${DecisionNoId}`);
  };

  public postEvaluationApproveDecisionNo = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO, data);
  };

  public deleteEvaluationApproveDecisionNoId = (DecisionNoId): Promise<any> => {
    return this.request.delete(
      `${evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO}/${DecisionNoId}`
    );
  };
  // end connect api cấp số quyết định của trường

  // lấy ngày đóng đăng kí thi đua
  public getLockDayRegisterEvaluationPersonal = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL +
        `?course_id=${filters.courseId}&type=${filters.type}&config_type=${
          filters.config_type || 0
        }`
    );
  };

  public getLockDayRegisterSchool = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL +
        `?course_id=${filters.courseId}&type=${filters.type}&school_id=${
          filters.schoolId
        }&config_type=${filters.config_type || 0}`
    );
  };

  public postLockDayRegisterEvaluationPersonal = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL, data);
  };

  public updateExtraInitiativeForSchool = (data): Promise<any> => {
    return this.request.post(
      evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL + '/extra_initiative',
      data
    );
  };

  // start connect api cấp số quyết định của sở
  public getListEvaluationDecisionNoSo = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO_SO +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId
        }&type=${filters.type}&is_khoi_28=${filters.isKhoi28 || false}`
    );
  };

  public postEvaluationApproveDecisionNoSo = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO_SO, data);
  };

  public deleteEvaluationApproveDecisionNoSo = (id): Promise<any> => {
    return this.request.delete(evaluationApi.URL_EVALUATION_APPROVE_DECISION_NO_SO + '/' + id);
  };

  // quản lý chấm sáng kiến role giáo viên

  public getEvaluationInitiativesScoreMe = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_INITIATIVES_SCORE_ME +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${filters.courseId}`
    );
  };

  public postEvaluationInitiativesScore = (data, id): Promise<any> => {
    return this.request.patch(evaluationApi.URL_EVALUATION_INITIATIVES_SCORE + '/' + id, data);
  };

  public getListEvaluationResultEndYear = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${filters.courseId}&is_filter_bktp=${filters.is_filter_bktp}`
    );
  };

  public postListEvaluationSendResultEndYear = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_SEND_RESULT_END_YEAR, data);
  };

  public postListEvaluationSendUserDescription = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_SEND_USER_DESCRIPTION, data);
  };

  // xuất file kết quả cuối năm menu sở
  public exportEvaluationSummaryResultEndYear = (filter): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_SUMMARY_EXPORT +
        `?course_id=${filter.courseId}&time=${dateNow.getTime()}`
    );
  };

  public getListEvaluationResultEndYearPersonal = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_PERSONAL +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || YEAR_ID_DEFAULT
        }&school_id=${filters.schoolId}`
    );
  };

  public getListEvaluationResultEndYearGroup = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_GROUP +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || YEAR_ID_DEFAULT
        }&school_id=${filters.schoolId}&grade_id=${filters.gradeId}`
    );
  };

  public getListEvaluationResultEndYearEXportFile = (filters?): Promise<any> => {
    const dateNow = new Date();
    return this.request.get(
      evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_EXPORT_FILE +
        `?course_id=${filters.courseId || YEAR_ID_DEFAULT}&time=${dateNow.getTime()}`
    );
  };

  public EvaluationResultEndYearUpdate = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_UPDATE, data);
  };

  public EvaluationResultEndYearSendSo = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_SENDSO, data);
  };

  public EvaluationResultEndYearLock = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_LIST_RESULT_END_YEAR_LOCK, data);
  };

  // connect api danh sách thi đua cá nhân PGD quận 3
  public getListEvaluationTitlesPersonalPgdQ3 = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_PERSONAL_PGDQ3 +
        `?grade_id=${filters.gradeId || 0}&course_id=${filters.courseId || YEAR_ID_DEFAULT_PGDQ3}`
    );
  };

  // connect api danh sách thi đua cá nhân PGD quận 3 => làm mới
  public getListEvaluationTitlesPersonalPgdQ3New = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_PERSONAL_PGDQ3_NEW +
        `?course_id=${filters.courseId || YEAR_ID_DEFAULT_PGDQ3}`
    );
  };

  // public getListEvaluationTitlesPersonalExportFile = (filters?): Promise<any> => {
  //   const dateNow = new Date();
  //   return this.request.get(
  //     evaluationApi.URL_EVALUATION_EXPORT_FILE_PERSONAL_SO +
  //       `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
  //   );
  // };

  // public getListEvaluationTitlesGroupExportFile = (filters?): Promise<any> => {
  //   const dateNow = new Date();
  //   return this.request.get(
  //     evaluationApi.URL_EVALUATION_EXPORT_FILE_GROUP_SO +
  //       `?grade_id=${filters.gradeId}&course_id=${filters.courseId}&time=${dateNow.getTime()}`
  //   );
  // };
  // connect api danh sách thi đua tập thể tk sở PGD quận 3
  public getListEvaluationTitlesGroupPgdQ3 = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_GROUP_PGDQ3 +
        `?grade_id=${filters.gradeId || 100}&course_id=${filters.courseId || YEAR_ID_DEFAULT_PGDQ3}`
    );
  };

  // connect api danh sách thi đua tập thể tk sở PGD quận 3 => làm mới
  public getListEvaluationTitlesGroupPgdQ3New = (filters?): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLES_GROUP_PGDQ3_NEW +
        `?course_id=${filters.courseId || YEAR_ID_DEFAULT_PGDQ3}`
    );
  };

  public postLockDayRegisterEvaluationPersonalPgd = (data): Promise<any> => {
    return this.request.post(evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL_PGD, data);
  };

  public updateExtraInitiativeForSchoolPgd = (data): Promise<any> => {
    return this.request.post(
      evaluationApi.URL_EVALUATION_LOCK_REGISTER_PERSONAL_PGD + '/extra_initiative',
      data
    );
  };

  public getDetailUserPgd = (filters): Promise<any> => {
    return this.request.get(
      evaluationApi.URL_EVALUATION_TITLE_INITIATIVES_PGD +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&user_id=${filters.userId}`
    );
  };
}
