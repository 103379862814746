import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { SelfAssessmentApi } from '../constants/selfAssessment.constants';

export class SelfAssessmentHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getApi = (filters): Promise<any> => {
    return this.request.get(
      SelfAssessmentApi.GET_API +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || 0
        }&status=${filters.statusId}`
    );
  };

  public getApiSchool = (filters): Promise<any> => {
    console.log('this.request', this.request);
    return this.request.get(
      SelfAssessmentApi.GET_API_SCHOOL +
        `?page_id=${filters.pageId}&per_page=${filters.perPage}&course_id=${
          filters.courseId || 0
        }&status=${filters.statusId}`
    );
  };

  public getDetailApi = (paramUrl): Promise<any> => {
    return this.request.get(SelfAssessmentApi.GET_API + `/${paramUrl.id}/${paramUrl.periodId}`);
  };

  public getDetailApiSchool = (paramUrl): Promise<any> => {
    return this.request.get(
      SelfAssessmentApi.GET_API_SCHOOL + `/${paramUrl.id}/${paramUrl.periodId}`
    );
  };

  public updateReview = (data): Promise<any> => {
    return this.request.post(SelfAssessmentApi.GET_API + '/save_evaluation', data);
  };

  public updateReviewEduSchool = (data): Promise<any> => {
    return this.request.post(SelfAssessmentApi.GET_API + '/save_evaluation/so', data);
  };

  public updateSelfEvaluation = (data): Promise<any> => {
    return this.request.post(SelfAssessmentApi.UPDATE_SELF_EVALUATION, data);
  };
}
