import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Card, Input, Select, Space, Tooltip } from 'antd';
import { TITLE_NAVBAR, YEAR_ID_DEFAULT } from '_constants/default.constants';

import * as common from 'store/common/shared/slice';
import * as FromCreateInitiativeSlice from 'store/initiativeAchievement/shared/slice';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { selectListCoursesBase } from 'store/common/shared/selectors';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import { CommonSaga } from 'store/common/shared/saga';
import ButtonCreate from 'components/ButtonCreate';
import Table, { ColumnsType } from 'antd/lib/table';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import useAuth from 'hooks/useAuth';
import Pagination from 'components/Pagination';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';
import { selectDataUser } from 'store/user/shared/selectors';
import ModalNoti from 'components/Modal/ModalNoti';
import ModalEdit from 'views/DecentralizedManagement/components/ModalEdit';
import ModalPrivilegeTeacherSo from '../components/ModalPrivilegeTeacherSo';
import { selectListSchools } from 'store/common/shared/selectors';
import ModalEditDecentralized from '../components/ModalEditDecentralized';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContentButton = styled.div`
  display: flex;
  justify-content: end;
  gap: 6px;
`;

interface DataType {
  key: string;
  id: number;
  ma_cong_chuc: string;
  user_fullname: string;
  position?: string;
  name: string;
  status: number;
}

const roles = [
  {
    value: 1,
    label: 'Giám khảo 1'
  },
  {
    value: 2,
    label: 'Giám khảo 2'
  }
];

export function DecentralizedManagemenSo() {
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  useInjectReducer({
    key: FromCreateInitiativeSlice.sliceKey,
    reducer: FromCreateInitiativeSlice.reducer
  });
  useInjectSaga({ key: FromCreateInitiativeSlice.sliceKey, saga: CreateInitiativeSaga });

  const listSchool: any = useSelector(selectListSchools);
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const listReview: any = useSelector(selectListReview);
  const dataUser: any = useSelector(selectDataUser);
  const localStorage = new LocalStorageService();
  const dispatch = useDispatch();
  const [yearSchool, setYearSchool] = useState<number>(YEAR_ID_DEFAULT);
  const [role, setRole] = useState<number>(1);
  const [titleReview, setTitleReview] = useState<string>('');
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [valueEdit, setValueEdit] = useState<any>();
  const [isOpenNoti, setIsOpenNoti] = useState<boolean>(false);
  const [schoolObj, setSchoolObj] = useState<any>();
  const [isPenPrivilegeTeacher, setIsPenPrivilegeTeacher] = useState(false);
  const [filterData, setFilterData] = useState({
    key_search: '',
    courseId: YEAR_ID_DEFAULT,
    perPage: 10,
    pageId: 1,
    gradeId: 18 //Phòng ban
  });
  const [filterData1, setFilterData1] = useState({
    key_search: '',
    name: '',
    provinceId: 1,
    districtId: 0,
    courseId: YEAR_ID_DEFAULT,
    perPage: 100,
    pageId: 1,
    gradeId: 18,
    type: 0, // 1: thpt,
    isExtraInitiative: true
  });

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, [filterData]);
  };

  useEffectOnMount(() => {
    dispatch(common.actions.getCoursesBase(filterData));
    dispatch(FromCreateInitiativeSlice.actions.getListInitiativeSo(filterData));
    dispatch(common.actions.getListSchool(filterData1));
  });

  const setNewFilters = (newFilter) => {
    setFilterData(newFilter);
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filterData.pageId === 1) return;
    const newFilter = Object.assign({}, filterData, {
      pageId: filterData.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filterData, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filterData.perPage) return true;
      return false;
    }
    return true;
  }, [listReview]);

  const onChangeOptionYear = (value) => {
    const newFilter = Object.assign({}, filterData, {
      courseId: parseInt(value),
      key_search: titleReview
    });
    setNewFilters(newFilter);
    setYearSchool(value);
  };
  const onChangeValue = (e) => {
    setTitleReview(e.target.value);
  };

  const handleSearch = (e) => {
    const newFilter = Object.assign({}, filterData, {
      key_search: titleReview,
      perPage: filterData.perPage,
      pageId: filterData.pageId
    });
    setNewFilters(newFilter);
  };
  const handleRefresh = () => {
    dispatch(FromCreateInitiativeSlice.actions.getListInitiativeSo(filterData));
  };

  const onClickDetail = (item) => {
    if (
      item.description === '' &&
      item.effective === '' &&
      _.isEmpty(item.files) &&
      _.isEmpty(item.files_description)
    ) {
      setIsOpenNoti(true);
      return;
    }
    setSchoolObj(item);
    setIsOpenEdit(true);
    setValueEdit(item);
  };

  const onClickDelete = (item) => {
    setIsOpenModalConfirm(true);
  };

  const handleConfirmDelete = () => {};

  const onChangeOptionYearRole = (e) => {
    setRole(e);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      key: 'id',
      width: 80,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Chấm sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (text) => {
        return <span style={{ color: '#1890ff' }}>{text}</span>;
      }
    },
    {
      title: 'Phòng ban',
      key: 'name',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        const nameSchool = listSchool.find((item) => item.id === record.school_id);
        if (_.isEmpty(nameSchool?.name)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return <span style={{ color: '#1890ff' }}>{nameSchool?.name}</span>;
      }
    },
    {
      title: 'Giám khảo 1',
      key: 'teacher_info_1',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.teacher_info_1)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return <span style={{ color: '#1890ff' }}>{record.teacher_info_1}</span>;
      }
    },
    {
      title: 'Giám khảo 2',
      // dataIndex: 'teacher_info_2',
      key: 'teacher_info_2',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (_.isEmpty(record.teacher_info_2)) {
          return <span style={{ color: '#1890ff' }}>--.--</span>;
        }
        return <span style={{ color: '#1890ff' }}>{record.teacher_info_2}</span>;
      }
    },
    {
      title: 'Tình trạng',
      key: 'status',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        return (
          <span style={{ color: '#1890ff' }}>
            {record.status_cham_diem === 1 && record.status_cham_diem2 === 1
              ? 'Đã chấm'
              : 'Chưa chấm'}
          </span>
        );
      }
    },
    {
      title: 'Hành động',
      key: 'active',
      width: 100,
      align: 'center',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      render: (record) => {
        if (!record.published) {
          return (
            <Space>
              <Tooltip title="Chỉnh sửa phân quyền " placement="bottomLeft">
                <span className="clone" onClick={() => onClickDetail(record)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-edit"></i>
                  </div>
                </span>
              </Tooltip>
            </Space>
          );
        }
      }
    }
  ];

  return (
    <div className="pb-0">
      <Helmet>
        <title>Danh sách giám khảo chấm sáng kiến</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      Danh sách giám khảo chấm sáng kiến
      <div className="px-15">
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-9 gap-15 sm:gap-10">
              <div className="md:span-1">
                <Select
                  placeholder="Chọn năm"
                  style={{
                    width: '100%'
                  }}
                  onChange={($event) => onChangeOptionYear($event)}
                  value={yearSchool}
                  options={
                    !_.isEmpty(listCourseBase) &&
                    listCourseBase.map((item) => {
                      return {
                        value: item.id,
                        label: item.name
                      };
                    })
                  }
                />
              </div>
              {/* <div className="md:span-2">
                <Select
                  placeholder="Vai trò"
                  style={{
                    width: '100%'
                  }}
                  onChange={($event) => onChangeOptionYearRole($event)}
                  value={role}
                  options={roles.map((item) => {
                    return {
                      value: item.value,
                      label: item.label
                    };
                  })}
                />
              </div> */}
              <div className="md:span-4">
                <Input.Search
                  allowClear
                  placeholder="Tên sáng kiến"
                  onChange={(e) => onChangeValue(e)}
                  value={titleReview}
                  onPressEnter={(e) => handleSearch(e)}
                  onSearch={handleSearch}
                  enterButton
                />
              </div>
              {/* <ButtonSubmit className="md:span-2" text="Tìm kiếm" handleClick={handleSearch} /> */}
            </div>
          </Card>
        </ContainerHeader>

        <div className="overflow-wrap">
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                dataSource={!_.isEmpty(listReview) && listReview}
                size="middle"
                bordered
                pagination={false}
                // loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        <div className="mt-30">
          <Pagination
            current={filterData.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filterData.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isOpenModalConfirm && (
        <ModalConfirmBasic
          isOpen={isOpenModalConfirm}
          titleName="Bạn có chắc chắn muốn xóa mục này?"
          handleOk={handleConfirmDelete}
          handleCancel={() => setIsOpenModalConfirm(!isOpenModalConfirm)}
        />
      )}
      {isOpenEdit && (
        <ModalEditDecentralized
          isOpen={isOpenEdit}
          dataDetail={valueEdit}
          dataUser={dataUser}
          handleRefresh={handleRefresh}
          handleCancel={() => setIsOpenEdit(!isOpenEdit)}
          school={schoolObj}
          listDepartment={!_.isEmpty(listSchool) && listSchool}
        />
      )}
      {isOpenNoti && (
        <ModalNoti
          isOpen={isOpenNoti}
          titleName="Giáo viên chưa nhập đầy đủ thông tin sáng kiến!"
          handleCancel={() => setIsOpenNoti(!isOpenNoti)}
        />
      )}
    </div>
  );
}
