import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { TeacherApi } from '../constants/teacher.constants';

export class TeacherHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListTeacher = ({
    subjectId,
    keyword,
    pageId,
    perPage,
    userId,
    schoolId
  }): Promise<any> => {
    return this.request.post(TeacherApi.LIST_TEACHER, {
      from_date: '',
      page_id: pageId,
      per_page: perPage,
      subject_id: subjectId,
      to_date: '',
      keyword,
      show_all: true,
      user_id: userId || 0,
      school_id: schoolId
    });
  };

  public getListTeacherPgd = (data): Promise<any> => {
    return this.request.post(TeacherApi.LIST_TEACHER_PGD, {
      from_date: '',
      page_id: data.pageId,
      per_page: data.perPage,
      subject_id: data.subjectId,
      to_date: '',
      keyword: data.keyword,
      key_search: data.key_search,
      school_id: parseInt(data.school_id.toString()),
      user_id: data.userId
    });
  };

  public getListSubjects = (): Promise<any> => {
    return this.request.post(TeacherApi.EDU_SUBJECTS, {
      page_id: 1,
      per_page: -1
    });
  };

  public removeTeacher = ({ courseId, recordsId }): Promise<any> => {
    return this.request.post(TeacherApi.REMOVE_TEACHER, {
      course_id: courseId,
      records_id: recordsId
    });
  };

  public searchTeachersByMacongchuc = (teacherInfo): Promise<any> => {
    return this.request.post(TeacherApi.SEARCH_TEACHERS_BY_MACONGCHUC, teacherInfo);
  };

  public migrateMacongchuc = (teacherInfo): Promise<any> => {
    return this.request.post(TeacherApi.MIGRATE_MACONGCHUC, teacherInfo);
  };

  public addTeacher = (teachers): Promise<any> => {
    return this.request.post(TeacherApi.ADD_TEACHER, {
      teachers: teachers
    });
  };

  public editTeacher = ({ fullname, subjectId, teacherRecordId }): Promise<any> => {
    return this.request.post(TeacherApi.EDIT_TEACHER, {
      fullname,
      subject_id: subjectId,
      teacher_record_id: teacherRecordId
    });
  };

  public resetPassword = ({ userId, password }): Promise<any> => {
    return this.request.post(TeacherApi.CHANGE_PASSWORD, {
      user_id: userId,
      password
    });
  };

  public changeSubject = ({ course_id, records_id, subject_id }): Promise<any> => {
    return this.request.post(TeacherApi.CHANGE_SUBJECT, {
      course_id,
      records_id,
      subject_id
    });
  };

  public getListGrades = ({ courseId }): Promise<any> => {
    return this.request.post(TeacherApi.LIST_GRADES, {
      course_id: courseId,
      page_id: 1,
      per_page: -1
    });
  };

  public getListClass = ({ course_id, grade_id }): Promise<any> => {
    return this.request.post(TeacherApi.LIST_CLASS_BY_GRADES, {
      course_id,
      grade_id,
      page_id: 1,
      per_page: -1
    });
  };

  public roleTeacher = ({ classes_id, grade_id, user_id }): Promise<any> => {
    return this.request.post(TeacherApi.ROLE_TEACHER, {
      classes_id,
      grade_id,
      user_id
    });
  };

  public addTeacherByFile = ({ course_id, file }): Promise<any> => {
    return this.request.post(TeacherApi.ADD_TEACHER_BY_FILE, {
      course_id,
      file
    });
  };

  public hiddenThudua = (data): Promise<any> => {
    return this.request.post(TeacherApi.HIDDEN_THIDUA, data);
  };
}
