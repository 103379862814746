import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

const ContentContainer = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;

const LeftSpan = styled('span')`
  text-align: left;
  width: 160px;
`;
interface ModelExportFileSchoolProps {
  isOpen: boolean;
  handleClose: () => void;
  handleExportMamNonScorecard: (event: any) => void;
  handleExportTieuHocScorecard: (event: any) => void;
  handleExportTHCSScorecard: (event: any) => void;
}

function ModelExportFiles({
  isOpen,
  handleClose,

  handleExportMamNonScorecard,
  handleExportTieuHocScorecard,
  handleExportTHCSScorecard
}: ModelExportFileSchoolProps) {
  return (
    <Modal
      title="Xuất file"
      centered
      visible={isOpen}
      onCancel={() => handleClose()}
      width={400}
      footer={null}>
      <ContentContainer>
        <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportMamNonScorecard}>
          <LeftSpan>Xuất bảng điểm mầm non.</LeftSpan>
        </Button>
        <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportTieuHocScorecard}>
          <LeftSpan>Xuất bảng điểm Tiểu học.</LeftSpan>
        </Button>
        <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportTHCSScorecard}>
          <LeftSpan>Xuất bảng điểm THCS.</LeftSpan>
        </Button>
      </ContentContainer>
    </Modal>
  );
}

export default ModelExportFiles;
