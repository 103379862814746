import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectTeacher = (state: RootState) => state?.teacher || initialState;

export const selectLoading = createSelector([selectTeacher], (teacher) => teacher.loading);

export const selectError = createSelector([selectTeacher], (teacher) => teacher.error);

export const selectSuccess = createSelector([selectTeacher], (teacher) => teacher.success);

export const selectListTeacher = createSelector([selectTeacher], (teacher) => teacher.listTeacher);
export const selectListSubjects = createSelector(
  [selectTeacher],
  (teacher) => teacher.listSubjects
);
export const selectListTeacherPgd = createSelector(
  [selectTeacher],
  (teacher) => teacher.listTeacherPgd
);
