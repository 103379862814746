import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React, { useEffect } from 'react';

interface IModalConfirm {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

function ModalConfirmRemove({ isOpen, handleOk, handleCancel }: IModalConfirm) {
  return (
    <Modal maskClosable={false} centered visible={isOpen} onCancel={() => handleCancel()} footer={null}>
      <div className="p-18">
        <Space wrap>
          <ExclamationCircleOutlined style={{ fontSize: 25, color: '#FAAD13' }} />
          <span style={{ fontSize: 16 }}>
            Bạn có muốn xóa cơ quan phụ trách này?
          </span>
        </Space>
        <div className="flex justify-end mt-30">
          <Space align="baseline">
            <Button key="back" size="large" onClick={handleCancel}>
              Không
            </Button>
            <Button key="submit" size="large" type="primary" danger onClick={handleOk}>
              Có
            </Button>
          </Space>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(ModalConfirmRemove);
