import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { AddressApi } from '../constants/address.constants';

export class AddressHttp {
  requestv2: any;
  constructor(endPointv2 = APP_CONSTANT.API.ENDPOINTV2) {
    this.requestv2 = new HttpRequest(endPointv2).request;
  }

  public getProvinces = (): Promise<any> => {
    return this.requestv2.post(`${AddressApi.GET_PROVINCES}`);
  };

  public getDistricts = ({ province_id }): Promise<any> => {
    return this.requestv2.post(`${AddressApi.GET_DISTRICTS}`, { province_id });
  };

  public getWards = ({ province_id, district_id, page_id, per_page }): Promise<any> => {
    return this.requestv2.post(`${AddressApi.GET_WARDS}`, { province_id, district_id, page_id, per_page });
  };
  
}
