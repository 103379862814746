import { HttpRequest } from 'services/api';
import { APP_CONSTANT } from '_constants/default.constants';
import { CreateInitiativeApi } from '../constants/initiativeAchievement.constants';

export class InitiativeAchievementHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getListInitiative = ({
    pageId,
    perPage,
    courseId = 0,
    statusPermission = 0,
    gradeId = 0,
    key_search = ''
  }): Promise<any> => {
    return this.request.get(
      //`${CreateInitiativeApi.LIST_INITIATIVE}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&ma_cong_chuc=${keyword}&id_num=${keyword}&name=${keyword}&user_fullname=${keyword}`
      `${CreateInitiativeApi.LIST_INITIATIVE}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&grade_id=${gradeId}&status_permission=${statusPermission}&key_search=${key_search}`
    );
  };

  public getListInitiativeSo = ({
    pageId,
    perPage,
    courseId = 0,
    statusPermission = 0,
    key_search = '',
    gradeId = 0
  }): Promise<any> => {
    return this.request.get(
      //`${CreateInitiativeApi.LIST_INITIATIVE}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&ma_cong_chuc=${keyword}&id_num=${keyword}&name=${keyword}&user_fullname=${keyword}`
      `${CreateInitiativeApi.LIST_INITIATIVE}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&status_permission=${statusPermission}&key_search=${key_search}&grade_id=${gradeId}`
    );
  };

  public deleteInitiative = (id): Promise<any> => {
    return this.request.delete(`${CreateInitiativeApi.LIST_INITIATIVE}/${id}`);
  };

  public getListEffectScope = ({ pageId, perPage }): Promise<any> => {
    return this.request.get(
      `${CreateInitiativeApi.LIST_EFFECT_SCOPE}?page_id=${pageId}&per_page=${perPage}`
    );
  };

  public addInitiative = ({
    title,
    courseId,
    description,
    effect_scope_id,
    files,
    score,
    phan_loai,
    decision_no = '',
    decision_date = ''
  }): Promise<any> => {
    return this.request.post(CreateInitiativeApi.ADD_INITIATIVE, {
      name: title,
      course_id: courseId,
      description: description,
      effect_scope_id: effect_scope_id,
      files: files,
      score: score,
      phan_loai: phan_loai,
      decision_no: decision_no,
      decision_date: decision_date
    });
  };

  public addInitiativeUser = (data): Promise<any> => {
    return this.request.post(CreateInitiativeApi.ADD_INITIATIVE, data);
  };

  // public deleteInitiative = ({ id }): Promise<any> => {
  //   return this.request.delete(`${CreateInitiativeApi.DELETE_INITIATIVE}/${id}`);
  // };

  public detailInitiative = (id): Promise<any> => {
    return this.request.get(`${CreateInitiativeApi.DETAIL_INITIATIVE}/${id}`);
  };

  public updateInitiative = ({
    title,
    courseId,
    description,
    effect_scope_id,
    files,
    score,
    initiativeId,
    decision_no = '',
    decision_date = ''
  }): Promise<any> => {
    return this.request.patch(CreateInitiativeApi.UPDATE_INITIATIVE + '/' + initiativeId, {
      name: title,
      course_id: courseId,
      description: description,
      effect_scope_id: effect_scope_id,
      files: files,
      score: score,
      evaluation_initiative_id: initiativeId,
      decision_no: decision_no,
      decision_date: decision_date
    });
  };

  public updateInitiativeTeacher = (data, initiativeId): Promise<any> => {
    return this.request.patch(CreateInitiativeApi.UPDATE_INITIATIVE + '/' + initiativeId, data);
  };

  public uploadFileCertificates = (data, initiativeId): Promise<any> => {
    return this.request.patch(
      CreateInitiativeApi.UPDATE_INITIATIVE + '/certificates/' + initiativeId,
      data
    );
  };

  public sendInitiativesToSo = ({
    courseId,
    action,
    ma_cong_chuc,
    id_num,
    name,
    user_fullname,
    page_id,
    per_page
  }): Promise<any> => {
    return this.request.post(CreateInitiativeApi.SEND_INITIATIVE_TO_SO, {
      course_id: courseId,
      ma_cong_chuc,
      id_num,
      name,
      user_fullname,
      action,
      page_id,
      per_page
    });
  };

  public sendInitiativesScoreToSo = (data): Promise<any> => {
    return this.request.post(CreateInitiativeApi.SEND_INITIATIVE_SCORE_TO_SO, data);
  };

  public getEvaluationInitiativesSo = ({
    pageId,
    perPage,
    courseId = 0,
    key_search = '',
    school_id = 0
  }): Promise<any> => {
    return this.request.get(
      `${CreateInitiativeApi.EVALUATION_INITIATIVE_SO}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&key_search=${key_search}&school_id=${school_id}`
    );
  };

  public downloadEvaluationInitiativesSo = ({
    pageId,
    perPage,
    courseId = 0,
    key_search = '',
    school_id = 0,
    gradeId = 0
  }): Promise<any> => {
    return this.request.get(
      `${CreateInitiativeApi.EVALUATION_INITIATIVE_SO}/by_grade?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&key_search=${key_search}&school_id=${school_id}&grade_id=${gradeId}`
    );
  };

  public approveEvaluationInitiativeSo = (request, id = ''): Promise<any> => {
    return this.request.post(
      CreateInitiativeApi.APPROVE_EVALUATION_INITIATIVE_SO + '/' + id,
      request
    );
  };

  public postRoleScore = (request): Promise<any> => {
    return this.request.post(CreateInitiativeApi.URL_ROLES_SCORE, request);
  };

  public getDataState = (state: any) => {
    return new Promise((resolve, reject) => {
      resolve({
        ok: true,
        data: state
      });
    });
  };

  public exportFile = (school_id = '0'): Promise<any> => {
    return this.request.get(
      CreateInitiativeApi.URL_EXPORT_FILE_EVALUATION_INITIATIVE_SO +
        '?school_id=' +
        school_id +
        '&page_id=1&per_page=-1'
    );
  };

  public exportFileSchool = (): Promise<any> => {
    return this.request.get(
      CreateInitiativeApi.URL_EXPORT_FILE_EVALUATION_INITIATIVE_SO + '?page_id=1&per_page=1'
    );
  };

  public evaluationInitiativeDecisionNo = ({
    pageId,
    perPage,
    courseId = 0,
    pgdId = 0
  }): Promise<any> => {
    return this.request.get(
      `${CreateInitiativeApi.EVALUATION_DECISIONS_NO_INITIATIVE}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&pgd_id=${pgdId}`
    );
  };

  public publishGrade28 = (data): Promise<any> => {
    return this.request.post(CreateInitiativeApi.PUBLISH_GRADE_28, data);
  };

  // PGD quận 3
  public getEvaluationInitiativesPgd = ({
    pageId,
    perPage,
    courseId = 0,
    key_search = '',
    school_id = 0
  }): Promise<any> => {
    return this.request.get(
      `${CreateInitiativeApi.EVALUATION_INITIATIVE_PGD}?page_id=${pageId}&per_page=${perPage}&course_id=${courseId}&key_search=${key_search}&school_id=${school_id}`
    );
  };
}
